import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import { AdminMyCoupons, B2BplansTabPanel } from './PlansAndCouponsTabPanel';

const PlansAndCouponsTabs = () => {
const [value, setValue] = useState("retailplans");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
        <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box>
            <Tabs onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={
                  value === "retailplans"
                    ? {
                        backgroundColor: "#2D256C",
                        width: "130px",
                        borderRadius: "5px 0px 0px 5px",
                        color: "white",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "white",
                        width: "130px",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "5px 0px 0px 5px",
                        color: "black",
                        minHeight: "40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                }
                label="Retail Plans"
                value="retailplans"
              />
              <Tab
                sx={
                  value === "b2bplans"
                  ? {
                    backgroundColor: "#2D256C",
                    width: "130px",
                    borderRadius: "0px 0px 0px 0px",
                    color: "white",
                    minHeight:"40px",
                    fontSize:"12px",
                    fontWeight:600
                  }
                : {
                    backgroundColor: "white",
                    width: "130px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "0px 0px 0px 0px",
                    color: "black",
                    minHeight:"40px",
                    fontSize:"12px",
                    fontWeight:600
                  }
                }
                label="B2B Plans"
                value="b2bplans"
              />
              <Tab
                sx={
                  value === "coupons"
                  ? {
                    backgroundColor: "#2D256C",
                    width: "130px",
                    borderRadius: "0px 5px 5px 0px",
                    color: "white",
                    minHeight:"40px",
                    fontSize:"12px",
                    fontWeight:600
                  }
                : {
                    backgroundColor: "white",
                    width: "130px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "0px 5px 5px 0px",
                    color: "black",
                    minHeight:"40px",
                    fontSize:"12px",
                    fontWeight:600
                  }
                }
                label="My Coupons"
                value="coupons"
              />
              
            </Tabs>
          </Box>
          <TabPanel value="retailplans">
            
          </TabPanel>
          <TabPanel value="b2bplans">
            <B2BplansTabPanel/>
          </TabPanel>
          <TabPanel value="coupons">
            <AdminMyCoupons/>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default PlansAndCouponsTabs