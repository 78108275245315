import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../styles/MyCoupons.scss";
import { getCouponDetails } from "../../services/PlansAndProcedure";
import Loader from './../Loader';
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const CouponDetails = () => {
  const { id } = useParams();
  console.log(id);
  const navigate=useNavigate()
  const [values, setValues] = useState();
  const {permissionsData}=useContext(Permissions);
  let couponsPermissionsModule=permissionsData?.filter(item=>item.module==="coupons");
  let couponsPermissions=couponsPermissionsModule && couponsPermissionsModule[0]?.permissions;
  let CouponHistoryPermission = couponsPermissions?.find((item) => item.action === "Coupon History")?.permission;

  useEffect(() => {
    const getCoupon = async () => {
      const { data } = await getCouponDetails(id);
      if (data) {
        setValues(data);
      }
    };

    getCoupon();
  }, []);

  function formatDate(input) {
    if(!input) return
    // var date = new Date(input);
    // // Months use 0 index.
    //     return  date.getDate() + '/' + parseInt(date.getMonth()) + 1 + '/' + date.getFullYear();

    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  }

  

  function tConvert(time24) {
    if(!time24) return
    console.log('time24: ', time24);

    var ts = time24;
    console.log('ts: ', ts);
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    console.log('ts: ', ts);
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  if(!values){
    <Loader/>
  }

  return (
    <div>
      <Card sx={{boxShadow:"none",border:"0.5px solid #D9D9D9"}}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Box
              sx={{
                border: "0.5px solid #D9D9D9",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box className="couponcodeheading">
                <Typography >{values?.couponCode}</Typography>
                {CouponHistoryPermission && <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          borderRadius:"3px",
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#D83F87!important" },
                        }}
                        size="small"
                        onClick={()=>navigate(`/user/couponhistory/${values.couponCode}`)}
                      >
                        
                        History
                      </Button>}
                {/* <VisibilityIcon className="visibilityicon" /> */}
              </Box>
              <div className="plandesktopbody" style={{ padding: 5 }}>
                <Typography variant="caption">{values?.description}</Typography>
                
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#D83F87",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Speciality Covered
                </Typography>
                <Grid container spacing={1}>
                  {values?.speciality.map((item, i) => (
                    <Grid item xs={3} key={i}>
                      <Box sx={{ textAlign: "center", width: "90%" }}>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            background: "#FFEFF9",
                            padding: "5px",
                            color: "gray",
                            borderRadius: "3px",
                            mt:"10px"
                          }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                <Grid
                  container
                  spacing={2}
                    sx={{ mt: "10px"}}
                >
                  {[
                    { title: "No. Of Usage", value: values?.noOfUsagePerUser },
                    { title: "MOQ", value: values?.moq+" ₹" },
                    {
                      title: "Start Date",
                      value: formatDate(values?.startDate),
                    },
                    { title: "Start Time", value: tConvert(values?.startTime) },
                    { title: "End Date", value: formatDate(values?.endDate) },
                    { title: "End Time", value: tConvert(values?.endTime) },
                    {
                      title: "Maximum Discount",
                      value:
                        values?.consultationChargeMaxDiscount &&
                        values?.restOfTheBillMaxDiscount
                          ? (parseInt(values?.consultationChargeMaxDiscount) +
                            parseInt(values?.restOfTheBillMaxDiscount))+" ₹"
                          : values?.consultationChargeMaxDiscount
                          ? values?.consultationChargeMaxDiscount+" ₹"
                          : values?.restOfTheBillMaxDiscount
                          ? values?.restOfTheBillMaxDiscount+" ₹"
                          : values?.totalBillMaxDiscount
                          ? values?.totalBillMaxDiscount+" ₹"
                          : "",
                    },
                    {
                      title:values?.restOfTheBillPlanAmtPer?"Rest Of The Bill Discount Percentage":"",
                      value:values?.restOfTheBillPlanAmtPer?values?.restOfTheBillPlanAmtPer+" %":""
                    },
                    {
                      title:values?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":"",
                      value:values?.consultationChargePlanAmtPer?values?.consultationChargePlanAmtPer+" %":""
                    },
                    {
                      title:values?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                      value:values?.totalBillPlanAmtPer? values?.totalBillPlanAmtPer+" %":""
                    },
                    {
                      title:"Fixed Amount",
                      value:values?.consultationChargePlanAmounttVal?values?.consultationChargePlanAmounttVal+" ₹":values?.restOfTheBillPlanAmounttVal?values.restOfTheBillPlanAmounttVal+" ₹":values?.totalBillPlanAmounttVal?values?.totalBillPlanAmounttVal+" ₹":"None"
                    }
                  ].filter((e)=>e.title && e.value).map((item, i) => (
                    <Grid key={i} item md={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {item.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </div>

              {/* --------------------------------plan mobile body-------------------- */}
              <div className="planmobilebody" style={{ padding: 5 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: "10px"}}
                >
                  {[
                    { title: "No. Of Usage", value: values?.noOfUsagePerUser },
                    { title: "MOQ", value: values?.moq+" ₹" },
                    {
                      title: "Start Date",
                      value: formatDate(values?.startDate),
                    },
                    { title: "Start Time", value: tConvert(values?.startTime) },
                    { title: "End Date", value: formatDate(values?.endDate) },
                    { title: "End Time", value: tConvert(values?.endTime) },
                    {
                      title: "Maximum Discount",
                      value:
                        values?.consultationChargeMaxDiscount &&
                        values?.restOfTheBillMaxDiscount
                          ? (parseInt(values?.consultationChargeMaxDiscount) +
                            parseInt(values?.restOfTheBillMaxDiscount))+" ₹"
                          : values?.consultationChargeMaxDiscount
                          ? values?.consultationChargeMaxDiscount+" ₹"
                          : values?.restOfTheBillMaxDiscount
                          ? values?.restOfTheBillMaxDiscount+" ₹"
                          : values?.totalBillMaxDiscount
                          ? values?.totalBillMaxDiscount+" ₹"
                          : "",
                    },
                    {
                      title:values?.restOfTheBillPlanAmtPer?"Rest Of The Bill Discount Percentage":"",
                      value:values?.restOfTheBillPlanAmtPer?values?.restOfTheBillPlanAmtPer+" %":""
                    },
                    {
                      title:values?.consultationChargePlanAmtPer ? "Consultation Discount Percentage":"",
                      value:values?.consultationChargePlanAmtPer?values?.consultationChargePlanAmtPer+" %":""
                    },
                    {
                      title:values?.totalBillPlanAmtPer? "Total Bill Discount Percentage":"",
                      value:values?.totalBillPlanAmtPer? values?.totalBillPlanAmtPer+" %":""
                    },
                    {
                      title:"Fixed Amount",
                      value:values?.consultationChargePlanAmounttVal?values?.consultationChargePlanAmounttVal+" ₹":values?.restOfTheBillPlanAmounttVal?values.restOfTheBillPlanAmounttVal+" ₹":values?.totalBillPlanAmounttVal?values?.totalBillPlanAmounttVal+" ₹":"None"
                    }
                  ].filter((e)=>e.title && e.value).map((item, i) => (
                    <Grid key={i} item  xs={6}>
                      {console.log("titles",item)}
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                        className="title"
                      >
                        {" "}
                        {item.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                        className="value"
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#D83F87",
                      fontWeight: "bold",
                      fontSize: "12px",
                      mt: "10px",
                    }}
                    className="specialitycovered"
                  >
                    Speciality Covered
                  </Typography>

                  <br />
                  <Grid container spacing={1}>
                  {values?.speciality.map((item, i) => (
                    <Grid item xs={3} key={i}>
                      <Box sx={{ textAlign: "center", width: "90%" }}>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            background: "#FFEFF9",
                            color: "gray",
                            borderRadius: "3px",
                            padding: "5px 10px",
                            width:"100%"
                          }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                </Box>
              </div>
            </Box>
          </Grid>

          {/* ----------------------right grid---------------- */}
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                border: "0.5px solid #D9D9D9",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#2D256C",
                  borderRadius: "8px 8px 0px 0px",
                  boxShadow: "none",
                  fontSize: "14px",
                  padding: "1.5%",
                  fontWeight: "bold",
                }}
                className="benifits"
              >
                Procedures
              </div>
              <br />
              <div style={{ padding: 5 }}>
                {values?.procedures.map((item, i) => (
                  <Typography fontSize="14px" >{i+1} {item}</Typography>
                ))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CouponDetails;
