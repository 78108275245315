import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CssTextField } from "../../styles/textFields";
import { generateOtp, verifyOtp } from "../../services/messages";
import { useState } from "react";
import {
  generateOtpToBuyPlan,
  getB2CCustomerDetailsForNumber,
} from "../../services/login";
import { toast } from "react-toastify";

const Verification = ({
  selected,
  setSelected,
  number,
  setNumber,
  oTP,
  setOTP,
  setHealth,
  setFamilyInfo,
}) => {
  const [otpSent, setOTPSent] = useState(false);
  const [isSubmitting,setIsSubmitting]=useState(false);
  const [otpVerified, setOTPVerified] = useState(false);

  const error = () => {
    toast.error("This Number is already enrolled", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  async function checkOTP() {
    const { data } = await verifyOtp(oTP, number);
    setOTPVerified(data);
  }
  async function sendOTP() {
    setIsSubmitting(true)
    const { data } = await generateOtpToBuyPlan(number);
    if(data){
      setOTPSent(data.type);
      toast.success("OTP Sent Sucessfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
      setIsSubmitting(false)
      toast.error("Some Error Occoured, Please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const { data: val } = await getB2CCustomerDetailsForNumber(number);
    console.log("val: ", val.response);
    if (val?.response) {
      setHealth(val?.b2cCustomerMemberDetailsRequest);
      setFamilyInfo(val?.b2cCustomerMemberDetailsRequest);
    }
  }

  return (
    <Card sx={{ minHeight: "300px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          {" "}
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Enter Mobile Number
          </Typography>
          <div>
            <CssTextField
              fullWidth
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              size="small"
              placeholder="Enter Mobile Number"
              variant="outlined"
              type="number"
            />
          </div>
          <div>
            <Typography variant="caption" sx={{ color: "green" }}>
              {otpSent === "success" && "OTP Sent Sucessfully"}
            </Typography>{" "}
          </div>
        </Grid>

        <Grid item xs={12} lg={3}>
          {" "}
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            sx={{
              width: "15em",
              backgroundColor: isSubmitting?"#898989":"#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              marginLeft: "10px",
              marginTop: "36px",
            }}
            onClick={() => {
              sendOTP();
            }}
          >
            Send OTP
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Enter OTP
          </Typography>
          <CssTextField
            fullWidth
            value={oTP}
            onChange={(e) => {
              setOTP(e.target.value);
            }}
            size="small"
            placeholder="Enter OTP"
            variant="outlined"
            type="number"
          />{" "}
          <div>
            {" "}
            <div>
              <Typography
                variant="caption"
                sx={{ color: otpVerified.type === "success" ? "green" : "red" }}
              >
                {otpVerified.message}
              </Typography>{" "}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          {" "}
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              marginLeft: "10px",
              marginTop: "36px",
            }}
            onClick={() => {
              checkOTP()();
            }}
          >
            Check OTP
          </Button>
        </Grid>
      </Grid>

      <br />
      <br />
      <Button
        variant="contained"
        type="submit"
        sx={{
          width: "15em",
          backgroundColor: "#2d256c!important",
          fontSize: "12px",
          padding: "8px 15px",
          marginLeft: "10px",
        }}
        onClick={() => otpVerified.type === "success" && setSelected("Plans")}
      >
        Next
      </Button>
    </Card>
  );
};

export default Verification;
