import React from "react";
import "../styles/Terms_Conditions.scss";

const Terms_Conditions = () => {
  return (
    <div>
      <div className="terms_conditions_box">
        <h3>
          <u>MOST IMPORTANT TERMS AND CONDITIONS</u>
        </h3>
          <br/>
        <div>
          <table>
            <tr>
              <td>Service Fees/Commission on patient referral</td>
              <td>20% of total treatment value</td>
            </tr>
            <tr>
              <td>Consultation Fees</td>
              <td>Waived off where treatment is prescribed</td>
            </tr>
            <tr>
              <td>Discount on services </td>
              <td>
                May be offered by the clinics from time to time as per their
                wish
              </td>
            </tr>
            <tr>
              <td>Cap on treatment cost</td>
              <td>Clinics cannot charge more than the usual rates</td>
            </tr>
            <tr>
              <td>Payment gateway charges</td>
              <td>
                Included in the 20% commission for Tron customers, For others,
                2.5%
              </td>
            </tr>
            <tr>
              <td>Financing Charges</td>
              <td>
                Where No cost EMI is extended to the customers at the clinic
                through Tron payment portal, the subvention cost will be ~7.5%
                of the total treatment value, which will be deducted by the NBFC
                offering finance options. The same will have to be borne by the
                clinic or accordingly passed on to the patient by the clinic.
                However, the amount received in such a case will be less 7.5% of
                the subvention cost.
              </td>
            </tr>
            <tr>
              <td>Clinic Digital Branding (optional)</td>
              <td>
                Rs. 1,000 per annum (To include Google MyBusiness geotagging,
                GMB based SEO)
              </td>
            </tr>
            <tr>
              <td colspan="2">
                All payments to be either made online or through cheque in the
                name of the company “Tron Healthcare Private Limited”, Bank a/c
                No. 777705…….., IFSC ICIC0006745
              </td>
            </tr>
            <tr>
              <td colspan="2">
                All standard taxes will be applicable. GST inputs where required
                may be intimated to the company along with the GST number of the
                clinic.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Terms_Conditions;
