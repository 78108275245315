import http from "./httpServices";
import config from "../config.json";


export function generateOtp(mobile){
    return http.get(config.apiUrl+"generateOtp/"+mobile);
}

export function verifyOtp(otp, mobile){
    return http.get(config.apiUrl+"verifyOtp/"+otp+"/91"+mobile);
}
