import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import PublishIcon from "@mui/icons-material/Publish";
import "../../styles/StartAppointment.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { CssTextField } from "../../styles/textFields";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClinicComplaints,
  getClinicProcedure,
} from "../../services/PlansAndProcedure";
import AppointmentBillingFirst from "./AppointmentBillingFirst";
import AppointmentBillingSecond from "./AppointmentBillingSecond";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import { getPreviousAppointments } from "../../services/clinicOnboarding";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const StartAppointment = () => {
  const [open, setOpen] = useState(false);
  const { speciality, appointmentId, customeruniqueId } = useParams();
  const [complaints, setComplaints] = useState([]);
  const [selectedComplaints, setSelectedComplaints] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedProcedureData, setSelectedProcedureData] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [previousAppointments, setPreviousAppointments] = useState({});
  const [consultationCharges, setConsultationCharges] = useState();
  const [discount, setDiscount] = useState();
  const [plan, setPlan] = useState();
  const [totalAmountToPay, setTotalAmountToPay] = useState();
  const [steps, setSteps] = useState(0);
  // console.log(speciality, appointmentId, customeruniqueId);
  const navigate = useNavigate();
  const [otherCharges, setOtherCharges] = useState([{ name: "", charges: 0 }]);
  const { permissionsData } = useContext(Permissions);
  const token = JSON.parse(localStorage.getItem("token"));
  const clinicUniqueId = token?.uniqueId;
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions = clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let BillingPermission = clinicPermissions?.find(
    (item) => item.action === "Billing"
  )?.permission;

  // console.log(speciality, appointmentId);

  const handleComplaintsCheckboxChange = (event, complaint) => {
    if (event.target.checked) {
      setSelectedComplaints((prevSelectedComplaints) => [
        ...prevSelectedComplaints,
        complaint,
      ]);
    } else {
      setSelectedComplaints((prevSelectedComplaints) =>
        prevSelectedComplaints.filter((selected) => selected !== complaint)
      );
    }
  };
  // console.log("selected complaints", selectedComplaints);

  const handleProceduresCheckboxChange = (event, procedure) => {
    if (event.target.checked) {
      const newSelectedProcedures = [...selectedProcedures, procedure];

      setSelectedProcedures(newSelectedProcedures);

      const newSelectedComplaintsData = [
        ...selectedProcedureData,
        { name: procedure, charges: "" },
      ];

      setSelectedProcedureData(newSelectedComplaintsData);
    } else {
      setSelectedProcedures((prevSelectedProcedures) =>
        prevSelectedProcedures.filter((selected) => selected !== procedure)
      );
      setSelectedProcedureData((prevSelectedProcedureData) =>
        prevSelectedProcedureData.filter(
          (proedureData) => proedureData.name !== procedure
        )
      );
    }
  };
  // console.log("selected procedures", selectedProcedures);
  // console.log("selectedProcedures Data", selectedProcedureData);

  function splitComplaintsArrays(complaints) {
    const length = complaints.length;
    const midIndex = Math.ceil(length / 2);
    const leftSideComplaints = complaints.slice(0, midIndex);
    const rightSideComplaints = complaints.slice(midIndex);
    return [leftSideComplaints, rightSideComplaints];
  }

  const [leftSideComplaints, rightSideComplaints] =
    splitComplaintsArrays(complaints);
  // console.log("leftsidecomplaints", leftSideComplaints);
  // console.log("rightsidecomplaints", rightSideComplaints);

  function splitProcedureArrays(procedures) {
    const length = procedures.length;
    const midIndex = Math.ceil(length / 2);
    const leftSideProcedures = procedures.slice(0, midIndex);
    const rightSideProcedures = procedures.slice(midIndex);
    return [leftSideProcedures, rightSideProcedures];
  }

  const [leftSideProcedures, rightSideProcedures] =
    splitProcedureArrays(procedures);
  // console.log("leftsideprocedures", leftSideProcedures);
  // console.log("rightsideprocedures", rightSideProcedures);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getComplaintsAndProcedure = async () => {
    try {
      let response1 = await getClinicComplaints(speciality);
      let response2 = await getClinicProcedure(speciality);
      let complaintData = response1.data;
      let procedureData = response2.data;
      setComplaints(complaintData);
      setProcedures(procedureData);
      console.log(response1, response2);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getPreviousAppointmentsData = async () => {
    try {
      const res = await getPreviousAppointments(clinicUniqueId, appointmentId);
      setPreviousAppointments(res.data);
      console.log("prev appointments", res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      await getComplaintsAndProcedure();
      if (appointmentId) {
        await getPreviousAppointmentsData();
      }
    };
    fetchData();
  }, [appointmentId]);
  return (
    <>
      {steps === 0 && (
        <div className="entirebox">
          <Typography className="treatmentplan" variant="h5">
            Treatment Plan
          </Typography>
          <Card className="maincard desktopcard">
            <Card className="leftcard">
              <Box className="leftcardhead">
                <Typography variant="h5">Appointment ID</Typography>
                <Typography variant="h4">{appointmentId}</Typography>
              </Box>
              <Box className="leftcardbody">
                <Typography variant="h5" sx={{ pl: "10px" }}>
                  Chief Complaints
                </Typography>
                <form className="form">
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={6} sm={6} className="leftside">
                      <FormGroup>
                        {leftSideComplaints?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  size="small"
                                  defaultChecked={selectedComplaints?.includes(
                                    el.complaint
                                  )}
                                  onChange={(event) =>
                                    handleComplaintsCheckboxChange(
                                      event,
                                      el.complaint
                                    )
                                  }
                                />
                              }
                              label={`${el.complaint}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6} className="rightside">
                      <FormGroup>
                        {rightSideComplaints?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  size="small"
                                  defaultChecked={selectedComplaints?.includes(
                                    el.complaint
                                  )}
                                  onChange={(event) =>
                                    handleComplaintsCheckboxChange(
                                      event,
                                      el.complaint
                                    )
                                  }
                                />
                              }
                              label={`${el.complaint}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                    {/* <Typography
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    backgroundColor: "#F5F5F5",
                  }}
                  onChange={(e) => {
                    console.log("e: ", e);
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <PublishIcon sx={{ fontSize: "15px", color: "#B5B5B5" }} />{" "}
                    <br />
                    <span style={{ fontSize: "8px", color: "#B5B5B5" }}>
                      Upload Related Documents/Images
                    </span>
                  </Box>

                  <input
                    accept="image/*"
                    id="icon-button-photo"
                    // capture="user"
                    type="file"
                    hidden
                  />
                </Typography> */}
                  </Stack>

                  <Typography variant="h5">Investigation</Typography>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                    {/* <Typography
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    backgroundColor: "#F5F5F5",
                  }}
                  onChange={(e) => {
                    console.log("e: ", e);
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <PublishIcon sx={{ fontSize: "15px", color: "#B5B5B5" }} />{" "}
                    <br />
                    <span style={{ fontSize: "8px", color: "#B5B5B5" }}>
                      Upload Related Documents/Images
                    </span>
                  </Box>

                  <input
                    accept="image/*"
                    id="icon-button-photo"
                    // capture="user"
                    type="file"
                    hidden
                  />
                </Typography> */}
                  </Stack>
                  <Typography variant="h5">Treatments</Typography>

                  <Grid container spacing={1} className="checkboxes">
                    <Grid item md={6} xs={6} sm={6} className="leftside">
                      <FormGroup>
                        {leftSideProcedures?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  defaultChecked={selectedProcedures?.includes(
                                    el.procedureName
                                  )}
                                  size="small"
                                  onChange={(event) =>
                                    handleProceduresCheckboxChange(
                                      event,
                                      el.procedureName
                                    )
                                  }
                                />
                              }
                              label={`${el.procedureName}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6} className="rightside">
                      <FormGroup>
                        {rightSideProcedures?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  size="small"
                                  defaultChecked={selectedProcedures?.includes(
                                    el.procedureName
                                  )}
                                  onChange={(event) =>
                                    handleProceduresCheckboxChange(
                                      event,
                                      el.procedureName
                                    )
                                  }
                                />
                              }
                              label={`${el.procedureName}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* <Box>
                <Typography variant="h5">Procedures</Typography>
                <Box sx={{ display: "flex", gap: "30px" }}>
                  <FormGroup>
                    {leftSideProcedures?.map((el, id) => {
                      return (
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "12px",
                            },
                          }}
                          required
                          control={<Checkbox size="small" />}
                          label={`${el.procedureName}`}
                        />
                      );
                    })}
                  </FormGroup>
                  <FormGroup>
                    {rightSideProcedures?.map((el, id) => {
                      return (
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "12px",
                            },
                          }}
                          required
                          control={<Checkbox size="small" />}
                          label={`${el.procedureName}`}
                        />
                      );
                    })}
                  </FormGroup>
                </Box>
              </Box> */}
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                    {/* <Typography
                  variant="contained"
                  component="label"
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    color: "#fff",
                    backgroundColor: "#F5F5F5",
                  }}
                  onChange={(e) => {
                    console.log("e: ", e);
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <PublishIcon sx={{ fontSize: "15px", color: "#B5B5B5" }} />{" "}
                    <br />
                    <span style={{ fontSize: "8px", color: "#B5B5B5" }}>
                      Upload Related Documents/Images
                    </span>
                  </Box>

                  <input
                    accept="image/*"
                    id="icon-button-photo"
                    // capture="user"
                    type="file"
                    hidden
                  />
                </Typography> */}
                  </Stack>
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      mt: "10px",
                    }}
                  >
                    {BillingPermission && (
                      <Button
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "center",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "12px",
                          padding: "10px",
                          borderRadius: "3px",
                          "&:hover": { backgroundColor: "#D83F87!important" },
                        }}
                        size="small"
                        onClick={() => {
                          setSteps(steps + 1);
                        }}
                      >
                        Proceed To Billing
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Card>
            <Card className="rightcard">
              <Box className="rightheadbox">
                <Box className="subbox1">
                  <Typography variant="h5">
                    {previousAppointments?.customerName}
                  </Typography>
                  <Typography variant="h4">
                    <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                    {previousAppointments?.customerNo}
                  </Typography>
                </Box>
              </Box>
              <Box className="rightbodybox">
                <Typography variant="h4">Previous Appointments</Typography>

                <>
                  {previousAppointments?.perviousAppointmentResponse
                    ?.filter(
                      (appointment) =>
                        appointment.appointmentStatus == "Completed"
                    )
                    ?.map((el, id) => {
                      return (
                        <Card
                          className="appointmentscard"
                          onClick={() =>
                            navigate(
                              `/user/pastappointmentinformation/${el.appointmentId}`
                            )
                          }
                        >
                          <Box className="cardhead">
                            <Typography variant="h5">
                              {el?.appointmentId}
                            </Typography>
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={handleOpen}
                            />
                          </Box>
                          <Box className="cardbodybox">
                            <Box className="datebox">
                              <CalendarMonthIcon />
                              <Typography variant="h4">
                                {formattedDate(el?.createdAt)}
                              </Typography>
                            </Box>
                            {/* <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              mt: "10px",
                              color: "#2D256C",
                            }}
                            variant="h4"
                          >
                            {el.clinicname}
                          </Typography> */}
                            <Box className="specialitybox">
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  backgroundColor: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                {el?.speciality}
                              </Typography>
                            </Box>
                            {/* <Box className="analysisbox">
                            <Typography>
                              <b>Doctor’s Analysis </b>
                              {el.doctoranalysis}
                            </Typography>
                          </Box> */}
                          </Box>
                        </Card>
                      );
                    })}
                </>
              </Box>
            </Card>
          </Card>

          {/* -----------------------------------Mobile screen--------------------------------- */}

          <Card className="maincard mobilecard">
            <Card className="bodycard">
              <Box className="cardhead">
                <Typography variant="h5">Appointment ID</Typography>
                <Typography variant="h4">{appointmentId}</Typography>
              </Box>
              <Accordion
                sx={{
                  boxShadow: "none",
                  borderBottom: "0.5px solid #D9D9D9",
                  borderRadius: "0px 0px 3px 3px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#D83F87" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="customernamebox"
                >
                  <Box>
                    <Box className="subbox1">
                      <Typography variant="h5">
                        {previousAppointments?.customerName}
                      </Typography>
                      <Typography variant="h4">
                        <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                        {previousAppointments?.customerNo}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="previousappointmentbox">
                    <Typography variant="h4">Previous Appointments</Typography>

                    <>
                      {previousAppointments?.perviousAppointmentResponse
                        ?.filter(
                          (appointment) =>
                            appointment.appointmentStatus == "Completed"
                        )
                        .map((el, id) => {
                          return (
                            <Card
                              className="appointmentscard"
                              onClick={() =>
                                navigate(
                                  `/user/pastappointmentinformation/${el.appointmentId}`
                                )
                              }
                            >
                              <Box className="cardhead">
                                <Typography variant="h5">
                                  {el?.appointmentId}
                                </Typography>
                                <VisibilityIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={handleOpen}
                                />
                              </Box>
                              <Box className="cardbodybox">
                                <Box className="datebox">
                                  <CalendarMonthIcon />
                                  <Typography variant="h4">
                                    {formattedDate(el?.createdAt)}
                                  </Typography>
                                </Box>
                                {/* <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              mt: "10px",
                              color: "#2D256C",
                            }}
                            variant="h4"
                          >
                            {el.clinicname}
                          </Typography> */}
                                <Box className="specialitybox">
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      backgroundColor: "#FFEFF9",
                                      padding: "5px",
                                      color: "gray",
                                      textAlign: "center",
                                    }}
                                  >
                                    {el?.speciality}
                                  </Typography>
                                </Box>
                                {/* <Box className="analysisbox">
                            <Typography>
                              <b>Doctor’s Analysis </b>
                              {el.doctoranalysis}
                            </Typography>
                          </Box> */}
                              </Box>
                            </Card>
                          );
                        })}
                    </>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box className="leftcardbody">
                <Typography variant="h5" sx={{ pl: "10px" }}>
                  Complaints
                </Typography>
                <form className="form">
                  <Box className="checkboxes">
                    <Box className="bodybox">
                      <FormGroup>
                        {complaints?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  size="small"
                                  defaultChecked={selectedComplaints?.includes(
                                    el.complaint
                                  )}
                                  onChange={(event) =>
                                    handleComplaintsCheckboxChange(
                                      event,
                                      el.complaint
                                    )
                                  }
                                />
                              }
                              label={`${el.complaint}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Box>
                  </Box>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                  </Stack>
                  <Typography variant="h5">Investigation</Typography>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                  </Stack>

                  <Typography variant="h5">Treatments</Typography>

                  <Box className="checkboxes">
                    <Box className="bodybox">
                      <FormGroup>
                        {procedures?.map((el, id) => {
                          return (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                              required
                              control={
                                <Checkbox
                                  defaultChecked={selectedProcedures?.includes(
                                    el.procedureName
                                  )}
                                  size="small"
                                  onChange={(event) =>
                                    handleProceduresCheckboxChange(
                                      event,
                                      el.procedureName
                                    )
                                  }
                                />
                              }
                              label={`${el.procedureName}`}
                            />
                          );
                        })}
                      </FormGroup>
                    </Box>
                  </Box>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", mt: "10px" }}
                  >
                    <TextField
                      size="large"
                      fullWidth
                      type="text"
                      //   sx={{height:"100px!important"}}
                    />
                  </Stack>
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      mt: "10px",
                    }}
                  >
                    {BillingPermission && (
                      <Button
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "center",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          padding: "10px",

                          "&:hover": { backgroundColor: "#D83F87!important" },
                        }}
                        size="small"
                        onClick={() => {
                          setSteps(steps + 1);
                        }}
                      >
                        Proceed To Billing
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Card>
          </Card>

          {/* --------------------------Modal------------------------ */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Modal>
        </div>
      )}
      {steps === 1 && (
        <AppointmentBillingFirst
          steps={steps}
          setSteps={setSteps}
          selectedProceduresData={selectedProcedureData}
          setSelectedProceduresData={setSelectedProcedureData}
          consultationCharges={consultationCharges}
          setConsultationCharges={setConsultationCharges}
          otherCharges={otherCharges}
          setOtherCharges={setOtherCharges}
        />
      )}
      {steps === 2 && (
        <AppointmentBillingSecond
          steps={steps}
          setSteps={setSteps}
          selectedProceduresData={selectedProcedureData}
          setSelectedProceduresData={setSelectedProcedureData}
          consultationCharges={consultationCharges}
          setConsultationCharges={setConsultationCharges}
          selectedProcedures={selectedProcedures}
          discount={discount}
          setDiscount={setDiscount}
          totalAmountToPay={totalAmountToPay}
          setTotalAmountToPay={setTotalAmountToPay}
          plan={plan}
          setPlan={setPlan}
          customerUniqueId={customeruniqueId}
          speciality={speciality}
          otherCharges={otherCharges}
          setOtherCharges={setOtherCharges}
          selectedComplaints={selectedComplaints}
        />
      )}
    </>
  );
};

export default StartAppointment;
