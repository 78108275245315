import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import Carousel from "react-multi-carousel";
import React, { useEffect, useState } from "react";
import "../../styles/clinicView.scss";
import { getParticularClinicData } from "../../services/clinicOnboarding";
import config from "../../config.json";
import moment from "moment";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const imagesresponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ClinicsClinicView = () => {
  let token = JSON.parse(localStorage.getItem("token") || []);
  let uniqueId = token.uniqueId;
  const [clinicData, setClinicData] = useState([]);
  const [clinicImages, setClinicImages] = useState([]);
  console.log(clinicData);
  console.log(clinicImages);

  const getClinicDetails = async () => {
    try {
      const { data } = await getParticularClinicData(uniqueId);
      setClinicData(data);
      setClinicImages(data.clinicImageRequest.filter((x) => x.image));
      // console.log(data.clinicImageRequest.filter((x)=>x.image))
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("YYYY");
    return formattedDob;
  };

  useEffect(() => {
    getClinicDetails();
  },[]);
  return (
    <Box>
      <Card className="clinicViewdetailsCard" variant="outlined">
        <Card variant="outlined">
          <Box>
            <Box className="clinicdetailsmainbox">
              <Box className="clinicleftbox">
                <Box className="clinicdetailsbox">
                  {clinicImages && (
                    <img
                      style={{ height: "150px" }}
                      src={config.imageUrl + clinicImages[0]?.image}
                      alt="clinicpic"
                    />
                  )}
                  <Box className="details">
                    {clinicData.initialValue_ClinicDetails && (
                      <Typography variant="h5">
                        {clinicData.initialValue_ClinicDetails.clinicName}
                      </Typography>
                    )}

                    <Box>
                      <LocationOnIcon />
                      {clinicData.initialValue_ClinicDetails && (
                        <Typography variant="h6">
                          {clinicData.initialValue_ClinicDetails.address}
                        </Typography>
                      )}
                    </Box>
                    <Typography>View Location</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="600" mt="10px" variant="h6">Our Speciality</Typography>
                  <Box sx={{ display: "flex", gap: "10px",mt:"10px" }}>
                    {clinicData.initialValue_ClinicDetails && (
                      <>
                        {clinicData.initialValue_ClinicDetails.clinicType.map(
                          (el, id) => {
                            return (
                              <Box
                                sx={{
                                  padding: "10px",
                                  background: "#FFEFF9",
                                  borderRadius: "10",
                                  fontSize: "10px",
                                }}
                              >
                                {el}
                              </Box>
                            );
                          }
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className="clinicrightbox">
                {clinicData.initialValue_ClinicDetails && (
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {clinicData.initialValue_ClinicDetails.ownerName}
                  </Typography>
                )}
                <Stack direction="row" spacing={2}>
                  <PhoneIcon />
                  {clinicData.initialValue_ClinicDetails && (
                    <Typography sx={{ fontSize: "14px" }}>
                      {clinicData.initialValue_ClinicDetails.number}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <MailIcon />
                  {clinicData.initialValue_ClinicDetails && (
                    <Typography sx={{ fontSize: "14px" }}>
                      {clinicData.initialValue_ClinicDetails.email}
                    </Typography>
                  )}
                </Stack>
                <Box className="financialdetailsbox">
                  <Typography sx={{ fontSize: "12px",fontWeight:"600",mb:"5px" }} variant="h6">
                    Financial Details
                  </Typography>
                  <Grid container spacing={3} >
                    <Grid item md={6} sm={6}>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Account Holder Name
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#444444",
                            fontWeight: 600,
                          }}
                        >
                          {clinicData.initialValue_FinanceDetails.accountHolder}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={6}>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Pan card No.
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#444444",
                            fontWeight: 600,
                          }}
                        >
                          {clinicData.initialValue_FinanceDetails.pan}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6} sm={6}>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Account No.
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#444444",
                            fontWeight: 600,
                          }}
                        >
                          {clinicData.initialValue_FinanceDetails.accountNumber}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={6}>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        IFSC Code
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#444444",
                            fontWeight: 600,
                          }}
                        >
                          {clinicData.initialValue_FinanceDetails.ifsc}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            {/* -------------------images---------------- */}
            <Box className="imagescarouselbox" sx={{ marginTop: "10px" }}>
              <Carousel
                id="carouselcontainer"
                swipeable={false}
                draggable={false}
                style={{ padding: "10px" }}
                showDots={true}
                responsive={imagesresponsive}
              >
                {clinicImages.map((el, i) => {
                  return (
                    <>
                      <img
                        style={{ height: "136px" }}
                        src={config.imageUrl + el.image}
                        alt={`clincimages${i}`}
                      />
                    </>
                  );
                })}
              </Carousel>
            </Box>
          </Box>
        </Card>
        <Box className="doctorscarouselbox">
          <Typography variant="h5">Our Experts</Typography>

          <Carousel
            className="carouselcontainer"
            swipeable={true}
            draggable={true}
            showDots={true}
            gap="10px"
            // autoPlay="false"
            autoPlaySpeed={1000}
            keyBoardControl={true}
            containerClass="react-multi-carousel-list"
            responsive={responsive}
            itemClass="item"
          >
            {clinicData.length === 0 ? (
              <>Loading...</>
            ) : (
              <Box>
                {clinicData.initialValues_DoctorDetails.map((el, id) => {
                  return (
                    <Card
                      sx={{
                        backgroundColor: "#F5F3F7",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                      variant="outlined"
                    >
                      <img
                        style={{ height: "130px" }}
                        src={config.imageUrl + el.image}
                        alt=""
                      />
                      <Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600,fontSize:"14px", color: "#2D256C" }}
                          >
                            Dr. {el.name}
                          </Typography>
                          <Typography
                            sx={{ color: "#000000", fontSize: "14px" }}
                          >
                            {el.specialisationAndDegree.map(
                              (specialization, id) => {
                                return <>{specialization.degree}</>;
                              }
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ color: "#959595",fontSize:"14px" }}>
                            {formattedDate(el.practiceSince)}{" "}
                            {/* <span style={{ color: "#8C86B7" }}>1998</span>{" "} */}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ color: "#D83F87", fontSize: "12px" }}
                          >
                            Specialization
                          </Typography>
                          <Typography
                            sx={{ color: "#444444", fontWeight: 600, fontSize:"14px" }}
                          >
                            {el.specialisationAndDegree.map(
                              (specialization, id) => {
                                return <>{specialization.specialisation}</>;
                              }
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  );
                })}
              </Box>
            )}
          </Carousel>
        </Box>
      </Card>

      {/* ----------------------------Clinicdetails Box Mobile View------------------------- */}
      <Box className="mobileClinicViewdetailsbox">
            <Box className="clinicimagesbox">
                <Box className="clinicmainimage">
                  {clinicImages && (
                    <img
                        src={config.imageUrl + clinicImages[0]?.image}
                        alt="clinicpic"
                    />
                  )}
                </Box>
                
                <Box className="subimages" >
                {clinicImages &&
                  clinicImages.slice(0,3).map((request, index) => {
                  return (
                    <Box className="imagebox" key={request.id}>
                      <img
                        src={config.imageUrl + request.image}
                        alt={`clinicImage${index}`}
                      />
                    </Box>
                  );
                  })}
                </Box>
            </Box>
            <Box className="clinicdetails">
                  <Box className="clinicnameAndAddress">
                    <Box className="details" >
                      <Box>
                      {clinicData.initialValue_ClinicDetails && (
                        <Typography variant="h5">
                            {clinicData.initialValue_ClinicDetails.clinicName}
                        </Typography>
                      )}
                      <Box className="address">
                        <LocationOnIcon />
                          {clinicData.initialValue_ClinicDetails && (
                            <Typography>
                                {clinicData.initialValue_ClinicDetails.address}
                            </Typography>
                          )}
                      </Box>
                      <Typography variant="h6">View Location</Typography>
                      </Box>
                    </Box>
                      <Box className="specilalitiesbtnbox">
                        {clinicData.initialValue_ClinicDetails &&
                          clinicData.initialValue_ClinicDetails.clinicType.map(
                            (speciality, id) => {
                              return <button>{speciality}</button>;
                            }
                          )}
                      </Box>
                  </Box>
            </Box>
            <Card className="clinicOwnerdetailsbox" >
            {clinicData.initialValue_ClinicDetails && (
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {clinicData.initialValue_ClinicDetails.ownerName}
                  </Typography>
                )}
                <Stack direction="row" spacing={2}>
                  <PhoneIcon sx={{fontSize:"14px"}}/>
                  {clinicData.initialValue_ClinicDetails && (
                    <Typography sx={{ fontSize: "12px" }}>
                      {clinicData.initialValue_ClinicDetails.number}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <MailIcon sx={{fontSize:"14px"}}/>
                  {clinicData.initialValue_ClinicDetails && (
                    <Typography sx={{ fontSize: "12px" }}>
                      {clinicData.initialValue_ClinicDetails.email}
                    </Typography>
                  )}
                </Stack>
                <Box className="financialdetailsbox">
                  <Typography sx={{ fontSize: "14px",fontWeight:"600" }} variant="h6">
                    Financial Details
                  </Typography>
                  <Stack direction="row" spacing={13}>
                    <Box>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Account Holder Name
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          className="accountdetails"
                        >
                          {clinicData.initialValue_FinanceDetails.accountHolder}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Pan card No.
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          className="accountdetails"
                        >
                          {clinicData.initialValue_FinanceDetails.pan}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing={14.5}>
                    <Box>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        Account No.
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          className="accountdetails"
                        >
                          {clinicData.initialValue_FinanceDetails.accountNumber}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "10px", color: "#D83F87" }}>
                        IFSC Code
                      </Typography>
                      {clinicData.initialValue_FinanceDetails && (
                        <Typography
                          className="accountdetails"
                        >
                          {clinicData.initialValue_FinanceDetails.ifsc}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Box>
            </Card>
            <Box className="doctordetailsbox">
            <Typography variant="h5">Our Experts</Typography>
            <Box className="doctordetailscard">
              {clinicData.initialValues_DoctorDetails &&
                clinicData.initialValues_DoctorDetails.map(
                  (el, id) => {
                    return (
                      <Card
                        sx={{
                          backgroundColor: "#FFF6FF",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        variant="outlined"
                      >
                        <img
                          style={{ height: "100px" }}
                          src={config.imageUrl + el.image}
                          alt="doctorimage"
                        />
                        <Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, color: "#2D256C" ,fontSize:"12px"}}
                            >
                              Dr. {el.name}
                            </Typography>
                            <Typography
                              sx={{ color: "#000000", fontSize: "12px" }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.degree}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#959595",fontSize:"10px" }}>
                              {formattedDate(el.practiceSince)}{" "}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ color: "#D83F87", fontSize: "12px" }}
                            >
                              Specialization
                            </Typography>
                            <Typography
                              sx={{ color: "#444444", fontWeight: 600,fontSize:"12px" }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.specialisation}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    );
                  }
                )}
            </Box>
            </Box>
      </Box>
    </Box>
  );
};

export default ClinicsClinicView;
