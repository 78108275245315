import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {
  CheckingExistingCustomer,
  getAvailableTimeSlots,
  getCancelledAppointmentRequestData,
  getCustomerCancelledReqWithDate,
  getCustomerPastAppointmentRequest,
  getCustomerPastAppointmentWithDate,
  getCustomerUpcomingReqWithDate,
  getTodayAppointmentRequestData,
  getUpcomingAppointmentRequestData,
  rescheduleAppointment,
} from "../../services/customerOnboarding";
import { toast } from "react-toastify";
import { PostAcceptOrRejectAppointment } from "../../services/clinicOnboarding";
import RescheduleModal from "./RescheduleModal";
import Loader from "../Loader";
import "../../styles/Tables.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import moment from "moment";

function createData(slno, clinicname, date, time, city, action) {
  return {
    slno,
    clinicname,
    date,
    time,
    city,
    action,
  };
}
const rows = [
  createData(
    "1",
    "HRD Dental Clinic",
    "20 March, 2023",
    "11 AM - 12 PM",
    "Dehradun",
    <RemoveRedEyeIcon />
  ),
  createData(
    "2",
    "Thapar Dental Clinic",
    "20 March, 2023",
    "1 PM - 3 PM",
    "Dehradun",
    <RemoveRedEyeIcon />
  ),
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F3F7",
  border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

const phoneRegExp = /^[6-9]\d{9}$/;
const todayschema = yup
  .object({
    name: yup.string().required(),
    phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().required("You must select an option!"),
    datevalid: yup.date().required("This Field is required"),
    time: yup.string().required("This Field Is Required"),
  })
  .required();

const TodayTable = () => {
  const [todayOpen, setTodayOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [todayData, setTodayData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [dailyBasis, setDailybasis] = useState("");
  const [acceptReject, setAcceptReject] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.username;
  const uniqueId = token?.uniqueId;
  const clinicuniqueId = modalData?.clinicUniqueId;
  const [existingCustomerUniqueId, setExistingCustomerUniqueId] = useState("");
  // console.log("clinicuniqueId...", clinicuniqueId);
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  let TodayPermission = clinicPermissions?.find(
    (item) => item.action === "Today"
  )?.permission;
  let ReschedulePermission = clinicPermissions?.find(
    (item) => item.action === "Reschedule Appointment"
  )?.permission;
  // let ReschedulePermission=false
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;
  console.log("clinicpermissions", TodayPermission);

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const handleTodayModalOpen = (el) => {
    reset();
    console.log("element id......", el);
    setTodayOpen(true);
    setModalData(el);
    handleExistingCustomer();
  };

  const handleExistingCustomer = async () => {
    try {
      const res = await CheckingExistingCustomer(modalData?.patientNumber);
      const data = res.data;
      if (data) {
        // console.log("uniqueid",data.uniqueId);
        // console.log("existing customer data",data);
        setExistingCustomerUniqueId(data.uniqueId);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };

  const handleTodayClose = () => {
    setTodayOpen(false);
    setReschedule(false);
  };

  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;
  console.log("entiredate..", entiredate);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(todayschema),
  });

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}:00`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        id: modalData.id,
        patientName: data.name,
        patientNumber: data.phonenumber,
        clinicUniqueId: clinicuniqueId,
        customerUniqueId: existingCustomerUniqueId,
        specialty: data.speciality,
        slot: data.slot,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remark,
        createdBy: token.username,
      };
      console.log("formdata........", formdata);
      try {
        await rescheduleAppointment(formdata);
        reset();
        handleTodayClose();
        toast.success("Successfully Rescheduled Appointment", {
          position: "top-right",
        });
        setReschedule(true);
      } catch (err) {
        console.log(err.message);
        toast.error("Something went wrong Check Server", {
          position: "top-right",
        });
      }
    }
  };

  const getTodayRequestData = async () => {
    try {
      const response = await getTodayAppointmentRequestData(uniqueId);
      console.log("response....", response.data);
      let data = response.data;
      const sortedData = data.sort((a, b) => {
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });

      console.log("newdata response with time..", newdata);
      setTodayData(newdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  // const getTimeslots = async () => {
  //   try {
  //     console.log("dailyBasis in getTimeSlot", dailyBasis);
  //     const response = await getAvailableTimeSlots(
  //       clinicuniqueId,
  //       entiredate,
  //       dailyBasis
  //     );
  //     console.log("response", response.data);
  //     let data = response.data;
  //     if (data) {
  //       await setAvailableTime(data);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // const modifiedTimings = availableTime.map((timing) => {
  //   const parts = timing.split(" - ");
  //   const startTime = parts[0];
  //   const endTime = parts[1];

  //   // Converting start time to 12-hour format
  //   const start = new Date(`2000-01-01T${startTime}`);
  //   const startHours = start.getHours();
  //   const startMinutes = start.getMinutes();
  //   const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
  //     .toString()
  //     .padStart(2, "0")}`;

  //   // Converting end time to 12-hour format
  //   const end = new Date(`2000-01-01T${endTime}`);
  //   const endHours = end.getHours();
  //   const endMinutes = end.getMinutes();
  //   const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
  //     .toString()
  //     .padStart(2, "0")}`;

  //   // Determine AM/PM
  //   const amPm = startHours < 12 && dailyBasis === "morning" ? "AM" : "PM";

  //   return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
  // });
  // console.log("modifiedtimgs...", modifiedTimings);

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      toast.success("Sucessfully Cancelled the Appointment", {
        position: "top-right",
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    getTodayRequestData();
  }, [acceptReject, reschedule]);

  // useEffect(() => {
  //   getTimeslots();
  // }, [dailyBasis]);

  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "0.5px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {todayData.map((el, id) => {
          return (
            <>
              {TodayPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId}
                        </Typography>
                        {/* <Box
                        style={{
                          background: "#00984F",
                          padding: "10px",
                          borderRadius: "6px",
                          lineHeight: "0.5",
                        }}
                      >
                        <Typography variant="caption" sx={{ color: "#fff" }}>
                          {el.request}
                        </Typography>
                      </Box> */}
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Appointment Status
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {el.appointmentStatus}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7.5}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.clinicName}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <LocationOnIcon />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "10px" }}
                              >
                                {el.clinicAddress}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Location
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Clinic Details
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", width: "50%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3.5}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "40px",
                        }}
                        container
                      >
                        {ReschedulePermission && (
                          <button
                            onClick={() => {
                              console.log("id.......", el.id);
                              handleTodayModalOpen(el);
                            }}
                            style={{
                              background: "#D83F87",
                              textAlign: "left",
                              border: "none",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              marginBottom: "10px",
                              width: "75%",
                              fontSize: "12px",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                            Reschedule
                          </button>
                        )}
                        <br />
                        {CancelAppointmentPermission && (
                          <button
                            style={{
                              background: "#3E2732",
                              border: "none",
                              textAlign: "left",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              fontSize: "12px",
                              width: "75%",
                              display: "flex",
                              gap: "5px",
                            }}
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            <CancelPresentationIcon
                              style={{ fontSize: "0.9rem" }}
                            />{" "}
                            Cancel
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}

              {/* -------------------------Dialog box for Reject---------------------- */}
              <Dialog
                open={rejectopen}
                onClose={handleRejectDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Are you sure you want to Reject ?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#D83F87",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    onClick={() => {
                      handleReject(itemId);
                      handleRejectDialogClose();
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#2d256c",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#2d256c!important" },
                    }}
                    onClick={handleRejectDialogClose}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>

              <RescheduleModal
                open={todayOpen}
                date={date}
                modalData={modalData}
                handleClose={handleTodayClose}
                clinicuniqueId={clinicuniqueId}
                setDate={setDate}
                onYearChangeHandler={onYearChangeHandler}
                setDailybasis={setDailybasis}
                dailyBasis={dailyBasis}
                handleOpen={handleTodayModalOpen}
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                reset={reset}
                entiredate={entiredate}
              />
            </>
          );
        })}
      </Card>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {todayData.map((el, id) => {
          return (
            <>
              {TodayPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      {ReschedulePermission && (
                        <button
                          onClick={() => {
                            console.log("id.......", el.id);
                            handleTodayModalOpen(el);
                          }}
                          style={{
                            background: "#D83F87",
                            textAlign: "left",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            marginBottom: "10px",
                            width: "90%",
                            fontSize: "10px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <CalendarMonthIcon style={{ fontSize: "14px" }} />{" "}
                          Reschedule
                        </button>
                      )}
                      {CancelAppointmentPermission && (
                        <button
                          style={{
                            background: "#3E2732",
                            border: "none",
                            textAlign: "left",
                            alignItems: "center",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            fontSize: "10px",
                            width: "90%",
                            display: "flex",
                            gap: "5px",
                          }}
                          onClick={() => {
                            handleRejectdialogOpen(el.id);
                            console.log("reject id........ ", el.id);
                          }}
                        >
                          <CancelPresentationIcon
                            style={{ fontSize: "14px" }}
                          />{" "}
                          Cancel
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
};

// ----------------Upcoming Table---------------------
const upcomingschema = yup
  .object({
    name: yup.string().required(),
    phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().notOneOf([""], "You must select an option!"),
    datevalid: yup.date().required("This Field is required"),
    time: yup.string().required("This Field Is Required"),
  })
  .required();

const UpcomingTable = ({
  newUpcomingSelectDate,
  upcomingData,
  setUpcomingData,
}) => {
  const [Upcomingopen, setUpcomingOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [modalData, setModalData] = useState([]);
  const [dailyBasis, setDailybasis] = useState("");
  const [availableTime, setAvailableTime] = useState([]);
  const [acceptReject, setAcceptReject] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.username;
  const uniqueId = token?.uniqueId;
  const clinicuniqueId = modalData?.clinicUniqueId;
  // console.log("clinicuniqueId...", clinicuniqueId);
  const [existingCustomerUniqueId, setExistingCustomerUniqueId] = useState("");
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let UpcomingPermission = clinicPermissions?.find(
    (item) => item.action === "Upcoming"
  )?.permission;
  let ReschedulePermission = clinicPermissions?.find(
    (item) => item.action === "Reschedule Appointment"
  )?.permission;
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  const handleUpcomingModelOpen = (el) => {
    reset();
    console.log("element id......", el);
    setUpcomingOpen(true);
    setModalData(el);
    handleExistingCustomer();
  };

  const handleExistingCustomer = async () => {
    try {
      const res = await CheckingExistingCustomer(modalData.patientNumber);
      const data = res.data;
      if (data) {
        // console.log("uniqueid",data.uniqueId);
        console.log("existing customer data", data);
        setExistingCustomerUniqueId(data.uniqueId);
        // console.log("uniqueId",existingCustomerUniqueId)
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };

  const handleUpcomingClose = () => {
    setUpcomingOpen(false);
    setReschedule(false);
  };

  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;
  console.log("entiredate..", entiredate);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(upcomingschema),
  });

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}:00`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        id: modalData.id,
        patientName: data.name,
        patientNumber: data.phonenumber,
        clinicUniqueId: clinicuniqueId,
        customerUniqueId: existingCustomerUniqueId,
        specialty: data.speciality,
        slot: data.slot,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remark,
        createdBy: token.username,
      };
      console.log("formdata........", formdata);
      try {
        await rescheduleAppointment(formdata);
        reset();
        handleUpcomingClose();
        toast.success("Successfully Rescheduled Appointment", {
          position: "top-right",
        });
        setReschedule(true);
      } catch (err) {
        console.log(err.message);
        toast.error("Something went wrong Check Server", {
          position: "top-right",
        });
      }
    }
  };

  const getUpcomingRequestData = async () => {
    try {
      const response = await getUpcomingAppointmentRequestData(uniqueId);
      console.log("response....", response.data);
      let data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`)
        );
      });

      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });

      console.log("newdata response with time..", newdata);

      setUpcomingData(newdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  // const getTimeslots = async () => {
  //   try {
  //     console.log("dailyBasis in getTimeSlot", dailyBasis);
  //     const response = await getAvailableTimeSlots(
  //       clinicuniqueId,
  //       entiredate,
  //       dailyBasis
  //     );
  //     console.log("response", response.data);
  //     let data = response.data;
  //     if (data) {
  //       await setAvailableTime(data);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // const modifiedTimings = availableTime.map((timing) => {
  //   const parts = timing.split(" - ");
  //   const startTime = parts[0];
  //   const endTime = parts[1];

  //   // Converting start time to 12-hour format
  //   const start = new Date(`2000-01-01T${startTime}`);
  //   const startHours = start.getHours();
  //   const startMinutes = start.getMinutes();
  //   const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
  //     .toString()
  //     .padStart(2, "0")}`;

  //   // Converting end time to 12-hour format
  //   const end = new Date(`2000-01-01T${endTime}`);
  //   const endHours = end.getHours();
  //   const endMinutes = end.getMinutes();
  //   const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
  //     .toString()
  //     .padStart(2, "0")}`;

  //   // Determine AM/PM
  //   const amPm = startHours < 12 && dailyBasis === "morning" ? "AM" : "PM";

  //   return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
  // });
  // console.log("modifiedtimgs...", modifiedTimings);

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      toast.success("Sucessfully Cancelled the Appointment", {
        position: "top-right",
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect(() => {
  //   getUpcomingRequestData();
  // }, [acceptReject,reschedule]);

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCustomerUpcomingReqWithDate(
          newUpcomingSelectDate,
          username
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setUpcomingData(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newUpcomingSelectDate) {
      fetchData();
    } else {
      getUpcomingRequestData();
    }
  }, [acceptReject, reschedule, newUpcomingSelectDate]);

  // useEffect(() => {
  //   getTimeslots();
  // }, [dailyBasis]);
  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "0.5px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {upcomingData.map((el, id) => {
          return (
            <>
              {UpcomingPermission && (
                <Card
                  key={el.id}
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId}
                        </Typography>
                        {/* <Box
                        style={{
                          background: "#00984F",
                          padding: "10px",
                          borderRadius: "6px",
                          lineHeight: "0.5",
                        }}
                      >
                        <Typography variant="caption" sx={{ color: "#fff" }}>
                          {el.request}
                        </Typography>
                      </Box> */}
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7.5}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.clinicName}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <LocationOnIcon />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "10px" }}
                              >
                                {el.clinicAddress}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Location
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Clinic Details
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", width: "50%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "40px",
                        }}
                        container
                      >
                        {ReschedulePermission && (
                          <button
                            onClick={() => {
                              console.log("id.......", el.id);
                              handleUpcomingModelOpen(el);
                            }}
                            style={{
                              background: "#D83F87",
                              textAlign: "left",
                              border: "none",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              marginBottom: "10px",
                              width: "75%",
                              fontSize: "12px",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                            Reschedule
                          </button>
                        )}
                        <br />
                        {CancelAppointmentPermission && (
                          <button
                            style={{
                              background: "#3E2732",
                              border: "none",
                              textAlign: "left",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              fontSize: "12px",
                              width: "75%",
                              display: "flex",
                              gap: "5px",
                            }}
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            <CancelPresentationIcon
                              style={{ fontSize: "0.9rem" }}
                            />{" "}
                            Cancel
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}

              {/* -------------------------Dialog box for Reject---------------------- */}
              <Dialog
                open={rejectopen}
                onClose={handleRejectDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Are you sure you want to Reject ?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#D83F87",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    onClick={() => {
                      handleReject(itemId);
                      handleRejectDialogClose();
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#2d256c",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      "&:hover": { backgroundColor: "#2d256c!important" },
                    }}
                    onClick={handleRejectDialogClose}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
              {/* --------------------------------MODAL----------------------------- */}
              <RescheduleModal
                open={Upcomingopen}
                date={date}
                modalData={modalData}
                handleClose={handleUpcomingClose}
                clinicuniqueId={clinicuniqueId}
                setDate={setDate}
                onYearChangeHandler={onYearChangeHandler}
                setDailybasis={setDailybasis}
                dailyBasis={dailyBasis}
                handleOpen={handleUpcomingModelOpen}
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                reset={reset}
                entiredate={entiredate}
              />
            </>
          );
        })}
      </Card>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {upcomingData.map((el, id) => {
          return (
            <>
              {UpcomingPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      {ReschedulePermission && (
                        <button
                          onClick={() => {
                            console.log("id.......", el.id);
                            handleUpcomingModelOpen(el);
                          }}
                          style={{
                            background: "#D83F87",
                            textAlign: "left",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            marginBottom: "10px",
                            width: "90%",
                            fontSize: "10px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <CalendarMonthIcon style={{ fontSize: "14px" }} />{" "}
                          Reschedule
                        </button>
                      )}
                      {CancelAppointmentPermission && (
                        <button
                          style={{
                            background: "#3E2732",
                            border: "none",
                            textAlign: "left",
                            alignItems: "center",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            fontSize: "10px",
                            width: "90%",
                            display: "flex",
                            gap: "5px",
                          }}
                          onClick={() => {
                            handleRejectdialogOpen(el.id);
                            console.log("reject id........ ", el.id);
                          }}
                        >
                          <CancelPresentationIcon
                            style={{ fontSize: "14px" }}
                          />{" "}
                          Cancel
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
};

// --------------------------------------Past Table---------------------------------

const PastTable = ({ pastData, setPastData, newPastSelectDate }) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let PastPermission = clinicPermissions?.find(
    (item) => item.action === "Past"
  )?.permission;
  let OpenAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Open Appointment"
  )?.permission;

  const getCustomerPastRequestData = async () => {
    try {
      const response = await getCustomerPastAppointmentRequest(uniqueId);
      console.log("response....", response.data);
      let data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeB}`) - new Date(`1970/01/01 ${timeA}`)
        );
      });

      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });

      console.log("newdata response with time..", newdata);

      setPastData(newdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCustomerPastAppointmentWithDate(
          newPastSelectDate,
          uniqueId
        );
        let data = response.data;
        console.log("pastreq with data", data);
        setPastData(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newPastSelectDate) {
      fetchData();
    } else {
      getCustomerPastRequestData();
    }
  }, [newPastSelectDate]);
  return (
    <div>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "0.5px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {pastData.map((el, id) => {
          return (
            <>
              <Card
                key={el.id}
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "0.5px solid #D9D9D9",
                  borderRadius: "5px",
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={2.5}>
                    <Box
                      sx={{
                        padding: "15px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        Appointment ID
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", fontSize: "12px" }}
                      >
                        {el.appointmentId}
                      </Typography>
                      {/* <Box
                        style={{
                          background: "#00984F",
                          padding: "10px",
                          borderRadius: "6px",
                          lineHeight: "0.5",
                        }}
                      >
                        <Typography variant="caption" sx={{ color: "#fff" }}>
                          {el.request}
                        </Typography>
                      </Box> */}
                    </Box>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid item xs={7.5}>
                    <Grid container spacing={1}>
                      <Grid item xs={5}>
                        <Box sx={{ padding: "15px" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {el.clinicName}
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <LocationOnIcon />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "10px" }}
                            >
                              {el.clinicAddress}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontSize: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              View Location
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontSize: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              View Clinic Details
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: "center", width: "50%" }}>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                background: "#FFEFF9",
                                padding: "5px",
                                color: "gray",
                                borderRadius: "6px",
                              }}
                            >
                              {el.specialty}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ padding: "15px" }}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Appointment Date
                          </Typography>
                          <Typography
                            v4ant="subtitle1"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {formattedDate(el.date)}
                          </Typography>
                          <Box sx={{ marginTop: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Time
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.time}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box
                          sx={{
                            padding: "15px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Remarks
                          </Typography>{" "}
                          <br />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "12px" }}
                          >
                            {el.remark}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}

                  <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "20px",
                        mt: "40px",
                      }}
                      container
                    >
                      {OpenAppointmentPermission && (
                        <button
                          style={{
                            background: "#D83F87",
                            textAlign: "left",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "8px 15px",
                            marginBottom: "10px",
                            width: "65%",
                            fontSize: "12px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                          onClick={() =>
                            navigate(
                              `/user/customerpastappointmentinformation/${el.appointmentId}`
                            )
                          }
                        >
                          <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                          Open
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {pastData.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                }}
                className="mobilecontentcard"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className="appointmentid" variant="h6">
                      {el.appointmentId}
                    </Typography>
                    <Typography className="dateheading" variant="caption">
                      Date
                    </Typography>
                    <Typography className="date" variant="subtitle1">
                      {formattedDate(el.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="speciality">
                      {el.specialty}
                    </Typography>
                    <Typography variant="caption" className="timeheading">
                      Time
                    </Typography>
                    <Typography variant="subtitle1" className="time">
                      {el.time}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ backgroundColor: "#F5F5F5!important" }}
                    item
                    xs={4}
                  >
                    {OpenAppointmentPermission && (
                      <button
                        style={{
                          background: "#D83F87",
                          textAlign: "left",
                          border: "none",
                          borderRadius: "3px",
                          cursor: "pointer",
                          color: "#fff",
                          padding: "5px 10px",
                          marginTop: "15px",
                          marginLeft: "15px",
                          width: "65%",
                          fontSize: "10px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                        }}
                        onClick={() =>
                          navigate(
                            `/user/customerpastappointmentinformation/${el.appointmentId}`
                          )
                        }
                      >
                        <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                        Open
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>
    </div>
  );
};

// ----------------------------------------Cancelled Table----------------------------------------

const ClinicCancelledTable = ({
  newCancelSelectedDate,
  cancelledAppointments,
  setCancelledAppointments,
}) => {
  const [date, setDate] = useState(new Date());
  const [acceptReject, setAcceptReject] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token?.username;
  const uniqueId = token?.uniqueId;
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let CancelledPermission = clinicPermissions?.find(
    (item) => item.action === "Cancelled"
  )?.permission;

  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;
  console.log("entiredate..", entiredate);

  const getCancelledAppointmentRequests = async () => {
    setLoading(true);
    try {
      const response = await getCancelledAppointmentRequestData(uniqueId);
      console.log("response....", response.data);
      let data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeB}`) - new Date(`1970/01/01 ${timeA}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });

      console.log("newdata response with time..", newdata);
      if (newdata) {
        setCancelledAppointments(newdata);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCustomerCancelledReqWithDate(
          newCancelSelectedDate,
          username
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setCancelledAppointments(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newCancelSelectedDate) {
      fetchData();
    } else {
      getCancelledAppointmentRequests();
    }
  }, [acceptReject, newCancelSelectedDate]);

  // useEffect(() => {
  //   getTimeslots();
  // }, [dailyBasis]);
  if (loading)
    return (
      <h1>
        <Loader />
      </h1>
    );
  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "0.5px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {cancelledAppointments.map((el, id) => {
          return (
            <>
              {CancelledPermission && (
                <Card
                  key={el.id}
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId === null ? (
                            <>No Appointment Id</>
                          ) : (
                            el.appointmentId
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7.5}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.clinicName}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <LocationOnIcon />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "10px" }}
                              >
                                {el.clinicAddress}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Location
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                View Clinic Details
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", width: "50%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark === "" ? <>No Remarks</> : el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Box sx={{ pt: "50px" }}>
                        <Typography fontSize="14px">
                          This appointment was cancelled.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {cancelledAppointments.map((el, id) => {
          return (
            <>
              {CancelledPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId
                          ? el.appointmentId
                          : "No AppointmentID"}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      <Typography sx={{ fontSize: "12px", mt: "10px" }}>
                        This appointment was canceled.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
};

export { TodayTable, PastTable, UpcomingTable, ClinicCancelledTable };
