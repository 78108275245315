import { Autocomplete, Box, Button, Card, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../../styles/PlansProcedure.scss";
import { toast } from "react-toastify";
import { AddClinicComplaints } from "../../services/PlansAndProcedure";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };
  

const ComplaintsModal = ({speciality,setComplaintAdded}) => {
    const [open, setOpen] = useState(false);
    const [isSubmitting,setIsSubmitting]=useState(false);
    const [complaints, setComplaints] = useState([]);
    const [complaintErrorMsg,setComplaintErrorMsg]=useState();
    const token = JSON.parse(localStorage.getItem("token"));
    const username = token?.username;

    const onTagsChange = (event, values) => {
      setComplaints(values);
      // console.log("values", values);
      if(values.length>0){
        setComplaintErrorMsg()
      }
    };
    const handleOpen = () => {
      setOpen(true);
      setComplaintAdded(false)
    };
    const handleClose = () => {
      setOpen(false);
      setComplaints([]);
      setComplaintErrorMsg();
      setComplaintAdded(true)
    };

 

  const handleSubmit = async (event) => {
    event.preventDefault()
    if(complaints.length===0){
      setComplaintErrorMsg("This Field is Required")
    }else{
      setComplaintErrorMsg();
      const formdata = {
        speciality: speciality,
        createdBy: username,
        values: complaints,
      };
      console.log(formdata)
      try {
        setIsSubmitting(true)
        let res=await AddClinicComplaints(formdata)
        if(res){
          handleClose();
          toast.success("Complaints Submitted Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      } catch (error) {
        console.log(error.message)
        toast.error("Please Check Your Server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmitting(false)
      }
    }
  };
  return (
    <div>
         <Button
        sx={{
          backgroundColor: "#2d256c",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "capitalize",
          fontSize: "10px",
          padding: "5px 10px",
          borderRadius:"3px",
          "&:hover": { backgroundColor: "#2d256c!important" },
        }}
        size="small"
        type="submit"
        onClick={handleOpen}
      >
        Add Complaints
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ textAlign: "center",fontSize:"17px" }}
            variant="h5"
            component="h2"
          >
            Add Complaints
          </Typography>
          <form onSubmit={handleSubmit}>
            <Typography id="modal-modal-description"  sx={{fontWeight:"bold",mt:2,fontSize:"14px"}}>
              Complaints (Press Enter after every entry)
            </Typography>
             <Autocomplete
              multiple
              freeSolo
              options={[]}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
            {
              complaintErrorMsg &&
              <span style={{ color: "red" }} className="error">
              {complaintErrorMsg}
              </span>
            }

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  background: "#696969",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px"
                }}
                type="reset"
              >
                Cancel
              </button>
              <button
              disabled={isSubmitting}
                style={{
                  background: isSubmitting?"#898989":"#D83F87",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px"
                }}
                type="submit"
              >
                Submit
              </button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default ComplaintsModal