import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { AddClinicProcedure } from "../../services/PlansAndProcedure";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};


const AddProcedure = ({ specialities, setSpecialities }) => {
  const [open, setOpen] = useState(false);
  const [procedure, setProcedure] = useState([]);
  const [specialitiesOption, setSpecialitiesOption] = useState("");
  const [specialityErrorMsg,setSpecialityErrorMsg]=useState()
  const [procedureErrorMsg,setProcedureErrorMsg]=useState();
  const [isSubmitting,setIsSubmitting]=useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token?.username;


  const onTagsChange = (event, values) => {
    setProcedure(values);
    // console.log("values", values);
    if(values.length>0){
      setProcedureErrorMsg()
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSpecialitiesOption("")
    setProcedure([]);
    setProcedureErrorMsg();
    setSpecialityErrorMsg();
    setIsSubmitting(false)
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault()
    if(specialitiesOption==="" || procedure.length===0){
      if(specialitiesOption===""){
        setSpecialityErrorMsg("This Field is Required")
      }else{
        setSpecialityErrorMsg()
      }
      if(procedure.length===0){
        setProcedureErrorMsg("This Field is Required")
      }else{
        setProcedureErrorMsg()
      }
    }
    else{
      const formdata = {
            speciality: specialitiesOption,
            createdBy: username,
            values: procedure,
          };
          console.log(formdata)
          try {
                setIsSubmitting(true)
                await AddClinicProcedure(formdata)
                handleClose();
                toast.success("Procedure Submitted Sucessfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } catch (error) {
                console.log(error.message)
                toast.error("Please Check Your Server", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setIsSubmitting(false)
            }
    }
  };
  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#2d256c!important",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "capitalize",
          fontSize: "12px",
          padding: "8px 15px",
          "&:hover": { backgroundColor: "#2d256c!important" },
        }}
        size="small"
        type="submit"
        onClick={handleOpen}
      >
        Add Procedures
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ textAlign: "center",fontSize:"17px" }}
            variant="h5"
            component="h2"
          >
            Add Procedure
          </Typography>
          <form onSubmit={handleSubmit}>
            <Typography sx={{fontWeight:"bold",mt:"10px",fontSize:"14px"}}>Select Speciality</Typography>
            <Select
              placeholder="Select"
              size="small"
              className="inputbox"
              fullWidth
              onChange={(e)=>{
                setSpecialitiesOption(e.target.value);
                console.log(e.target.value)
                setSpecialityErrorMsg()
              }}
            >
              <MenuItem value=""></MenuItem>
              {specialities.map((el, id) => {
                return (
                  <MenuItem key={id} value={el.speciality}>
                    {el.speciality}
                  </MenuItem>
                );
              })}
            </Select>
            {
              specialityErrorMsg &&
              <span style={{ color: "red" }} className="error">
              {specialityErrorMsg}
              </span>
            }
            <Typography sx={{fontWeight:"bold",mt:"10px",fontSize:"14px"}}>Procedure Name (Press Enter after every entry) </Typography>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
            {
              procedureErrorMsg &&
              <span style={{ color: "red" }} className="error">
              {procedureErrorMsg}
              </span>
            }
           
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  background: "#696969",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px"
                }}
                type="reset"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                style={{
                  background: isSubmitting?"#898989":"#D83F87",
                  color: "white",
                  border: "none",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  fontSize:"12px",
                  cursor: isSubmitting?"default":"pointer"
                }}
                type="submit"
              >
                Submit
              </button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddProcedure;
