import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { flushSync } from 'react-dom';
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import {
    DatePicker,
    MobileDatePicker,
    MobileTimePicker,
  } from "@mui/x-date-pickers";
  import * as yup from "yup";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { useForm } from "react-hook-form";
  import {
    addCoupon,
    getClinicProcedure,
    getClinicSpecialities,
    getIndividualCouponDetails,
  } from "../../services/PlansAndProcedure";
  import { toast } from "react-toastify";
  import { Navigate, useNavigate, useParams } from "react-router-dom";
  import Loader from "../Loader";
import dayjs from "dayjs";
  
  // const phoneRegExp = /^[6-9]\d{9}$/;
  // speciality: yup.array().required("You must select an option!"),
  
  
  const EditCoupon = () => {
    const [startDate, setStartDate] = useState(null);
    const [entireStartDate,setEntireStartDate]=useState("")
    const [endDate, setEndDate] = useState(null);
    const [entireEndDate,setEntireEndDate]=useState("")
    const [startTime, setStartTime] = useState(null);
    const [extractStartTime,setExtractStartTime]=useState("")
    const [endTime, setEndTime] = useState(null);
    const [extractEndTime,setExtractEndTime]=useState("")
    const [alignment, setAlignment] = useState("inpercentage");
    const [restofBillToggle, setRestofBillToggle] = useState("inpercentage");
    const [totalbillToggle, setTotalBillToggle] = useState("inpercentage");
    const [specialities, setSpecialities] = useState([]);
    const [allSpecialities,setAllSpecialities]=useState([])
    const [specialityOptions, setSpecialityOptions] = useState("");
    const [procedures,setProcedures]=useState([])
    const [allProcedures, setAllProcedures] = useState([]);
    const [getCouponDetailsById,setCouponDetailsById]=useState({});
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [isSubmitting,setIsSubmitting]=useState(false);
    let token=JSON.parse(localStorage.getItem("token"));
    let uniqueId=token?.uniqueId
    let username=token?.username
    const {id}=useParams();
    const navigate=useNavigate()
    console.log(id)
  

    const handleCheckboxChange = (checkboxValue) => {
      console.log("checkboxvalue",checkboxValue)
      if (checkboxValue === "checkbox1" || checkboxValue === "checkbox2") {
        setSelectedCheckbox((prevSelectedCheckboxes) => {
          if (prevSelectedCheckboxes.includes("checkbox3")) {
            return [checkboxValue];
          } else {
            return prevSelectedCheckboxes.includes(checkboxValue)
              ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
              : [...prevSelectedCheckboxes, checkboxValue];
          }
        });
      } else if (checkboxValue === "checkbox3") {
        setSelectedCheckbox((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes.includes(checkboxValue)
            ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
            : [checkboxValue]
        );
      }
    };

    const schema = yup
    .object({
      couponcode: yup.string().required("This Field is required"),
      description: yup.string().required("This Field is required"),
      moq: yup.string().required("This Field is required"),
      noofusage: yup.string().required("This Field is required"),
      totalnoofusage: yup.string().required("This Field is required"),
      ...(selectedCheckbox.includes("checkbox1") ? (alignment==="inpercentage"? {consultaionChargePlanAmountPercentage:yup.number().typeError('Please enter a valid number')
      .max(100, 'Number must be less than or equal to 100')
      .required('Number is required'),
      consultationChargeMaxDiscount:yup.string().required("This Field is required")}:{consultationChargePlanAmountValue:yup.string().required("This Field is required")}):{}),
      ...(selectedCheckbox.includes("checkbox2") ? (restofBillToggle==="inpercentage"? {restOfBillCouponAmountPercentage:yup.number().typeError('Please enter a valid number')
      .max(100, 'Number must be less than or equal to 100')
      .required('Number is required'),
      restOfBillMaxDiscount:yup.string().required("This Field is required")}:{ restOfBillCouponAmountValue:yup.string().required("This Field is required")}) : {}),
      ...(selectedCheckbox.includes("checkbox3")? (totalbillToggle==="inpercentage"? {totalBillPlanAmountPercentage:yup.number().typeError('Please enter a valid number')
     .max(100, 'Number must be less than or equal to 100')
     .required('Number is required'),
     totalBillMaxDiscount:yup.string().required("This Field is required")}:{totalBillPlanAmountValue:yup.string().required("This Field is required")}) : {})
      // noOfFreeConsulation:yup.string().required("This Field is required"),
    })
    .required();
    // ---------------------Time extraction--------------------
    function startTimeExtracting(time){
      const hours=time?.$H;
      const min=time?.$m;
      let  totaltime=`${hours>9?hours:"0"+hours}:${min>9?min:"0"+min}`;
      // console.log("starttime",totaltime)
      setExtractStartTime(totaltime)
    }
    
  
    function endTimeExtracting(time){
      const hours=time?.$H;
      const min=time?.$m;
      let  totaltime=`${hours>9?hours:"0"+hours}:${min>9?min:"0"+min}`;
      // console.log("endtime",totaltime);
      setExtractEndTime(totaltime)
    }
  
  
    // --------------Date Extracting start --------------------;
    function StartDateExtracting(startDate) {
      let currentdate = startDate?.$D;
      let month = startDate?.$M + 1;
      let year = startDate?.$y;
      const entireStartDate = `${year}-${month > 9 ? month : "0" + month}-${
        currentdate > 9 ? currentdate : "0" + currentdate
      }`;
      setEntireStartDate(entireStartDate)
    }
    
  
    function EndDateExtracting(endDate) {
      let currentdate = endDate?.$D;
      let month = endDate?.$M + 1;
      let year = endDate?.$y;
      const entireEndDate = `${year}-${month > 9 ? month : "0" + month}-${
        currentdate > 9 ? currentdate : "0" + currentdate
      }`;
      setEntireEndDate(entireEndDate)
    }
  
    const onYearChangeHandler = () => {
      alert("Year changed!");
    };
  
    
    
  
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
    } = useForm({
      mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
      defaultValues:{
        // name:value
      },
      resolver: yupResolver(schema),
    });
  
    const onTagsChange = (event,values) => {
      console.log(values);
      getProcedures({specialities:values})
      // if (values.length === 1) {
      //   setSpecialityOptions(values[0]);
      // } else if (values.length > 1) {
      //   setSpecialityOptions(values[values.length - 1]);
      // } else {
      //   setSpecialityOptions("");
      // }
      // setAllSpecialities(values) 
    };
    console.log("all specialities",allSpecialities)
    console.log("specialities",specialities)
    console.log("speciality options",specialityOptions);

    const onProceduresChange = (event,values) => {
      console.log("procedure values",values)
      setProcedures(values)
    };
  
    // console.log(specialityOptions);
    const getSpecialities = async () => {
      try {
        let response = await getClinicSpecialities();
        let data = response.data?.map((el) => el.speciality);
        console.log(data);
        setSpecialities(data);
      } catch (error) {
        console.log(error.message);
      }
    };
  
    const getProcedures = async ({specialities}) => {
      try {
        let proce=[]
        for (let i = 0; i < specialities.length; i++) {
          if(specialities[i]==="All"){
            proce.push("All")
          }else{
            const e = specialities[i];
            const response = await getClinicProcedure(e);
            proce.push(...response.data?.map((el) => el.procedureName))
          }
        }
        console.log('proce',proce);
        setAllProcedures([...proce]);
      } catch (error) {
        console.log(error.message);
      }
    };
    // console.log(allProcedures);
  
    const getCouponDetailsIndividually=async()=>{
      try {
        const response=await getIndividualCouponDetails(id);
        const data=response.data;
        console.log('data.procedure',data.procedures);
        //call procedures
        getProcedures({specialities:data.speciality})
        setCouponDetailsById(data);
        setEntireStartDate(data.startDate);
        setEntireEndDate(data.endDate)
        setExtractStartTime(data.startTime);
        setExtractEndTime(data.endTime);
        setProcedures(data.procedures);
        setSpecialities(data.speciality)
        setAllSpecialities(data.speciality);
        let defaultCheckedValue=[];
        if(data?.consultationCharge){
          defaultCheckedValue.push("checkbox1")
        }if(data?.consultationCharge && data?.restOfTheBill){
          defaultCheckedValue.push("checkbox1","checkbox2")
        }
        if(data?.restOfTheBill){
          defaultCheckedValue.push("checkbox2")
        }
        if(data?.totalBill){
          defaultCheckedValue.push("checkbox3")
        }
        setSelectedCheckbox(defaultCheckedValue);
        setValue("consultaionChargePlanAmountPercentage",data.consultationChargePlanAmtPer)
        setValue("consultationChargePlanAmountValue",data.consultationChargePlanAmounttVal)
        setValue("consultationChargeMaxDiscount",data.consultationChargeMaxDiscount)
        setValue("restOfBillCouponAmountPercentage",data.restOfTheBillPlanAmtPer)
        setValue("restOfBillCouponAmountValue",data.restOfTheBillPlanAmounttVal)
        setValue("restOfBillMaxDiscount",data.restOfTheBillMaxDiscount)
        setValue("totalBillPlanAmountPercentage",data.totalBillPlanAmtPer)
        setValue("totalBillPlanAmountValue",data.totalBillPlanAmounttVal)
        setValue("totalBillMaxDiscount",data.totalBillMaxDiscount)
      } catch (error) {
        console.log(error.messsage)
      }
      
  }
    const onSubmit = async (data) => {
      console.log("data",data);
      console.log("all values",{
        entireStartDate, entireEndDate, extractStartTime, extractEndTime, procedures, allSpecialities
      })
      if(!(selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3"))){
        toast.info("Please Select the Any One Of Coupon Payment Details", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if(data &&
        (selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3")) &&
         entireStartDate && 
         entireEndDate && 
         extractStartTime && 
         extractEndTime && 
         procedures.length>=1 
         && allSpecialities.length>=1){
        const formdata={
            id:id,
          couponCode:data.couponcode,
          speciality:allSpecialities,
          description:data.description,
          procedures:procedures,
          moq:data.moq,
          startDate:entireStartDate,
          startTime:extractStartTime,
          endDate:entireEndDate,
          endTime:extractEndTime,
          noOfUsagePerUser:data.noofusage,
          totalNoOfUsage:data.totalnoofusage,
          consultationCharge:selectedCheckbox.includes("checkbox1") ,
          consultationChargePlanAmtPer:data.consultaionChargePlanAmountPercentage?.toString(),
          consultationChargePlanAmounttVal:data.consultationChargePlanAmountValue,
          consultationChargeMaxDiscount:data.consultationChargeMaxDiscount,
          restOfTheBill:selectedCheckbox.includes("checkbox2") ,
          restOfTheBillPlanAmtPer:data.restOfBillCouponAmountPercentage?.toString(),
          restOfTheBillPlanAmounttVal:data.restOfBillCouponAmountValue,
          restOfTheBillMaxDiscount:data.restOfBillMaxDiscount,
          totalBill:selectedCheckbox.includes("checkbox3") ,
          totalBillPlanAmtPer:data.totalBillPlanAmountPercentage?.toString(),
          totalBillPlanAmounttVal:data.totalBillPlanAmountValue,
          totalBillMaxDiscount:data.totalBillMaxDiscount,
          freeConsultation:false,
          noOfFreeConsultation:"",
          clinicUniqueId:uniqueId,
          createdBy:username
        }
        console.log("formdata",formdata)
        try {
          setIsSubmitting(true)
          let res= await addCoupon(formdata)
          toast.success("Coupon Updated Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if(res){
            navigate("/user/coupons")
          }else{
            setIsSubmitting(false)
          }
        } catch (error) {
          toast.error("Please Check your server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      }
    };
  
    function timecheck(time){
      const [hour,min]=time.split(":")
      return dayjs().set("hour",hour).set("minute",min)
    }
   
    useEffect(() => {
      if(specialityOptions){
        getProcedures();
      }
      if(specialityOptions===""){
        setAllProcedures([])
      }
      if(id){
        getCouponDetailsIndividually()
      }
    }, [specialityOptions]);    

  
    if(!getCouponDetailsById.couponCode) return <Loader/>
    return (
      <div>
        <Box sx={{ paddingLeft: "10px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5" fontSize="17px" mb="10px">Edit Coupon Details</Typography>
            <Card>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Coupon Code
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Coupon Name"
                      {...register("couponcode")}
                      defaultValue={`${getCouponDetailsById.couponCode}`}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.couponcode?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Speciality
                    </FormLabel>
                    {/* <Select placeholder="Select"  {...register("speciality")} size="small" className="inputbox">
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="ophthalmology">Ophthalmology</MenuItem>
                    <MenuItem value="ent">ENT</MenuItem>
                    <MenuItem value="dental">Dental</MenuItem>
                    <MenuItem value="gastroenterology">Gastroenterology</MenuItem>
                    <MenuItem value="gynecology">Gynecology</MenuItem>
                  </Select> */}
                    <Autocomplete
                      multiple
                      freeSolo
                      options={specialities}
                      onChange={onTagsChange}
                      onFocus={getSpecialities}
                      defaultValue={getCouponDetailsById.speciality}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    {(allSpecialities.length===0 && getCouponDetailsById.speciality.length===0 ) && (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Description
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="Description"
                    {...register("description")}
                    defaultValue={getCouponDetailsById.description}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.description?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "10px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Procedures
                    </FormLabel>
                    {/* <Select placeholder="Select" {...register("procedure")} size="small" className="inputbox">
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="ophthalmology">Ophthalmology</MenuItem>
                    <MenuItem value="ent">ENT</MenuItem>
                    <MenuItem value="dental">Dental</MenuItem>
                    <MenuItem value="gastroenterology">Gastroenterology</MenuItem>
                    <MenuItem value="gynecology">Gynecology</MenuItem>
                  </Select> */}
                  {console.log("allprocedures",allProcedures)}
                    {allProcedures.length>0 && <Autocomplete
                      multiple
                      freeSolo
                      options={allProcedures}
                      onChange={onProceduresChange}
                      defaultValue={getCouponDetailsById.procedures}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />}
                    {(procedures.length === 0 && getCouponDetailsById.procedures.length===0) && (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      MOQ
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="MOQ"
                      {...register("moq")}
                      defaultValue={getCouponDetailsById.moq}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.moq?.message}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "10px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Start Date
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat="DD/MM/YYYY"
                        value={startDate===null?getCouponDetailsById.startDate:startDate} 
                        onChange={(date) => {
                          setStartDate(date);
                          StartDateExtracting(date);
                        }}
                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ backgroundColor: "#FFFFFF" }}
                          />
                        )}
                        onYearChange={onYearChangeHandler}
                        orientation={"portrait"}
                      />
                    </LocalizationProvider>
                    {(startDate === null && !getCouponDetailsById.startDate) && (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Start Time
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        value={startTime===null ?timecheck(getCouponDetailsById.startTime):startTime}
                        onChange={(time) => {
                          setStartTime(time)
                          startTimeExtracting(time)
                        }}
                        // defaultValue={startTime==null?getCouponDetailsById.startTime:startTime}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ backgroundColor: "#FFFFFF" }}
                            placeholder="Select Time"
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {(startTime === null && !getCouponDetailsById.startTime)&& (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      End Date
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        minDate={new Date()}
                        value={endDate===null?getCouponDetailsById.endDate:endDate}
                        onChange={(date) => {
                          setEndDate(date);
                          EndDateExtracting(date);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ backgroundColor: "#FFFFFF" }}
                          />
                        )}
                        onYearChange={onYearChangeHandler}
                        orientation={"portrait"}
                      />
                    </LocalizationProvider>
                    {(endDate === null && !getCouponDetailsById.endDate) && (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "10px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      End Time
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* {console.log("line 580 endtime",getCouponDetailsById.endTime,timecheck(getCouponDetailsById.endTime))} */}
                      <MobileTimePicker
                        value={endTime===null ?timecheck(getCouponDetailsById.endTime):endTime }
                        onChange={(time) => {
                          console.log("line 582",time)
                          setEndTime(time)
                          endTimeExtracting(time)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ backgroundColor: "#FFFFFF" }}
                            placeholder="Select Time"
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {(endTime === null && !getCouponDetailsById.endTime)  && (
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                        This Field Required
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      No. Of Usage Per User
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="No. Of Usage Per User"
                      defaultValue={getCouponDetailsById.noOfUsagePerUser}
                      {...register("noofusage")}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.noofusage?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Total No Of Usage
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Total No Of Usage"
                      {...register("totalnoofusage")}
                      defaultValue={getCouponDetailsById.totalNoOfUsage}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.totalnoofusage?.message}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
  
            {/* ----------------------------------Coupon Payment Details----------------------- */}


            <Typography sx={{ mt: "10px",fontSize:"17px",mb:"10px" }} variant="h5">
              Coupon Payment Details
            </Typography>
            <Card>
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => setConsultaion(e.target.checked)}
                      checked={selectedCheckbox.includes('checkbox1')}
                      onChange={() => {
                        handleCheckboxChange('checkbox1')
                      }}
                    />
                  }
                  label="For Consultation Charges"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        className="formlabel"
                      >
                        Coupon Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={(e) => {
                            setAlignment(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({consultationChargePlanAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({consultaionChargePlanAmountPercentage:"",consultationChargeMaxDiscount:""})
                            }
                          }}
                          aria-label="Platform"
                          // disabled={!consultaion}
                          disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                          {alignment === "inpercentage"  &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!consultaion}
                              disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                              sx={{
                                "& input::placeholder": {
                                  textAlign: "right",
                                },
                              }}
                              name="percentage"
                              placeholder="%"
                              {...register("consultaionChargePlanAmountPercentage")}
                              type="text"
                              defaultValue={getCouponDetailsById.consultationChargePlanAmtPer}
                            />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.consultaionChargePlanAmountPercentage?.message}
                            </span>
                            </>
                            }
                          {alignment === "fixedamount"  &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!consultaion}
                              disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                              placeholder="Rs."
                              name="amount"
                              {...register("consultationChargePlanAmountValue")}
                              defaultValue={getCouponDetailsById.consultationChargePlanAmounttVal}
                            />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.consultationChargePlanAmountValue?.message}
                            </span>
                            </>
                            }
                          
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!consultaion}
                        disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ? selectedCheckbox.includes("checkbox3"):[]}
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!consultaion}
                        disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ? selectedCheckbox.includes("checkbox3"):[]}
                        {...register("consultationChargeMaxDiscount")}
                        defaultValue={getCouponDetailsById.consultationChargeMaxDiscount}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.consultationChargeMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
              {/* ----------For Rest of Bill------------------- */}
  
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => setRestofBill(e.target.checked)}
                      checked={selectedCheckbox.includes('checkbox2')}
                      onChange={() => handleCheckboxChange('checkbox2')}
                    />
                  }
                  label="For Rest Of The Bill"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                        className="formlabel"
                      >
                        Coupon Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={restofBillToggle}
                          exclusive
                          onChange={(e) => {
                            setRestofBillToggle(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({restOfBillCouponAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({restOfBillCouponAmountPercentage:"",restOfBillMaxDiscount:""})
                            }
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                          {restofBillToggle === "inpercentage" &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!restofBill}
                              disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                              sx={{
                                "& input::placeholder": {
                                  textAlign: "right",
                                },
                              }}
                              type="number"
                              defaultValue={getCouponDetailsById.restOfTheBillPlanAmtPer}
                              placeholder="%"
                              {...register("restOfBillCouponAmountPercentage")}
                            />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.restOfBillCouponAmountPercentage?.message}
                            </span>
                            </>
                              }
                            {restofBillToggle === "fixedamount" &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!restofBill}
                              disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                              placeholder="Rs."
                              {...register("restOfBillCouponAmountValue")}
                              type="number"
                              defaultValue={getCouponDetailsById.restOfTheBillPlanAmounttVal}
                            />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.restOfBillCouponAmountValue?.message}
                            </span>
                            </>
                          }
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                        type="number"
                        {...register("restOfBillMaxDiscount")}
                        defaultValue={getCouponDetailsById.restOfTheBillMaxDiscount}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
              {/* ----------------Total Bill--------------------- */}
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => setTotalBill(e.target.checked)}
                      checked={selectedCheckbox.includes("checkbox3")}
                    onChange={() => {
                      handleCheckboxChange('checkbox3')
                    }}
                    />
                  }
                  label="For Total Bill"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                        className="formlabel"
                      >
                        Coupon Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={totalbillToggle}
                          exclusive
                          aria-label="Platform"
                          onChange={(e) => {
                            setTotalBillToggle(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({totalBillPlanAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({totalBillPlanAmountPercentage:"",totalBillMaxDiscount:""})
                            }
                          }}
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                          {totalbillToggle === "inpercentage" &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!totalBill}
                              disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                              sx={{
                                "& input::placeholder": {
                                  textAlign: "right",
                                },
                              }}
                              type="text"
                              InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                              placeholder="%"
                              {...register("totalBillPlanAmountPercentage")}
                              defaultValue={getCouponDetailsById.totalBillPlanAmtPer}
                            />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                                {errors.totalBillPlanAmountPercentage?.message}
                            </span>
                            </>
                            }
                          {totalbillToggle === "fixedamount" &&
                            <>
                            <TextField
                              size="small"
                              // disabled={!totalBill}
                              disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                              placeholder="Rs."
                              type="number"
                              {...register("totalBillPlanAmountValue")}
                              defaultValue={getCouponDetailsById.totalBillPlanAmounttVal}
                            />
                              <span style={{ color: "red",fontSize:"10px" }} className="error">
                                {errors.totalBillPlanAmountValue?.message}
                              </span>
                            </>
                          }
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3')&& totalbillToggle==="inpercentage" ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3')&& totalbillToggle==="inpercentage" ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                        type="number"
                        {...register("totalBillMaxDiscount")}
                        defaultValue={getCouponDetailsById.totalBillMaxDiscount}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.totalBillMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
              {/* ----------------------------Free Consultation--------------------------- */}
              {/* <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      onClick={(e) => setFreeConsultation(e.target.checked)}
                    />
                  }
                  label="Free Consultation"
                />
                <br />
  
                <FormControl sx={{ width: "35%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                    disabled={!freeConsultation}
                  >
                    No. Of Free Consultation
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="No. Of Free Consultation"
                    type="number"
                    disabled={!freeConsultation}
                    {...register("noOfFreeConsulation")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.noOfFreeConsulation?.message}
                  </span>
                </FormControl>
              </Box> */}
            </Card>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
              <Button
                disabled={isSubmitting}
                sx={{
                  backgroundColor: isSubmitting?"#898989":"#2d256c",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  padding: "8px 15px",
                  "&:hover": { backgroundColor: "#2d256c!important" },
                }}
                size="small"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </div>
    );
  };
  
  export default EditCoupon;
  