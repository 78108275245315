import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPlanHistoryDetails } from '../../services/PlansAndProcedure';

const columns = [
    { id: "planname", label: "Plan Name", minWidth: 80 },
    {
      id: "plandiscount",
      label: "Plan Discount",
      minWidth: 80,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "customername",
      label: "Customer Name",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "totalbillamount",
      label: "Total Bill Amount",
      minWidth: 80,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "planusedon",
      label: "Plan Used On",
      minWidth: 120,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "customernumber",
        label: "Customer Phone Number",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
  ];
const PlanBillingHistory = () => {
    const {planname}=useParams()
    console.log("plan name",planname)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [planHistory,setPlanHistory]=useState([])
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getIndividualPlanHistorydetails=async()=>{
        console.log(planname)
          try {
            let res=await getPlanHistoryDetails(planname);
            let data=res.data;
            setPlanHistory(data);
            console.log("individual plan history",data)
          } catch (error) {
            console.log(error.message)
          }
    }

    useEffect(()=>{
        getIndividualPlanHistorydetails()
    },[])
  return (
    <div>
        <Box>
        <Typography variant="h5" fontSize="17px">Plan Billing and History</Typography>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            mt: "10px",
            backgroundColor: "#f7f7fc",
          }}
          className="scrollbar"
        >
          <TableContainer >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#2D256C",
                        fontWeight: 600,
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {planHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, id) => {
                    return (
                      <TableRow
                        sx={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                        key={item.id}
                        tabIndex={-1}
                      >
                        <TableCell align="left" sx={{color:"#444444",fontSize:"14px"}}>{item.planName}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"14px"}}>{item.planDiscount}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"14px"}}>{item.customerName}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"14px"}}>{item.totalBillAmount}</TableCell>
                        <TableCell align="center" sx={{color:"#444444",fontSize:"14px"}} >{item.planUsedOn}</TableCell>
                        <TableCell align="center" sx={{color:"#444444",fontSize:"14px"}} >{item.customerPhoneNumber}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={planHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{backgroundColor:"#FFFFFF"}}
          />
        </Paper>
        </Box>
    </div>
  )
}

export default PlanBillingHistory