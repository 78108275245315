import React, { useState } from 'react'
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import { useEffect } from 'react';
import { getCustomerDetailsByMerchantTransactionId, setCustomerLoginStatus } from '../../services/login';
import Footer from '../Footer';

const B2CPaymentStatus = () => {
    const [status, setStatus] = useState(false);
    const [details, setDetails] = useState();
    const [payment, setPayment] = useState();
    const {id}= useParams()

    useEffect(()=>{
      const getDetails= async ()=>{
        const {data}= await getCustomerDetailsByMerchantTransactionId(id)
        console.log('data: ', data);
        if(data){
          if(JSON.parse(data?.paymentStatus)?.success===true){
            console.log("Tirrruuuuu");
            setStatus(true)
            setPayment(JSON.parse(data?.paymentStatus))
            console.log('JSON.parse(data?.paymentStatus: ', JSON.parse(data?.paymentStatus));
            const res = await setCustomerLoginStatus(data?.b2cCustomerDetails?.id)
            console.log('res: ', res);
          }
          setDetails(data)
        }
      }
      getDetails()
    },[])
    const navigate=useNavigate()
  return (
    <div>
      <Navbar/>
        <Box className="b2cpaymentmainbox">
        <Card>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={7}>
              <Card sx={{ padding: "3%!important" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box>
                    {status ? (
                      <VerifiedIcon sx={{ color: "green", fontSize: "40px" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red", fontSize: "40px" }} />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", fontSize: "17px" }}
                    >
                      {status ? "Plan Purchased" : "Payment Failed"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#898989",
                        fontWeight: 600,
                      }}
                    >
                      {status
                        && `Your ${details?.retailPlanAndPricing?.planName} Plan has been Purchased. This is your Plan Id ${details?.retailPlanAndPricing?.uniqueId}`
                      }
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card sx={{ mt: "20px" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{backgroundColor:"#febfde",padding: "2%!important"}}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color:  "#2d256c" ,
                      }}
                    >
                      Customer Name
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color:  "#2d256c",
                      }}
                    >
                      Customer ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                     {details?.b2cCustomerDetails?.name}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                     {details?.b2cCustomerDetails?.uniqueId}
                    </Typography>
                  </Grid>
                </Grid>

                <Box mt="10px">
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color:"#2d256c",
                    }}
                  >
                    Note:
                  </Typography>
                  <ul
                    style={{
                      color: "#2d256c",
                      marginTop: "10px",
                    }}
                  >
                    
                      <li>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#898989",
                        }}
                      >
                        Go to the login Screen.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#898989",
                        }}
                      >
                        Select the option Customer form the drop-down field
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#898989",
                        }}
                      >
                        Use your registered number to generate OTP
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#898989",
                        }}
                      >
                        Enter the OTP & Login the system.
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#d83f87" ,
                        mt:"20px"
                      }}
                    >
                      Facing any issue while logging in, connect with us at <span style={{color:"#200f5d"}} >+91 80 4009 1997 | +91 73 0000 2911</span> or mail us at support@tron.health.
                    </Typography>
                </Box>
              </Card>
            </Grid>

            {/* --------------------------------Right side-------------------------- */}
            <Grid item xs={12} md={5}>
              <Card sx={{ padding: "2%!important" }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", fontSize: "17px",color:"#22155b" }}
                >
                  Payment Details
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#4f4f4f",
                      }}
                    >
                      Reference ID
                    </Typography>
                    {/* <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: "#4f4f4f",
                      }}
                    >
                      Date of Payment
                    </Typography> */}
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: "#4f4f4f",
                      }}
                    >
                      Payment Mode
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                   {payment?.data?.merchantTransactionId}
                    </Typography>
                    {/* <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      12 January,2023
                    </Typography> */}
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                       {payment?.data?.paymentInstrument?.type}
                    </Typography>
                    
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#22155b",
                      }}
                    >
                      Plan Price
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#22155b",
                      }}
                    >
                      GST Amount
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "5px",
                        color: "#04aa41",
                      }}
                    >
                      Coupon Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                      ₹ {parseInt(details?.b2cCustomerDetails?.pricePerPerson)*parseInt(details?.b2cCustomerDetails?.noOfMembers)}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                      ₹ {parseInt(details?.b2cCustomerDetails?.taxes)}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "5px",
                      }}
                    >
                      ₹ {parseInt(details?.b2cCustomerDetails?.discount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#d83f87" ,
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#4f4f4f" ,
                      }}
                    >
                      ₹ {parseInt(details?.b2cCustomerDetails?.total)}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
            <Button
              sx={{
                backgroundColor: "#3E2732",
                textAlign: "left",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "12px",
                padding: "8px 15px",
                borderRadius: "3px",

                "&:hover": { backgroundColor: "#3E2732!important" },
              }}
              size="small"
              onClick={()=>navigate("/login")}
            >
              Click here to Login
            </Button>
          </Box>
        </Card>
        {/* ---------------------------------footer------------------------------ */}
      <Footer/>
      </Box>
    </div>
  )
}

export default B2CPaymentStatus