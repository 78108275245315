import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ChangingPassword } from "../../services/clinicOnboarding";
import { toast } from "react-toastify";
import "../../styles/ClinicAppointments.scss"

// const passwordregex =/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
// the above password is regex for all cases Onecapital OneSmall special characters and number;

const schema = yup
  .object({
    originalpassword:yup.string().required("Password is Required"),
    newpassword: yup.string().required("Password is Required"),
    confirmnewpassword: yup.string().oneOf([yup.ref('newpassword')], 'Your passwords do not match.').required("Password is Required"),
  })
  .required();

const PasswordChange = () => {
  const [showOriginalPassword, setShowOriginalPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const token=JSON.parse(localStorage.getItem("token"));
  const email=token?.email

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async(data) => {
    if (data) {
      console.log(data);
      const formdata={
        username : email,
        oldpassword : data.originalpassword,
        newpassword : data.confirmnewpassword
      }
      console.log("formdata",formdata)
      try {
        const res= await ChangingPassword(formdata)
        if(res){
          toast.success("Password Changed Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          reset()
        }
      } catch (error) {
        toast.error("Please Check Your Server", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  return (
    <div >
      <Typography
        sx={{ fontWeight: "800", fontSize: "17px", color: "#D83F87",mb:"10px" }}
      >
        Change Password
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "95%",
            height: "100vh",
            backgroundColor: "#FFFDFF",
            padding: "20px 0px 0px 20px",
          }}
        >
          <FormControl className="formcontrol">
            <FormLabel
              sx={{ color: "#2D256C", fontSize: "14px",fontWeight:600 }}
              className="formlabel"
            >
              Original Password
            </FormLabel>
            <TextField
              className="inputbox"
              size="small"
              placeholder="Original Password"
              type={showOriginalPassword ? "text" : "password"}
              sx={{ backgroundColor: "#FFFFFF" }}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        "&:hover": { backgroundColor: "#FFFFFF!important" },
                      }}
                      onClick={() =>
                        setShowOriginalPassword(
                          (showOriginalPassword) => !showOriginalPassword
                        )
                      }
                    >
                      {showOriginalPassword ? (
                        <VisibilityIcon sx={{ color: "#D83F87" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#D83F87" }} />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
              {...register("originalpassword")}
            />
            <span style={{ color: "red" }} className="error">
              {errors.originalpassword?.message}
            </span>
          </FormControl>
          <FormControl className="formcontrol">
            <FormLabel
              sx={{ color: "#2D256C", fontSize: "14px",fontWeight:600 }}
              className="formlabel"
            >
              New Password
            </FormLabel>
            <TextField
              className="inputbox"
              size="small"
              placeholder="New Password"
              type={showNewPassword ? "text" : "password"}
              sx={{ backgroundColor: "#FFFFFF" }}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        "&:hover": { backgroundColor: "#FFFFFF!important" },
                      }}
                      onClick={() =>
                        setShowNewPassword(
                          (showNewPassword) => !showNewPassword
                        )
                      }
                    >
                      {showNewPassword ? (
                        <VisibilityIcon sx={{ color: "#D83F87" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#D83F87" }} />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
              {...register("newpassword")}
            />
            <span style={{ color: "red" }} className="error">
              {errors.newpassword?.message}
            </span>
          </FormControl>
          <FormControl className="formcontrol">
            <FormLabel
              sx={{ color: "#2D256C", fontSize: "14px",fontWeight:600 }}
              className="formlabel"
            >
              Confirm Password
            </FormLabel>
            <TextField
              className="inputbox"
              size="small"
              placeholder="New Password"
              type={confirmNewPassword ? "text" : "password"}
              sx={{ backgroundColor: "#FFFFFF" }}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        "&:hover": { backgroundColor: "#FFFFFF!important" },
                      }}
                      onClick={() =>
                        setConfirmNewPassword(
                          (confirmNewPassword) => !confirmNewPassword
                        )
                      }
                    >
                      {confirmNewPassword ? (
                        <VisibilityIcon sx={{ color: "#D83F87" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#D83F87" }} />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
              {...register("confirmnewpassword")}
            />
            <span style={{ color: "red" }} className="error">
              {errors.confirmnewpassword?.message}
            </span>
          </FormControl>
          <Box>
            <Button
              type="submit"
              sx={{
                backgroundColor: "#D83F87",
                textAlign: "left",
                color: "#FFFFFF",
                textTransform: "capitalize",
                padding: "8px 15px",
                fontSize:"12px"
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default PasswordChange;
