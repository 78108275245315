import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import {
  addCustomer,
  getCustomerDetailsById,
  getPlansForCustomerDropdown,
} from "../../services/customerOnboarding";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";

const phoneRegExp = /^[6-9]\d{9}$/;
const schema = yup
  .object({
    firstName: yup.string().required("This Field is Required"),
    lastName: yup.string().required("This Field is Required"),
    mobileNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
    email: yup.string().email("Invalid email").required("Email is required"),
    dob: yup.string().required("This Field is required"),
    planUniqueId:yup.string().required("Please select a plan").nullable(false)
  })
  .required();

const AddCustomer = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [customerDataById, setCustomerDataById] = useState({});
  const [plansData,setPlansData]=useState([]);
  const { id } = useParams();
  const [fetching, setFetching] = useState(Boolean(id));
  const [isSubmitting,setIsSubmitting]=useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const navigate = useNavigate();
  // console.log("id",id)

  function handledate(value) {
    let currentdate = value?.$D;
    let month = value?.$M + 1;
    let year = value?.$y;
    let entireDate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setDateOfBirth(entireDate);
    return entireDate;
  }

  //   console.log(dateOfBirth)
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      dob: dateOfBirth,
      planUniqueId:""
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    if (id) {
      const formdata = {
        id: id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileNumber: values.mobileNumber,
        uniqueId: uniqueId,
        dob: values.dob,
        planUniqueId:values.planUniqueId
      };
      try {
        setIsSubmitting(true)
        let res = await addCustomer(formdata);
        toast.success("Customer Updated Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (res) {
          actions.resetForm();
          navigate("/user/customerlisting");
        }else{
          setIsSubmitting(false)
        }
      } catch (error) {
        toast.error("Please Check your server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmitting(false)
      }
    } else {
      if (values) {
        // console.log("values",values)
        const formdata = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobileNumber: values.mobileNumber,
          uniqueId: uniqueId,
          dob: values.dob,
          planUniqueId:values.planUniqueId
        };
        // console.log("formdata", formdata);
        try {
          setIsSubmitting(true)
          let res = await addCustomer(formdata);
          toast.success("Customer Added Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (res) {
            actions.resetForm();
            navigate("/user/customerlisting");
          }
        } catch (error) {
          toast.error("Please Check your server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      }
    }
  }

  useEffect(() => {
    const getUniqueCustomerDetails = async () => {
      try {
        setFetching(true);
        let res = await getCustomerDetailsById(id);
        let data = res.data;
        setCustomerDataById(data);
        if (data) {
          setFetching(false);
          setFieldValue("firstName", data.firstName, true);
          setFieldValue("lastName", data.lastName, true);
          setFieldValue("email", data.email, true);
          setFieldValue("mobileNumber", data.mobileNumber, true);
          setFieldValue("dob", data.dob, true);
          setDateOfBirth(data.dob)
          setFieldValue("planUniqueId",data.planUniqueId,true)
        }
        // console.log("customer Data by id",data)
      } catch (error) {
        console.log(error.message);
      }
    };
    if (id) {
      getUniqueCustomerDetails();
    }

    const getCustomerPlansInDropdown=async()=>{
      try {
        const res=await getPlansForCustomerDropdown();
        const data=res.data
        if(data){
          console.log("plansdata",data)
          setPlansData(data)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getCustomerPlansInDropdown()
  }, [id]);

  // console.log(fetching);

  if (fetching)
    <>
      <Loader />
    </>;
  // console.log(customerDataById);
  return (
    <div>
      <Typography variant="h5" fontSize="17px">
        {id ? "Edit Customer" : "Add Customer"}
      </Typography>
      <Card>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  First Name
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.firstName}
                  placeholder="First Name"
                  name="firstName"
                  id="firstName"
                />
              </FormControl>
              {errors.firstName && touched.firstName && (
                <span style={{ color: "red" }}>{errors.firstName}</span>
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Last Name
                </FormLabel>
                <TextField
                  className="inputbox"
                  size="small"
                  onChange={handleChange}
                  value={values.lastName}
                  onBlur={handleBlur}
                  placeholder="Last Name"
                  name="lastName"
                  id="lastName"
                />
              </FormControl>
              {errors.lastName && touched.lastName && (
                <span style={{ color: "red" }}>{errors.lastName}</span>
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Email
                </FormLabel>
                <TextField
                  className="inputbox"
                  size="small"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  name="email"
                  id="email"
                />
              </FormControl>
              {errors.email && touched.email && (
                <span style={{ color: "red" }}>{errors.email}</span>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Mobile Number
                </FormLabel>
                <TextField
                  className="inputbox"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobileNumber}
                  placeholder="Mobile Number"
                  name="mobileNumber"
                  id="mobileNumber"
                />
              </FormControl>
              {errors.mobileNumber && touched.mobileNumber && (
                <span style={{ color: "red" }}>{errors.mobileNumber}</span>
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  D.O.B
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateOfBirth}
                    onChange={(value) => {
                      setFieldValue("dob", handledate(value), true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                      />
                    )}
                    orientation={"portrait"}
                    name="dob"
                    id="dob"
                  />
                </LocalizationProvider>
              </FormControl>
              {errors.dob && touched.dob && (
                <span style={{ color: "red" }}>{errors.dob}</span>
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
              <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Select Plan
                </FormLabel>
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                id="planUniquId"
                name="planUniqueId"
                size="small"
                value={values.planUniqueId}
              >
                <MenuItem value=""></MenuItem>
                {
                  plansData.map((el,id)=>{
                    return <MenuItem value={el.planUniqueId}>{el.planName}</MenuItem>
                  })
                }
              </Select>
              </FormControl>
              {errors.planUniqueId && touched.planUniqueId && (
                <span style={{ color: "red" }}>{errors.planUniqueId}</span>
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={isSubmitting}
              sx={{
                backgroundColor: isSubmitting?"#898989":"#2d256c!important",
                textAlign: "center",
                color: "#FFFFFF",
                mt: "10px",
                textTransform: "capitalize",
                fontSize: "12px",
                padding:"8px 15px",
                borderRadius:"3px",
                "&:hover": { backgroundColor: "#2d256c!important" },
              }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Card>
    </div>
  );
};

export default AddCustomer;
