import {
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import eye from "../../assets/blackeye.svg";
import ent from "../../assets/blackent.svg";
import tooth from "../../assets/pinktooth.svg";
import stomach from "../../assets/blackstomach.svg";
import uterus from "../../assets/blackuterus.svg";
import "../../styles/PlansProcedure.scss";
import AddSpeciality from "./AddSpeciality";
import AddProcedure from "./AddProcedure";
import { useNavigate } from "react-router-dom";
import AddComplaints from "./AddComplaints";
import { getClinicSpecialities } from "../../services/PlansAndProcedure";
import config from "../../config.json";
import "../../styles/layout.scss"
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const PlansProcedure = () => {
  const [specialities, setSpecialities] = useState([]);
  const [pageRender,setPageRender]=useState(false)
  const navigate = useNavigate();
  // console.log("specialities", specialities);
  const {permissionsData}=useContext(Permissions);
  let procedurePermissionsModule=permissionsData?.filter(item=>item.module==="procedure");
  let procedurePermissions=procedurePermissionsModule && procedurePermissionsModule[0]?.permissions;
  let AddProcedurePermission = procedurePermissions?.find((item) => item.action === "Add Procedure")?.permission;
  let AddComplaintsPermission = procedurePermissions?.find((item) => item.action === "Add Complaint")?.permission;
  let AddSpecialitiesPermission = procedurePermissions?.find((item) => item.action === "Add Speciality")?.permission;
  let ViewPermission = procedurePermissions?.find((item) => item.action === "View")?.permission;
  // let ViewPermission=false
  console.log("procedurepermissions",procedurePermissions);

  const getSpecialities=async()=>{
    try {
      let response=await getClinicSpecialities();
      let data=response.data;
      console.log(data)
      setSpecialities(data)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(()=>{
    getSpecialities()
  },[pageRender])
  return (
    <div>
      <Box id="headingbox">
        <Grid container spacing={1}>
          <Grid item md={8} sm={6} xs={12}>
            <Typography variant="h5" fontSize="17px">Procedures & Speciality</Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12} sx={{pt:"7px" }}>
          <Grid container spacing={1}>
          <Grid item md={4} sm={4} xs={4}>
          {AddComplaintsPermission && <AddComplaints
            specialities={specialities}
            setSpecialities={setSpecialities}
            setPageRender={setPageRender}
          />}
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
          {AddProcedurePermission && <AddProcedure
            specialities={specialities}
            setSpecialities={setSpecialities}
            getSpecialities={getSpecialities}
            setPageRender={setPageRender}
          />}
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
          {AddSpecialitiesPermission && <AddSpeciality
            specialities={specialities}
            setSpecialities={setSpecialities}
            setPageRender={setPageRender}
          />}
          </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Box>
      <Card className="specialitiescard" sx={{ height: "70vh",boxShadow:"none",border:"0.5px solid #D9D9D9" }}>
        <Box className="services">
          {specialities.map((el, id) => {
            return (
              <Box
                onClick={() => {
                  if(ViewPermission){
                    navigate(`/user/procedures/${el.speciality}`);
                  }
                }}
                sx={{ cursor: ViewPermission?"pointer":"default" }}
              >
                <Box className="squarebox">
                  <img src={config.imageUrl+el.specialityImage} alt="Eye" />
                </Box>
                <Typography>{el.speciality}</Typography>
              </Box>
            );
          })}
        </Box>
      </Card>
    </div>
  );
};

export default PlansProcedure;
