import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../styles/MyCoupons.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getCouponDetailsForCustomers,
  getCouponListForCustomer,
} from "../../services/PlansAndProcedure";

const MyCoupons = () => {
  const navigate = useNavigate();
  const [myCoupons, setMyCoupons] = useState([]);

  useEffect(() => {
    const getCoupons = async () => {
      const { data } = await getCouponListForCustomer();
      console.log("data: ", data);
      if (data) {
        setMyCoupons(data);
      }
    };
    getCoupons();
  }, []);

  return (
    <>
      <>
        <Card
          sx={{
            padding: "1% 2% !important",
            marginBottom: "10px",
            border: "0.5px solid #D9D9D9",
            borderRadius: "5px",
            overflowY: "scroll",
            height: "65vh",
            boxShadow:"none"
          }}
          className="desktopcard"
        >
          {myCoupons?.map((el, id) => {
            return (
              <>
                <Card
                  sx={{
                    padding: "0px !important",
                    marginBottom: "10px",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "5px",
                    cursor: "pointer",
                    boxShadow:"none"
                  }}
                  onClick={() => {
                    navigate(`/user/coupondetails/${el.id}`);
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={2}
                      sx={{ padding: 1, backgroundColor: "#F5F5F5" }}
                    >
                      <Box
                        sx={{
                          paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Coupon Code
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "#2D2828",
                          }}
                        >
                          {el?.couponCode}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7} sx={{ padding: 1 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Number Of Usage
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }} variant="h6">
                            {el?.noOfUsagePerUser}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            MOQ
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }} variant="h6">
                            {el?.moq}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Specialities Covered
                          </Typography>
                          {/* <Grid container spacing={1}>
                            {el?.speciality?.split(",").map((item) => (
                              <Grid item xs={3}>
                                <Box sx={{ textAlign: "center", width: "90%" }}>
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      background: "#FFEFF9",
                                      padding: "5px",
                                      color: "gray",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid> */}
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Box
                              sx={{
                                padding: "8px",
                                background: "#FFEFF9",
                                borderRadius: "10",
                                fontSize: "10px",
                                color: "grey",
                              }}
                            >
                              {el.speciality}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={3} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        Start Time
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }} variant="h6">
                        {el?.startTime}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        End Time
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }} variant="h6">
                        {el?.endTime}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </>
            );
          })}
        </Card>

        {/* ------------------------------------Mobile screen card--------------------------- */}
        <Box>
          {myCoupons?.map((item, id) => {
            return (
              <Card
                sx={{
                  padding: "0px!important",
                  marginBottom: "10px",
                  border: "0.5px solid #D9D9D9",
                  borderRadius: "5px",
                  boxShadow:"none"
                }}
                onClick={() => {
                  navigate(`/user/coupondetails/${item.id}`);
                }}
                className="mobilescreencard"
              >
                <Box className="couponcodehead">
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    {item.couponCode}
                  </Typography>
                  <VisibilityIcon />
                </Box>
                <Box sx={{ p: "2%" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" className="title">
                        Start Time
                      </Typography>
                      <Typography variant="h6" className="value">
                        {item.startTime}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" className="title">
                        End Time
                      </Typography>
                      <Typography variant="h6" className="value">
                        {item.endTime}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ pt: "5px" }} spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" className="title">
                        No. Of Usage
                      </Typography>
                      <Typography variant="h6" className="value">
                        {item.noOfUsagePerUser}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" className="title">
                        MOQ
                      </Typography>
                      <Typography variant="h6" className="value">
                        {item.moq}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#D83F87",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Specialities Covered
                    </Typography>

                    {/* <Box sx={{ textAlign: "center", width: "100%", display:"flex",flexWrap:"wrap",gap:"10px", mt:"10px" }}>
                      {item?.speciality?.map((el) => {
                        return (
                          <Typography
                            sx={{
                              fontSize: "10px",
                              background: "#FFEFF9",
                              padding: "5px 15px",
                              color: "gray",
                              borderRadius: "5px",
                            }}
                          >
                            {el}
                          </Typography>
                        )
                      })}
                    </Box> */}
                    <Box
                      sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                    >
                      <Box
                        sx={{
                          padding: "8px",
                          background: "#FFEFF9",
                          borderRadius: "10",
                          fontSize: "12px",
                          color: "grey",
                        }}
                      >
                        {item.speciality}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            );
          })}
        </Box>
      </>
    </>
  );
};

export default MyCoupons;
