import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  activateOrDeactivatePlan,
  getPlansAndPricingList,
} from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const columns = [
  { id: "s.no", label: "S.No", minWidth: 50 },
  { id: "planname", label: "Plan Name", minWidth: 100 },
  {
    id: "price",
    label: "Price",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "validity",
    label: "Validity",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "moq",
    label: "MOQ",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const PlansAndPricing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [plansAndPricingData, setPlansAndPricingData] = useState([]);
  const [acceptReject, setAcceptReject] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const username = token?.username;
  const {permissionsData}=useContext(Permissions);
  let plansPermissionsModule=permissionsData?.filter(item=>item.module==="plans");
  let planPermissions=plansPermissionsModule[0]?.permissions;
  let AddPermission = planPermissions?.find((item) => item.action === "Add Plan")?.permission;
  let PlanHistoryPermission = planPermissions?.find((item) => item.action === "Plan History")?.permission;
  let DeactivatePermission = planPermissions?.find((item) => item.action === "Deactivate")?.permission;
  let EditPermission = planPermissions?.find((item) => item.action === "Edit")?.permission;
  let ListPermission=planPermissions?.find((item) => item.action === "List")?.permission;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRejectdialogOpen = (id) => {
    console.log("deactivate id", id);
    setRejectOpen(true);
    setItemid(id);
  };
  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  const getThePlansAndPricing = async () => {
    try {
      let response = await getPlansAndPricingList(uniqueId);
      const data = response.data;
      console.log(data);
      setPlansAndPricingData(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const formatRupeesWithCommas=(number)=>{
    if (typeof number === "undefined" || number === null) {
    return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleDeactivatePlan = async (id) => {
    const decativatePlan = {
      id: id,
      status: "Inactive",
      createdBy: username,
    };
    // console.log("Rejected data.........",decativatePlan)
    try {
      await activateOrDeactivatePlan(decativatePlan);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      toast.success("Sucessfully Deactivated the Plan", {
        position: "top-right",
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getThePlansAndPricing();
  }, [acceptReject]);
  return (
    <div>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" fontSize="17px">B2B Plans & Pricing</Typography>
          {AddPermission && <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "12px",
              borderRadius:"3px",
              padding: "10px 15px",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            size="small"
            type="submit"
            onClick={() => navigate("/user/addplan")}
          >
            Add Plan
          </Button>}
        </Box>

        {/* -------------------Table--------------- */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            mt: "10px",
            backgroundColor: "#f7f7fc",
          }}
          className="scrollbar"
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#2D256C",
                        fontWeight: 600,
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {ListPermission && <TableBody>
                {plansAndPricingData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, id) => {
                    return (
                      <TableRow
                        sx={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                        key={item.id}
                        tabIndex={-1}
                      >
                        <TableCell
                          align="left"
                          sx={{ color: "#444444", fontSize: "14px" }}
                        >
                          {id + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "#444444", fontSize: "14px" }}
                        >
                          {item.planName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "#444444", fontSize: "14px" }}
                        >
                          {formatRupeesWithCommas(item.planPrice)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "#444444", fontSize: "14px" }}
                        >
                          {item.validtill}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "#444444", fontSize: "14px" }}
                        >
                          {item.moq}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#D83F87" }}>
                          <VisibilityIcon sx={{color:"#2d256c"}} /> 
                          {(EditPermission && item.status )?<EditIcon sx={{color:"#2d256c"}} onClick={()=>{
                            navigate(`/user/editplan/${item.id}`)
                          }}  />:""}{" "}
                          {DeactivatePermission && <DoDisturbIcon sx={{color:"#2d256c"}}
                            onClick={() => {
                              console.log(item.id);
                              handleRejectdialogOpen(item.id);
                            }}
                          />}{" "}
                          {PlanHistoryPermission && <SummarizeIcon sx={{color:"#2d256c"}} onClick={()=>{
                            navigate(`/user/planbillinghistory/${item.planName}`)
                          }}/>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={plansAndPricingData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ backgroundColor: "#FFFFFF" }}
          />
        </Paper>
      </Box>
      {/* -------------------------Dialog box for Dectivate plan---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Are You Sure To Deactivate This Plan
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              handleDeactivatePlan(itemId);
              handleRejectDialogClose();
            }}
            autoFocus
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
          >
            Yes
          </Button>
          <Button  
          sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }} 
            onClick={handleRejectDialogClose}
            >No</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlansAndPricing;
