import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import { Search, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import { CssTextField } from "../../styles/textFields";
import { StyledTableBagCell, StyledTableBagRow } from "../../styles/table";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  getStaffDataDateRange,
  getStaffList,
} from "../../services/staffOnboarding";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { roles } from "../../services/rolesAndPermissions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "roleName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },

  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableBagRow>
        {headCells.map((headCell, index) => (
          <StyledTableBagCell
            key={index}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableBagCell>
        ))}
      </StyledTableBagRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function RolesList() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [rowData, setRowData] = React.useState([]);
  const [dateTo, setDateTo] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState("");

  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    const getStaffLists = async () => {
      const { data } = await roles();
      console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      const sortedData = data;
      setRowData(sortedData);
    };
    getStaffLists();
  }, []);

  const rows = rowData.filter(
    (item) =>
      item.role
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase()) );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //   const downloadExcel = () => {
  //     const workSheet = XLSX.utils.json_to_sheet(rowData);
  //     const workBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
  //     //Buffer
  //     let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //     //Binary String
  //     XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  //     //Download
  //     XLSX.writeFile(workBook, "branches.xlsx");
  //   };

  function formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }
  async function handleDateRange() {
    if (!dateTo || !dateFrom) {
      return;
    } else {
      const from = formattedDate(dateFrom);
      // console.log('from: ', from);
      const to = formattedDate(dateTo);
      const { data } = await getStaffDataDateRange(from, to);
      // console.log('data: ', data);
      if (data) {
        setRowData(data);
        setOpen(!open);
      }
    }
  }

  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* <div style={{ marginTop: "75px" }}></div> */}
      {/* <QuickTracking /> */}
      <Typography variant="h5" fontSize="17px" mb="10px">
        Roles List
      </Typography>

      <Card
        sx={{
          // margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={10} xs={7} sm={6} mb={2}>
            {" "}
            {/* xs={10} removed here added md sm */}
            <CssTextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="Search..."
              type="text"
              value={search}
              className="searchboxtextfield"
              onChange={(e) => setSearch(e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: "0px" }}>
                    <IconButton
                      sx={{
                        backgroundColor: "#F7F7FC",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                      edge="start"
                    >
                      <Search sx={{ backgroundColor: "#F7F7FC" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={0.5} xs={1} sm={2}>
            
          </Grid>

          <Grid item md={1.5} xs={4} sm={4}>
            {" "}
            {/* xs={1.5} removed here added */}
            {/* <Link to="/vendor/vendorDeliveryRequest" className="link-style"> */}
            <Link to="/user/createRole">
              <Button
                className="addnewbtn"
                variant="contained"
                sx={{ marginTop: 0,padding:"8px 15px",backgroundColor:"#2d256c!important","&:hover":{backgroundColor:"#2d256c!important"},fontSize:"12px" }}
              >
                Add New
              </Button>
            </Link>
            {/* </Link> */}
          </Grid>
          {/* <Grid item xs={0.5}>
              
          <Button variant="contained" sx={{marginTop:0,width:"100%"}}>Back</Button>
          </Grid> */}
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow:
                "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1100 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      console.log("isItemSelected: ", isItemSelected);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableBagRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          // selected={isItemSelected}
                        >
                          <StyledTableBagCell align="left" padding="normal">
                            {row?.role}
                          </StyledTableBagCell>
                          <StyledTableBagCell align="left" padding="normal">
                            {row?.description}
                          </StyledTableBagCell>

                          <StyledTableBagCell>
                            {" "}
                            {
                              <Link to={`/user/createRole/${row.id}`}>
                                <RemoveRedEyeIcon sx={{ color: "gray" }} />
                              </Link>
                            }
                          </StyledTableBagCell>
                        </StyledTableBagRow>
                      );
                    })}{" "}
                  {emptyRows > 0 && (
                    <StyledTableBagRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <StyledTableBagCell colSpan={6} />
                    </StyledTableBagRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              sx={{ backgroundColor: "#F7F7FC" }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Card>
    </>
  );
}
