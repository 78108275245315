import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { CssTextField } from "../../styles/textFields";
import {
  GetAppointmentCharges,
  getCouponDetails,
  getCustomerAppointmentDetailsAndHistory,
  getPlanDetails,
} from "../../services/clinicOnboarding";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  addAppointmentCharges,
  checkPaymentStatusForAppointmentId,
  initiatePayment,
  processAppointmentPayment,
} from "../../services/PlansAndProcedure";
import Loader from "../Loader";
import CancelIcon from "@mui/icons-material/Cancel";

const AppointmentBillingSecond = ({
  steps,
  setSteps,
  selectedProceduresData,
  setSelectedProceduresData,
  consultationCharges,
  setConsultationCharges,
  selectedProcedures,
  discount,
  setDiscount,
  plan,
  setPlan,
  customerUniqueId,
  speciality,
  totalAmountToPay,
  setTotalAmountToPay,
  setOtherCharges,
  otherCharges,
  selectedComplaints,
}) => {
  const [coupon, setCoupon] = useState("");
  const [clinicDash, setClinicDash] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [couponValues, setCouponValues] = useState("");
  const [planDetails, setPlanDetails] = useState([]);
  const { selectedPlanName } = useParams();
  const [planName, setPlanName] = useState("");
  console.log("planName: ", planName);
  const [planDiscountValue, setPlanDiscountValue] = useState(0);
  const [previousSubmittedData, setPreviousSubmittedData] = useState({});
  const [previousTreatmentCharges, setPreviousTreatmentCharges] = useState([]);
  const { appointmentId } = useParams();
  const { customerNumber } = useParams();
  const { customeruniqueId } = useParams();
  const { member } = useParams();
  const [loading, setLoading] = useState(false);

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    // try {
    //   const res = await GetAppointmentCharges(appointmentId);
    //   console.log(res.data);
    //   setPreviousSubmittedData(res.data);
    //   setPreviousTreatmentCharges(res.data.appointmentTreatmentCharges);
    // } catch (error) {
    //   console.log(error.message);
    // } finally {
    //   setOpen(false);
    // }
    setOpen(false);
  };

  let moqval;

  const error = () => {
    toast.error("This Coupon Is Invalid", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorNotSubmit = () => {
    toast.error("Some Error Occoured", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const successSubmit = () => {
    toast.success("Sucessfully Submitted Data", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const successPayment = () => {
    toast.success("Payment Link has been sent to customer dashboard as well", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorMOQ = () => {
    toast.error("The Mimimum Order Value should be more than " + moqval, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getCouponDetail = async (value) => {
    setDiscount("");
    // setTotalAmountToPay("");
    const { data } = await getCouponDetails(value);
    if (data) {
      moqval = data?.moq;
      if (!data.status) {
        error();
      }

      console.log("data: ", data);
      setCouponValues(data);

      if (
        parseInt(
          [...selectedProceduresData, ...otherCharges]
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
        ) +
          parseInt(consultationCharges) <
        data?.moq
      ) {
        errorMOQ();
        return;
      }

      const all = data?.procedures.filter((element) => {
        return element === "All";
      });

      const intersection = selectedProceduresData.filter((element) =>
        data?.procedures.includes(element.name)
      );
      if (all.length === 0) {
        if (intersection.length === 0) {
          console.log("intersection: ", intersection);
          error();
          return;
        }
      }

      const totalForDiscount = all
        ? [...selectedProceduresData, ...otherCharges]
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
        : intersection
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr));

      if (data?.totalBill) {
        const total =
          parseInt(
            [...selectedProceduresData, ...otherCharges]
              ?.map((item) => item.charges)
              ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
          ) + parseInt(consultationCharges);
        if (data?.totalBillPlanAmtPer) {
          const percentage =
            (parseInt(total) * parseInt(data?.totalBillPlanAmtPer)) / 100;

          percentage > parseInt(data?.totalBillMaxDiscount)
            ? parseInt(data?.totalBillMaxDiscount) > parseInt(total)
              ? setDiscount(total)
              : setDiscount(data?.totalBillMaxDiscount)
            : parseInt(percentage) > parseInt(total)
            ? setDiscount(total)
            : setDiscount(percentage);
          return;
        }

        total > data?.totalBillPlanAmounttVal
          ? setDiscount(data?.totalBillPlanAmounttVal)
          : setDiscount(total);
      }
      if (data?.restOfTheBill && data?.restOfTheBillPlanAmtPer) {
        var consul = 0;
        const percentage =
          (parseInt(totalForDiscount) *
            parseInt(data?.restOfTheBillPlanAmtPer)) /
          100;
        const val =
          percentage > parseInt(data?.restOfTheBillMaxDiscount)
            ? parseInt(data?.restOfTheBillMaxDiscount) >
              parseInt(totalForDiscount)
              ? totalForDiscount
              : data?.restOfTheBillMaxDiscount
            : parseInt(percentage) > parseInt(totalForDiscount)
            ? totalForDiscount
            : percentage;

        if (data?.consultationCharge) {
          const percentageCon =
            (parseInt(consultationCharges) *
              parseInt(data?.consultationChargePlanAmtPer)) /
            100;
          consul =
            percentageCon > parseInt(data?.consultationChargeMaxDiscount)
              ? parseInt(data?.consultationChargeMaxDiscount) >
                parseInt(consultationCharges)
                ? consultationCharges
                : data?.consultationChargeMaxDiscount
              : parseInt(percentageCon) > parseInt(consultationCharges)
              ? consultationCharges
              : percentageCon;
        }
        setDiscount(parseInt(val) + parseInt(consul));
        return;
      } else if (data?.consultationChargePlanAmtPer) {
        console.log(
          "data?.consultationChargePlanAmtPer: ",
          data?.consultationChargePlanAmtPer
        );
        console.log("consultationCharges: ", consultationCharges);
        const percentage =
          (parseInt(consultationCharges) *
            parseInt(data?.consultationChargePlanAmtPer)) /
          100;
        console.log("percentage: ", percentage);
        console.log(
          "data?.consultationChargeMaxDiscount: ",
          data?.consultationChargeMaxDiscount,
          "consulation ",
          consultationCharges
        );
        percentage > parseInt(data?.consultationChargeMaxDiscount)
          ? parseInt(data?.consultationChargeMaxDiscount) >
            parseInt(consultationCharges)
            ? setDiscount(consultationCharges)
            : setDiscount(data?.consultationChargeMaxDiscount)
          : parseInt(percentage) > parseInt(consultationCharges)
          ? setDiscount(consultationCharges)
          : setDiscount(percentage);

        console.log("Doneeeeee");
        return;
      }
      if (data?.restOfTheBill) {
        console.log("Herereererererererre");
        console.log(
          "data?.restOfTheBillPlanAmounttVal: ",
          data?.restOfTheBillPlanAmounttVal
        );
        const val =
          parseInt(totalForDiscount) >
          parseInt(data?.restOfTheBillPlanAmounttVal)
            ? data?.restOfTheBillPlanAmounttVal
            : totalForDiscount;
        console.log("val: ", val);
        if (data?.consultationCharge) {
          console.log(
            "            parseInt(data?.consultationChargePlanAmounttVal) > parseInt(consultationCharges): ",
            parseInt(data?.consultationChargePlanAmounttVal) >
              parseInt(consultationCharges)
          );

          consul =
            parseInt(data?.consultationChargePlanAmounttVal) >
            parseInt(consultationCharges)
              ? consultationCharges
              : data?.consultationChargePlanAmounttVal;
          console.log("consul: ", consul);
        }
        setDiscount(parseInt(val) + parseInt(consul ? consul : 0));
      } else if (data?.consultationCharge) {
        // consultationCharges > data?.consultationChargePlanAmounttVal
        //   ? setDiscount(data?.consultationChargePlanAmounttVal)
        //   : setDiscount(consultationCharges);
        parseInt(data?.consultationChargePlanAmounttVal) >
        parseInt(consultationCharges)
          ? setDiscount(consultationCharges)
          : setDiscount(data?.consultationChargePlanAmounttVal);
      }
    }
  };

  console.log("Dscountttttttttt", discount);

  useEffect(() => {
    if (discount) {
      setTotalAmountToPay(
        parseInt(
          [...selectedProceduresData, ...otherCharges]
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
        ) +
          parseInt(consultationCharges) -
          discount
      );
    }
    if (planDiscountValue) {
      setTotalAmountToPay(
        parseInt(
          [...selectedProceduresData, ...otherCharges]
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
        ) +
          parseInt(consultationCharges) -
          discount -
          parseInt(planDiscountValue)
      );
    }
  }, [discount]);

  useEffect(() => {
    const total =
      parseInt(
        [...selectedProceduresData, ...otherCharges]
          ?.map((item) => item.charges)
          ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
      ) +
      parseInt(consultationCharges) -
      parseInt(discount ? discount : 0);
    console.log(">>>>>>>>>>>>>", total);
    setTotalAmountToPay(total);
  }, []);

  useEffect(() => {
    var discountvalue;
    const getPlanDetailsbySpeciality = async () => {
      try {
        const res = await getPlanDetails(customerUniqueId, speciality, member);
        let val = res.data;
        console.log("planddata", val);
        let data;
        if (val?.b2cplan.length > 0) {
          console.log("val: ", val);
          const data = val?.b2cplan.filter(
            (item) => item.uniqueId === selectedPlanName
          )[0];
          console.log("data>>>>>: ", data);
          setPlanName(data?.planName);
          console.log("procedures: ", data?.procedures);
          const all = data?.procedures.filter((element) => element === "All");

          console.log("all: ", all);

          const intersection = selectedProceduresData.filter((element) =>
            data?.procedures.includes(element.name)
          );
          console.log("intersection: ", intersection);
          if (all.length === 0) {
            if (intersection.length === 0) {
              error();
              return;
            }
          }
          const totalForDiscount = all.length
            ? [...selectedProceduresData, ...otherCharges]
                ?.map((item) => item.charges)
                ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
            : intersection
                ?.map((item) => item.charges)
                ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr));
          console.log("totalForDiscount: ", totalForDiscount);

          const discountAmount =
            (parseInt(totalForDiscount) * parseInt(data?.discountPercent)) /
            100;

          setPlanDiscountValue(discountAmount);
          console.log("discountAmount: ", discountAmount);
          const discount =
            data?.treatmentCover > discountAmount
              ? discountAmount
              : data?.treatmentCover;
          console.log("discount: ", discount);

          setPlanDiscountValue(discount);
          setTotalAmountToPay(
            parseInt(
              [...selectedProceduresData, ...otherCharges]
                ?.map((item) => item.charges)
                ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
            ) +
              parseInt(consultationCharges) -
              parseInt(discount)
          );
        } else {
          data = val?.b2bplan;
        }

        setPlanDetails(data);
        console.log(
          "planperce",
          parseInt(data[0].consultationChargePlanAmtPer)
        );

        const totalcharges =
          parseInt(
            [...selectedProceduresData, ...otherCharges]
              ?.map((item) => item.charges)
              ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
          ) + parseInt(consultationCharges);

        const treatmentCharges = [...selectedProceduresData, ...otherCharges]
          ?.map((item) => item.charges)
          ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr));

        if (data) {
          setPlanName(data.map((plan) => plan?.planName));
          if (data[0]?.consultationCharge || data[0]?.restOfTheBill) {
            if (data[0]?.consultationChargePlanAmtPer) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                discountvalue =
                  (parseInt(consultationCharges) *
                    parseInt(data[0]?.consultationChargePlanAmtPer)) /
                  100;
                if (discountvalue < data[0]?.consultationChargeMaxDiscount) {
                  setPlanDiscountValue(discountvalue);
                  // console.log("planDiscountValue",planDiscountValue)
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                } else {
                  setPlanDiscountValue(data[0]?.consultationChargeMaxDiscount);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(data[0]?.consultationChargeMaxDiscount)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      ?.filter((item) => item.charges > 0)
                      ?.map((item) => item.charges)
                      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
            }

            if (data[0]?.consultationChargePlanAmounttVal) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                if (
                  data[0]?.consultationChargePlanAmounttVal >
                  parseInt(consultationCharges)
                ) {
                  discountvalue = parseInt(consultationCharges);
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                } else {
                  discountvalue = parseInt(
                    data[0]?.consultationChargePlanAmounttVal
                  );
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      .filter((item) => item.charges > 0)
                      .map((item) => item.charges)
                      .reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
            }

            if (data[0]?.restOfTheBillPlanAmtPer) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                discountvalue =
                  (parseInt(treatmentCharges) *
                    parseInt(data[0]?.restOfTheBillPlanAmtPer)) /
                  100;
                if (discountvalue < data[0]?.restOfTheBillMaxDiscount) {
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                } else {
                  setPlanDiscountValue(data[0]?.restOfTheBillMaxDiscount);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(data[0]?.restOfTheBillMaxDiscount)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      .filter((item) => item.charges > 0)
                      ?.map((item) => item.charges)
                      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
            }

            if (data[0]?.restOfTheBillPlanAmounttVal) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                if (
                  data[0]?.restOfTheBillPlanAmounttVal >
                  parseInt(treatmentCharges)
                ) {
                  discountvalue = parseInt(treatmentCharges);
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                } else {
                  discountvalue = parseInt(
                    data[0]?.restOfTheBillPlanAmounttVal
                  );
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      ?.filter((item) => item.charges > 0)
                      ?.map((item) => item.charges)
                      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
            }

            if (data[0]?.consultationCharge && data[0]?.restOfTheBill) {
              if (
                data[0]?.consultationChargePlanAmtPer &&
                data[0]?.restOfTheBillPlanAmtPer
              ) {
                if (totalcharges >= parseInt(data[0]?.moq)) {
                  discountvalue =
                    (parseInt(consultationCharges) *
                      parseInt(data[0]?.consultationChargePlanAmtPer)) /
                      100 +
                    (parseInt(treatmentCharges) *
                      parseInt(data[0]?.restOfTheBillPlanAmtPer)) /
                      100;
                  if (
                    discountvalue <
                    parseInt(data[0]?.consultationChargeMaxDiscount) +
                      parseInt(data[0]?.restOfTheBillMaxDiscount)
                  ) {
                    setPlanDiscountValue(discountvalue);
                    setTotalAmountToPay(
                      parseInt(
                        [...selectedProceduresData, ...otherCharges]
                          ?.map((item) => item.charges)
                          ?.reduce(
                            (acc, curr) => parseInt(acc) + parseInt(curr)
                          )
                      ) +
                        parseInt(consultationCharges) -
                        parseInt(discountvalue)
                    );
                  } else {
                    setPlanDiscountValue(
                      parseInt(data[0]?.consultationChargeMaxDiscount) +
                        parseInt(data[0]?.restOfTheBillMaxDiscount)
                    );
                    setTotalAmountToPay(
                      parseInt(
                        [...selectedProceduresData, ...otherCharges]
                          ?.map((item) => item.charges)
                          ?.reduce(
                            (acc, curr) => parseInt(acc) + parseInt(curr)
                          )
                      ) +
                        parseInt(consultationCharges) -
                        parseInt(data[0]?.restOfTheBillMaxDiscount) -
                        parseInt(data[0]?.consultationChargeMaxDiscount)
                    );
                  }
                } else {
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.filter((item) => item.charges > 0)
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) + parseInt(consultationCharges)
                  );
                }
              }
            }
            if (data[0]?.consultationCharge && data[0]?.restOfTheBill) {
              if (
                data[0]?.consultationChargePlanAmounttVal &&
                data[0]?.restOfTheBillPlanAmounttVal
              ) {
                var bothfixedvalues =
                  parseInt(data[0]?.consultationChargePlanAmounttVal) +
                  parseInt(data[0]?.restOfTheBillPlanAmounttVal);
                if (totalcharges >= parseInt(data[0]?.moq)) {
                  if (bothfixedvalues > parseInt(totalcharges)) {
                    discountvalue = parseInt(totalcharges);
                    setPlanDiscountValue(discountvalue);
                    setTotalAmountToPay(
                      parseInt(
                        [...selectedProceduresData, ...otherCharges]
                          ?.map((item) => item.charges)
                          ?.reduce(
                            (acc, curr) => parseInt(acc) + parseInt(curr)
                          )
                      ) +
                        parseInt(consultationCharges) -
                        parseInt(discountvalue)
                    );
                  } else {
                    discountvalue = parseInt(bothfixedvalues);
                    setPlanDiscountValue(discountvalue);
                    setTotalAmountToPay(
                      parseInt(
                        [...selectedProceduresData, ...otherCharges]
                          ?.map((item) => item.charges)
                          ?.reduce(
                            (acc, curr) => parseInt(acc) + parseInt(curr)
                          )
                      ) +
                        parseInt(consultationCharges) -
                        parseInt(discountvalue)
                    );
                  }
                } else {
                  moqval = data[0]?.moq;
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.filter((item) => item.charges > 0)
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) + parseInt(consultationCharges)
                  );
                }
              }
            }
          }

          if (data[0]?.totalBill) {
            if (data[0]?.totalBillPlanAmtPer) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                discountvalue =
                  (parseInt(totalcharges) *
                    parseInt(data[0]?.totalBillPlanAmtPer)) /
                  100;
                console.log("percebtagevalue", discountvalue);
                if (discountvalue < data[0]?.totalBillMaxDiscount) {
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(totalcharges - parseInt(discountvalue));
                } else {
                  setPlanDiscountValue(data[0]?.totalBillMaxDiscount);
                  setTotalAmountToPay(
                    totalcharges - parseInt(data[0]?.totalBillMaxDiscount)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      ?.filter((item) => item.charges > 0)
                      ?.map((item) => item.charges)
                      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
              // percentage > parseInt(data[0]?.totalBillMaxDiscount)
              //   ? parseInt(data[0]?.totalBillMaxDiscount) >
              //   parseInt(total)
              //   ? setPlanDiscountValue(total)
              //     : setPlanDiscountValue(data[0]?.totalBillMaxDiscount)
              //   : parseInt(percentage) > parseInt(total)
              //   ? setPlanDiscountValue(total)
              //   : setPlanDiscountValue(percentage);
              // percentage>parseInt(data[0]?.totalBillMaxDiscount) ?
              //  setPlanDiscountValue(data[0]?.totalBillMaxDiscount): setPlanDiscountValue(data[0]?.totalBillMaxDiscount)

              //  setTotalAmountToPay(total-parseInt(data[0]?.totalBillMaxDiscount))
            }

            if (data[0]?.totalBillPlanAmounttVal) {
              if (totalcharges >= parseInt(data[0]?.moq)) {
                if (data[0]?.totalBillPlanAmounttVal > parseInt(totalcharges)) {
                  discountvalue = parseInt(totalcharges);
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                } else {
                  discountvalue = parseInt(data[0]?.totalBillPlanAmounttVal);
                  setPlanDiscountValue(discountvalue);
                  setTotalAmountToPay(
                    parseInt(
                      [...selectedProceduresData, ...otherCharges]
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                    ) +
                      parseInt(consultationCharges) -
                      parseInt(discountvalue)
                  );
                }
              } else {
                setTotalAmountToPay(
                  parseInt(
                    [...selectedProceduresData, ...otherCharges]
                      ?.filter((item) => item.charges > 0)
                      ?.map((item) => item.charges)
                      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                  ) + parseInt(consultationCharges)
                );
              }
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    getPlanDetailsbySpeciality();
  }, []);

  const proceedToBilling = async () => {
    console.log("Hereeeeeeeeeeeeeeeeeeeeeeeeee");

    const val = {
      consultationCharges: consultationCharges,
      appointmentTreatmentCharges: [
        ...selectedProceduresData,
        ...otherCharges,
      ].map((item) => {
        const data = { nameOfTreatment: item?.name, charges: item?.charges };
        return data;
      }),

      totalTreatmentCost:
        parseInt(
          [...selectedProceduresData, ...otherCharges]
            ?.filter((item) => item.charges > 0)
            ?.map((item) => item.charges)
            ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
        ) + parseInt(consultationCharges),
      planName: planName[0],
      planDiscount: planDiscountValue
        ? Math.round(planDiscountValue)?.toString()
        : "0",
      couponCode: coupon,
      complaints: selectedComplaints,
      couponDiscount: discount,
      totalPayableAfterDiscount: totalAmountToPay < 0 ? 0 : totalAmountToPay,
      appointmentId: appointmentId,
      customerUniqueId: customeruniqueId,
      createdBy: JSON.parse(localStorage.getItem("token"))?.username,
    }; //!Might have to make changes in the planName

    // if (previousSubmittedData.id) {
    //   val.id = previousSubmittedData.id;
    //   val.appointmentTreatmentCharges = previousTreatmentCharges.map(
    //     (el, id) => ({
    //       id: el.id,
    //       nameOfTreatment: el.nameOfTreatment,
    //       charges: el.charges,
    //     })
    //   );
    // }

    try {
      const { data } = await addAppointmentCharges(val);
      if (data) {
        console.log("data: ", data);
        setOpen(true);
        successPayment();
        successSubmit();
        // window.open(data?.data?.instrumentResponse?.redirectInfo?.url)
      }
    } catch (ex) {
      errorNotSubmit();
    }

    // try{
    //   const {data}= await initiatePayment({
    //     "amount": val?.totalPayableAfterDiscount,
    //     "mobileNumber":customerNumber
    //   })
    //   console.log('data: ', data?.data?.instrumentResponse?.redirectInfo?.url);
    //   if(data?.data?.instrumentResponse?.redirectInfo?.url){
    //     setLoading(true)
    //     window.open(data?.data?.instrumentResponse?.redirectInfo?.url,"_blank")
    //   }
    // }catch(e){
    // console.log(e);
    // }
  };

  const navigate = useNavigate();
  async function checkStatus() {
    const { data } = await checkPaymentStatusForAppointmentId(appointmentId);
    console.log("data: ", data);
    if (data.success === true) {
      // window.open(data?.data?.instrumentResponse?.redirectInfo?.url,"_self")
      toast.success("Status Completed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate(`/user/paymentStatus/${data?.data?.merchantTransactionId}`);
    } else {
      toast.warning("Status Pending", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const formatRupeesWithCommas = (number) => {
    if (typeof number === "undefined" || number === null) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  async function payViaClinic() {
    const { data } = await processAppointmentPayment(appointmentId);
    console.log("data: ", data);
    if (data.success === true) {
      setIsSubmitting(true);
      window.open(data?.data?.instrumentResponse?.redirectInfo?.url, "_self");
    } else {
      toast.error("Cannot be processed at the moment", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSubmitting(false);
    }
  }

  return (
    <>
      {loading ? (
        <>
          <Loader />
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Please Do Not Close or Change this window until the transaction is
            completed
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="h5"
            className="appointmentbillheading"
            fontWeight="bold"
            sx={{ color: "#D83F87", mb: "10px" }}
          >
            Appointment Billing
          </Typography>
          <Card
            className="billingbodycard"
            sx={{
              padding: 4,
              boxShadow: "none",
              border: "0.5px solid #D9D9D9",
            }}
          >
            <div style={{ border: "2px solid #D9D9D9" }}>
              <div style={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="14px"
                      variant="body1"
                      fontWeight="bold"
                    >
                      Consultation Charges
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize="14px"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {consultationCharges
                        ? formatRupeesWithCommas(consultationCharges) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div style={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      Treatment Charges
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {[...selectedProceduresData, ...otherCharges]
                        ?.filter((item) => item.charges > 0)
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                        ? formatRupeesWithCommas(
                            [...selectedProceduresData, ...otherCharges]
                              ?.filter((item) => item.charges > 0)
                              ?.map((item) => item.charges)
                              ?.reduce(
                                (acc, curr) => parseInt(acc) + parseInt(curr)
                              )
                          ) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div style={{ padding: "10px" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: 4, color: "#444444" }}
                >
                  {[...selectedProceduresData, ...otherCharges].map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={6}>
                          {" "}
                          <Typography
                            variant="body2"
                            fontSize="14px"
                            fontWeight="bold"
                          >
                            {item.name}
                          </Typography>{" "}
                        </Grid>
                        <Grid item xs={6}>
                          {" "}
                          <Typography
                            fontSize="14px"
                            variant="body2"
                            fontWeight="bold"
                          >
                            {item.name && item.charges
                              ? formatRupeesWithCommas(item.charges) + " ₹"
                              : ""}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                </Grid>
              </div>
              <Divider />
              <div style={{ padding: "10px", background: "#D9D9D9" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      Total Charges
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {parseInt(
                        [...selectedProceduresData, ...otherCharges]
                          ?.filter((item) => item.charges > 0)
                          ?.map((item) => item.charges)
                          ?.reduce(
                            (acc, curr) => parseInt(acc) + parseInt(curr)
                          )
                      ) + parseInt(consultationCharges)
                        ? formatRupeesWithCommas(
                            parseInt(
                              [...selectedProceduresData, ...otherCharges]
                                ?.filter((item) => item.charges > 0)
                                ?.map((item) => item.charges)
                                ?.reduce(
                                  (acc, curr) => parseInt(acc) + parseInt(curr)
                                )
                            ) + parseInt(consultationCharges)
                          ) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div style={{ padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    {" "}
                    <Typography
                      variant="body1"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {planDiscountValue ? planName : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <Typography
                      variant="body1"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {planDiscountValue ? "Plan Discount" : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      fontSize="14px"
                      fontWeight="bold"
                    >
                      {planDiscountValue
                        ? formatRupeesWithCommas(planDiscountValue) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div style={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <CssTextField
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      sx={{
                        backgroundColor: "#2D256C",
                        textAlign: "center",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "12px",
                        padding: "10px",
                        mr: "10px",
                        borderRadius: "3px",
                        "&:hover": { backgroundColor: "#D83F87!important" },
                      }}
                      size="small"
                      onClick={() => {
                        getCouponDetail(coupon);
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    {couponValues && (
                      <Typography
                        variant="body2"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Coupon Discount:{" "}
                        {discount
                          ? formatRupeesWithCommas(discount) + " ₹"
                          : ""}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Divider />

              <div style={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid className="totalpaybill" item xs={6}>
                    <Typography fontSize="14px" variant="h6">
                      Total Payable Amount After Discount
                    </Typography>
                  </Grid>
                  <Grid className="totalpaybill" item xs={6}>
                    {console.log("totalamounttopay", totalAmountToPay)}
                    <Typography fontSize="14px" variant="h6">
                      {parseInt(totalAmountToPay) < 0
                        ? "0"
                        : formatRupeesWithCommas(totalAmountToPay) + " ₹"}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </div>

              <div style={{ padding: "10px" }}>
                <Box
                  sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    mt: "10px",
                    mb: "10px",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#2D256C",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      padding: "10px",
                      mr: "10px",

                      // "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    size="small"
                    onClick={() => {
                      setSteps(steps - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#D83F87",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "10px",
                      padding: "10px",

                      "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    size="small"
                    onClick={() => {
                      setOpen(true);

                      proceedToBilling();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>
          </Card>

          {/* ------------------------------Mobile screen------------------------- */}
          <Box className="billingMobilebodycard">
            <Card sx={{ padding: 4 }}>
              <div style={{ border: "2px solid #D9D9D9" }}>
                <Grid
                  sx={{ padding: 1 }}
                  justifyContent="space-between"
                  alignItems="center"
                  container
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Consultation Charges
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body2"
                      fontWeight="bold"
                    >
                      {consultationCharges
                        ? formatRupeesWithCommas(consultationCharges) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  sx={{ padding: 1 }}
                  justifyContent="space-between"
                  alignItems="center"
                  container
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Treatments Charges
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body2"
                      fontWeight="bold"
                    >
                      {[...selectedProceduresData, ...otherCharges]
                        ?.filter((item) => item.charges > 0)
                        ?.map((item) => item.charges)
                        ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr))
                        ? formatRupeesWithCommas(
                            [...selectedProceduresData, ...otherCharges]
                              ?.filter((item) => item.charges > 0)
                              ?.map((item) => item.charges)
                              ?.reduce(
                                (acc, curr) => parseInt(acc) + parseInt(curr)
                              )
                          ) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: 3, color: "#444444" }}
                >
                  {[...selectedProceduresData, ...otherCharges].map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={6}>
                          {" "}
                          <Typography
                            sx={{ fontSize: "12px" }}
                            variant="body2"
                            fontWeight="bold"
                          >
                            {item.name}
                          </Typography>{" "}
                        </Grid>
                        <Grid item xs={6}>
                          {" "}
                          <Typography
                            sx={{ fontSize: "12px" }}
                            variant="body2"
                            fontWeight="bold"
                          >
                            {item.charges
                              ? formatRupeesWithCommas(item.charges) + " ₹"
                              : ""}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                </Grid>
                <Divider />
                <div style={{ background: "#FFF6FF" }}>
                  <Grid
                    sx={{ padding: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                    container
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="body1"
                        fontWeight="bold"
                      >
                        Total Amount
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="body2"
                        fontWeight="bold"
                      >
                        {parseInt(
                          [...selectedProceduresData, ...otherCharges]
                            ?.map((item) => item.charges)
                            ?.reduce(
                              (acc, curr) => parseInt(acc) + parseInt(curr)
                            )
                        ) + parseInt(consultationCharges)
                          ? formatRupeesWithCommas(
                              parseInt(
                                [...selectedProceduresData, ...otherCharges]
                                  ?.map((item) => item.charges)
                                  ?.reduce(
                                    (acc, curr) =>
                                      parseInt(acc) + parseInt(curr)
                                  )
                              ) + parseInt(consultationCharges)
                            ) + " ₹"
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider />
                <Grid
                  sx={{ padding: 1 }}
                  justifyContent="space-between"
                  alignItems="center"
                  container
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      {planDiscountValue ? "Plan Discount" : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      {planDiscountValue
                        ? formatRupeesWithCommas(planDiscountValue) + " ₹"
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  sx={{ padding: 1 }}
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <CssTextField
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      sx={{
                        backgroundColor: "#2D256C",
                        textAlign: "center",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "10px",
                        padding: "10px 40px",
                        mr: "10px",
                        "&:hover": { backgroundColor: "#D83F87!important" },
                      }}
                      size="small"
                      onClick={() => {
                        getCouponDetail(coupon);
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ padding: 1 }}
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Coupon Discount
                    </Typography>
                  </Grid>
                  <Grid item>
                    {couponValues && (
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="body2"
                        fontWeight="bold"
                      >
                        Discount: {formatRupeesWithCommas(discount)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Card>
            <div style={{ background: "#FFF6FF", marginTop: "20px" }}>
              <Grid
                container
                sx={{ padding: 1 }}
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="totalpaybill" item>
                  <Typography variant="h6">
                    Total Payable Amount After Discount
                  </Typography>
                </Grid>
                <Grid className="totalpaybill" item>
                  <Typography variant="h6">
                    {parseInt(totalAmountToPay) < 0
                      ? "0"
                      : formatRupeesWithCommas(totalAmountToPay) + " ₹"}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: "10px" }}>
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  mt: "10px",
                  mb: "10px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#2D256C",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "10px",
                    padding: "10px",
                    mr: "10px",

                    // "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
                    setSteps(steps - 1);
                  }}
                >
                  Back
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#D83F87",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "10px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
                    setOpen(true);
                    proceedToBilling();
                  }}
                >
                  Submit
                </Button>
              </Box>
            </div>
            <Modal
              open={open}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* { 
              !clinicDash?(<> <Typography sx={{textAlign:"center"}} id="modal-modal-title" variant="h6" component="h2">
             Pay through
                </Typography>
                <div style={{display:"flex", justifyContent:"space-evenly", marginBottom:"10px"}}> 
                <Button
                  sx={{
                    backgroundColor: "#2D256C",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "10px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
payViaClinic()
                  }}
                >
                  Clinic Dashboard
                </Button><Button
                  sx={{
                    backgroundColor: "#2D256C",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "10px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
                    setClinicDash(true)
                  }}
                >
              Customer Dashboard
                </Button>
                </div>
                </>):( */}

                {/* {<div
                  className="cancelIcon"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  <CancelIcon />
                </div>} */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#2D256C",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      padding: "10px",

                      "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    size="small"
                    onClick={() => {
                      checkStatus();
                    }}
                  >
                    Check Status
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    sx={{
                      backgroundColor: isSubmitting ? "#898989" : "#D83F87",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      padding: "10px",

                      "&:hover": { backgroundColor: "#2D256C!important" },
                    }}
                    size="small"
                    onClick={() => {
                      payViaClinic();
                    }}
                  >
                    Pay Here
                  </Button>

                  <Button
                    sx={{
                      backgroundColor: "#2D256C",
                      textAlign: "center",
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                      fontSize: "12px",
                      padding: "10px",

                      "&:hover": { backgroundColor: "#D83F87!important" },
                    }}
                    size="small"
                    onClick={() => {
                      navigate(`/user/clinicappointments/today`);
                    }}
                  >
                    Back to Appointments
                  </Button>
                </div>
              </Box>
            </Modal>
          </Box>
        </>
      )}
    </>
  );
};

export default AppointmentBillingSecond;
