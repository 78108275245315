import * as React from "react";
import {
  Typography,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import { Search, Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import { CssTextField } from "../../styles/textFields";
import { StyledTableBagCell, StyledTableBagRow } from "../../styles/table";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  getStaffDataDateRange,
  getStaffList,
} from "../../services/staffOnboarding";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "S.no",
  },
  {
    id: "clinicName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "clinicType",
    numeric: true,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Mail",
  },
  {
    id: "ownerName",
    numeric: true,
    disablePadding: false,
    label: "Designation",
  },
  {
    id: "city",
    numeric: true,
    disablePadding: false,
    label: "City",
  },

  {
    id: "",
    // numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableBagRow>
        {headCells.map((headCell, index) => (
          <StyledTableBagCell
            key={index}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ "&:hover": { color: "#d9d9d9" } }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableBagCell>
        ))}
      </StyledTableBagRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function StaffList() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [rowData, setRowData] = React.useState([]);
  const [dateTo, setDateTo] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState("");

  const [width, setWidth] = React.useState(window.innerWidth);
  const { permissionsData } = useContext(Permissions);
  let staffPermissionsModule = permissionsData?.filter(
    (item) => item.module === "staff"
  );
  let staffPermissions =
    staffPermissionsModule && staffPermissionsModule[0]?.permissions;
  let AddPermission = staffPermissions?.find(
    (item) => item.action === "Add"
  )?.permission;
  let ViewPermission = staffPermissions?.find(
    (item) => item.action === "View"
  )?.permission;
  // let ViewPermission=false
  console.log("staff permissions", AddPermission);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    const getStaffLists = async () => {
      const { data } = await getStaffList();
      // console.log("data: ", data);
      // const sortedData = data.sort((a, b) => a.createdAt - b.createdAt);
      const sortedData = data;
      setRowData(sortedData);
    };
    getStaffLists();
  }, []);

  const rows = rowData.filter(
    (item) =>
      item.firstName
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase()) ||
      item.email.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.designation.toString().toLowerCase().includes(search) ||
      item.mobile.toString().toLowerCase().includes(search) ||
      item.lastName.toString().toLowerCase().includes(search) ||
      item.baseCity.toString().toLowerCase().includes(search)
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //   const downloadExcel = () => {
  //     const workSheet = XLSX.utils.json_to_sheet(rowData);
  //     const workBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workBook, workSheet, "branches");
  //     //Buffer
  //     let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //     //Binary String
  //     XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  //     //Download
  //     XLSX.writeFile(workBook, "branches.xlsx");
  //   };

  function formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }
  async function handleDateRange() {
    if (!dateTo || !dateFrom) {
      return;
    } else {
      const from = formattedDate(dateFrom);
      // console.log('from: ', from);
      const to = formattedDate(dateTo);
      const { data } = await getStaffDataDateRange(from, to);
      // console.log('data: ', data);
      if (data) {
        setRowData(data);
        setOpen(!open);
      }
    }
  }

  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* <div style={{ marginTop: "75px" }}></div> */}
      {/* <QuickTracking /> */}
      <Grid container spacing={1}>
        <Grid item md={5} xs={12} sm={5}>
          <Typography variant="h5" sx={{ mb: "10px", fontSize: "17px" }}>
            Staff List
          </Typography>
        </Grid>
        <Grid item md={7} xs={12} sm={7}>
          <Grid container spacing={1}>
            <Grid item md={9} sm={9} xs={7}>
              <CssTextField
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Search..."
                type="text"
                value={search}
                className="searchboxtextfield"
                onChange={(e) => setSearch(e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: "0px" }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "#F7F7FC",
                          borderRadius: "8px 0px 0px 8px",
                        }}
                        edge="start"
                      >
                        <Search sx={{ backgroundColor: "#F7F7FC" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={1} sm={1} xs={1.5}>
            <Box sx={{backgroundColor:"#ffffff",display:"flex",justifyContent:"center",padding:"4px",borderRadius:"3px"}}>
              <CalendarMonthIcon
                onClick={() => {
                  setOpen(true);
                }}
                sx={{ cursor: "pointer", fontSize: "2rem" }}
                />
              </Box>
              <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                  setDateFrom("");
                  setDateTo("");
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    borderRadius: "8px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        Date Range From
                      </Typography>
                      <DatePicker
                        name={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                          // handleChange("practiceSince", index, newValue);
                        }}
                        value={dateFrom}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <CssTextField
                            // error={errors && errors[index]?.practiceSince}
                            // helperText={
                            //   errors &&
                            //   errors[index]?.practiceSince &&
                            //   "This Field is required"
                            // }
                            focused
                            {...params}
                            placeholder="Date Range From"
                            size="small"
                            // name={item.value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontWeight="bold"
                      >
                        Date Range To
                      </Typography>
                      <DatePicker
                        name={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                          // handleChange("practiceSince", index, newValue);
                        }}
                        value={dateTo}
                        minDate={dateFrom}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <CssTextField
                            // error={errors && errors[index]?.practiceSince}
                            // helperText={
                            //   errors &&
                            //   errors[index]?.practiceSince &&
                            //   "This Field is required"
                            // }
                            focused
                            {...params}
                            placeholder="Date Range To"
                            size="small"
                            name={dateTo}
                            value={dateTo}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    sx={{ ml: "86%", mt: 2 }}
                    onClick={() => {
                      handleDateRange();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Modal>
            </Grid>
            <Grid item md={2} sm={2} xs={3.5}>
              {AddPermission && (
                <Link to="/user/staffOnboarding">
                  <Button
                    className="addnewbtn"
                    variant="contained"
                    sx={{
                      marginTop: 0,
                      fontSize: "12px",
                      padding: "8px 15px",
                      "&:hover": { backgroundColor: "#2d256c!important" },
                    }}
                  >
                    Add New
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper
        // sx={{
        //   width: "100%",
        //   mb: 2,
        //   boxShadow:
        //     "0px 2px 0px -5px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 4px 0px rgb(0 0 0 / 4%)",
        // }}
        sx={{
          width: "100%",
          overflow: "hidden",
          mt: "10px",
          backgroundColor: "#f7f7fc",
        }}
      >
        <TableContainer>
          <Table
            sx={{ minWidth: 1100 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  console.log("isItemSelected: ", isItemSelected);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableBagRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.id}
                      // selected={isItemSelected}
                    >
                      <StyledTableBagCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        padding="normal"
                        sx={{ width: "8%" }}
                        style={{ fontSize: "14px" }}
                      >
                        {/* <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      > */}
                        {index + 1}
                        {/* <Avatar alt={row.name} src="/static/images/avatar/1.jpg" /> */}
                        {/* </StyledBadge> */}
                      </StyledTableBagCell>
                      <StyledTableBagCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        padding="normal"
                        style={{ fontSize: "14px" }}
                      >
                        {row?.firstName} {row?.lastName}
                      </StyledTableBagCell>
                      <StyledTableBagCell
                        align="left"
                        padding="normal"
                        style={{ fontSize: "14px" }}
                      >
                        {row?.mobile}
                      </StyledTableBagCell>
                      <StyledTableBagCell
                        align="left"
                        padding="normal"
                        style={{ fontSize: "14px" }}
                      >
                        {row.email}
                      </StyledTableBagCell>
                      <StyledTableBagCell
                        align="left"
                        padding="normal"
                        style={{ fontSize: "14px" }}
                      >
                        {row.designation}
                      </StyledTableBagCell>
                      <StyledTableBagCell
                        align="left"
                        padding="normal"
                        style={{ fontSize: "14px" }}
                      >
                        {row.baseCity}
                      </StyledTableBagCell>

                      <StyledTableBagCell style={{ fontSize: "14px" }}>
                        {" "}
                        {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /> */}
                        {/* <Link
                              className="link-style"
                              to={`viewDA/${row.id}`}
                            > */}
                        {/* </Link> */}
                        {ViewPermission && (
                          <Link to={`/user/staffOnboarding/${row.id}`}>
                            <RemoveRedEyeIcon
                              sx={{ color: "gray", fontSize: "1.5rem" }}
                            />
                          </Link>
                        )}
                      </StyledTableBagCell>
                    </StyledTableBagRow>
                  );
                })}{" "}
              {emptyRows > 0 && (
                <StyledTableBagRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableBagCell colSpan={6} />
                </StyledTableBagRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          sx={{ backgroundColor: "#F7F7FC" }}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
