import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddHomeIcon from "@mui/icons-material/AddHome";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DomainIcon from "@mui/icons-material/Domain";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FeedIcon from "@mui/icons-material/Feed";
import logo from "../images/Appbarlogo.svg";
import { ArrowDropDown, Diversity1TwoTone } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Permissions } from "./Context/PermissionsContext";
import staffLogo from "../assets/staff.svg";
import appointments from "../assets/appointment.svg";
import customerListingLogo from "../assets/customer listing.svg";
import onBoardingReports from "../assets/onboarding report.svg";
import Plans from "../assets/Plans.svg";
import Billing from "../assets/Billing.svg";
import ClinicDetails from "../assets/Clinic.svg";
import AppointmentReq from "../assets/Appoint req.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pendingPayments from "../assets/pending payments.svg"
import plansAndCoupons from "../assets/plans and coupon.svg"
import myAppointments from "../assets/user calender.svg"
import "../styles/Appbar.scss";

const Appbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();
  const tokens = localStorage.getItem("token");
  const role = JSON.parse(tokens)?.role;
  const designation = JSON.parse(tokens)?.designation;
  const location = useLocation();
  const uniqueId = JSON.parse(tokens)?.uniqueId;

  const { permissionsData } = useContext(Permissions);
  console.log("permissionsdata", permissionsData);

  let plansPermissionsModule = permissionsData?.filter(
    (item) => item.module === "plans"
  );
  let billingPermissionsModule = permissionsData?.filter(
    (item) => item.module === "billing"
  );
  let reportPermissionsModule = permissionsData?.filter(
    (item) => item.module === "report"
  );
  let procedurePermissionsModule = permissionsData?.filter(
    (item) => item.module === "procedure"
  );
  let staffPermissionsModule = permissionsData?.filter(
    (item) => item.module === "staff"
  );
  let appointmentPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let couponsPermissionsModule = permissionsData?.filter(
    (item) => item.module === "coupons"
  );
  let customerPermissionsModule = permissionsData?.filter(
    (item) => item.module === "customer"
  );
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "clinic"
  );
  let reportpermission =
    reportPermissionsModule &&
    reportPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let procedurepermission =
    procedurePermissionsModule &&
    procedurePermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let billingpermission =
    billingPermissionsModule &&
    billingPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let staffpermission =
    staffPermissionsModule &&
    staffPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let clinicpermission =
    clinicPermissionsModule &&
    clinicPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let planspermission =
    plansPermissionsModule &&
    plansPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let customerpermission =
    customerPermissionsModule &&
    customerPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let couponpermission =
    couponsPermissionsModule &&
    couponsPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  let myappointmentspermission =
    appointmentPermissionsModule &&
    appointmentPermissionsModule[0]?.permissions.some(
      (permission) => permission.permission
    );
  // console.log("reportpermission",myappointmentspermission);
  let clinicPermissionsArray =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions
      ? clinicPermissionsModule[0]?.permissions
      : "";
  let ClinicDetailsPermission =
    clinicPermissionsArray &&
    clinicPermissionsArray?.find((item) => item.action === "Clinic Details")
      ?.permission;
  // let ClinicDetailsPermission=false
  let AppointmentRequestPermissions =
    appointmentPermissionsModule && appointmentPermissionsModule[0]?.permissions
      ? appointmentPermissionsModule[0]?.permissions
      : "";
  let RequestAppointmentPermission =
    AppointmentRequestPermissions &&
    AppointmentRequestPermissions?.find(
      (item) => item.action === "Request Appointment"
    )?.permission;
  let NewAppointmentPermission =
    AppointmentRequestPermissions &&
    AppointmentRequestPermissions?.find(
      (item) => item.action === "New Appointment"
    )?.permission;
  // console.log("planspermissions", couponpermission);


  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    setShowNavbar(false);
  }, [location])

  return (
    <>
      <div className="appbar">
        <NavLink
          to={
            (role === "Admin" || role === "Staff")
              ? "/user/clinicList"
              : role === "Customer"
              ? "/user/appointments/today"
              : role === "Clinic"
              ? "/user/clinicappointments/today"
              : "/"
          }
        >
          <img src={logo} className="imagee" alt="logo" />
        </NavLink>
        {tokens && (
          <div className="appbar-right">
            <Badge
              badgeContent={1}
              color="secondary"
              sx={{ marginRight: "15px" }}
              className="notificationicon"
            >
              <NotificationsIcon color="action" />
            </Badge>

            <div className="profile">
              <Avatar
                alt="Profile"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9RzmCAKhjBMu6GN8LHxNLTw8Vt347xfIXWA&usqp=CAU"
                className="profileicon"
              />
              {/* <ArrowDropDown className="icons" /> */}
              <Box className="hamburger" onClick={handleShowNavbar}>
                <MenuIcon />
              </Box>
              <Box className={`hamburger-elements  ${showNavbar && "active"}`}>
                <ul>
                  {clinicpermission &&
                    role !== "Customer" &&
                    role !== "Clinic" && (
                      <NavLink to="clinicList">
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          <AddHomeIcon
                            sx={{ height: `30%`, color: "#D83F87" }}
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            Clinic OnBoarding
                          </Typography>
                        </Box>
                      </NavLink>
                    )}
                    
                  {reportpermission && (
                    <NavLink to="onboardingReport">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={onBoardingReports}
                          className="sidemenuicon"
                          alt="onboarding reports logo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Onboarding Reports
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {staffpermission && (
                    <NavLink to="staffList">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={staffLogo}
                          className="sidemenuicon"
                          alt="stafflogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Staff
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {myappointmentspermission &&
                    (role === "Customer" || role === "Clinic") && (
                      <NavLink
                        to={
                          role === "Customer"
                            ? "appointments/today"
                            : role === "Clinic"
                            ? "clinicappointments/today"
                            : ""
                        }
                      >
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          <img
                            src={myAppointments}
                            className="sidemenuicon"
                            alt="appointmentslogo"
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            My Appointments
                          </Typography>
                        </Box>
                      </NavLink>
                    )}

                  {ClinicDetailsPermission && role === "Clinic" && (
                    <NavLink to="clinicsclinicview">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={ClinicDetails}
                          className="sidemenuicon"
                          alt="appointmentslogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Clinic Details
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {myappointmentspermission &&
                    designation === "Customer Support Executive" && (
                      <NavLink to="cseAppointments">
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          <img
                            src={myAppointments}
                            className="sidemenuicon"
                            alt="appointmentslogo"
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            Appointments
                          </Typography>
                        </Box>
                      </NavLink>
                    )}

                  {NewAppointmentPermission &&
                    (designation === "Customer Support Executive" ||
                      role === "Customer") && (
                      <NavLink
                        to={
                          designation === "Customer Support Executive"
                            ? "cseAppointmentBooking"
                            : designation === "Customer"
                            ? "/user/newappointments"
                            : ""
                        }
                      >
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          {/* <CalendarMonthIcon
                            sx={{ height: `30%`, color: "#D83F87" }}
                          /> */}
                          <img
                            src={appointments}
                            className="sidemenuicon"
                            alt="appointmentslogo"
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            New Appointment
                          </Typography>
                        </Box>
                      </NavLink>
                    )}

                  {RequestAppointmentPermission &&
                    (role === "Customer" || role === "Clinic") && (
                      <NavLink
                        to={
                          role === "Customer"
                            ? "/user/customerappointmentsRequest"
                            : role === "Clinic"
                            ? "/user/appointmentrequests"
                            : ""
                        }
                      >
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          {/* <PersonAddAlt1Icon sx={{ height: `30%`, color: "#D83F87", mr: 1 }}/> */}
                          <img
                            src={AppointmentReq}
                            className="sidemenuicon"
                            alt="appointmentslogo"
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            {role === "Clinic"
                              ? "Appointment Requests"
                              : role === "Customer"
                              ? "Requested Appointments"
                              : ""}
                          </Typography>
                        </Box>
                      </NavLink>
                    )}

                  {customerpermission && (
                    <NavLink to="customerlisting">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={customerListingLogo}
                          className="sidemenuicon"
                          alt="customerlistinglogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Customer Listing
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {customerpermission && (
                    <NavLink to="retailPlanscustomerlisting">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={customerListingLogo}
                          className="sidemenuicon"
                          alt="customerlistinglogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Retail Plans Customer List
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {billingpermission &&
                    (role === "Admin" || role === "Clinic") && (
                      <NavLink to="/user/billingAndSettlementTable">
                        <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                          <img
                            src={Billing}
                            className="sidemenuicon"
                            alt="appointmentslogo"
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            sx={{ color: "#ffffff" }}
                            fontSize="0.89rem"
                          >
                            Billing And Settlement
                          </Typography>
                        </Box>
                      </NavLink>
                    )}

                  {procedurepermission && (
                    <Accordion
                      sx={{
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#2d256c",
                      }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#ffffff" }} />
                        }
                        sx={{ padding: "0px", margin: "0px!important" }}
                      >
                        <img
                          src={Plans}
                          className="sidemenuicon"
                          alt="planslogo"
                          style={{ marginRight: "10px" }}
                        />{" "}
                        <Typography
                          sx={{
                            color: "#ffffff",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Plans & Procedure
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0px 0px 0px 10px" }}>
                        {procedurepermission && (
                          <NavLink to="plansAndProcedure">
                            <Box sx={{ display: "flex", gap: "5px" }}>
                              <FiberManualRecordIcon
                                sx={{
                                  height: `30%`,
                                  color: "#D83F87",
                                  fontSize: "1rem",
                                }}
                              />{" "}
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#ffffff",
                                  fontWeight: 600,
                                }}
                              >
                                Speciality & Procedures
                              </Typography>
                            </Box>
                          </NavLink>
                        )}

                        {planspermission &&
                          (role === "Admin" ||
                            designation === "Customer Support Executive") && (
                            <NavLink to="plansAndPricing">
                              <Box
                                sx={{ display: "flex", gap: "5px", mt: "10px" }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    height: `30%`,
                                    color: "#D83F87",
                                    fontSize: "1rem",
                                  }}
                                />{" "}
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    fontWeight: 600,
                                  }}
                                >
                                  B2B Plans & Pricing
                                </Typography>
                              </Box>
                            </NavLink>
                          )}
                        {planspermission &&
                          (role === "Admin" ||
                            designation === "Customer Support Executive") && (
                            <NavLink to="retailPlansAndPricing">
                              <Box
                                sx={{ display: "flex", gap: "5px", mt: "10px" }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    height: `30%`,
                                    color: "#D83F87",
                                    fontSize: "1rem",
                                  }}
                                />{" "}
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    fontWeight: 600,
                                  }}
                                >
                                  Retail Plans & Pricing
                                </Typography>
                              </Box>
                            </NavLink>
                          )}

                        {couponpermission &&
                          (role === "Admin" ||
                            designation === "Customer Support Executive") && (
                            <NavLink to="coupons">
                              <Box
                                sx={{ display: "flex", gap: "5px", mt: "10px" }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    height: `30%`,
                                    color: "#D83F87",
                                    fontSize: "1rem",
                                  }}
                                />{" "}
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "#ffffff",
                                    fontWeight: 600,
                                  }}
                                >
                                  Coupons
                                </Typography>
                              </Box>
                            </NavLink>
                          )}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {role === "Customer" && (
                    <NavLink to="/user/pendingPayment">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={pendingPayments}
                          className="sidemenuicon"
                          alt="planslogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          Pending Payments
                        </Typography>
                      </Box>
                    </NavLink>
                  )}

                  {role === "Customer" && (
                    <NavLink to="/user/customerPlansAndCoupons">
                      <Box className="appbar-menu-items" sx={{ mt: "10px" }}>
                        <img
                          src={plansAndCoupons}
                          className="sidemenuicon"
                          alt="planslogo"
                        />
                        <Typography
                          variant="subtitle2"
                          fontWeight="bold"
                          sx={{ color: "#ffffff" }}
                          fontSize="0.89rem"
                        >
                          My Plans & Coupons
                        </Typography>
                      </Box>
                    </NavLink>
                  )}
                  {role === "Admin" && (
                    <NavLink to={`/user/roles`}>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        sx={{
                          color: "#FFFFFF",
                          mt: "10px",
                          textAlign: "center",
                          backgroundColor: "#D83F87",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        fontSize="0.89rem"
                      >
                        Roles
                      </Typography>
                    </NavLink>
                  )}
                  {role === "Customer" && (
                    <NavLink to={`/user/profile/${uniqueId}`}>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        sx={{
                          color: "#FFFFFF",
                          mt: "10px",
                          textAlign: "center",
                          backgroundColor: "#D83F87",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        fontSize="0.89rem"
                      >
                        Profile
                      </Typography>
                    </NavLink>
                  )}
                  {role === "Clinic" && (
                    <NavLink to={`/user/passwordchange`}>
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        sx={{
                          color: "#FFFFFF",
                          mt: "10px",
                          textAlign: "center",
                          backgroundColor: "#D83F87",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        fontSize="0.89rem"
                      >
                        Change Password
                      </Typography>
                    </NavLink>
                  )}
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      sx={{
                        color: "#FFFFFF",
                        mt: "10px",
                        textAlign: "center",
                        backgroundColor: "#D83F87",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      fontSize="0.89rem"
                      onClick={() => {
                        window.localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </Typography>
                </ul>
              </Box>

              {/*-------------------some changes are done ------------------- */}
              <div className="options">
                {role === "Clinic" ? (
                  <>
                    <span
                      onClick={() => {
                        navigate("/user/passwordchange");
                      }}
                    >
                      Change Password
                    </span>
                    <span
                      onClick={() => {
                        window.localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </span>
                  </>
                ) : role === "Admin" ? (
                  <>
                    <span
                      onClick={() => {
                        navigate("/user/roles");
                      }}
                    >
                      Roles
                    </span>
                    <span
                      onClick={() => {
                        window.localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </span>
                  </>
                ) : role === "Customer" ? (
                  <>
                    <span
                      onClick={() => {
                        navigate(`/user/profile/${uniqueId}`);
                      }}
                    >
                      Profile
                    </span>
                    <span
                      onClick={() => {
                        window.localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </span>
                  </>
                ) : 
                <>
                    <span
                      onClick={() => {
                        window.localStorage.removeItem("token");
                        navigate("/");
                      }}
                    >
                      Logout
                    </span>
                </>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Appbar;
