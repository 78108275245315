import React from "react";
import footerLogo from "../assets/Tron-Logo-footer.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box className="footerbox">
      <Box className="footermenu">
        <Box className="footerlogosection">
          <img src={footerLogo} alt="footerLogo" />
          <p>
            Tron Healthcare collaborates with top-tier doctors and clinics in
            your local area to ensure you get the best OPD treatment on time,
            everytime.
          </p>
        </Box>
        <Box className="footermenuitems">
          <Box>
            <h3>Company</h3>
            <p>About</p>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
            {/* <Link to="https://tron.health/blog/">
                <p>Blog</p>
                </Link> */}
            <a
              href="https://tron.health/blog/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Blog</p>
            </a>
          </Box>
          {/* <Box>
                  <h3>Product</h3>
                  <p>One Two</p>
                  <p>One Three</p>
                  <p>One Four</p>
                </Box> */}
          <Box>
            <h3>Legal</h3>
            <Link to="/tnc">
              <p>Terms & Conditions</p>
            </Link>
            <Link to="/privacyPolicy">
              <p>Privacy policy</p>
            </Link>
          </Box>
        </Box>
        <Box className="signUpBox">
          <h2>Sign up for our newsletter</h2>
          <Box className="subscribeBox">
            {/* <input type="text" />
                <button>Subscribe</button> */}
            <OutlinedInput
              className="inputbox"
              size="small"
              onChange={(e) => console.log(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                      color: "#ffffff",
                      backgroundColor: "#D83F87",
                      padding: "7px 15px",
                      borderRadius: "20px",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#D83F87!important",
                        border: "none",
                      },
                    }}
                  >
                    Subscribe
                  </Button>
                </InputAdornment>
              }
            />
          </Box>
          <Box className="follow">
            {/* <img src={blueinstagram} alt="instafooter" />
                  <img src={bluetwitter} alt="twitterfooter" />
                  <img src={blueyoutube} alt="youtubefooter" /> */}
            <LinkedInIcon />
            <FacebookIcon />
            <a
              href="https://www.instagram.com/tron.health/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
          </Box>
        </Box>
      </Box>
      {/* <Box className="copyrights"> */}
      {/* <Box className="follow">
              <LinkedInIcon />
              <FacebookIcon />
              <InstagramIcon />
            </Box> */}
      {/* <Box className="copyrightstext">
          <p>© 2023, Tron.health All Rights Reserved.</p>
        </Box> */}
      {/* </Box> */}
      <Box className="footerbottom">
        <Box className="copyrightstext">
          <p>© 2023, Tron.health All Rights Reserved.</p>
        </Box>
        <Box className="footercontact" sx={{ textAlign: "right" }}>
          <Box>
            <p>
              Connect with us at 
              <a href={`tel:8040091997`}> +91 8040091997</a> |
              <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
              <a href={`mailto:support@tron.health`}> support@tron.health.</a>
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
