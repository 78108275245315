import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const TermnCon = () => {
  return (
    <div className="termconditionbody">
      <Navbar />
      <div className="termNCon">
        <h1>Terms and Conditions</h1>
        <ol>
          <li>
            Tron Healthcare Private Limited is an offering in India that
            promotes health and wellness by bringing awareness among the people
            and helps reduce out of pocket expenses by offering associated OPD
            plans and treatment packages. By stressing on the importance of
            wellness and human health, Tron creates an impact on quality of
            life. The products offered by Tron are not insurance products or
            insurance offerings. Tron promotes preventive healthcare and aims to
            make available health care affordable by subsidising the treatment
            costs and offering discounted preventive healthcare packages.
          </li><br/>
          <li>
            The preferred provider network of Tron includes third party
            practitioners and hospitals of repute that meet the quality
            standards of Tron and have agreed to perform the treatments at a
            particular cost and quality and within the agreed framework.{" "}
          </li><br/>
          <li>
            Tron has a Quality Audit Committee that audits the network of
            practitioners in terms of quality of infrastructure, processes and
            treatment care. Tron does not own any practices or is the direct
            financial beneficiary of any treatments undertaken.{" "}
          </li><br/>
          <li>
            Tron shall not be liable for any negligence or misconduct of the
            providers. Tron in its best effort basis endeavours to ensure that
            the practitioners/doctors engaged by Tron are doing so for a
            societal cause and in case any unethical practice is suspected
            against the practitioner, Tron disciplinary committee will
            de-empanel such a service provider from Tron’s network.{" "}
          </li><br/>
          <li>
            Customers on Tron’s platform choose clinics without bias based on
            the preference of the subscriber. Neither does Tron list the
            practitioners in any manner that discriminates between
            practitioners. Tron does not hold any reservations for or against
            any practitioner. It offers the service provider’s details to
            relevant customers only on the basis of the need and convenience of
            the customer.{" "}
          </li><br/>
          <li>
            Tron reserves a right to de-empanel practitioners in case they fail
            to adhere to the pricing norms or materially fail to carry out any
            of their obligations.{" "}
          </li><br/>
          <li>
            Tron is entitled to use the data of the subscribers for any research
            or epidemiological purposes. Tron appreciates feedback from its
            customers (via feedback section and contact us section of this
            website) and ensures resolution to all fair requests and does not
            encourage the use of any public media/ forum with respect to any
            issues with the services offered to its customers
          </li><br/>
          <li>
            Pricing for plans and packages listed on the website are current.
            While every care has been taken to label service products
            accurately, errors in the data entry and/or updating may occur.{" "}
          </li><br/>
          <li>
            Tron reserves the right to cancel any plan or package in such a case
            where a transaction has already been made and the price indicated
            was incorrect
          </li><br/>
          <li>
            In the aforementioned rare event, a full refund of the payment
            received from the customer shall be processed with accuracy.{" "}
          </li><br/>
          <li>
            All plans, offers and packages are offered by Tron either directly
            or through third party channel partners. All the benefits are made
            available to customers only when the customer uses the applications
            made available by Tron. Similarly, all Ofers, discounts and packages
            are also auto applied when the customer processes the payments
            through the Tron app. Tron does not guarantee availability of all
            discounts and benefits when a customer circumvents the systems
            offered by Tron, especially the payment channels.
          </li><br/>
          <li>
            All prices on this website are in Indian Rupees only, usage of
            international cards on the site may prompt the respective bank's
            applicable exchange rate and charges.{" "}
          </li><br/>
          <li>
            We have no jurisdiction on the same and/or any disputes/queries
            arising due to exchange rates and/or bank charges. The queries need
            to be directed towards the bank or institution that issued the said
            card or payment instrument.{" "}
          </li><br/>
          <li>
            All orders are acknowledged at current pricing only. The billing
            will be effective of the price of the time of receipt of the payment
            and raising of an invoice.{" "}
          </li><br/>
          <li>Prices are subject to change without advance notice.</li><br/>
          <li>Our products are liable for GST in India.</li><br/>
        </ol>
      </div>
      <Footer/>
    </div>
  );
};

export default TermnCon;
