import React, { useContext } from "react";
import AddHomeIcon from "@mui/icons-material/AddHome";
// import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
// import DomainIcon from '@mui/icons-material/Domain';
// import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import "../styles/layout.scss"
import { Permissions } from "./Context/PermissionsContext";
import staffLogo from "../assets/staff.svg";
import appointments from "../assets/appointment.svg";
import customerListingLogo from "../assets/customer listing.svg";
import onBoardingReports from "../assets/onboarding report.svg";
import Plans from "../assets/Plans.svg";
import Billing from "../assets/Billing.svg";
import ClinicDetails from "../assets/Clinic.svg"
import AppointmentReq from "../assets/Appoint req.svg"
import pendingPayments from "../assets/pending payments.svg"
import plansAndCoupons from "../assets/plans and coupon.svg"
import myAppointments from "../assets/user calender.svg"

const Leftbar = () => {

  const tokens = localStorage.getItem("token");
  const role = JSON.parse(tokens)?.role;
  const designation = JSON.parse(tokens)?.designation;

  const {permissionsData}=useContext(Permissions);
  


  let plansPermissionsModule=permissionsData?.filter(item =>item.module==="plans");
  let billingPermissionsModule=permissionsData?.filter(item =>item.module==="billing");
  let reportPermissionsModule=permissionsData?.filter(item=>item.module==="report");
  let procedurePermissionsModule=permissionsData?.filter(item=>item.module==="procedure");
  let staffPermissionsModule=permissionsData?.filter(item=>item.module==="staff");
  let appointmentPermissionsModule=permissionsData?.filter(item=>item.module==="appointment");
  let couponsPermissionsModule=permissionsData?.filter(item=>item.module==="coupons");
  let customerPermissionsModule=permissionsData?.filter(item=>item.module==="customer");
  let clinicPermissionsModule=permissionsData?.filter(item=>item.module==="clinic");
  let reportpermission=reportPermissionsModule && reportPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let procedurepermission=procedurePermissionsModule && procedurePermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let billingpermission=billingPermissionsModule && billingPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let staffpermission=staffPermissionsModule && staffPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let clinicpermission=clinicPermissionsModule && clinicPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let planspermission=plansPermissionsModule && plansPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let customerpermission=customerPermissionsModule && customerPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let couponpermission=couponsPermissionsModule && couponsPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  let myappointmentspermission=appointmentPermissionsModule && appointmentPermissionsModule[0]?.permissions.some(permission=>permission.permission);
  // console.log("reportpermission",myappointmentspermission);
  let clinicPermissionsArray=clinicPermissionsModule && clinicPermissionsModule[0]?.permissions?clinicPermissionsModule[0]?.permissions:"";
  let ClinicDetailsPermission = clinicPermissionsArray && clinicPermissionsArray?.find((item) => item.action === "Clinic Details")?.permission;
  // let ClinicDetailsPermission=false
  let AppointmentRequestPermissions=appointmentPermissionsModule && appointmentPermissionsModule[0]?.permissions?appointmentPermissionsModule[0]?.permissions:"";
  let RequestAppointmentPermission =AppointmentRequestPermissions && AppointmentRequestPermissions?.find((item) => item.action === "Request Appointment")?.permission;
  let NewAppointmentPermission=AppointmentRequestPermissions && AppointmentRequestPermissions?.find((item) => item.action === "New Appointment")?.permission;
  // console.log("planspermissions", couponpermission);


  
  // const leftMenu=[
  //   (clinicpermission && (role==="Admin" || designation==="Team Lead" || designation==="Onboarding Executive" || designation==="Customer Support Executive")) &&{ icon:AddHomeIcon, title:"Clinic Onboarding", link:"clinicList",className:"menu-items" },
  //   reportpermission && { icon:AddHomeIcon, title:"Onboarding Reports", link:"onboardingReport",className:"menu-items" },
  //   staffpermission && { icon:AssessmentIcon, title: "Staff", link:"staffList",className:"menu-items" },
  //   (myappointmentspermission && (role==="Customer")) && {icon:AddHomeIcon,title:"My Appointments",link:"appointments/today",className:"menu-items"},
  //   (myappointmentspermission && (designation==="Customer Support Executive")) && {icon:AddHomeIcon,title:"Appointments",link:"cseAppointments/upcoming",className:"menu-items"},
  //   (designation==="Customer Support Executive" && NewAppointmentPermission) && {icon:AddHomeIcon,title:"New Appointment",link:"cseAppointmentBooking",className:"menu-items"},
  //   (myappointmentspermission && role==="Clinic") && {icon:AddHomeIcon,title:"My Appointments",link:"clinicappointments/today",className:"menu-items"},
  //   (RequestAppointmentPermission && role==="Customer") && {icon:PersonAddAlt1Icon,title:"Requested Appointments",link:"/user/customerappointmentsRequest",className:"menu-items"},
  //   (ClinicDetailsPermission && role==="Clinic") && {icon:DomainIcon,title:"Clinic Details",link:"clinicsclinicview",className:"menu-items"},
  //   (RequestAppointmentPermission && (role==="Clinic" )) && {icon:PersonAddAlt1Icon,title:"Appointment Requests",link:"/user/appointmentrequests",className:"menu-items"},
  //   (NewAppointmentPermission && role==="Customer" ) && {icon:CalendarMonthIcon,title:"New Appointment",link:"/user/newappointments",className:"menu-items"},
  //   customerpermission && {icon:PersonAddAlt1Icon,title:"Customer Listing",link:"customerlisting",className:"menu-items"},
  //   procedurepermission && {icon:ViewHeadlineIcon,title:"Plans & Procedure",link:"plansAndProcedure",className:"menu-items"},
  //   procedurepermission && {icon:FiberManualRecordIcon,title:"Speciality & Procedures",link:"plansAndProcedure",className:"submenu-item"},
  //   (planspermission && (role ==="Admin" || designation==="Customer Support Executive")) && {icon:FiberManualRecordIcon,title:"Plans & Pricing",link:"plansAndPricing",className:"submenu-item"},
  //   (couponpermission && (role === "Admin" || designation==="Customer Support Executive")) && {icon:FiberManualRecordIcon,title:"Coupons",link:"coupons",className:"submenu-item"},
  //   (billingpermission && (role==="Admin" || role==="Clinic")) && {icon:PersonAddAlt1Icon,title:"Billing And Settlement",link:"/user/billingAndSettlementTable",className:"menu-items"},
  //   (role==="Customer") && {icon:CalendarMonthIcon,title:"Plans",link:"/user/customerPlansAndCoupons",className:"menu-items"}
  // ]
  

  // useEffect(()=>{
  //   getPermissions();
  // },[])

  return (
    <>
      <div className="leftbar">
        {/* {(role === "Admin" || designation === "Admin"
          ? leftMenuAdmin
          : (role === "Staff" && designation==="Onboarding Executive")
          ? leftMenuStaffOnBoardingExecutive
          : (role==="Staff" && designation==="Team Lead")
          ? leftMenuStaffTeamLead
          : (role==="Staff" && designation==="Customer Support Executive")
          ? leftMenuCustomerSupportExecutive
          : role ==="Customer"
          ? leftMenuCustomer
          : role ==="Clinic"
          ? leftMenuClinic
          : [{ icon: AddHomeIcon, title: "NotFound", link: "notfound" }]
        ).map((item, index) => {
          return(
          <>
          <Link to={item?.link?item.link:""} >
            <div className={`${item?.className}`} key={index} style={{marginTop:"10px"}}>
              {item?.icon ? <item.icon sx={{ height: `30%`, color: "#D83F87", mr: 1 }} />:""}{" "}
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                {item?.title}
              </Typography>
            </div>
          </Link>
        </>
        )})} */}


        { clinicpermission && role!=="Customer" && role!=="Clinic" && <Link to="clinicList" >
          <Box className="menu-items" sx={{mt:"10px"}}>
            <AddHomeIcon sx={{width:"13%", color: "#D83F87", mr: 1 }}/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Clinic OnBoarding
              </Typography>
          </Box>
        </Link>}

        { reportpermission && <Link to="onboardingReport" >
          <Box className="menu-items" sx={{mt:"10px"}}>
            <img src={onBoardingReports} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="onboarding reports logo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Onboarding Reports
              </Typography>
          </Box>
        </Link>}

        { staffpermission && <Link to="staffList" >
          <Box className="menu-items" sx={{mt:"10px"}}>
            <img src={staffLogo} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="stafflogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Staff
              </Typography>
          </Box>
        </Link>}

        { (myappointmentspermission && (role==="Customer" || role==="Clinic")) && <Link to={role==="Customer"?"appointments/today":role==="Clinic"?"clinicappointments/today":""} >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={myAppointments} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                My Appointments
              </Typography>
          </Box>
        </Link>}

        { ClinicDetailsPermission && role==="Clinic" && <Link to="clinicsclinicview" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={ClinicDetails} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Clinic Details
              </Typography>
          </Box>
        </Link>}

        { myappointmentspermission && designation==="Customer Support Executive" && <Link to="cseAppointments" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={myAppointments} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Appointments
              </Typography>
          </Box>
        </Link>}

        { NewAppointmentPermission && (designation==="Customer Support Executive" || role==="Customer") && <Link to={designation==="Customer Support Executive"?"cseAppointmentBooking":designation==="Customer"?"/user/newappointments":""} >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={appointments} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                New Appointment
              </Typography>
          </Box>
        </Link>}

        { (RequestAppointmentPermission && (role==="Customer" || role==="Clinic")) && <Link to={role==="Customer"?"/user/customerappointmentsRequest":role==="Clinic"?"/user/appointmentrequests":""} >
          <Box className="menu-items" sx={{mt:"10px"}}>
            {/* <PersonAddAlt1Icon sx={{ height: `30%`, color: "#D83F87", mr: 1 }}/> */}
            <img src={AppointmentReq} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                {role==="Clinic"?"Appointment Requests":role==="Customer"?"Requested Appointments":""}
              </Typography>
          </Box>
        </Link>}

        { customerpermission && <Link to="customerlisting" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={customerListingLogo} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="customerlistinglogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Customer Listing
              </Typography>
          </Box>
        </Link>}

        { customerpermission && <Link to="retailPlanscustomerlisting" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={customerListingLogo} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="customerlistinglogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Retail Plans Customer List
              </Typography>
          </Box>
        </Link>}

        {/* { role==="Admin" && <Link to="/user/PlansAndCoupons" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={Plans} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="planslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Plans & Coupons
              </Typography>
          </Box>
        </Link>} */}
        
        { (billingpermission && (role==="Admin" || role==="Clinic")) && <Link to="/user/billingAndSettlementTable" >
          <Box className="menu-items" sx={{mt:"10px"}}>
            <img src={Billing} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="appointmentslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Billing And Settlement
              </Typography>
          </Box>
        </Link>}
        { role==="Customer" && <Link to="/user/pendingPayment" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={pendingPayments} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="planslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                Pending Payments
              </Typography>
          </Box>
        </Link>}
        

        {procedurepermission && <Accordion sx={{border:"none",boxShadow:"none"}}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
          sx={{padding:"0px",margin:"0px!important"}}
        >
          <img src={Plans} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="planslogo"/>{" "}<Typography sx={{ color: "#2D256C",fontWeight:"bold",fontSize:"0.9rem" }}>Plans & Procedure</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding:"0px 0px 0px 10px"}}>
          {procedurepermission && <Link to="plansAndProcedure">
          <Box sx={{display:"flex",gap:"5px"}}>
            <FiberManualRecordIcon sx={{ height: `30%`, color: "#D83F87",fontSize:"1rem" }}/>{" "}
          <Typography sx={{fontSize:"12px",color:"#2D256C",fontWeight:600}}>Speciality & Procedures</Typography>
          </Box>
          </Link>}

          {(planspermission && (role ==="Admin" || designation==="Customer Support Executive")) &&<Link to="plansAndPricing">
          <Box sx={{display:"flex",gap:"5px",mt:"10px"}}>
            <FiberManualRecordIcon sx={{ height: `30%`, color: "#D83F87",fontSize:"1rem" }}/>{" "}
          <Typography sx={{fontSize:"12px",color:"#2D256C",fontWeight:600}}>B2B Plans & Pricing</Typography>
          </Box>
          </Link>}

          {(planspermission && (role ==="Admin" || designation==="Customer Support Executive")) &&<Link to="retailPlansAndPricing">
          <Box sx={{display:"flex",gap:"5px",mt:"10px"}}>
            <FiberManualRecordIcon sx={{ height: `30%`, color: "#D83F87",fontSize:"1rem" }}/>{" "}
          <Typography sx={{fontSize:"12px",color:"#2D256C",fontWeight:600}}>Retail Plans & Pricing</Typography>
          </Box>
          </Link>}

          {(couponpermission && (role ==="Admin" || designation==="Customer Support Executive")) &&<Link to="coupons">
          <Box sx={{display:"flex",gap:"5px",mt:"10px"}}>
            <FiberManualRecordIcon sx={{ height: `30%`, color: "#D83F87",fontSize:"1rem" }}/>{" "}
          <Typography sx={{fontSize:"12px",color:"#2D256C",fontWeight:600}}>Coupons</Typography>
          </Box>
          </Link>}
        </AccordionDetails>
      </Accordion>}

      

        { role==="Customer" && <Link to="/user/customerPlansAndCoupons" >
          <Box className="menu-items" sx={{mt:"10px"}}>
          <img src={plansAndCoupons} style={{ width: `13%`, color: "#D83F87", marginRight: 8 }} alt="planslogo"/>
          <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "#2D256C" }}
                fontSize="0.89rem"
              >
                My Plans & Coupons
              </Typography>
          </Box>
        </Link>}
      </div>
    </>
  );
};

export default Leftbar;
