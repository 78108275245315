import { Box, Button, Card, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import {
  addPotentialCustomer,
  checkClinicsAvailability,
} from "../../services/login";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};

const BasicInfo = ({ selected, setSelected, pincode, setPincode,name,setName,email, setEmail }) => {
  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const success = () => {
    toast.success("Sucessfully Submitted", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const [bool, setBool] = React.useState();
  async function checkPincode() {
    const { data } = await checkClinicsAvailability(pincode);
    console.log("data: ", data);
    setBool(data);
    if (data === false) {
      handleOpen();
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setBool();
  }, [pincode]);

  async function potentialClientsInfo() {
    const { data } = await addPotentialCustomer({
      name: name,
      mobileNumber: number,
      emailId:email,
      pincode: pincode,
    });
    console.log("data: ", data);
    if(data==="Sucessfully Added."){
      handleClose()
      success()
    }else{
      error()
    }
  }

  return (
    <>
    <Card sx={{ minHeight: "300px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Enter Name
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Name"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Enter Email
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Email"
            variant="outlined"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Enter Pincode
          </Typography>
          <CssTextField
            size="small"
            fullWidth
            placeholder="Enter Pincode"
            variant="outlined"
            type="number"
            onChange={(e) => {
              setPincode(e.target.value);
            }}
            value={pincode}
          />
          <div style={{ color: bool === true ? "green" : "red" }}>
            {bool === true && "Available"}
            {bool === false && "Not Available"}
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",
              padding: "8px 15px",
              marginLeft: "10px",
              marginTop: "35px",
            }}
            onClick={() => {
              pincode && checkPincode();
            }}
          >
            Check
          </Button>
        </Grid>
      </Grid>

      <br />
      <br />
      <Button
        variant="contained"
        type="submit"
        sx={{
          width: "15em",
          backgroundColor: "#2d256c!important",
          fontSize: "12px",

          padding: "8px 15px",
          // marginLeft: "10px",
        }}
        onClick={() => bool === true && setSelected("Verification")}
      >
        Next
      </Button>

    </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            We're not available in this area yet, but we'll let you know once
            this pin code joins the Tron network.
          </Typography>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Name
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Name"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Mobile Number
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Number"
            variant="outlined"
            type="number"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <br/>
          <br/>
               <Button
        variant="contained"
        type="submit"
        sx={{
          width: "15em",
          backgroundColor: "#2d256c!important",
          fontSize: "12px",

          padding: "8px 15px",
          // marginLeft: "10px",
        }}
        onClick={() => name&&number&&email && potentialClientsInfo()}
      >
        Submit
      </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BasicInfo;
