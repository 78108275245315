import React from "react";
import { Autocomplete, Button, Card, Grid, Typography } from "@mui/material";
import { CssTextField } from "../../styles/textFields";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from 'react-router-dom';

const ClinicDetails = ({ onSubmit, fields, formik ,edit}) => {
  const {id}=useParams()
  return (
    <>
      <form onSubmit={onSubmit} id="Clinic_Details">
        <Card>
          <Grid container spacing={2}>
            {fields.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item md={4} xs={12}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item.lable}
                  </Typography>

                  {item.type === "text" ? (
                    <CssTextField
                      size="small"
                      fullWidth
                      
                    disabled={(id)&&(!edit)?true:false}
                      placeholder={item.placeholder}
                      variant="outlined"
                      type="text"
                      name={item.value}
                      onChange={formik.handleChange}
                      value={formik.values[item.value]}
                      error={
                        formik.touched[item.value] &&
                        Boolean(formik.errors[item.value])
                      }
                      helperText={
                        formik.touched[item.value] && formik.errors[item.value]
                      }
                    />
                  ) : item.type === "dropdown" ? (
                    <Autocomplete
                      multiple={item.value==="clinicType"?true:false}
                      disableClearable
                      
                    disabled={(id)&&(!edit)?true:false}
                      options={item?.options}
                      name={item.value}
                      onChange={(event, value) => {
                        formik.setFieldValue(item.value, value);
                      }}
                      value={
                        formik.values[item.value]
                          ? formik.values[item.value]
                          : null
                      }
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          fullWidth
                          placeholder={item.lable}
                          variant="outlined"
                          type="text"
                          error={
                            formik.touched[item.value] &&
                            Boolean(formik.errors[item.value])
                          }
                          helperText={
                            formik.touched[item.value] &&
                            formik.errors[item.value]
                          }
                        />
                      )}
                    />
                  ) : item.type === "number" ? (
                    <CssTextField
                      size="small"
                      fullWidth
                      
                    disabled={(id)&&(!edit)?true:false}
                      placeholder={item.placeholder}
                      variant="outlined"
                      type="number"
                      name={item.value}
                      onChange={formik.handleChange}
                      value={formik.values[item.value]}
                      error={
                        formik.touched[item.value] &&
                        Boolean(formik.errors[item.value])
                      }
                      helperText={
                        formik.touched[item.value] && formik.errors[item.value]
                      }
                    />
                  ) : item.type === "calendar" ? (
                    <DatePicker
                      views={["year"]}
                      
                    disabled={(id)&&(!edit)?true:false}
                      name={item.value}
                      onChange={(value) =>
                        formik.setFieldValue(item.value, value, true)
                      }
                      value={formik.values[item.value]}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          placeholder={item.lable}
                          size="small"
                          name={item.value}
                          fullWidth
                          variant="outlined"
                          error={
                            formik.touched[item.value] &&
                            Boolean(formik.errors[item.value])
                          }
                          helperText={
                            formik.touched[item.value] &&
                            formik.errors[item.value]
                          }
                        />
                      )}
                    />
                  ) : null}
               
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} md={4}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Willing To Offer Discount?
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={6}>
                    <Autocomplete
                      disableClearable
                      
                    disabled={(id)&&(!edit)?true:false}
                      options={["Yes", "No"]}
                      name="givingDiscount"
                      onChange={(event, value) => {
                        formik.setFieldValue("givingDiscount", value);
                      }}
                      value={
                        formik.values["givingDiscount"]
                          ? formik.values["givingDiscount"]
                          : null
                      }//   onChange={(event, value) => {
                      //     formik.setFieldValue(item.value, value);
                      //   }}
                      //   value={
                      //     formik.values[item.value]
                      //       ? formik.values[item.value]
                      //       : null
                      //   }
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <CssTextField
                        error={
                          formik.touched["givingDiscount"] &&
                          Boolean(formik.errors["givingDiscount"])
                        }
                        helperText={
                          formik.touched["givingDiscount"] &&
                          formik.errors["givingDiscount"]
                        }
                          {...params}
                          placeholder="Yes/No"
                          variant="outlined"
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <CssTextField
                      size="small"
                      fullWidth
                      
                    disabled={(id)&&(!edit)?true:false}
                      placeholder="If Yes, then amount"
                      variant="outlined"
                      type="text"
                      name={"discountAmount"}
                      onChange={formik.handleChange}
                      value={formik.values["discountAmount"]}
                      error={
                        formik.touched["discountAmount"] &&
                        Boolean(formik.errors["discountAmount"])
                      }
                      helperText={
                        formik.touched["discountAmount"] && formik.errors["discountAmount"]
                      }
                     />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
          <div className="buttonNext">
            <div>
              <Button variant="contained" type="submit" sx={{ width: "15em","&:hover":{backgroundColor:"#2d256c!important"}, fontSize:"12px",padding:"8px 15px"}}>
                Next
              </Button>
            </div>
          </div>
        </Card>
      </form>
    </>
  );
};

export default ClinicDetails;
