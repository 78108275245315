import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import {
  CheckingExistingCustomer,
  getAvailableTimeSlots,
  rescheduleAppointment,
} from "../../services/customerOnboarding";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F3F7",
  // border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};
const phoneRegExp = /^[6-9]\d{9}$/;

const schema = yup
  .object({
    name: yup.string().required(),
    phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().notOneOf([""], "You must select an option!"),
    datevalid: yup.string().required("This Field is required"),
    time: yup.string().required("This Field Is Required"),
  })
  .required();

const CseRescheduleModal = ({ modalData,handleSearchSubmit }) => {
  const [availableTime, setAvailableTime] = useState([]);
  const [open, setOpen] = useState(false);
  const [dailyBasis, setDailybasis] = useState("");
  const [entiredate, setEntireDate] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.username;
  const uniqueId = token?.uniqueId;
  const clinicuniqueId = modalData?.clinicUniqueId;
  const [existingCustomerUniqueId,setExistingCustomerUniqueId]=useState("");

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };
//   console.log("rescheduleone",modalData);

  const handleRescheduleModelOpen = () => {
    // console.log("rescheduleone",el);
    setOpen(true);
    handleExistingCustomer()
  };

  const handleExistingCustomer = async () => {
    try {
      const res = await CheckingExistingCustomer(modalData.patientNumber);
      const data = res.data;
      if (data) {
        // console.log("uniqueid",data.uniqueId);
        console.log("existing customer data",data);
        setExistingCustomerUniqueId(data.uniqueId);
        // console.log("uniqueId",existingCustomerUniqueId)
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRescheduleModalClose = () => {
    setOpen(false);
  };

  const handleDateModification = (date) => {
    let currentdate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setEntireDate(entiredate);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        id: modalData.id,
        patientName: data.name,
        patientNumber: data.phonenumber,
        clinicUniqueId: clinicuniqueId,
        customerUniqueId:existingCustomerUniqueId,
        specialty: data.speciality,
        slot: data.slot,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remark,
        createdBy: token.username,
      };
      console.log("formdata........", formdata);
      try {
         const res=await rescheduleAppointment(formdata);
        reset();
        if(res){
            handleRescheduleModalClose();
            handleSearchSubmit()
        }
        toast.success("Successfully Rescheduled Appointment", {
          position: "top-right",
        });
      } catch (err) {
        console.log(err.message);
        toast.error("Something went wrong Check Server", {
          position: "top-right",
        });
      }
    }
  };

  const getTimeslots = async (dailyBasis) => {
    try {
      //   console.log("dailyBasis in getTimeSlot", dailyBasis);
      const response = await getAvailableTimeSlots(
        clinicuniqueId,
        modalData?.specialty,
        entiredate,
        dailyBasis
      );
      console.log("response......", response.data);
      // console.log(data);
      let data = response.data;
      if (data.length === 0) {
        toast.info(
          "Timings not available Please Choose another Date or Check by changing slot",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      const modifiedTimings = data.map((timing) => {
        const parts = timing.split(" - ");
        const startTime = parts[0];
        const endTime = parts[1];

        // Converting start time to 12-hour format
        const start = new Date(`2000-01-01T${startTime}`);
        const startHours = start.getHours();
        const startMinutes = start.getMinutes();
        const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
          .toString()
          .padStart(2, "0")}`;

        // Converting end time to 12-hour format
        const end = new Date(`2000-01-01T${endTime}`);
        const endHours = end.getHours();
        const endMinutes = end.getMinutes();
        const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
          .toString()
          .padStart(2, "0")}`;

        // Determine AM/PM
        const amPm = startHours < 12 && dailyBasis === "morning" ? "AM" : "PM";

        return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
      });
      //   console.log("modifiedtimings",modifiedTimings);
      if (data) {
        await setAvailableTime(modifiedTimings);
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  return (
    <>
      <Button
        onClick={handleRescheduleModelOpen}
        sx={{
          background: "#D83F87",
          border: "none",
          textAlign: "left",
          color: "#FFFFFF",
          textTransform: "capitalize",
          fontSize: "12px",
          padding: "8px 15px",
          display: "flex",
          borderRadius: "3px",
          justifyContent: "space-around",
          "&:hover": { backgroundColor: "#D83F87!important" },
        }}
      >
        <CalendarMonthIcon style={{ fontSize: "0.9rem", color: "#fff" }} />{" "}
        Reschedule
      </Button>
      
      <Modal
        open={open}
        onClose={handleRescheduleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="desktopReschedulemodal"
      >
        <Box sx={style}>
          <Typography
            sx={{
              color: "#D83F87",
              textAlign: "center",
              width: "95%",
              marginBottom: "20px",
              fontWeight: "600",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Reschedule Appointment
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Patient Name
                  </FormLabel>
                  <TextField
                    value={modalData.patientName}
                    {...register("name")}
                    className="inputbox"
                    size="small"
                    placeholder="Name"
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Mobile Number
                  </FormLabel>
                  <TextField
                    value={modalData.patientNumber}
                    {...register("phonenumber")}
                    className="inputbox"
                    size="small"
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Mobile Number"
                  />
                </FormControl>
              </Box>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Specialty
                </FormLabel>
                <Select
                  {...register("speciality")}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  defaultValue={modalData.specialty}
                >
                  <MenuItem value={modalData.specialty}>
                    {modalData.specialty}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Select Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    value={entiredate}
                    onChange={(date) => {
                      handleDateModification(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ backgroundColor: "#FFFFFF" }}
                        size="small"
                        {...register("datevalid", {
                          valueAsDate: true,
                        })}
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
                <span style={{ color: "red" }} className="error">
                  {errors.datevalid?.message}
                </span>
              </FormControl>

              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel className="formlabel">Morning/Evening</FormLabel>
                  {entiredate && <Select
                    placeholder="Select"
                    size="small"
                    {...register("slot")}
                    onChange={(e) => {
                      getTimeslots(e.target.value);
                      setDailybasis(e.target.value);
                      if (e.target.value) {
                        errors.slot.message = null;
                      }
                    }}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="morning">Morning</MenuItem>
                    <MenuItem value="evening">Evening</MenuItem>
                  </Select>}
                  {console.log("slot", errors.slot)}
                  <span className="error">{errors.slot?.message}</span>
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Select Time
                  </FormLabel>
                  <Select
                    {...register("time")}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    {availableTime.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span style={{ color: "red" }} className="error">
                    {errors.time?.message}
                  </span>
                </FormControl>
              </Box>
              {/* {dailyBasis && availableTime.length === 0 ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Timings not available Please Choose another Date or Check by
                  changing slot
                </span>
              ) : (
                ""
              )} */}
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Remarks
                </FormLabel>
                <TextField
                  value={modalData.remark}
                  {...register("remark")}
                  className="inputbox"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  size="small"
                  placeholder="Please write the remarks here"
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    background: "#696969",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="reset"
                >
                  Reset
                </button>
                <button
                  style={{
                    background: "#D83F87",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CseRescheduleModal;
