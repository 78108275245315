import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import purplelogo from "../assets/tronlogo-smallscreen.svg"
import React, { useState } from "react";
import "../styles/GetStartedModal.scss"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {  contact } from "../services/Contact";
import { toast } from "react-toastify";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "relative",
  top: "53%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
//   border: "2px solid #000",
  boxShadow: 24,
  // overFlow:"scroll",
  borderRadius:1,
  p: 0,
};

const phoneRegex = RegExp(
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([5-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
);

const schema = yup.object({
  fullname: yup.string().min(3,"This Field is Required").required(),
  // clinicname: yup.string().required(),
  email:yup.string().email().trim().min(3, "Enter Email").required(),
  phone: yup.string().matches(phoneRegex, "Invalid Phone Number").required("Phone Number is required")
}).required();

const generateCaptcha = () => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let randomString = "";
  for (let i = 0; i < 6; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  // setCaptchaText(randomString);
  return randomString
};
const GetStartedModal = ({ open, handleClose }) => {
  const [captchaText, setCaptchaText] = useState(generateCaptcha());
  const [captchaError,setCaptchaError]=useState(false);
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit,reset, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data,event) => {
    setLoading(true);
    const userInput = event.target.captcha.value;
    if (userInput === captchaText) {
      console.log("CAPTCHA passed!");
      try {
        console.log(data)
        await contact(data)
        setCaptchaError(false)
        reset();
        toast.success("Form Submitted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        console.log(error)
        toast.error("Something went wrong",error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 

      setTimeout(() => {
         handleClose();
         setCaptchaText(generateCaptcha());
        setLoading(false);
      }, 2000);

    } else {
      console.log("CAPTCHA failed!");
      setLoading(false);
      setCaptchaText(generateCaptcha()); // Regenerate a new CAPTCHA
      setCaptchaError(true)
      toast.error("Invalid Captcha", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mainbox" sx={style}>
          <div className="cancelIcon" onClick={handleClose}>
            <CancelIcon/>
          </div>
          <Box className="Modalcontentbox">
          <Box className="formbox">
          <img src={purplelogo} alt="purplelogo" />
          <form onSubmit={handleSubmit(onSubmit)}>
          <h3 style={{color:"black"}}>Let's get you started</h3>
          <br />
            <FormControl sx={{width:"90%"}} className="formcontrolbox">
              <FormLabel className="formlabel">Full Name</FormLabel>
              <TextField  placeholder="Full Name" size="small" {...register("fullname")}/>
              <span style={{color:"red",fontSize:"12px"}}>{errors.fullname?.message}</span>
            </FormControl>
            {/* <FormControl sx={{width:"90%"}} className="formcontrolbox">
              <FormLabel className="formlabel">Clinic Name</FormLabel>
              <TextField placeholder="Clinic Name" size="small" {...register("clinicname")} />
              <span style={{color:"red",fontSize:"12px"}}>{errors.clinicname?.message}</span>
            </FormControl> */}
            <FormControl sx={{width:"90%"}} className="formcontrolbox">
              <FormLabel className="formlabel">Email Address</FormLabel>
              <TextField placeholder="Email Address" size="small" {...register("email")} />
              <span style={{color:"red",fontSize:"12px"}}>{errors.email?.message}</span>
            </FormControl>
            <FormControl sx={{width:"90%"}} className="formcontrolbox">
              <FormLabel className="formlabel">Phone Number</FormLabel>
              <TextField type="number" placeholder="Phone Number" size="small" {...register("phone")} />
              <span style={{color:"red",fontSize:"12px"}}>{errors.phone?.message}</span>
            </FormControl>
            <FormControl sx={{width:"65%"}} className="formcontrolbox">
              <FormLabel className="formlabel">Enter the text you see in the image:</FormLabel>
              <Box className="captchabox">
              <Typography>{captchaText}</Typography>
              </Box>
              <TextField type="text" id="captcha" name="captcha"  size="small"/>
              {captchaError?<span style={{color:"red",fontSize:"12px"}} >Invalid Captcha</span>:""}
            </FormControl>
            <br />
            <br />
            <button type="submit">{loading ? <CircularProgress size={20} /> :  'Submit' }</button>
          </form>
          </Box>
          <Box className="formrightside">
            <h1>"Bringing Affordable Quality Healthcare To An OPD Clinic Near You."</h1>
          </Box>
          </Box>
          
        </Box>
      </Modal>
    </div>
  );
};

export default GetStartedModal;
