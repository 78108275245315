import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {
  getAvailableTimeSlots,
  getCustomerAppointmentRequestsData,
  rescheduleAppointment,
} from "../../services/customerOnboarding";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import "../../styles/customerAppointmentRequests.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PostAcceptOrRejectAppointment } from "../../services/clinicOnboarding";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F3F7",
  // border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};
const phoneRegExp = /^[6-9]\d{9}$/;
const schema = yup
  .object({
    name: yup.string().required(),
    phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().trim().required("You must select an option!"),
    datevalid: yup.string().required("This Field is required"),
    time: yup.string().trim().required("This Field Is Required"),
  })
  .required();
const CustomerAppointmentRequest = () => {
  const [customerAppointmentReqData, setCustomerAppointmentReqData] = useState(
    []
  );
  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.uniqueId;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [dailyBasis, setDailybasis] = useState("");
  const [availableTime, setAvailableTime] = useState([]);
  const [acceptReject, setAcceptReject] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const clinicuniqueId = modalData?.clinicUniqueId;
  const [date, setDate] = useState(new Date());
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let ReschedulePermission = clinicPermissions?.find(
    (item) => item.action === "Reschedule Appointment"
  )?.permission;
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  const handleOpen = (el) => {
    reset();
    console.log("element id......", el);
    setOpen(true);
    setModalData(el);
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  // let entiredate = `${year}` + `-0${month}` + `-${currentdate}`;
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;
  console.log("entiredate..", entiredate);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        id: modalData.id,
        patientName: data.name,
        patientNumber: data.phonenumber,
        clinicUniqueId: clinicuniqueId,
        specialty: data.speciality,
        slot: data.slot,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remark,
        createdBy: token.username,
      };
      console.log("formdata........", formdata);
      try {
        setIsSubmitting(true);
        await rescheduleAppointment(formdata);
        reset();
        handleClose();
        toast.success("Successfully Rescheduled Appointment", {
          position: "top-right",
        });
      } catch (err) {
        console.log(err.message);
        toast.error("Something went wrong Check Server", {
          position: "top-right",
        });
        setIsSubmitting(false);
      }
    }
  };

  const getCustomerAppointmentRequest = async () => {
    try {
      const response = await getCustomerAppointmentRequestsData(username);
      console.log(response.data);
      const data = response.data;
      let newdata = data.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("newdata response with time..", newdata);
      if (data) {
        setCustomerAppointmentReqData(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getTimeslots = async () => {
    try {
      console.log("dailyBasis in getTimeSlot", dailyBasis);
      const response = await getAvailableTimeSlots(
        clinicuniqueId,
        modalData.specialty,
        entiredate,
        dailyBasis
      );
      console.log("response", response.data);
      let data = response.data;
      if (data) {
        await setAvailableTime(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const modifiedTimings = availableTime.map((timing) => {
    const parts = timing.split(" - ");
    const startTime = parts[0];
    const endTime = parts[1];

    // Converting start time to 12-hour format
    const start = new Date(`2000-01-01T${startTime}`);
    const startHours = start.getHours();
    const startMinutes = start.getMinutes();
    const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
      .toString()
      .padStart(2, "0")}`;

    // Converting end time to 12-hour format
    const end = new Date(`2000-01-01T${endTime}`);
    const endHours = end.getHours();
    const endMinutes = end.getMinutes();
    const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
      .toString()
      .padStart(2, "0")}`;

    // Determine AM/PM
    const amPm = startHours < 12 && dailyBasis === "morning" ? "AM" : "PM";

    return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
  });
  console.log(modifiedTimings);

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      toast.success("Sucessfully Cancelled the Appointment", {
        position: "top-right",
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    getCustomerAppointmentRequest();
  }, [acceptReject]);

  useEffect(() => {
    if (dailyBasis) {
      getTimeslots();
    }
  }, [dailyBasis]);
  return (
    <div>
      <Box id="appointReq">
        <Typography
          variant="h5"
          sx={{ marginBottom: "10px", fontSize: "17px" }}
        >
          Appointment Requests
        </Typography>
        <Card className="thecardbox" variant="outlined">
          {customerAppointmentReqData &&
            customerAppointmentReqData.map((el, id) => {
              return (
                <Box className="tablemainbox">
                  <Box className="tablecontentbox1">
                    <Typography variant="caption" className="idhead">
                      Appointment ID
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#2D2828",
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {el.appointmentId}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox2">
                    <Box className="subbox1">
                      <Typography variant="h6" className="name">
                        {el.clinicName}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <LocationOnIcon style={{ fontSize: "14px" }} />
                        <Typography variant="caption" sx={{ fontSize: "10px" }}>
                          {el.clinicAddress == null ? (
                            <>No Address</>
                          ) : (
                            el.clinicAddress
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          paddingLeft: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          View Location
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          View Clinic Details
                        </Typography>
                      </Box>
                      <Box className="specialitybox">
                        <Typography
                          sx={{
                            fontSize: "10px",
                            backgroundColor: "#FFEFF9",
                            padding: "5px",
                            color: "gray",
                          }}
                        >
                          {el.specialty}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="subbox2">
                      <Box className="datebox">
                        <Typography>Appointment Date</Typography>
                        <Typography variant="h6">
                          {formattedDate(el.date)}
                        </Typography>
                      </Box>
                      <Box className="timebox">
                        <Typography>Appointment Time</Typography>
                        <Typography variant="h6">{el.time}</Typography>
                      </Box>
                    </Box>
                    <Box className="subbox3">
                      <Typography>Remarks</Typography>
                      <Typography variant="h6" className="remarks">
                        {el.remark === "" ? <>No Remarks</> : el.remark}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox3">
                    <Box>
                      {/* {console.log("apppointment last string value",el.appointmentId)} */}
                      {ReschedulePermission && (
                        <Button
                          onClick={() => handleOpen(el)}
                          sx={{
                            backgroundColor: "#D83F87",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            padding: "8px 15px",
                            fontSize: "12px",
                            display: "flex",
                            fontWeight: "600",
                            borderRadius: "3px",
                            justifyContent: "space-around",
                            "&:hover": { backgroundColor: "#D83F87!important" },
                          }}
                          size="small"
                        >
                          <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                          Reschedule
                        </Button>
                      )}
                      <br />
                      {CancelAppointmentPermission && (
                        <Button
                          sx={{
                            backgroundColor: "#3E2732",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            padding: "8px 15px",
                            borderRadius: "3px",
                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "space-around",
                            "&:hover": { backgroundColor: "#3E2732!important" },
                          }}
                          size="small"
                          onClick={() => {
                            handleRejectdialogOpen(el.id);
                            console.log("reject id........ ", el.id);
                          }}
                        >
                          {" "}
                          <CancelPresentationIcon sx={{ fontSize: "0.9rem" }} />
                          Cancel
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Card>
      </Box>

      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Reject ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              handleReject(itemId);
              handleRejectDialogClose();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* --------------------------------MODAL----------------------------- */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              color: "#D83F87",
              textAlign: "center",
              width: "95%",
              marginBottom: "20px",
              fontWeight: "600",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Reschedule Appointment
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Patient Name
                  </FormLabel>
                  <TextField
                    value={modalData.patientName}
                    {...register("name")}
                    className="inputbox"
                    size="small"
                    placeholder="Name"
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Mobile Number
                  </FormLabel>
                  <TextField
                    value={modalData.patientNumber}
                    {...register("phonenumber")}
                    className="inputbox"
                    size="small"
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Mobile Number"
                  />
                </FormControl>
              </Box>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Specialty
                </FormLabel>
                <Select
                  {...register("speciality")}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                  defaultValue={modalData.specialty}
                >
                  <MenuItem value={modalData.specialty}>
                    {modalData.specialty}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Select Date
                </FormLabel>
                {/* <TextField {...register("datevalid",{valueAsDate: true})} className="inputbox" type="date" size="small" /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ backgroundColor: "#FFFFFF" }}
                        size="small"
                        {...register("datevalid", {
                          valueAsDate: true,
                        })}
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
                <span style={{ color: "red" }} className="error">
                  {errors.datevalid?.message}
                </span>
              </FormControl>

              <Box sx={{ width: "95%", display: "flex", gap: "10px" }}>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel className="formlabel">Morning/Evening</FormLabel>
                  <Select
                    placeholder="Select"
                    size="small"
                    {...register("slot")}
                    onChange={(e) => {
                      setDailybasis(e.target.value);
                      if (e.target.value) {
                        errors.slot.message = null;
                      }
                    }}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="morning">Morning</MenuItem>
                    <MenuItem value="evening">Evening</MenuItem>
                  </Select>
                  {console.log("slot", errors.slot)}
                  <span className="error">{errors.slot?.message}</span>
                </FormControl>
                <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                  <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                    Select Time
                  </FormLabel>
                  <Select
                    {...register("time")}
                    sx={{ backgroundColor: "#FFFFFF" }}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    {modifiedTimings.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span style={{ color: "red" }} className="error">
                    {errors.time?.message}
                  </span>
                </FormControl>
              </Box>
              {dailyBasis && availableTime.length === 0 ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Timings not available Please Choose another Date or Check by
                  changing slot
                </span>
              ) : (
                ""
              )}
              <FormControl sx={{ width: "95%", marginBottom: "10px" }}>
                <FormLabel sx={{ color: "#2D256C" }} className="formlabel">
                  Remarks
                </FormLabel>
                <TextField
                  value={modalData.remark}
                  {...register("remark")}
                  className="inputbox"
                  sx={{ backgroundColor: "#FFFFFF" }}
                  size="small"
                  placeholder="Please write the remarks here"
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={reset}
                  style={{
                    background: "#696969",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="reset"
                >
                  Reset
                </button>
                <button
                  disabled={isSubmitting}
                  style={{
                    background: "#D83F87",
                    color: "white",
                    border: "none",
                    padding: "10px 30px 10px 30px",
                    borderRadius: "5px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
        }}
        className="mobilescreencard"
      >
        {customerAppointmentReqData.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                }}
                className="mobilecontentcard"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className="appointmentid" variant="h6">
                      {el.appointmentId}
                    </Typography>
                    <Typography className="dateheading" variant="caption">
                      Date
                    </Typography>
                    <Typography className="date" variant="subtitle1">
                      {formattedDate(el.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="speciality">
                      {el.specialty}
                    </Typography>
                    <Typography variant="caption" className="timeheading">
                      Time
                    </Typography>
                    <Typography variant="subtitle1" className="time">
                      {el.time}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ backgroundColor: "#F5F5F5!important" }}
                    item
                    xs={4}
                  >
                    {ReschedulePermission && (
                      <Button
                        onClick={() => handleOpen(el)}
                        sx={{
                          backgroundColor: "#D83F87",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#D83F87!important" },
                        }}
                        className="reschedulebtn"
                        size="small"
                      >
                        <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                        Reschedule
                      </Button>
                    )}

                    {CancelAppointmentPermission && (
                      <Button
                        sx={{
                          backgroundColor: "#3E2732",
                          textAlign: "left",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": { backgroundColor: "#3E2732!important" },
                        }}
                        className="cancelbtn"
                        size="small"
                        onClick={() => {
                          handleRejectdialogOpen(el.id);
                          console.log("reject id........ ", el.id);
                        }}
                      >
                        {" "}
                        <CancelPresentationIcon sx={{ fontSize: "0.9rem" }} />
                        Cancel
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>
      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>Finding it hard to reschedule an appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default CustomerAppointmentRequest;
