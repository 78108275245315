import http from "./httpServices";
import config from "../config.json";


export function loginTron(data){
    return http.post(config.apiUrl+"login", data);
}
export function addCustomerFeedback(data){
    return http.post(config.apiUrl+"addCustomerFeedback", data);
}
export function checkClinicsAvailability(data){
    return http.get(config.apiUrl+"checkClinicsAvailability/"+data);
}
export function checkIfFeedbackSubmitted(data){
    return http.get(config.apiUrl+"checkIfFeedbackSubmitted/"+data);
}
export function checkCustomersExsistance(data){
    return http.get(config.apiUrl+"checkCustomersExsistance/"+data);
}
export function generateLoginOtp(data){
    return http.get(config.apiUrl+"generateLoginOtp/"+data);
}
export function verifyLoginOtp(data, otp){
    return http.get(config.apiUrl+"verifyLoginOtp/"+otp+"/"+data);
}
export function addPotentialCustomer(data){
    return http.post(config.apiUrl+"addPotentialCustomer",data);
}
export function addB2CCustomerDetails(data){
    return http.post(config.apiUrl+"addB2CCustomerDetails",data);
}
export function getActiveRetailPlanAndPricingList (){
    return http.get(config.apiUrl+"getActiveRetailPlanAndPricingList ");
}
export function getB2CCustomerDetailsForNumber (number){
    return http.get(config.apiUrl+"getB2CCustomerDetailsForNumber/"+number);
}
export function generateOtpToBuyPlan (number){
    return http.get(config.apiUrl+"generateOtpToBuyPlan/"+number);
}
export function getCustomerDetailsByMerchantTransactionId(id){
    return http.get(config.apiUrl+"getCustomerDetailsByMerchantTransactionId/"+id);
}
export function setCustomerLoginStatus(id){
    return http.get(config.apiUrl+"setCustomerLoginStatus/"+id);
}
export function getCustomersExistingPLanList(number){
    return http.get(config.apiUrl+"getCustomersExistingPLanList/"+number);
}
export function retryPaymentForB2cPlans(id){
    return http.get(config.apiUrl+"retryPaymentForB2cPlans/"+id);
}
export function getListOfClinicCities(){
    return http.get(config.apiUrl+"getCitiesList");
}

