import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAppointmentPaymentStatus } from "../../services/clinicOnboarding";
import { setPaymentStatus } from "../../services/customerOnboarding";
import { retryAppointmentChargesPayment } from "../../services/PlansAndProcedure";
import moment from "moment";

const PaymentStatus = () => {
  const [status, setStatus] = useState(true);
  const [payment, setPayment] = useState();
  console.log('payment: ', payment);
  const [clinicDetails, setclinicDetails] = useState({});
  const {id}= useParams();
  const navigate=useNavigate()
  console.log('id: ', id);
  
  async function getStatus()
  {
    const {data}= await getAppointmentPaymentStatus(id)
    console.log('data: ', data);
    if(data){
    setclinicDetails(data)
    console.log("Hello",JSON.parse(data?.paymentStatus));
    setStatus(JSON.parse(data?.paymentStatus).code==="PAYMENT_SUCCESS"?true:false)
    setPayment(JSON.parse(data?.paymentStatus))
    const val=data;
    if(JSON.parse(data?.paymentStatus).code==="PAYMENT_SUCCESS"){
      const {data}= await setPaymentStatus(val.appointmentId, "Completed")
      console.log('data: ', data);
    }}
  }

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const formatRupeesWithCommas = (number) => {
    if (typeof number === "undefined" || number === null) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  React.useEffect(()=>{
    getStatus()
  },[])


  async function retryPayment(){
   
    try{
      const {data}= await retryAppointmentChargesPayment(payment?.data?.merchantTransactionId)
      console.log('data: ', data);
      if(data){
        window.open(data?.data?.instrumentResponse?.redirectInfo?.url,"_self"
          )
      }
    }catch(e){
      console.log("error",e.message);
    }
  }

  return (
    <div>
      <Box>
        <Card>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6.5} sm={12}>
              <Card sx={{ padding: "3%!important" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box>
                    {status ? (
                      <VerifiedIcon sx={{ color: "green", fontSize: "40px" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red", fontSize: "40px" }} />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", fontSize: "17px" }}
                    >
                      {status ? "Payment Confirmed" : "Payment Failed"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#898989",
                        fontWeight: 600,
                      }}
                    >
                      {status
                        ? "Your appointment details has been sent to: yourmailid@domain.com"
                        : "Your appointment details are here"}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card sx={{ padding: "3%!important", mt: "20px" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: status ? "#2d256c" : "#898989",
                      }}
                    >
                      Customer Name
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: status ? "#2d256c" : "#898989",
                      }}
                    >
                      Appointment ID
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: status ? "#2d256c" : "#898989",
                      }}
                    >
                      Appintment Date
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: status ? "#2d256c" : "#898989",
                      }}
                    >
                      Speciality
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                      {clinicDetails?.customerName}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      {clinicDetails?.appointmentId}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      {formattedDate(clinicDetails?.appointmentDate)}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      {clinicDetails?.speciality}
                    </Typography>
                  </Grid>
                </Grid>

                <Box mt="10px">
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: status ? "#2d256c" : "#898989",
                    }}
                  >
                    Treatments Done
                  </Typography>
                  <ol
                    style={{
                      "list-style-type": "decimal",
                      color: "#898989",
                      marginTop: "10px",
                    }}
                  >
                    {
                      clinicDetails?.appointmentTreatmentCharges?.filter(item => item.nameOfTreatment !== "").map((treatments,id)=>{
                        return(
                      <li>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#898989",
                        }}
                      >
                        {treatments.nameOfTreatment}
                      </Typography>
                    </li>
                        )
                      })
                    }
                    
                    
                  </ol>
                </Box>
              </Card>
            </Grid>

            {/* --------------------------------Right side-------------------------- */}
            <Grid item xs={12} md={5.5} sm={12}>
              <Card sx={{ padding: "2%!important" }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                >
                  Payment Details
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                  mt="10px"
                  sx={{ overflow: 'hidden' }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#4f4f4f",
                      }}
                    >
                      Reference ID
                    </Typography>
                    {/* <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: "#4f4f4f",
                      }}
                    >
                      Date of Payment
                    </Typography> */}
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: "#4f4f4f",
                      }}
                    >
                      Payment Mode
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        color: "#4f4f4f",
                      }}
                    >
                      Payment Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {payment?.data?.merchantTransactionId}
                    </Typography>
                    {/* <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      12 January,2023
                    </Typography> */}
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                     {payment?.data?.paymentInstrument?.type}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "10px",
                      }}
                    >
                      {status ? "Successful" : "Failed"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: status ? "#2d256c" : "#4f4f4f",
                      }}
                    >
                      Consultation Charges
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "5px",
                        color: status ? "#2d256c" : "#4f4f4f",
                      }}
                    >
                      Treatment Charges
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                     ₹ {formatRupeesWithCommas(clinicDetails?.consultationCharges)}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "5px",
                      }}
                    >
                      ₹ {formatRupeesWithCommas(clinicDetails?.appointmentTreatmentCharges?.filter(item=>item.charges>0).map((item) => item.charges).reduce((acc, curr) => parseInt(acc) + parseInt(curr)))}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: status ? "#00ae49" : "#4f4f4f",
                      }}
                    >
                      Plan Discount
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "5px",
                        color: status ? "#00ae49" : "#4f4f4f",
                      }}
                    >
                      Coupon Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                      }}
                    >
                      ₹ {formatRupeesWithCommas(clinicDetails?.planDiscount)}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#898989",
                        mt: "5px",
                      }}
                    >
                      ₹ {clinicDetails?.couponDiscount?formatRupeesWithCommas(clinicDetails?.couponDiscount):"0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  mt="10px"
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: status ? "#d83f87" : "#4f4f4f!important",
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: status ? "#4f4f4f" : "#898989",
                      }}
                    >
                      ₹ {formatRupeesWithCommas(clinicDetails?.totalPayableAfterDiscount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {status===false&&<Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
            <Button
              sx={{
                backgroundColor: "#3E2732",
                textAlign: "left",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "12px",
                padding: "8px 15px",

                borderRadius: "3px",

                "&:hover": { backgroundColor: "#3E2732!important" },
              }}
              size="small"
              onClick={()=> retryPayment()}
            >
              Retry
            </Button>
          </Box>}
          
        </Card>
      </Box>
    </div>
  );
};

export default PaymentStatus;
