import React, { useEffect, useState } from 'react'
import { getPlanDetailsForCustomer } from "../../services/PlansAndProcedure";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const columns = [
    { id: "appointmentid", label: "Appointment Id", minWidth: 80 },
    {
      id: "plandiscount",
      label: "Plan Discount",
      minWidth: 80,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "clinicname",
      label: "Clinic Name",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "speciality",
      label: "Speciality",
      minWidth: 80,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "customername",
      label: "Customer Name",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "totalbillamount",
      label: "Total Bill Amount",
      minWidth: 80,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "planusedon",
      label: "Plan Used On",
      minWidth: 120,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "customernumber",
        label: "Customer Phone Number",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
  ];
const PlanHistory = () => {
    const [page, setPage] = useState(0);
    const [plansHistory,setPlansHistory]=useState([])
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const {planname}=useParams();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(()=>{
      const getPlans=async()=>{
        try {
          let res=await getPlanDetailsForCustomer(planname)
          let data=res.data
          console.log("plans data",data)
          setPlansHistory(data)
        } catch (error) {
          console.log(error.message)
        }
      }
      getPlans()
    },[])

  return (
    <div>
        <Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontSize="17px" variant="h5">Plan History</Typography>
            <Typography variant="h5" fontSize="17px" >Plan Name: {planname}</Typography>
          </Stack>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            mt: "10px",
            backgroundColor: "#f7f7fc",
          }}
          className="scrollbar"
        >
          <TableContainer >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#2D256C",
                        fontWeight: 600,
                        color: "#FFFFFF",
                        fontSize:"!4px"
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plansHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, id) => {
                    return (
                      <TableRow
                        sx={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                        key={item.id}
                        tabIndex={-1}
                      >
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.appointmentId}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.planDiscount}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.clinicName}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.speciality}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.customerName}</TableCell>
                        <TableCell align="left" sx={{color:"#444444",fontSize:"12px"}}>{item.totalBillAmount}</TableCell>
                        <TableCell align="center" sx={{color:"#444444",fontSize:"12px"}} >{item.planUsedOn}</TableCell>
                        <TableCell align="center" sx={{color:"#444444",fontSize:"12px"}} >{item.customerPhoneNumber}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={plansHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{backgroundColor:"#FFFFFF"}}
          />
        </Paper>
        </Box>
    </div>
  )
}

export default PlanHistory