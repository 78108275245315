import { Box, Modal, TextField, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TuneIcon from '@mui/icons-material/Tune';
import "../../styles/NewApoointment.scss";
import React, { useState } from 'react'
import { getClinicByPincode } from '../../services/customerOnboarding';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:2,
  boxShadow: 24,
  p: 4,
};
const AppointmentsSearchbar = ({entireData,setClinicsSorted,setCurrentItems}) => {
  const [searchInput,setSearchInput]=useState("");
  const [pincode,setPincode]=useState("")
  const [pincodeModalopen, setPincodeModalOpen] = useState(false);
  const handleOpen = () => {
    setPincodeModalOpen(true)
  };
  const handleClose = () => {
    setPincodeModalOpen(false)
  };

  // console.log("all data in searchbar component....",entireData);

  const handleSubmit=async(e)=>{
    e.preventDefault();
    console.log(e)
    let filteredItems1=[];
    if(pincode!==""){
      const response= await getClinicByPincode(pincode);
      filteredItems1=response.data;
    }
    // console.log("pincode data by submit in search",filteredItems1);

    let filteredItems2=await entireData.filter((data)=>{
      if(data.clinicName.toLowerCase().includes(searchInput.toLowerCase()) || data.clinicType.toLowerCase().includes(searchInput.toLowerCase())){
        return data
      }
    })

    let filteredItems=[...filteredItems1,...filteredItems2]
    const uniqueIds = new Set(filteredItems.map(item => item.id));

     // Filter out repeated elements based on the unique IDs
  const uniqueItems = Array.from(uniqueIds).map(id =>
    filteredItems.find(item => item.id === id)
  );

  console.log("uniquearray items", uniqueItems)
    // console.log("filtered items..",filteredItems)
    setClinicsSorted(uniqueItems.slice(0,2))
    setCurrentItems(uniqueItems)
  }

  const handleMobileSubmit=async(e)=>{
    e.preventDefault()
    // let filteredItems1=[];
    // if(pincode!==""){
    //   const response= await getClinicByPincode(pincode);
    //   filteredItems1=response.data;
    // }
    let filteredItems2=await entireData.filter((data)=>{
      if(data.clinicName.toLowerCase().includes(searchInput.toLowerCase()) || data.clinicType.toLowerCase().includes(searchInput.toLowerCase())){
        return data
      }
    })

    setClinicsSorted(filteredItems2.slice(0,2));
    const uniqueIds = new Set(filteredItems2.map(item => item.id));
    const uniqueItems = Array.from(uniqueIds).map(id =>
        filteredItems2.find(item => item.id === id)
      );
      setCurrentItems(uniqueItems)
      // let filteredItems=[...filteredItems1,...filteredItems2]
      // const uniqueIds = new Set(filteredItems.map(item => item.id));
      // Filter out repeated elements based on the unique IDs
      // const uniqueItems = Array.from(uniqueIds).map(id =>
      //   filteredItems.find(item => item.id === id)
      // );

      // console.log("uniquearray items", uniqueItems)
        // console.log("filtered items..",filteredItems)
      // setClinicsSorted(uniqueItems.slice(0,2))
      // setCurrentItems(uniqueItems)
  }
  const handlePincode=async(e)=>{
    e.preventDefault();
    let filteredItems1=[];
    if(pincode!==""){
      const response= await getClinicByPincode(pincode);
      filteredItems1=response.data;
      handleClose()
      setClinicsSorted(filteredItems1.slice(0,2));
      const uniqueIds = new Set(filteredItems1.map(item => item.id));
      const uniqueItems = Array.from(uniqueIds).map(id =>
          filteredItems1.find(item => item.id === id)
        );
        setCurrentItems(uniqueItems)
    }
  }
  
  return (
    <div>
        <div className="searchbarbox">
        <form onSubmit={handleSubmit} >
          <TextField size="small" placeholder="Pincode" onChange={(e)=>setPincode(e.target.value)} />
          <Box className="search">
            <SearchIcon />
            <input
              type="text"
              placeholder="Search a Clinic and A Specialization"
              value={searchInput}
              onChange={(e)=>setSearchInput(e.target.value)}
            />
          </Box>
          <button type="submit">Submit</button>
        </form>
        {/* <Box className="locationbox">
          <Box>
            <LocationOnIcon />
            <Typography fontWeight="600">Location</Typography>
          </Box>
          <Typography className="yourlocation">{locationData.city===""||"Not found" ? "Your Location":locationData.city}</Typography>
        </Box> */}
      </div>
      <div className='mobilesearchbar'>
        <Box className="locationbox">
          <Box onClick={handleOpen}>
            <LocationOnIcon />
            <Typography fontWeight="600">Your Location</Typography>
          </Box>
        </Box>
      <form onSubmit={handleMobileSubmit} >
          <Box className="search">
            <button type="submit"><SearchIcon/></button>
            <input
              type="text"
              placeholder="Search a Clinic and A Specialization"
              value={searchInput}
              onChange={(e)=>setSearchInput(e.target.value)}
            />
          </Box>
          <TuneIcon className='filtericon' />
        </form>
      </div>

      {/* ---------------------------location modal---------------------- */}
      <Modal
        open={pincodeModalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handlePincode} >
          <TextField placeholder='Pincode' size='small' onChange={(e)=>setPincode(e.target.value)}/>
          <Box className="modalbuttons">
            <button type='submit'>Submit</button>
          </Box>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default AppointmentsSearchbar