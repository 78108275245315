import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState } from "react";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import { PostAcceptOrRejectAppointment } from "../../services/clinicOnboarding";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../../styles/cseTableCard.scss";
import CseRescheduleModal from "./CseRescheduleModal";
import moment from "moment";

const CseTableCard = ({
  setAcceptReject,
  acceptRject,
  cseSearchData,
  setCseSearchData,
  handleSubmit,
  searchClicked,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const [open, setOpen] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const navigate = useNavigate();
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let AcceptAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Accept Appointment"
  )?.permission;
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  let OpenAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Open Appointment"
  )?.permission;

  let ReschedulePermission = clinicPermissions?.find(
    (item) => item.action === "Reschedule Appointment"
  )?.permission;

  const success = () => {
    toast.success("Sucessfully Accepted the Appointment", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.success("Sucessfully Cancelled the Appointment", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleClickOpen = (id) => {
    console.log("item id", id);
    setOpen(true);
    setItemid(id);
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };

  const handleClose = () => {
    setOpen(false);
    setAcceptReject(false);
  };

  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const handleAccept = async (id) => {
    const Acceptdata = {
      id: id,
      response: "Accepted",
      updatedBy: token.username,
    };
    // console.log("accept data.....",Acceptdata)
    try {
      let res = await PostAcceptOrRejectAppointment(Acceptdata);
      setAcceptReject(true);
      console.log("Accepted data post success..");
      if (res) {
        success();
        handleSubmit();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      let res = await PostAcceptOrRejectAppointment(Rejectdata);
      setAcceptReject(true);
      console.log("Rejected data post success..");
      if (res) {
        error();
        handleSubmit();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Box>
        <Card sx={{ mt: "10px" }}>
          {searchClicked && cseSearchData.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>No Results Found</Typography>
            </Box>
          ) : (
            cseSearchData.map((el, id) => {
              return (
                <Box className="csecardBox">
                  <Box className="tablecontentbox1">
                    <Typography variant="caption" className="idhead">
                      Appointment ID
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#2D2828",
                        margin: "0px",
                        fontSize: "12px",
                      }}
                    >
                      {el.appointmentId}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />

                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox2">
                    {el.appointmentStatus !== "Accepted" && (
                      <Box className="subbox1">
                        <Typography variant="h6" className="name">
                          {el.patientName}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <PhoneIcon style={{ fontSize: "12px" }} />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "12px" }}
                          >
                            {el.patientNumber == null ? (
                              <>No number</>
                            ) : (
                              el.patientNumber
                            )}
                          </Typography>
                        </Box>
                        <Box className="specialitybox">
                          <Typography
                            sx={{
                              fontSize: "10px",
                              backgroundColor: "#FFEFF9",
                              padding: "5px",
                              color: "gray",
                              width: "100%",
                            }}
                          >
                            {el.specialty}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {el.appointmentStatus === "Accepted" && (
                      <Box className="subbox1">
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.clinicName}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <LocationOnIcon />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "10px" }}
                          >
                            {el.clinicAddress}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            View Location
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            View Clinic Details
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center", width: "50%" }}>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              background: "#FFEFF9",
                              padding: "5px",
                              color: "gray",
                              borderRadius: "6px",
                            }}
                          >
                            {el.specialty}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box className="subbox2">
                      <Box className="datebox">
                        <Typography>Appointment Date</Typography>
                        <Typography variant="h6">{formattedDate(el.date)}</Typography>
                      </Box>
                      <Box className="timebox">
                        <Typography>Appointment Time</Typography>
                        <Typography variant="h6">{el.time}</Typography>
                      </Box>
                    </Box>
                    <Box className="subbox3">
                      <Typography>Remarks</Typography>
                      <Typography variant="h6">{el.remark}</Typography>
                    </Box>
                  </Box>
                  {/* <Box className="divider"></Box> */}
                  <Divider orientation="vertical" flexItem />
                  <Box className="tablecontentbox3">
                    {el.appointmentStatus === "Pending" && (
                      <Box>
                        {AcceptAppointmentPermission && (
                          <Button
                            sx={{
                              backgroundColor: "#00984F",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              borderRadius: "3px",
                              padding: "8px 15px",
                              display: "flex",
                              justifyContent: "space-around",
                              "&:hover": {
                                backgroundColor: "#00984F!important",
                              },
                            }}
                            size="small"
                            onClick={() => {
                              handleClickOpen(el.id);
                              console.log("accept", el.id);
                            }}
                          >
                            <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                            Accept
                          </Button>
                        )}
                        <br />
                        {CancelAppointmentPermission && (
                          <Button
                            sx={{
                              backgroundColor: "#3E2732",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              padding: "8px 15px",
                              display: "flex",
                              borderRadius: "3px",
                              justifyContent: "space-around",
                              "&:hover": {
                                backgroundColor: "#3E2732!important",
                              },
                            }}
                            size="small"
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            {" "}
                            <CancelPresentationIcon
                              sx={{ fontSize: "0.9rem" }}
                            />
                            Cancel
                          </Button>
                        )}
                      </Box>
                    )}

                    {el.appointmentStatus === "Rejected" && (
                      <Box>
                        <Typography fontSize="14px">
                          This Appointment was Cancelled
                        </Typography>
                      </Box>
                    )}

                    {(el.appointmentStatus === "Completed" ||
                      el.appointmentStatus === "Billing Completed") && (
                      <Box>
                        {OpenAppointmentPermission && (
                          <Button
                            sx={{
                              backgroundColor: "#D83F87",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              borderRadius: "3px",
                              padding: "8px 15px",

                              display: "flex",
                              justifyContent: "space-around",
                              "&:hover": {
                                backgroundColor: "#D83F87!important",
                              },
                            }}
                            onClick={() =>
                              navigate(
                                `/user/customerpastappointmentinformation/${el.appointmentId}`
                              )
                            }
                          >
                            <CalendarMonthIcon
                              style={{ fontSize: "0.9rem", color: "#fff" }}
                            />{" "}
                            Open
                          </Button>
                        )}
                      </Box>
                    )}

                    {el.appointmentStatus === "Accepted" && (
                      <Box>
                        {/* {ReschedulePermission && (
                        <Button
                          onClick={() => {
                            console.log("id.......", el.id);
                            handleRescheduleModelOpen(el);
                          }}
                          sx={{
                            background: "#D83F87",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "8px 15px",
                            fontSize: "12px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                            justifyContent: "center",
                            "&:hover": { backgroundColor: "#D83F87!important" },
                          }}
                        >
                          <CalendarMonthIcon
                            style={{ fontSize: "0.9rem", color: "#fff" }}
                          />{" "}
                          Reschedule
                        </Button>
                      )} */}
                        {ReschedulePermission && (
                          <CseRescheduleModal
                            handleSearchSubmit={handleSubmit}
                            modalData={el}
                          />
                        )}
                        <br />
                        {CancelAppointmentPermission && (
                          <Button
                            sx={{
                              backgroundColor: "#3E2732",
                              textAlign: "left",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              padding: "8px 15px",
                              display: "flex",
                              borderRadius: "3px",
                              justifyContent: "space-around",
                              "&:hover": {
                                backgroundColor: "#3E2732!important",
                              },
                            }}
                            size="small"
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            {" "}
                            <CancelPresentationIcon
                              sx={{ fontSize: "0.9rem" }}
                            />
                            Cancel
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })
          )}
        </Card>
      </Box>

      {/* ----------------------Dialog box for Accept--------------- */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Accept ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              console.log("elemtnt", itemId);
              handleAccept(itemId);
              handleClose();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Reject ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              handleReject(itemId);
              handleRejectDialogClose();
            }}
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CseTableCard;
