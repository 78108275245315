import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../styles/LandingPage.css";
// import image from "../assets/headimage.svg";
// import image from "../assets/landingpageHeroimage.png";
import image from "../assets/Herooo.png";
import networkmap from "../assets/Mappp1.png";
import manicon from "../assets/man.svg";
import manwithplus from "../assets/manwithplus.svg";
import homeplus from "../assets/homeplus.svg";
import homeinsurance from "../assets/home.svg";
import healthinsurance from "../assets/health-insurance.svg";
import eye from "../assets/eye.svg";
import ent from "../assets/throat.svg";
import tooth from "../assets/tooth.svg";
import stomach from "../assets/stomach.svg";
import uterus from "../assets/uterus-(1).svg";
import opdimagemobile from "../assets/Opddummyimage.png";
import easybookappointment from "../assets/bookingappointmentpromotion.png";
import solutionforopddesktop from "../assets/solution_for_opd_desktop.svg";
import solutionforopdmobile from "../assets/solution_for_opd_mobile.svg";
import healthplanimage1 from "../assets/healthplanimage1.png";
import healthplanimage2 from "../assets/healthplanimage2.png";
import healthplanimage3 from "../assets/healthplanimage3.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import GetStartedModal from "./GetStartedModal";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { toast } from "react-toastify";
// import { contact } from "../services/Contact";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import TypingEffect from "./TypingEffect";
import Footer from "./Footer";
// import Scroll from "./SmoothScroll";
import axios from "axios";
import { getActiveRetailPlanAndPricingList } from "../services/login";
import OurOpdPlansSection from "./OurOpdPlansSection";

// const schema = yup
//   .object({
//     email: yup.string().email().trim().min(3, "Please Enter Email").required(),
//   })
//   .required();

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 1 },
    items: 1,
  },
};

const healthPlanstexts = [
  "Rigorously Audited Network: Carefully curated healthcare providers ensuring quality and reliability.",
  "Tech-Enabled Appointment Booking: Seamlessly schedule appointments through our user-friendly platform.",
  "Extensive OPD Coverage: Enjoy excellent coverage and a wide array of offerings for outpatient care.",
];
const healthplanleftslideimages = [
  healthplanimage1,
  healthplanimage2,
  healthplanimage3,
];

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate("");
  // console.log("line93",planDetails)
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });
  // const onSubmit = async (data, event) => {
  //   console.log("data", data);
  //   try {
  //     //api call
  //     await contact(data);
  //     toast.success("Form Submitted Successfully", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } catch (error) {
  //     toast.error("Please Check the Server", error.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  // owlcarousel start

  //   $('.owl-carousel').owlCarousel({
  //     // dots:false,
  //     autoplay:true,
  //     autoplayTimeout:2000,
  //     autoplayHoverPause:true,
  //     loop:true,
  //     margin:10,
  //     nav:true,
  //     // stagePadding:150,
  //     responsive:{
  //         0:{
  //             items:1
  //         },
  //         320:{
  //             items:3
  //         },
  //         768:{
  //             items:4
  //         },
  //         1000:{
  //             items:6
  //         }
  //     }
  // })

  // owlcarousel end



  useEffect(() => {
    var cursor = document.querySelector(".cursor");
    var cursor2 = document.querySelector(".cursor2");
    document.addEventListener("mousemove", function (e) {
      cursor.style.cssText = cursor2.style.cssText =
        "left: " + e.clientX + "px; top: " + e.clientY + "px;";
    });

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(
        (prevSlide) => (prevSlide + 1) % healthplanleftslideimages.length
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="Homepage">
      <Navbar />
      <Box className="landingpageContent">
        {/* <section id="up"></section>
      <section id="down"></section> */}
        {/* <section id="diagonal"></section> */}
        {/* <section id="left"></section>
        <section id="right"></section> */}
        {/* <Scroll /> */}
        <Box className="headContent">
          <h1 className="mobile">
            Bringing The Most <TypingEffect /> Quality Healthcare To An OPD
            Clinic Near You
          </h1>
          <Box id="headimagebox">
            <img
              id="headimage"
              // style={{ width: "100%" }}
              src={image}
              alt="OPD-healthcare-service"
            />
          </Box>
          <Box id="headtext">
            <h1 className="desktop">
              Bringing The Most <TypingEffect /> Quality Healthcare To An OPD
              Clinic Near You
            </h1>

            <h6>
              Do you want access to affordable and quality OPD healthcare?
            </h6>

            <Box className="getStartedBox">
              <button onClick={handleOpen} className="getstarted">
                Get Started
              </button>
            </Box>
          </Box>

          {/* ------------get started modal--------------- */}
          <GetStartedModal open={open} handleClose={handleClose} />
        </Box>

        {/* --------offering box------------ */}

        <Box className="offeringsBox">
          <Box>
            <Box className="offeringsTitleAndDesc">
              <h2>Our Offerings</h2>
              <p>
                Our OPD offerings are designed to make OPD healthcare
                accessible and affordable for everyone.
              </p>
            </Box>
            <Box className="offeringCards">
              <Box className="offeringCard">
                <img src={manicon} alt="OPD-corporate-healthcare" />
                <h4>Individual/Family</h4>
                <p>
                  Affordable plans that lower your OPD costs and
                  help you find quality healthcare at the click of a button.
                </p>
              </Box>
              <Box className="offeringCard">
                <img src={homeplus} alt="Health-Intermediaries" />
                <h4>Corporates</h4>
                <p>
                  Cost-effective, pocket-friendly OPD plans to lower
                  out-of-pocket medical expenses for your employees.
                </p>
              </Box>
              <Box className="offeringCard">
                <img src={manwithplus} alt="Health-Insurers" />
                <h4>Health Insurers</h4>
                <p>
                  Build a competitive edge with customized plans that include
                  our well-managed OPD network.
                </p>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* ---------------------------Our Network-------------------- */}

        <Box className="ournetworkbox">
          <Box id="ournetworkimage">
            <img
              id="networkimage"
              src={networkmap}
              alt="OPD-Service"
            />
          </Box>
          <Box id="ournetworktext">
            <h2>Tron OPD Network</h2>
            <p>
              Our OPD network evaluated, curated and audited by our team of
              experts specifically to deliver best-in-class OPD services. 
              Our partner clinics and hospitals are carefully chosen and
              brought onboard only after they clear our audits.
            </p>
            <Link to="/ouropdnetwork">
              <p>View Network</p>
            </Link>
          </Box>
        </Box>

        {/* ----------------Specialities Covered-------------------- */}

        <Box className="specialbox">
          <Box className="specialities">
            <h2>Specialities Covered</h2>{" "}
          </Box>
          <Box className="specialitiesbox">
            <Box>
              <Box className="roundbox">
                <img src={eye} alt="eye-specialist" />
              </Box>
              <p>Ophthalmology</p>
            </Box>
            <Box>
              <Box className="roundbox">
                <img src={ent} alt="ENT-specialist" />
              </Box>
              <p>ENT</p>
            </Box>
            <Box>
              <Box className="roundbox">
                <img src={tooth} alt="Dental-specialist" />
              </Box>
              <p>Dental</p>
            </Box>
            <Box>
              <Box className="roundbox">
                <img src={stomach} alt="Gastroenterology-specialist" />
              </Box>
              <p>Gastroenterology</p>
            </Box>
            <Box>
              <Box className="roundbox">
                <img src={uterus} alt="Gynecology-specialist" />
              </Box>
              <p>Gynecology</p>
            </Box>
          </Box>
        </Box>

        

        {/* ----------------------------------Our Plans---------------------------- */}

        <Box className="ourplansbox" sx={{ mt: "140px" }}>
          <h2
            className="ourplansheading"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#faf0ca",
              fontSize: "40px",
              fontWeight: "900",
            }}
          >
            Our OPD Plans
          </h2>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#faf0ca",
              marginBottom: "30px",
            }}
          >
            Get our OPD plans - the best solution for taking care of your family's outpatient healthcare!
          </p>

          {/* <Carousel
            // className="carouselcontainer"
            swipeable={true}
            draggable={true}
            showDots={true}
            // width="90%"
            // gap="10px"
            // autoPlay="false"
            autoPlaySpeed={1000}
            keyBoardControl={true}
            containerClass="react-multi-carousel--plans-list"
            responsive={responsive}
            // itemClass="planitem"
          >
            {retailPlansData.map((el, id) => {
              return (
                <Card className="planscarouselcard">
                  <Typography variant="h5">{el.planName}</Typography>

                  <Box className="discounttreatments">
                    <Typography sx={{ lineHeight: "15px" }} variant="subtitle2">
                      Total Discount on Treatments
                    </Typography>
                    <Typography
                      className="discountper"
                      sx={{ fontWeight: "bold" }}
                    >
                      {el?.discountPercent+"%"} 
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "10px" }} className="pricedetails">
                    <Typography className="priceheading">
                      Price Per Person
                    </Typography>
                    <Typography sx={{ lineHeight: "25px" }}>
                      ₹
                      <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                       {el?.planDiscountPrice}
                      </span>
                      /mon
                    </Typography>
                  </Box>
                  <Link to="/enrollInPlan">
                    <Button
                      sx={{
                        backgroundColor: "#D83F87",
                        textAlign: "left",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        padding: "8px 30px",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-around",
                        "&:hover": {
                          backgroundColor: "#D83F87!important",
                          border: "none",
                        },
                      }}
                    >
                      Buy Now
                    </Button>
                  </Link>
                </Card>
              );
            })}
          </Carousel> */}
          <OurOpdPlansSection/>
          
        </Box>

        {/* --------------------------------Our Clients-------------------------------- */}

        {/* <Box className="ourclientsbox"> */}
          {/* <Box className="ourclientsboxtext">
            <h2>Our Clients</h2>
            <p>
              The success of our OPD plans can be seen through our portfolio of
              clients. From insurance providers to corporates, we're helping
              companies transform their healthcare offerings.
            </p>
          </Box> */}
          {/* ----------owl carousel--------- */}

          {/* <Box className="clientimagebox owl-carousel owl-theme">
              <div className="item"><img className="carouselImages" src={googleimg} alt="googleimg" /></div>
              <div className="item"><img className="carouselImages" src={facebookimg} alt="facebookimg" /></div>
              <div className="item"><img className="carouselImages" src={pininterestimg} alt="pininterestimg" /></div>
              <div className="item"><img className="carouselImages" src={webflowimg} alt="webflowimg" /></div>
              <div className="item"><img className="carouselImages" src={youtubeimg} alt="youtubeimg" /></div>
              <div className="item"><img className="carouselImages" src={twitchimg} alt="twitchimg" /></div>
              <div className="item"><img className="carouselImages" src={googleimg} alt="googleimg" /></div>
          </Box> */}
        {/* </Box> */}

        {/* ------------------------Never Pay for OPD-------------------- */}

        <Box className="neverPayForGoBox">
          <Box className="opdinsurance">
            {/* <p className="desktopparagraphtag">Your OPD Healthcare Is Here</p> */}
            <h2>Never Pay For OPD Visits Again</h2>
            <p className="desktopparagraphtag">
              Get access to our OPD network today.
            </p>
            <p className="mobileparagraphtag">
              Your OPD Healthcare Is Here. Get access to our OPD network today.
            </p>
            <Box className="opdinsurancesectionbox">
              <Box className="homeinsurancebox">
                <Box className="homeinsuranceimage">
                  <img src={homeinsurance} alt="best-health-insurance" />
                </Box>
                <Box className="insurancecontent">
                  <p>
                    Access healthcare without worrying about visiting charges,
                    ensuring you get the care you need, anytime.
                  </p>
                </Box>
              </Box>
              <Box className="healthinsurancebox">
                <Box className="healthinsuranceimage">
                  <img src={healthinsurance} alt="health-insurance-plans" />
                </Box>
                <Box className="insurancecontent">
                  <p>
                  Say goodbye to all your OPD expenses; our plans cover consultations and outpatient treatments.
                  </p>
                </Box>
              </Box>
            </Box>
            {/* <button onClick={handleOpen} className="getstarted">
              Get Started
            </button> */}
          </Box>
          {/* ----right image---- */}
          <Box className="opdinsurancerightbox">
            {/* <img src={opdimage} className="desktop" alt="rightimageopd" />
            <img src={opdimagemobile} className="mobile" alt="rightimageopd" /> */}
            <img src={opdimagemobile} alt="OPD-healthcare-service" />
          </Box>

          {/* <button className="getstarted">Get Started</button> */}
        </Box>

        {/* --------------------------Customer ui for booking Appointment info---------------------- */}

        <Box className="bookappointmentinfomainbox">
          <h2></h2>
          <Box className="easybookappointmentinfobox">
            <Box className="imagecontent">
              <img src={easybookappointment} alt="OPD-Service" />
            </Box>
            <Box className="rightcontent">
              <h2>
                OPD appointments at the click of a button
              </h2>
              <span>
                At Tron, we understand the importance of hassle-free, quality
                and affordable OPD healthcare access. Say goodbye to huge bills
                and long waiting times. You can now schedule your OPD
                appointments with just a few clicks and avail great discounts.
                We believe that accessing quality OPD healthcare should be as
                straightforward as possible, and we've made it our mission to
                simplify the journey for you.
              </span>
              <Box className="getbutton">
                <button
                onClick={handleOpen}
                >
                  Get Started
                </button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* --------------------------health plans------------------------ */}
        <Box className="healthplanswholecontentbox">
          <Box className="healthplanheadingbox">
            <Box>
              <h2>Plans that provide a well rounded OPD healthcare service.</h2>
              <p>
              We provide a trusted network, easy bookings, and comprehensive OPD coverage for you.
              </p>
            </Box>
          </Box>
          <Box className="healthplansbox">
            <Box className="healthplancontent">
              {/* <Box className="healthplanstartedbtn">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  {...register("email")}
                />
                <button type="submit">Get Started</button>
              </form>
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  paddingLeft: "10px",
                  paddingTop: "0px",
                }}
              >
                {errors.email?.message}
              </span>
            </Box> */}
              <img
                key={activeIndex}
                src={healthplanleftslideimages[activeIndex]}
                style={{ animation: "fadeInOut 3s ease-in-out" }}
                alt={`Slide ${activeIndex + 1}`}
              />
            </Box>
            <Box className="healthplancards">
              {healthPlanstexts.map((text, id) => {
                return (
                  <Box
                    key={id}
                    className={`healthplancard ${
                      id === activeIndex ? "active" : ""
                    }`}
                  >
                    <p onClick={() => handleSlideChange(id)}>{text}</p>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        {/* -------------------------Experience Insurance------------------- */}

        <Box className="experienceinsurancebox">
          <Box className="box1">
            <h2>Experience Seamless Process Of Healthcare</h2>
          </Box>
          <Box className="box2">
            <p>
              Amplify Your OPD Healthcare Policy With Access To Our Platform
            </p>
          </Box>
        </Box>

        {/*--------------------------- One solution for OPD------------------ */}

        <Box className="solutionforopdbox">
          <img
            className="solutionforopdimg1"
            src={solutionforopddesktop}
            alt="one solution for all your opd insurance needs"
          />
          <img
            className="solutionforopdimg2"
            src={solutionforopdmobile}
            alt="one solution for all your opd insurance needs"
          />
        </Box>

        {/* -------------------------Footer---------------------------- */}
      </Box>
      <Footer />
      <Box className="cursor"></Box>
      <Box className="cursor2"></Box>
    </Box>
  );
};

export default LandingPage;
