import http from "./httpServices";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.token;

const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };

export function getClinicByLatLong(lat,long){
    return http.get(config.apiUrl+"getNearestClinicForLatLong/"+lat+"/"+long,head);
}

export function getClinicByPincode(pincode){
    return http.get(config.apiUrl+"getNearestClinicForPincode/"+pincode,head);
}

export function clinicDetailsByuniqueId(id){
  return http.get(config.apiUrl+"customerClinicView/"+id,head);
}

export function getSpecialityOptions(id){
  return http.get(config.apiUrl+"getClinicSpecialities/"+id,head);
}

export function getAvailableTimeSlots(id,speciality,entiredate,dailybasis){
  return http.get(config.apiUrl+"getAvailableTimeSlots/"+speciality+"/"+entiredate+"/"+dailybasis+"/"+id,head)
}

export function getCustomerAppointmentRequestsData(username){
  return http.get(config.apiUrl+"getCustomerAppointments/"+username+"/Pending",head)
}

export function getUpcomingAppointmentRequestData(username){
  return http.get(config.apiUrl+"getCustomerUpcommingAppointmentRequests/"+username,head)
}

export function getTodayAppointmentRequestData(username){
  return http.get(config.apiUrl+"getTodayCustomerAppointments/"+username,head)
}
export function getCancelledAppointmentRequestData(username){
  return http.get(config.apiUrl+"getCustomerAppointments/"+username+"/Rejected",head)
}
export function rescheduleAppointment(formdata){
  return http.post(config.apiUrl + "rescheduleAppointment",formdata, head)
}

export function getCustomerUpcomingReqWithDate(entiredate,username){
  return http.get(config.apiUrl + "getCustomerUpcommingAppointmentRequestsOfDate/"+entiredate+"/"+ username, head)
}

export function getCustomerCancelledReqWithDate(entiredate,username){
  return http.get(config.apiUrl + "getCustomerAppointmentRequestsOfDate/Rejected/"+entiredate+"/"+ username, head)
}
export function verifyBookAppointmentOtp(otp,number){
  return http.get(config.apiUrl + "verifyBookAppointmentOtp/"+otp+"/"+number, head)
}
export function bookAppointmentOtp(number){
  return http.get(config.apiUrl + "bookAppointmentOtp/"+number, head)
}

export function bookAppointment(formdata){
  return http.post(config.apiUrl + "bookAppointment",formdata, head);
}

export function addCustomer(formdata){
  return http.post(config.apiUrl + "addCustomer",formdata, head);
}

export function getCustomerList(){
  return http.get(config.apiUrl + "getCutomerList", head)
}

export function getCustomerDetailsById(id){
  return http.get(config.apiUrl+"getCutomerDetails/"+id,head)
}

export function getUpdates(){
  return http.get(config.apiUrl+"getUpdateMessage",head)
}
export function manuallyVerifyTheClinic(data){
  return http.get(config.apiUrl+"manuallyVerifyTheClinic/"+data,head)
}

export function getCustomerPastAppointmentRequest(uniqueId){
  return http.get(config.apiUrl + "getCustomerAppointments/"+uniqueId+"/Completed", head)
}

export function getCustomerPastAppointmentWithDate(entiredate,uniqueId){
  return http.get(config.apiUrl + "getCustomerAppointmentRequestsOfDate/Completed/"+entiredate+"/"+uniqueId, head)
}

export function getPossibleClinicNames(data){
  return http.post(config.apiUrl + "getPossibleClinicNames",data, head)
}

export function getClinicDetailsByPincodeAndClinicNameInTextfield(values){
  return http.post(config.apiUrl + "searchClinicWithPincodeAndName", values,head)
}

export function getClinicListNames(){
  return http.get(config.apiUrl + "getClinicsListForDropdown", head)
}

export function getPotentialCustomer(){
  return http.get(config.apiUrl + "getPotentialCustomer", head)
}


export function getAppointmentsforCustomerSalesExecutive(payload){
  return http.post(config.apiUrl + "getAppointmentsForCustomerSalesExecutives", payload,head)
}

export function AppointmentSearchForCSE(payload){
  return http.post(config.apiUrl + "appointmentSearchForCustomerSalesExecutives", payload,head)
}

export function CheckingExistingCustomer(patientNumber){
  return http.get(config.apiUrl + "checkForExixtingCustomerNumber/"+patientNumber, head)
}

export function getPlansForCustomerDropdown(){
  return http.get(config.apiUrl + "getPlansForDropdown", head)
}

export function searchClinicWithPincodeAndCity(values){
  return http.post(config.apiUrl+"searchClinicWithPincodeAndCity",values)
}

export function getB2CCustomerDetailsForNumber(number){
  return http.get(config.apiUrl + "getB2CCustomerDetailsForNumber/"+number, head)
}

export function getPlansForProfile(uniqueId){
  return http.get(config.apiUrl + "getPlansForProfile/"+uniqueId, head)
}

export function getB2CcustomerDetailsById(id){
  return http.get(config.apiUrl + "getB2CCustomerDetailsById/"+id, head)
}

export function plansDropdownForAppointmentBooking(speciality,id){
return http.get(config.apiUrl + "plansDropdownForAppointmentBooking/"+speciality+"/"+id, head)
}
export function getCustomerMembersForPLan(plan,id){
return http.get(config.apiUrl + "getCustomerMembersForPLan/"+plan+"/"+id, head)
}
export function getCustomerAppointments(id){
return http.get(config.apiUrl + "getCustomerAppointments/"+id+"/Billing Completed", head)
}
export function setPaymentStatus(id, status){
return http.get(config.apiUrl + "setPaymentStatus/"+id+"/"+status, head)
}