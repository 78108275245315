import React from 'react';

const Loader = () => {
    return ( 
    <div style={{textAlign:"center"}}>

        <div  class="lds-facebook"><div></div><div></div><div></div></div> 
    </div>
);}
 
export default Loader;