import clinicdemoimg from "../src/assets/clinicdemopic.svg"
import kmimage from "../src/assets/km'slogo.svg"

export const data=[
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
    {
        demopic:clinicdemoimg,
        heading:"Dr. Sawai’s Dental Clinic",
        subhead:"Ultra Premium | Dental Clinic",
        kmsimg:kmimage,
        kms:"1.7 KM",
        address:"Kaulagarh Road, Kishan Nagar Chowk, Dehradun",
        speciality:"Speciality",
        buttontext:"Book An Appointment"
    },
]