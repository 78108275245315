import http from "./httpServices";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token=JSON.parse(tokens)?.token;
const id=JSON.parse(tokens)?.uniqueId;
const username=JSON.parse(tokens)?.username;
  
const head = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: `Bearer ${token}`,
    },
  };


export function createStaffDetails(data){
    return http.post(config.apiUrl+"staff", data,head);
}

export function getStaffList(){
    return http.get(config.apiUrl+"staffuniqueid/"+id, head);
}
export function getParticularStaff(val){
    return http.get(config.apiUrl+"staffid/"+val, head);
}
export function getStaffDataDateRange(from, to){
  return http.get(config.apiUrl+"staffdate/"+id+"/"+from+"/"+to, head);
}
export function getTeamLeadList(from, to){
  return http.get(config.apiUrl+"teamleads", head);
}
export function getStaffRoleList(role){
  return http.get(config.apiUrl+"stafflist/"+ role, head);
}

