import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import { toast } from "react-toastify";
import "../../styles/StartAppointment.scss";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AppointmentBillingFirst = ({
  steps,
  setSteps,
  selectedProceduresData,
  setSelectedProceduresData,
  consultationCharges,
  setConsultationCharges,
  setOtherCharges,
  otherCharges,
}) => {
  const error = () => {
    toast.error("Please Fill All The Charges", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Typography
        variant="h5"
        fontWeight="bold"
        className="appointmentbillheading"
        sx={{ color: "#D83F87" }}
      >
        Appointment Billing
      </Typography>
      <br />
      <Card sx={{ padding: 4,boxShadow:"none", border:"0.5px solid #D9D9D9" }}>
        <div style={{ border: "2px solid #D9D9D9" }}>
          <div style={{ padding: "10px" }}>
            <Grid sx={{ alignItems: "center" }} container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  className="subtexts"
                  variant="body1"
                  fontWeight="bold"
                >
                  Consultation Charges
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  size="small"
                  fullWidth
                  placeholder="Consultation Charges"
                  variant="outlined"
                  type="number"
                  onChange={(e) => setConsultationCharges(e.target.value)}
                  value={consultationCharges}
                  //   error={
                  //     formik.touched[item.value] &&
                  //     Boolean(formik.errors[item.value])
                  //   }
                  //   helperText={
                  //     formik.touched[item.value] && formik.errors[item.value]
                  //   }
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div style={{ padding: "10px" }}>
            <Typography variant="body1" className="subtexts" fontWeight="bold">
              Treatment Charges
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ padding: 4, textAlign: "center", alignItems: "center" }}
            >
              {selectedProceduresData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className="proceduresnames">
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CssTextField
                      size="small"
                      fullWidth
                      placeholder="Charges"
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        const data = [...selectedProceduresData];
                        data[index]["charges"] = e.target.value;
                        setSelectedProceduresData(data);
                      }}
                      value={item?.charges}
                      //   error={
                      //     formik.touched[item.value] &&
                      //     Boolean(formik.errors[item.value])
                      //   }
                      //   helperText={
                      //     formik.touched[item.value] && formik.errors[item.value]
                      //   }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
          <Divider />
          <div style={{ padding: "10px" }}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <Typography variant="body1" className="subtexts" fontWeight="bold">
              Other Charges
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Tooltip title="Add More">
                <AddCircleIcon
                  onClick={() => {
                    const val = [...otherCharges];
                    val.push({ name: "", charges: 0 });
                    setOtherCharges(val);
                  }}
                />
              </Tooltip>
            </div>
              
            </div>
            <Grid
              container
              spacing={1}
              sx={{ padding: 4, textAlign: "center", alignItems: "center" }}
            >
              {otherCharges.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={0.5}>
                    {i + 1}.
                  </Grid>
                  <Grid item xs={5.5}>
                    <CssTextField
                      size="small"
                      fullWidth
                      placeholder="Procedure Name"
                      variant="outlined"
                      onChange={(e) => {
                        const val = [...otherCharges];
                        val[i].name = e.target.value;
                        setOtherCharges(val);
                      }}
                      value={item?.name}
                    />
                  </Grid>
                  <Grid item xs={5.5}>
                    {" "}
                    <CssTextField
                      size="small"
                      fullWidth
                      placeholder="Charges"
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        const val = [...otherCharges];
                        val[i].charges = e.target.value;
                        setOtherCharges(val);
                      }}
                      value={item?.charges}
                      //   error={
                      //     formik.touched[item.value] &&
                      //     Boolean(formik.errors[item.value])
                      //   }
                      //   helperText={
                      //     formik.touched[item.value] && formik.errors[item.value]
                      //   }
                    />
                  </Grid>
                  {otherCharges.length > 1 && (
                    <Grid item xs={0.5}>
                      <DeleteIcon
                        onClick={() => {
                          const val = [...otherCharges];
                          val.splice(i, 1);
                          setOtherCharges(val);
                        }}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
            
          </div>
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              mt: "10px",
              mb: "10px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#2D256C",
                textAlign: "center",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "10px",
                padding: "10px",
                mr: "10px",
                borderRadius:"3px",
                // "&:hover": { backgroundColor: "#D83F87!important" },
              }}
              size="small"
              onClick={() => {
                setSteps(steps - 1);
              }}
            >
              Back
            </Button>
            <Button
              sx={{
                backgroundColor: "#D83F87",
                textAlign: "center",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "10px",
                padding: "10px",
                borderRadius:"3px",
                "&:hover": { backgroundColor: "#D83F87!important" },
              }}
              size="small"
              onClick={() => {
                const data = selectedProceduresData.filter(
                  (item) => !item.charges
                );
                const val = otherCharges.filter(
                  (item) => item.name && !item.charges
                );
                console.log("val: ", val);
                console.log("data: ", data);
                val.length === 0 && data.length === 0 && consultationCharges
                  ? setSteps(steps + 1)
                  : error();
              }}
            >
              Proceed To Billing
            </Button>
          </Box>
        </div>
      </Card>
    </>
  );
};

export default AppointmentBillingFirst;
