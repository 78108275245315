import { createTheme } from "@mui/material";


export const theme=createTheme({
    palette:{
          background: {
           
            default: "#F7F7F7"
          },
          text:{
            primary: "#2D256C"
          },
          root: {
            "&$selected": {
              backgroundColor: "#0A8AD1",
              "&:hover": {
                backgroundColor: "#0A8AD1",
              },
            },
          },
         primary:{
             main:"#2D256C",
             light:"#2D256C"
         },
         secondary:{
             main:"#BCD338"
            },
            danger:{
                main:"#FF0000"
            },
            
         otherColor:{        //custom color
                main:"#999"
         },
         appbarColor:{
             main:"#ffff"
         },
         textColor:"#2D256C"
         
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        color:"#11142D"
      },
     
})
