import { Box, Card, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../styles/ViewRetailCustomerdetails.scss";
import { TabContext, TabPanel } from "@material-ui/lab";
import {
  getB2CCustomerDetailsForNumber,
  getPlansForProfile,
} from "../../services/customerOnboarding";
import moment from "moment";
import Loader from "../Loader";

const CustomerProfile = () => {
  const [value, setValue] = useState("");
  const [planName, setPlanName] = useState("");
  const [loader, setLoader] = useState(true);
  const [customerProfileData, setCustomerProfileData] = useState({});
  let b2cPlans = customerProfileData?.b2cPlans;
  let planMembers = b2cPlans?.find(
    (customer) => customer?.retailPlanAndPricing?.planName === planName
  );
  let b2cCustomerDetails = planMembers?.b2cCustomerDetailsRequests;
  let b2cCustomerMemberdetails =
    b2cCustomerDetails?.b2cCustomerMemberDetailsRequest;
  const token = JSON.parse(localStorage.getItem("token"));
  const number = token?.username;
  const uniqueId = token?.uniqueId;
  // console.log(id);
  // console.log("line18", planName);
  // console.log("line20", planMembers);

  // console.log("b2customermemberdetails", b2cCustomerMemberdetails);

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    // navigate(`/user/profile/${newValue}`);
    setValue(newValue);
  };

  const handlePlanName = (event, newValue) => {
    setPlanName(newValue);
  };

  const getPlansForParticularProfile = async () => {
    try {
      setLoader(true);
      const res = await getPlansForProfile(uniqueId);
      // console.log("plansline38",res.data)
      setTimeout(() => {
        if (res && res.data) {
          setCustomerProfileData(res.data);
          setLoader(false);
        } else {
          setLoader(false);
          console.log("No data found");
        }
      }, 3000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob;
  };

  

  useEffect(() => {
    getPlansForParticularProfile();
  }, []);

  useEffect(() => {
    if (b2cCustomerMemberdetails?.length > 0 && !value) {
      setValue(b2cCustomerMemberdetails[0]?.name);
    }
    if (b2cPlans?.length > 0 && !planName) {
      setPlanName(b2cPlans[0]?.retailPlanAndPricing?.planName);
    }
  }, [b2cCustomerMemberdetails, value, b2cPlans]);

  return (
    <div>
      {loader && (
        <>
          <Loader />
        </>
      )}
      {!loader && Object.keys(customerProfileData).length === 0 && (
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )}
      {!loader && Object.keys(customerProfileData).length > 0 && (
        <Box>
          <TabContext value={planName}>
            <Box>
              <Tabs onChange={handlePlanName} aria-label="lab API tabs example">
                {b2cPlans?.map((el, id) => {
                  return (
                    <Tab
                      sx={
                        planName === `${el?.retailPlanAndPricing?.planName}`
                          ? {
                              backgroundColor: "#2D256C",
                              width: "130px",
                              borderRadius: "3px 3px 3px 3px",
                              color: "white",
                              minHeight: "40px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: 600,
                            }
                          : {
                              backgroundColor: "white",
                              width: "130px",
                              border: "0.5px solid #D9D9D9",
                              borderRadius: "3px 3px 3px 3px",
                              color: "black",
                              minHeight: "40px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: 600,
                            }
                      }
                      label={el?.retailPlanAndPricing?.planName}
                      value={el?.retailPlanAndPricing?.planName}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </TabContext>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt="10px"
          >
            <Grid item xs={12} md={12} sm={12}>
              <Card className="mainpersondetails">
                <Box className="namebox">
                  <Typography variant="h5">
                    {b2cCustomerDetails?.name}
                  </Typography>
                  <Typography>{b2cCustomerDetails?.planUniqueId}</Typography>
                </Box>
                <Box className="personaldetails">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={3} md={3}>
                      <Typography className="heading">Plan Type</Typography>
                      <Typography className="content">
                        {planMembers?.retailPlanAndPricing?.planType}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography className="heading">Pincode</Typography>
                      <Typography className="content">
                        {b2cCustomerDetails?.pincode}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography className="heading">Plan Duration</Typography>
                      <Typography className="content">
                        {b2cCustomerDetails?.duration == "1"
                          ? `${b2cCustomerDetails?.duration}year`
                          : `${b2cCustomerDetails?.duration}years`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography className="heading">Enrolled Date</Typography>
                      <Typography className="content">
                        {formattedDate(
                          b2cCustomerDetails?.merchantTransactionDatetime
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    mt="5px"
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={4} md={3} sm={3}>
                      <Typography className="heading">Phone Number</Typography>
                      <Typography className="content">
                        {b2cCustomerDetails?.number}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} sm={6}>
                      <Typography className="heading">Mail Id</Typography>
                      <Typography className="content">
                        {b2cCustomerDetails?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3} sm={3}>
                      <Typography className="heading">
                        Discounts On Treatment
                      </Typography>
                      <Typography className="content">{planMembers?.retailPlanAndPricing?.discountPercent} %</Typography>
                    </Grid>
                    <Grid item xs={4} md={3} sm={3}>
                      <Typography className="heading">
                        Speciality
                      </Typography>
                      <Typography className="content">{planMembers?.retailPlanAndPricing?.specialities}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", mt: "20px" }}>
            <TabContext value={value}>
              <Box>
                <Tabs onChange={handleChange} aria-label="lab API tabs example">
                  {b2cCustomerMemberdetails?.map((el, id) => {
                    return (
                      <Tab
                        sx={
                          value === `${el.name}`
                            ? {
                                backgroundColor: "#2D256C",
                                width: "130px",
                                borderRadius: "3px 3px 3px 3px",
                                color: "white",
                                minHeight: "40px",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                fontWeight: 600,
                              }
                            : {
                                backgroundColor: "white",
                                width: "130px",
                                border: "0.5px solid #D9D9D9",
                                borderRadius: "3px 3px 3px 3px",
                                color: "black",
                                minHeight: "40px",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                fontWeight: 600,
                              }
                        }
                        label={el.name}
                        value={el.name}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              {b2cCustomerMemberdetails?.map((el, id) => {
                return (
                  <TabPanel value={el.name}>
                    <MemberDetails
                      tabvalue={value}
                      memberData={b2cCustomerMemberdetails}
                      retailPlanandPricing={planMembers?.retailPlanAndPricing}
                    />
                  </TabPanel>
                );
              })}
            </TabContext>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CustomerProfile;

const MemberDetails = ({ tabvalue, memberData, retailPlanandPricing }) => {
  // console.log("line230",tabvalue)
  // console.log("memberdata",memberData);
  const selectedMember = memberData.find((member) => member.name === tabvalue);
  console.log("selectedmember", selectedMember);
  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob;
  };
  const formatRupeesWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      <Card className="membercarddetails">
        <Box className="detailsbox">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography className="heading">Date of Birth</Typography>
              <Typography className="content">
                {formattedDate(selectedMember.dob)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="heading">Relation</Typography>
              <Typography className="content">
                {selectedMember.relation}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="detailsbox">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4} md={4}>
              <Typography className="heading">Total Coverage Amount</Typography>
              <Typography className="content">
                Rs. {formatRupeesWithCommas(retailPlanandPricing?.treatmentCover)}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                className="heading"
                sx={{ color: "#444444!important" }}
              >
                Utilised Coverage
              </Typography>
              <Typography className="content">
                Rs.{" "}
                {formatRupeesWithCommas(Math.floor(
                  retailPlanandPricing?.treatmentCover -
                    selectedMember?.treatmentCover
                ))}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                className="heading"
                sx={{ color: "#00984F!important" }}
              >
                Remaining Coverage Amount
              </Typography>
              <Typography className="content">
                Rs. {formatRupeesWithCommas(selectedMember?.treatmentCover)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="healthinfobox">
          <Typography variant="h4">Health Info Questions</Typography>
          <Grid
            container
            spacing={1}
            columnSpacing={{ xs: 5, sm: 1, md: 1 }}
            mt="10px"
          >
            {selectedMember?.b2cCustomerQuestionDetails?.map((el, id) => {
              return (
                <>
                  <Grid item md={7} xs={7}>
                    {el.question}
                  </Grid>
                  <Grid item md={5} xs={5}>
                    {el.response}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Card>
    </div>
  );
};
