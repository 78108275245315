import React, { createContext, useEffect, useState } from 'react'
import { getRolePermissions } from '../../services/rolesAndPermissions';

const Permissions=createContext();

const PermissionsContext = ({children}) => {
    const tokens = JSON.parse(localStorage.getItem("token"));
    let role = tokens?.role;
    let designation=tokens?.designation
    if(role==="Staff"){
      role=designation
    }
    const [permissionsData,setPermissionsData]=useState([]);
    const [loading,setLoading]=useState(false);
//   let plansPermissions=data.filter(item =>item.module==="plans");
//   let billingPermissions=data.filter(item =>item.module==="billing");
//   let reportPermissions=data.filter(item=>item.module==="report");
//   let procedurePermissions=data.filter(item=>item.module==="procedure");
//   let staffPermissions=data.filter(item=>item.module==="staff");
//   let appointmentPermissions=data.filter(item=>item.module==="appointment");
//   let couponsPermissions=data.filter(item=>item.module==="coupons");
//   let customerPermissions=data.filter(item=>item.module==="customer");
//   let clinicPermissions=data.filter(item=>item.module==="clinic");
    const getPermissions=async()=>{
        try {
          if(role){
            const res=await getRolePermissions(role);
            const data=res.data;
            // console.log("rolespermissions",data);
          //let permissionsarraydata=res.data?.permissions
            setPermissionsData(data?.permissions)
          }
        } catch (error) {
          console.log(error.message)
        }
      }

      useEffect(()=>{
        
          getPermissions()
        
      },[])
  return (
    <Permissions.Provider value={{permissionsData,loading,setLoading}}>{children}</Permissions.Provider>
  )
}

export {PermissionsContext,Permissions}