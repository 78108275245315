import { InputBase, styled, TextField,Autocomplete, Button } from "@mui/material";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#A9A9A9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A9A9A9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#A9A9A9",
        
      },
      "&:hover fieldset": {
        borderColor: "#A9A9A9",
        
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A9A9A9",
      },
    },
  });

  const FormAutoComplete = styled(Autocomplete)({
    "& label.Mui-focused": {
      color: "#EEECFA",
    },
    '& .MuiInputBase-input': {
      border: '0px solid #EEECFA',
      borderRadius:"8px"
    }
    ,
    "& .MuiInput-underline:after": {
      borderBottomColor: "#EEECFA",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "#EEECFA",
  
      "& fieldset": {
        borderColor: "#EEECFA",
        
      },
      "&:hover fieldset": {
        borderColor: "#EEECFA",
        
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EEECFA",
      },
    },
  });


  export {CssTextField};
  export {FormAutoComplete};