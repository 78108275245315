import React, { useState, useEffect } from "react";
import syringe from "../assets/syringe.svg";

const TypingEffect = () => {
  const [text, setText] = useState("");
  const originalText = "Affordable";
  const typingSpeed = 150; // Adjust typing speed as needed
  const pauseDuration = 1000; // Adjust pause duration between cycles

  useEffect(() => {
    const typeAndErase = () => {
      let currentIndex = 0;
      const typingInterval = setInterval(() => {
        if (currentIndex <= originalText.length) {
          setText(originalText.slice(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(typingInterval);

          // Simulate backspace
          const backspaceInterval = setInterval(() => {
            if (currentIndex >= 0) {
              setText(originalText.slice(0, currentIndex));
              currentIndex--;
            } else {
              clearInterval(backspaceInterval);

              // Pause before starting a new cycle
              setTimeout(() => {
                typeAndErase();
              }, pauseDuration);
            }
          }, typingSpeed);
        }
      }, typingSpeed);
    };

    typeAndErase();

    return () => {
      // Cleanup code, if needed
    };
  }, []);

  return (
    <div>
      <span className="typing-text" >{text} <img className="blinking-image" src={syringe} alt="syringe" /></span>
    </div>
  );
};

export default TypingEffect;