import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';

const ParticularCoupon = () => {
    return ( <>
    <Card sx={{px:"20px", py:"20px"}}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
    <Box sx={{border:"2px solid #F5F5F5",
    borderRadius: "8px 8px 0px 0px",
}}
    
              
                
                >
<div style={{
                  backgroundColor: "#2D256C",
                  borderRadius: "8px 8px 0px 0px",
                  boxShadow: "none",
                  color:"#fff",
                  fontSize:15,
                  padding:"1.5%",
                  fontWeight:"bold"
                
                }}>Coupon Code</div>
                <br/>
                <div style={{padding:5}}>
                    
                <Typography variant="caption"> Coupon Description</Typography>
                <br/>
                <br/>
                <Typography variant="subtitle2" sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}> Coupon Description</Typography>

                          <br/>
                          <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <Box sx={{ textAlign: "center", width: "90%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                }}
                              >
                               First Speciality
                              </Typography>
                            </Box>
                            </Grid>
                          <Grid item xs={3}>
                            <Box sx={{ textAlign: "center", width: "90%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                }}
                              >
                               Second Speciality
                              </Typography>
                            </Box>
                            </Grid>
                          </Grid>

                          <br/>

                          <Grid container spacing={2} 
                        //   sx={{ textAlign: "center"}}
                          >
                            
                            {[
                                {title:"No Of Usage", value:"2"},
                                {title:"MOQ", value:"8000"},
                                {title:"Start Date", value:"23 June, 2023"},
                                {title:"Start Time", value:"11:15 AM"},
                                {title:"End Date", value:"23 July, 2023"},
                                {title:"End Time", value:"12:15 AM"},
                                ].map((item,i)=><Grid key={i} item xs=
                                {6}>
                                     <Typography variant="subtitle2" sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}> {item.title}</Typography>
                            <Typography variant='h6' sx={{
                           
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}>{item.value}</Typography>
                                </Grid>)}
                          </Grid>

                          </div>
                </Box>

            </Grid>
            <Grid item xs={4}>
            <Box sx={{border:"2px solid #F5F5F5",
    borderRadius: "8px 8px 0px 0px",
}}
    
              
                
                >
                    <div style={{
                  backgroundColor: "#D9D9D9",
                  color: "#2D256C",
                  borderRadius: "8px 8px 0px 0px",
                  boxShadow: "none",
                 
                  fontSize:15,
                  padding:"1.5%",
                  fontWeight:"bold"
                
                }}>Procedures</div>
                <br/>
                <div style={{padding:5}}>
                    
                    <ol>
                        <li> <Typography variant='subtitle2'>Humphrey Field Analyzer (HFA)</Typography></li>
                        <li> <Typography variant='subtitle2'>Optical Coherence Tomography (OCT)</Typography></li>
                        <li> <Typography variant='subtitle2'>Retina Dilated Examination Tonometery</Typography></li>
                    </ol>

</div></Box>
            </Grid>

                </Grid>
    </Card>
    </> );
}
 
export default ParticularCoupon;