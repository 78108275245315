import { Box, Typography } from '@mui/material'
import cliniclabimg from "../../assets/cliniclabimg.svg"
import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import "../../styles/clinicView.scss"

const ClinicView = () => {
  return (
    <Box className="clinicviewbox">
        <Box className="clinicviewcontentbox">
            <Box className="cliniccard" sx={{display:"flex",justifyContent:"space-between"}} >
                <Box className="leftside" >
                <Typography variant='h5' >Dr. Sawai Dental Clinic</Typography>
                <Box className="addresslocationbox" >
                    <LocationOnIcon/>
                    <Typography>A-70, Kaulagarh Rd, Rajender Nagar, Dehradun, Uttarakhand 248001</Typography>
                </Box>
                <Typography>View Location</Typography>
                <Box className="detailsbox" >
                    <Typography>Shailesh Baluni</Typography>
                    <Box>
                        <PhoneIcon/>
                        <Typography>790 036 9391 | 980 087 9779</Typography>
                    </Box>
                    <Box>
                        <MailIcon/>
                        <Typography>shaileshbaluni@gmail.com</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography>Financial Details</Typography>
                    <Box className="financialdetailscontent" sx={{display:'flex',gap:"20px"}} >
                        <Box>
                            <Box>
                            <Typography>Account Holder Name</Typography>
                            <Typography>Shailesh Baluni</Typography>
                            </Box>
                            <Box>
                                <Typography>Account No.</Typography>
                                <Typography>28930020040056</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                            <Typography>Pan card No.</Typography>
                            <Typography>CTAYB9988F</Typography>
                            </Box>
                            <Box>
                                <Typography>IFSC Code</Typography>
                                <Typography>HUFC004056</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                </Box>
                <Box className="rightside" >
                    <img src={cliniclabimg} alt="cliniclabimg" />
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default ClinicView