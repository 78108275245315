import React, { useEffect, useState } from "react";
import { getActiveRetailPlanAndPricingList } from "../services/login";
import { TabContext, TabPanel } from "@material-ui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const OurOpdPlansSection = () => {
  const [retailPlansData, setRetailPlansData] = useState([]);
  const [value, setValue] = useState("family");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRetailPlanPricingList = async () => {
    try {
      const res = await getActiveRetailPlanAndPricingList();
      const data = res.data.sort(
        (a, b) => a.planDiscountPrice - b.planDiscountPrice
      );
      // console.log("line177",data)
      setRetailPlansData(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getRetailPlanPricingList();
  }, []);

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            width: { xs: 220, sm: 350, md: 380 },
            borderRadius: "50px",
            margin: "auto",
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            onChange={handleChange}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              minHeight: "55px",
            }}
            centered
          >
          <Tab
              sx={
                value === "family"
                  ? {
                      backgroundColor: "#d83f87",
                      width: "48%",
                      borderRadius: "60px",
                      textTransform: "capitalize",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: 600,
                    }
                  : {
                      backgroundColor: "white",
                      width: "48%",
                      // border: "0.5px solid #D9D9D9",
                      borderRadius: "60px",
                      textTransform: "capitalize",
                      color: "#d83f87",
                      fontSize: "16px",
                      fontWeight: 600,
                    }
              }
              label="Family"
              value="family"
            />
            <Tab
              sx={
                value === "individual"
                  ? {
                      backgroundColor: "#d83f87",
                      borderRadius: "60px",
                      color: "white",
                      textTransform: "capitalize",
                      fontSize: "16px!important",
                      width: "48%!important",
                      fontWeight: 600,
                    }
                  : {
                      backgroundColor: "white",
                      textTransform: "capitalize",
                      // border: "0.5px solid #D9D9D9",
                      borderRadius: "60px",
                      color: "#d83f87",
                      fontSize: "16px!important",
                      width: "48%!important",
                      fontWeight: 600,
                    }
              }
              label="Individual"
              value="individual"
            />
            
          </Tabs>
        </Box>
        <TabPanel value="individual">
          <PlanCard
            planData={retailPlansData?.filter(
              (plan) => plan.planType === "Individual"
            )}
          />
        </TabPanel>
        <TabPanel value="family">
          <PlanCard
            planData={retailPlansData?.filter(
              (plan) => plan.planType === "Family"
            )}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default OurOpdPlansSection;

export const PlanCard = ({ planData }) => {
  const [viewOpen, setViewOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const formatRupeesWithCommas = (number) => {
    // if (typeof number === "undefined" || number === null) {
    //   return "";
    // }
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const handleViewDetailsOpen = (plandetails) => {
    console.log("plandetails", plandetails);
    setViewOpen(true);
    setPlanDetails(plandetails);
  };
  console.log("planData", planData);

  const handleViewDetailsClose = () => setViewOpen(false);
  return (
    <div>
      <Box>
        <Box className="plansmainbox">
          {planData?.map((el, id) => {
            return (
              <div className="planscard">
                {el.recommended && (
                  <Box className="recommendedbox">Recommended</Box>
                )}
                <Box
                  className="plansCardBody"
                  sx={{
                    // backgroundColor: el.recommended ? "#ffd4e8" : "#ffffff",
                    backgroundColor:"#ffffff"
                  }}
                >
                  <Typography variant="h5">{el.planName}</Typography>
                  <Box className="discounttreatments">
                    <Typography
                      className="discountper"
                      sx={{ fontWeight: "bold" }}
                    >
                      {el.discountPercent + "%"}
                    </Typography>
                    <Typography sx={{ lineHeight: "15px" }} variant="subtitle2">
                      Discount on Treatments
                    </Typography>
                    {/* ---------------------------Dialogbutton---------------------- */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                        mt: "10px",
                        color: "#D83F87",
                      }}
                      onClick={() => handleViewDetailsOpen(el)}
                    >
                      View Details {">>"}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "30px" }} className="pricedetails">
                    {/* <Typography className="priceheading">
                          Price Per Person
                        </Typography> */}
                    <Typography sx={{ lineHeight: "25px" }}>
                      ₹
                      <span style={{ fontSize: "40px", fontWeight: "bold" }}>
                        {el.planDiscountPrice}
                      </span>
                      /mon
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Link to="/enrollInPlan">
                      <Button
                        sx={{
                          // backgroundColor: "#D83F87",
                          border: "0.5px solid #D83F87",
                          textAlign: "left",
                          color: "#D83F87",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          padding: "8px 15px",
                          fontWeight: 600,
                          display: "flex",
                          justifyContent: "space-around",
                          "&:hover": {
                            backgroundColor: "#D83F87!important",
                            border: "none",
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        Get Started
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>
            );
          })}

          {/* {retailPlansData?.length>0 &&<Box className="viewallitems" onClick={()=>navigate("/enrollInPlan")}>
                <Typography variant="h5">Buy Now</Typography>
                <ArrowForwardIcon sx={{color:"#FFFFFF",fontSize:"25px"}} />
              </Box>} */}
        </Box>
      </Box>

      {/* ---------------------------------Dialogbox------------------------------ */}

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={viewOpen}
        onClose={handleViewDetailsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="plantitle">{planDetails?.planName}</DialogTitle>
        <DialogContent sx={{ mt: "20px" }}>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                PlanType
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#D83F87",
                  fontWeight: "bold",
                }}
              >
                {planDetails?.planType}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Discount On Treatments
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#D83F87",
                  fontWeight: "bold",
                }}
              >
                {planDetails?.discountPercent} %
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Treatment Cover
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#D83F87",
                  fontWeight: "bold",
                }}
              >
                {formatRupeesWithCommas(planDetails?.treatmentCover)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Min. Number of Members
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#D83F87",
                  fontWeight: "bold",
                }}
              >
                {planDetails?.minimumSize}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Min. Plan Tenure
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={5} md={5}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#D83F87",
                  fontWeight: "bold",
                }}
              >
                {planDetails?.plantenure}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt="10px">*Terms And Conditions Apply</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
