import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/Navbar.css";
import TronLogo from "../assets/TronLogoWhitePurple.svg";
import tronlogosmallscreen from "../assets/tronlogo-smallscreen.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate("");

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <nav className="navbar">
        <Box className="container">
          <Box
            onClick={() => navigate("/")}
            sx={{ cursor: "pointer" }}
            className="logo"
          >
            <img src={TronLogo} alt="logo" />
          </Box>
          <Box onClick={() => navigate("/")} className="smallscreenlogo">
            <img src={tronlogosmallscreen} alt="logo" />
          </Box>
          <Box className="menu-icon" onClick={handleShowNavbar}>
            <MenuIcon />
          </Box>
          <Box className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>
              {/* <li>
              <NavLink to="/">Home</NavLink>
            </li> */}
              <li>
                <NavLink to="/ouropdnetwork">OPD Network</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li>
                {/* <NavLink to="https://tron.health/blog/">Blog</NavLink> */}
                <a
                  href="https://tron.health/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
              {/* <li>
              <NavLink to="/about">About</NavLink>
            </li> */}
            </ul>
            <NavLink to="/login">
              <button className="loginbtn">Login</button>
            </NavLink>
          </Box>
        </Box>
      </nav>
    </Box>
  );
};

export default Navbar;
