import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
  } from "@mui/material";
  import React, { useContext, useEffect, useState } from "react";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import {
    DatePicker,
    MobileDatePicker,
    MobileTimePicker,
  } from "@mui/x-date-pickers";
  import * as yup from "yup";
  import { yupResolver } from "@hookform/resolvers/yup";
  import { useForm } from "react-hook-form";
  import {
    addPlanAndPricing,
    getClinicProcedure,
    getClinicSpecialities,
  } from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';
  
  // const phoneRegExp = /^[6-9]\d{9}$/;

  
  const AddPlan = () => {
    const [consultaion, setConsultaion] = useState(false);
    const [restofBill, setRestofBill] = useState(false);
    const [totalBill, setTotalBill] = useState(false);
    const [isSubmitting,setIsSubmitting]=useState(false);
    const [alignment, setAlignment] = useState("inpercentage");
    const [restofBillToggle, setRestofBillToggle] = useState("inpercentage");
    const [totalbillToggle, setTotalBillToggle] = useState("inpercentage");
    const [specialities, setSpecialities] = useState([]);
    const [specialityOptions, setSpecialityOptions] = useState("");
    const [allSpecialities,setAllSpecialities]=useState([]);
    const token=JSON.parse(localStorage.getItem("token"));
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [fileError, setFileError] = useState('');
    const [selectedFile,setSelectedFile]=useState(null);
    const [excelData,setExcelData]=useState([])
    const uniqueId=token?.uniqueId;
    const username=token?.username;
    const navigate=useNavigate("")

    const handleCheckboxChange = (checkboxValue) => {
      console.log("checkboxvalue",checkboxValue)
      if (checkboxValue === "checkbox1" || checkboxValue === "checkbox2") {
        setSelectedCheckbox((prevSelectedCheckboxes) => {
          if (prevSelectedCheckboxes.includes("checkbox3")) {
            return [checkboxValue];
          } else {
            return prevSelectedCheckboxes.includes(checkboxValue)
              ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
              : [...prevSelectedCheckboxes, checkboxValue];
          }
        });
      } else if (checkboxValue === "checkbox3") {
        setSelectedCheckbox((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes.includes(checkboxValue)
            ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
            : [checkboxValue]
        );
      }
    };
    


    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const allowedExtensions = ['.xlsx', '.xls'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      if (!allowedExtensions.includes(fileExtension)) {
        setFileError('Invalid file format. Please upload an Excel file (.xlsx, .xls).');
        return;
      }
      const fileReader = new FileReader();
  
      fileReader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          // console.log("excelsheetdata",jsonData)
          const requiredFields = ['First Name', 'Last Name', "Email" ,'Mobile Number', 'D.O.B'];
          const missingFields = requiredFields.filter((field) => !headers.includes(field));
          
          const updatedJsonData = jsonData.map(obj => {
            const updatedObj = {};
            updatedObj.firstName = obj["First Name"];
            updatedObj.lastName = obj["Last Name"];
            updatedObj.email = obj.Email;
            updatedObj.mobileNumber = obj["Mobile Number"].toString();
            updatedObj.dob = obj["D.O.B"];
            return updatedObj;
          });
          

          // console.log(updatedJsonData);

          if (missingFields.length > 0) {
            setFileError(`Missing fields in Excel: ${missingFields.join(', ')}`);
          } else {
            setFileError('');
            const convertExcelDateToNormal = (excelDate) => {
              const epochDiff = 25569; // Number of days between Excel epoch (01/01/1900) and Unix epoch (01/01/1970)
              const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
              const date = new Date((excelDate - epochDiff) * millisecondsPerDay);
              const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
              const day = date.getDate();
              const year = date.getFullYear();
              return `${year}-${month>9?month:"0"+month}-${day>9?day:"0"+day}`;
            };
            const formattedDateofbirth=updatedJsonData.map((row)=>{
              const excelDate = row.dob;
              const normalDate = convertExcelDateToNormal(excelDate);
              return { ...row,dob: normalDate };
            })
            console.log(formattedDateofbirth)
            
            setExcelData(formattedDateofbirth)
          }
        } catch (error) {
          setFileError('Invalid Excel file. Please upload a valid Excel file.');
        }
      };
  
      if (file) {
        setFileError('');
        setSelectedFile(file)
        fileReader.readAsArrayBuffer(file);
      }
    };
    console.log(selectedFile);
    console.log("excelfiledata",excelData)

    const schema = yup
    .object({
      planname: yup.string().required("This Field is required"),
      description: yup.string().required("This Field is required"),
      validtill:yup.string().notOneOf([""], "You must select an option!"),
      moq: yup.string().required("This Field is required"),
      coverage: yup.string().required("This Field is required"),
      planprice:yup.string().required("This Field is required"),
      plancappingamount:yup.string().required("This Field is required"),
     ...(selectedCheckbox.includes("checkbox1")? (alignment==="inpercentage" ? {consultaionChargePlanAmountPercentage:yup.number().typeError('Please enter a valid number')
     .max(100, 'Number must be less than or equal to 100')
     .required('Number is required'),
     consultationChargeMaxDiscount:yup.string().required("This Field is required")}:{consultationChargePlanAmountValue:yup.string().required("This Field is required")}):{}),
     ...(selectedCheckbox.includes("checkbox2") ? (restofBillToggle==="inpercentage" ? {restOfBillPlanAmountPercentage:yup.number().typeError('Please enter a valid number')
     .max(100, 'Number must be less than or equal to 100')
     .required('Number is required'),
     restOfBillMaxDiscount:yup.string().required("This Field is required")}:{restOfBillPlanAmountValue:yup.string().required("This Field is required")}) : {}),
      ...(selectedCheckbox.includes("checkbox3") ? (totalbillToggle==="inpercentage" ? {totalBillPlanAmountPercentage:yup.number().typeError('Please enter a valid number')
      .max(100, 'Number must be less than or equal to 100')
      .required('Number is required'),
      totalBillMaxDiscount:yup.string().required("This Field is required")}:{totalBillPlanAmountValue:yup.string().required("This Field is required")}) : {})
    })
    .required();
  
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
      resolver: yupResolver(schema),
    });
    const onTagsChange = (event, values) => {
      console.log(values.length);
      if (values.length === 1) {
        setSpecialityOptions(values[0]);
      } else if (values.length > 1) {
        setSpecialityOptions(values[values.length - 1]);
      } else {
        setSpecialityOptions("");
      }
      setAllSpecialities(values)
      // console.log("values", values);
    };
    console.log(specialityOptions);
    const getSpecialities = async () => {
      try {
        let response = await getClinicSpecialities();
        let data = response.data?.map((el) => el.speciality);
        console.log(data);
        setSpecialities(data);
      } catch (error) {
        console.log(error.message);
      }
    };
  
  
    console.log("selectedcheckbox",selectedCheckbox)
    const onSubmit = async (data) => {
      if(!(selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3"))){
        toast.info("Please Select the Any One Of Plan Benefit Details", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      console.log("data", data);
      if(data &&
        (selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3")) 
        && allSpecialities.length>=1){
        const formdata={
          planName:data.planname,
          speciality:allSpecialities,
          description:data.description,
          moq:data.moq,
          validtill:data.validtill,
          coverage:data.coverage,
          planPrice:data.planprice,
          planCappingAmount:data.plancappingamount,
          customerDetails:excelData,
          consultationCharge:selectedCheckbox.includes("checkbox1"),
          consultationChargePlanAmtPer:data.consultaionChargePlanAmountPercentage?.toString(),
          consultationChargePlanAmounttVal:data.consultationChargePlanAmountValue,
          consultationChargeMaxDiscount:data.consultationChargeMaxDiscount,
          restOfTheBill:selectedCheckbox.includes("checkbox2"),
          restOfTheBillPlanAmtPer:data.restOfBillPlanAmountPercentage?.toString(),
          restOfTheBillPlanAmounttVal:data.restOfBillPlanAmountValue,
          restOfTheBillMaxDiscount:data.restOfBillMaxDiscount,
          totalBill:selectedCheckbox.includes("checkbox3"),
          totalBillPlanAmtPer:data.totalBillPlanAmountPercentage?.toString(),
          totalBillPlanAmounttVal:data.totalBillPlanAmountValue,
          totalBillMaxDiscount:data.totalBillMaxDiscount,
          clinicUniqueId:uniqueId,
          createdBy:username
        }
        console.log("formdata",formdata)
        try {
          setIsSubmitting(true)
          let res= await addPlanAndPricing(formdata)
          toast.success("Plan Added Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if(res){
            navigate("/user/plansAndPricing")
          }else{
            setIsSubmitting(false)
          }
        } catch (error) {
          toast.error("Please Check your server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      }
    };
  
    
    return (
      <div>
        <Box sx={{ paddingLeft: "10px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5">Plan Details</Typography>
            <Card>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Plan Name
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Plan Name"
                      {...register("planname")}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.planname?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Speciality
                    </FormLabel>
                    <Autocomplete
                    multiple
                    freeSolo
                    options={specialities}
                    onChange={onTagsChange}
                    onFocus={getSpecialities}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  {allSpecialities.length===0 && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%", mt: "10px" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Description
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="Description"
                    {...register("description")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.description?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "10px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Validity Till
                    </FormLabel>
                    {/* <TextField
                    className="inputbox"
                    size="small"
                    placeholder="1Year/2Year/3Year"
                    {...register("validtill")}
                  /> */}
                  <Select placeholder="Select" {...register("validtill")} size="small" className="inputbox">
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="1year">1 Year</MenuItem>
                  <MenuItem value="2years">2 Years</MenuItem>
                  <MenuItem value="3years">3 Years</MenuItem>
                  
                </Select>
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.validtill?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      MOQ
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="MOQ"
                      {...register("moq")}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.moq?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Coverage
                    </FormLabel>
                    <Select placeholder="Select"  {...register("coverage")} size="small" className="inputbox">
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="self">Self</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                    
                  </Select>
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.coverage?.message}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "10px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Plan Price
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Plan Price"
                      {...register("planprice")}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.planprice?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Plan Capping Amount
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Plan Capping Amount"
                      {...register("plancappingamount")}
                    />
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      {errors.plancappingamount?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                    >
                      Customer Details
                    </FormLabel>
                    <TextField
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        error={Boolean(fileError)}
                        helperText={fileError}
                        size="small"
                      />
                      <Typography variant="caption" color="textSecondary">
                        Only Excel files (.xlsx, .xls) are allowed.
                      </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              
            </Card>
  
            {/* ----------------------------------Plan Payment Details----------------------- */}
            <Typography sx={{ mt: "10px" }} variant="h5">
            Plan Benefits Details
            </Typography>
            <Card>
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => {
                      //   if(e.target.checked){
                      //     setConsultaion(true)
                      //     setRestofBill(true)
                      //     setTotalBill(false)
                      //   }else{
                      //     setConsultaion(false)
                      //     setRestofBill(false)
                      //     setTotalBill(false)
                      //   }
                      // }}
                      checked={selectedCheckbox.includes('checkbox1')}
                      onChange={() => {
                        handleCheckboxChange('checkbox1')
                        reset({
                          totalBillPlanAmountPercentage:"",
                          totalBillPlanAmountValue:"",
                          totalBillMaxDiscount:""
                        })
                      }}
                    />
                  }
                  label="For Consultation Charges"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        className="formlabel"
                      >
                        Plan Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={(e) => {
                            setAlignment(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({consultationChargePlanAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({consultaionChargePlanAmountPercentage:"",consultationChargeMaxDiscount:""})
                            }
                          }}
                          aria-label="Platform"
                          // disabled={!consultaion}
                          disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                        {alignment === "inpercentage"  &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            name="percentage"
                            placeholder="%"
                            {...register("consultaionChargePlanAmountPercentage")}
                            type="text"
                          />
                          <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.consultaionChargePlanAmountPercentage?.message}
                          </span>
                          </>
                          }
                        {alignment === "fixedamount"  &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                            placeholder="Rs."
                            name="amount"
                            {...register("consultationChargePlanAmountValue")}
                          />
                          <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.consultationChargePlanAmountValue?.message}
                          </span>
                          </>
                          }
                        
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!consultaion}
                        disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!consultaion}
                        disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                        {...register("consultationChargeMaxDiscount")}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.consultationChargeMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
              {/* ------------------------------For Rest of Bill------------------- */}
  
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => {
                      //   if(e.target.checked){
                      //     setRestofBill(true);
                      //     setConsultaion(true);
                      //     setTotalBill(false)
                      //   }else{
                      //     setRestofBill(false);
                      //     setConsultaion(false);
                      //     setTotalBill(false)
                      //   }
                      // }}
                      checked={selectedCheckbox.includes('checkbox2')}
                      onChange={() => {
                        handleCheckboxChange('checkbox2')
                        reset({
                        totalBillPlanAmountPercentage:"",
                        totalBillPlanAmountValue:"",
                        totalBillMaxDiscount:""
                      })
                      }}
                    />
                  }
                  label="For Rest Of The Bill"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                        className="formlabel"
                      >
                        Plan Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={restofBillToggle}
                          exclusive
                          onChange={(e) => {
                            setRestofBillToggle(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({restOfBillPlanAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({restOfBillPlanAmountPercentage:"",restOfBillMaxDiscount:""})
                            }
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                        {restofBillToggle === "inpercentage" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            type="number"
                            placeholder="%"
                            {...register("restOfBillPlanAmountPercentage")}
                          />
                          <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillPlanAmountPercentage?.message}
                          </span>
                          </>
                            }
                          {restofBillToggle === "fixedamount" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                            placeholder="Rs."
                            {...register("restOfBillPlanAmountValue")}
                            type="number"
                          />
                          <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillPlanAmountValue?.message}
                          </span>
                          </>
                        }
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle === "inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!restofBill}
                        disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle === "inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                        {...register("restOfBillMaxDiscount")}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
              {/* ----------------Total Bill--------------------- */}
              <Box sx={{ mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "#D83F87",
                      fontWeight: "bold",
                    },
                  }}
                  required
                  control={
                    <Checkbox
                      size="small"
                      // onClick={(e) => {
                      //   if(e.target.checked){
                      //     setTotalBill(true);
                      //     setConsultaion(false);
                      //     setRestofBill(false)
                      //   }else{
                      //     setTotalBill(false);
                      //     setConsultaion(false);
                      //     setRestofBill(false)
                      //   }
                      // }}
                      checked={selectedCheckbox.includes('checkbox3')}
                      onChange={() => {
                        handleCheckboxChange('checkbox3')
                        reset({consultationChargePlanAmountValue:"",
                        consultaionChargePlanAmountPercentage:"",
                        consultationChargeMaxDiscount:"",
                        restOfBillPlanAmountPercentage:"",
                        restOfBillPlanAmountValue:"",
                        restOfBillMaxDiscount:""})
                      }}
                    />
                  }
                  label="For Total Bill"
                />
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ mt: "5px" }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={8}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                        className="formlabel"
                      >
                        Plan Discount
                      </FormLabel>
                      <Box sx={{ display: "flex" }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={totalbillToggle}
                          exclusive
                          aria-label="Platform"
                          onChange={(e) => {
                            setTotalBillToggle(e.target.value);
                            if(e.target.value==="inpercentage"){
                              reset({totalBillPlanAmountValue:"",})
                            }
                            if(e.target.value==="fixedamount"){
                              reset({totalBillPlanAmountPercentage:"",totalBillMaxDiscount:""})
                            }
                          }}
                        >
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="inpercentage"
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                          >
                            In Percentage
                          </ToggleButton>
                          <ToggleButton
                            sx={{
                              "&.Mui-selected, &.Mui-selected:hover": {
                                color: "#FFFFFF",
                                backgroundColor: "#2D256C",
                              },
                            }}
                            value="fixedamount"
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                          >
                            Fixed Amount
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControl sx={{ width: "65%" }}>
                        {totalbillToggle === "inpercentage" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            type="text"
                            InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                            placeholder="%"
                            {...register("totalBillPlanAmountPercentage")}
                          />
                          <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.totalBillPlanAmountPercentage?.message}
                          </span>
                          </>
                          }
                        {totalbillToggle === "fixedamount" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                            placeholder="Rs."
                            type="number"
                            {...register("totalBillPlanAmountValue")}
                          />
                            <span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.totalBillPlanAmountValue?.message}
                            </span>
                          </>
                        }
                        </FormControl>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#2D256C",
                        }}
                        className="formlabel"
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3') && totalbillToggle === "inpercentage" ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                      >
                        Maximum Discount Value
                      </FormLabel>
                      <TextField
                        className="inputbox"
                        size="small"
                        placeholder="Maximum Discount Value"
                        // disabled={!totalBill}
                        disabled={selectedCheckbox.includes('checkbox3') && totalbillToggle === "inpercentage" ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[] }
                        {...register("totalBillMaxDiscount")}
                      />
                      <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.totalBillMaxDiscount?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
  
             
            </Card>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
              <Button
                disabled={isSubmitting}
                sx={{
                  backgroundColor: isSubmitting?"#898989":"#D83F87",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  padding: "5px 20px",
  
                  "&:hover": { backgroundColor: "#D83F87!important" },
                }}
                size="small"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </div>
    );
  };
  
  export default AddPlan;
  