import React, { useEffect } from "react";
import Terms_Conditions from "./Terms_Conditions";
import { CheckBox } from "@mui/icons-material";
import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import axios from "axios";
import { postTermsAgree } from "../services/clinicOnboarding";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import { toast } from "react-toastify";
import TronLogo from "../images/TronLogColor.png"

const TermsAndConditions = () => {
  const [accepted, setAccepted] = useState(false);
  const [ip, setIp] = useState("");

  console.log("ip: ", ip);

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorIP = () => {
    toast.error("Not able to fetch IP Address", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    if (res) {
      if (res.data.IPv4 === "Not found") {
        const { data } = await axios.get("https://api.ipify.org?format=json");
        if (data) {
          console.log("data: >>>>>>>>>>>>>>>>>", data);
          if (data.ip) {
            setIp(data.ip);
          } else {
            errorIP();
          }
        }
      } else {
        console.log(">>>>>>>>>>>>>", res.data.IPv4);
        setIp(res.data.IPv4);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setSubmitting(true);

    //   const {data} = await axios.get("https://api.ipify.org/?format=json");
    //   const ip=data.ip
    //  if(data){
    const val = {};
    val.uniqueId = id;
    val.ip = ip;
    console.log("ip: ", ip);
    val.clinicAgreement = "yes";
    if (!val.ip) {
      errorIP();
      return;
    }

    try {
      const { data } = await postTermsAgree(val);
      console.log("data: ", data);
      if (data) {
        setSubmitting(false);
        success();
        navigate("/");
      }
    } catch (er) {
      error();
      setSubmitting(false);
    }
    //  }
    // setIP(res.data.ip);
  };

  const [submitting, setSubmitting] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vh",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        open={submitting}
        onClose={() => {
          // setSubmitting(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Loader />
        </Box>
      </Modal>

      <div style={{ padding: "10px 15%" }}>
        <div style={{ textAlign: "center", lineHeight: 2 }}>
          <img src={TronLogo} alt="tronlogo"/>
          <h1>TRON HEALTHCARE PRIVATE LIMITED</h1>
          <h2>SERVICE PROVIDER AGREEMENT</h2>
        </div>
        <h5>INTRODUCTION:</h5>
        <p style={{ marginTop: "10px" }}>
          WHEREAS the Company herein have represented to Provider that they are
          engaged in the business of healthcare and as such they are providing
          <span style={{ fontWeight: "bold" }}> OPD healthcare plans</span> to
          their various prospective as well as existing clients / subscribers
          including individuals, companies or corporate houses for the purposes
          to provide health care and treatments / procedures etc. for their
          employees / individuals at the fee / cost / price as may be agreed by
          the Company with their said clients. The Company operates prepaid as
          well as claim-based payment specialized health care service plans
          individually and through medical health insurance companies for the
          provision of Covered OPD healthcare Services to corporate houses for
          their employees / individuals enrolled in such plans.
        </p>
        <p style={{ marginTop: "10px" }}>
          WHEREAS the Provider herein, being duly qualified{" "}
          <span style={{ fontWeight: "bold" }}>healthcare specialist</span>{" "}
          engaged in practice, is desirous to provide their services, as per
          terms of the Plans and copayments as may be provided by the Company to
          their clients, for health care / check ups / treatments / procedures
          etc. to the clients / individuals / corporate houses / members /
          Enrollees / subscribers and with the said intent and the company
          hereto are entering into this present agreement with the terms and
          conditions set out herein.
        </p>
        <br />
        <h5>DEFINITIONS</h5>
        <p style={{ marginTop: "10px" }}>
          Certain words whenever used in this Agreement, the following terms
          shall have the definitions contained in this Definition clause; rest
          of the words shall have their true sense and meanings as per common
          knowledge. Singular includes plural and gender includes vice versa in
          the same sense.
        </p>
        <br />

        <ol>
          {[
            {
              heading: "Commencement Date",
              subtext:
                "is the commencement date of this Agreement will be as dated above.",
            },
            {
              heading: "Copayment",
              subtext:
                "is a fee that may be charged to subscribers for certain healthcare services and collected by Provider at the time healthcare services are provided, as set forth in the healthcare plans under copayment. Provider shall charge and retain copayments for those specified covered healthcare services.",
            },
            {
              heading: "Covered Healthcare Services",
              subtext:
                "are those healthcare services which are required by a client /person referred by the Company, under healthcare plan chosen by client / person.",
            },
            {
              heading: "Employee",
              subtext:
                "means any person who is under employment of any corporate house.",
            },
            {
              heading: "Individual",
              subtext:
                "means any person or employee of any corporate house enrolled with the Company",
            },
            {
              heading: "Plan",
              subtext:
                "is any one of the healthcare plans or products administered by the Company",
            },
            {
              heading: "Patient / Client",
              subtext:
                "is an individual who is or whose employer is enrolled with the Company and as such he/she approached Provider for his/her healthcare needs.",
            },
            {
              heading: "Provider",
              subtext:
                "includes Provider, its employees, associates, partners, contractors, franchise, members or any other individuals / persons as may be engaged by the provider in any manner whatsoever.",
            },
            {
              heading: "Client",
              subtext:
                "is an individual, person, member, enrollee, subscriber, and corporate houses, groups, enrolled with the Company.",
            },
            {
              heading: "Subscriber Agreement",
              subtext:
                "is the agreement between the Company and any client / individual / corporate houses / members / subscribers which describes the services to be provided under the said agreement and for providing the said services, Provider hereby make them obliged and committed.",
            },
            {
              heading: "Software",
              subtext:
                "Means the exclusive software provided by the Company to Provider to maintain detailed information record of clients/ individual/ corporate houses / members / Enrollees / subscribers etc.",
            },
            // {
            //   heading: "Agreement",
            //   subtext:
            //     "is this Provider Agreement between the Company and Provider and any amendments, exhibits and attachments thereto.",
            // },
            // {
            //   heading: "Associate Practitioners",
            //   subtext:
            //     "are the persons / individuals who are working with and under instructions of the Provider.",
            // },

            // {
            //   heading: "Corporate House / Group",
            //   subtext:
            //     "means any employer, company, institution, agency, trust, fund, firm or any other organization entering into an Agreement with the Company for availing services as mentioned in this agreement and for which the provider is obliged and committed underthis present agreement.",
            // },

            // {
            //   heading: "Dentist",
            //   subtext:
            //     "is any person or entity licensed to practice dentistry which includes the provider himself and any person employed by or associated with the provider",
            // },

            // {
            //   heading: "Emergency Services",
            //   subtext:
            //     "are covered services for the sudden and unexpected onset or occurrence of a symptom, illness, or healthcare condition or injury which requires immediate diagnosis and/or treatment in order to alleviate or attempt to prevent severe pain, permanent disability, or healthcare complication.",
            // },

            // {
            //   heading: "Provider Management Software (PMS)",
            //   subtext:
            //     "is a cloud-based software exclusively developed as well as managed by the Company and for use in the office of the provider to maintain entire data related to the Company’s customers, communication and transaction purposes.",
            // },

            // {
            //   heading: "Provider Agreement",
            //   subtext:
            //     "is the present service provider agreement and amendments or addendums incorporated into this Agreement, and may be updated from time to time by the Company. Any of the instructions / manuals / communications etc. as may be sent by the Company in connection to the services under this agreement shall remain part and parcel of these present and shall have a binding force unless so intended specifically by the Company in writing.",
            // },
            // {
            //   heading: "Subscriber",
            //   subtext:
            //     "is an individual / corporate house who has contracted with the Company for healthcare services under any of the plans and who is enrolled to the provider.",
            // },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <li style={{ listStyleType: "upper-alpha" }}>
                <span style={{ fontWeight: "bold" }}>{item.heading} </span>
                <span>{item.subtext}</span>
              </li>
              <br></br>
            </React.Fragment>
          ))}
        </ol>
        <br></br>
        <h3 style={{ textAlign: "center" }}>
          WHEREAS based on the aforesaid representations and assurances given by
          Provider, the Parties hereto are entering into this present service
          provider agreement as under;
        </h3>
        <br />
        <ol>
          <li>
            <p>
              That the Provider hereby specifically{" "}
              <strong>agrees to provide Healthcare Services</strong> to the
              clients of the Company, as set forth in the applicable Addendum or
              communicated otherwise by the Company, in accordance with the
              terms of this Agreement (Provider agrees to abide by the
              discounts, benefits and coverage, and exclusions and limitation as
              may be set forth by the Company in any Addendum or communicated
              otherwise. The primary concern of Provider shall be the quality,
              as per satisfaction of the Company, of the Services provided to
              the patients.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the provider may sell <strong>individual plans</strong> from
              their office / clinic to any individual, when the Company, decides
              and authorises the provider to do so.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              The provider shall update/maintain the{" "}
              <strong>patients’ data / record</strong> on the software program
              as provided by the Company
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the introductory{" "}
              <strong>administrative fee / service charges will be 20%</strong>{" "}
              of billing amount inclusive of transaction fees of the total
              amount charged from any individual / patient referred by the
              company. The entire payment from any patient / individual shall be
              received in bank accounts of the Company through payment gateway
              in the software provided by the Company which after deduction of
              the administration fee / services charges and applicable taxes
              shall be reimbursed to the Provider
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider will be seeing patients who have{" "}
              <strong> Healthcare Insurance/benefits</strong> from either a part
              of Medical Insurance or some amount covered by their corporations
              with different limits of procedures covered and amounts. For these
              patients, Provider will first take prior Authorisation from the
              Company through the software provided. After receiving the
              approval, provider will do the needful and submit the claim form
              for <strong>Reimbursement</strong>, which will be made payable
              within <strong>07 days</strong> of submitting the claim.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall not receive any payment in cash and since
              there will be <strong>no cash transactions</strong> all the
              payments shall be received only through payment gateway provided
              by the Company for the said purposes in respect of the patients
              sent by the company.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider can use the Company software and Payment Gateway for
              their walkin patients also. There will not be any Administrative
              fees / services charges, for those patients but only
              <strong>transaction fees of 2.5%</strong> will be deducted and
              payment will be made within 3 working days.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the provider may charge <strong>copayments</strong> from the
              concerned patient as per the copayments of healthcare plans as may
              be communicated by the Company in writing from time to time or as
              reflected on software billing system. The Company shall at its own
              discretion and without any interruption solely perform
              administrative, accounting, enrollment, eligibility verification,
              fixation of charges / fees and other functions necessary for the
              administration and operation of any of its Plan.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider shall perform healthcare services for clients of
              the Company, which are enrolled with it. In such cases, the{" "}
              <strong>charges shall not exceed the amount</strong>(s) as set
              forth by the Company in case of insurance customers. Where the
              customer is not an insurance beneficiary, the{" "}
              <strong>prices charged shall not exceed the usual charges</strong>{" "}
              at the clinic. Provider shall receive by oneself complete payment
              of the copayment through the company software and Payment gateway
              and shall allow the discounts as may be required / communicated by
              the Company and no additional amount shall be charged.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Company will make{" "}
              <strong>reasonable efforts to market the Plan.</strong> Provider
              agrees that the Company, in its discretion, may use Provider
              information which may include but not be limited to name, address
              and telephone number as well as the names, addresses and telephone
              numbers and specialties of Provider in Plan marketing and
              informational materials including, without limitation, Plan
              directory of Participating Doctors.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Company will not enrol another clinic of similar price
              point and speciality within a 3 Km radius of the clinic enrolled.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That this Agreement applies to all programs provided for clients
              under contracts entered into with the Company. This Agreement will
              become effective on the commencement date and will remain in
              effect for an{" "}
              <strong>initial term of 60 consecutive months</strong> and may be
              renewed on such terms and conditions and for such further duration
              as may specifically be agreed between the parties. However, either
              party may terminate this Agreement for cause after giving the
              other party a 30 days notice in writing on the address mentioned
              in para 1 above.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall allow the Company, for making and{" "}
              <strong>affixing the sign board</strong> of the Company, on their
              Clinic/Hospital to identify the arrangement between the Company
              and the Provider
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              The parties hereto agree that the following criteria and shall be{" "}
              <strong>required to be maintained by the provider</strong> and
              their associates.
            </p>
          </li>
          <div>
            <React.Fragment>
              <ol style={{ listStyleType: "upper-roman" }}>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Provider and their associate are holding{" "}
                    <strong>requisite qualification</strong>, eligibility,
                    permissions, approvals or licenses as are required under the
                    law for the performance of their obligation / duty / job /
                    services during the course of performing any act pursuant to
                    this present agreement.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Providers clinic / hospital is{" "}
                    <strong>easy to locate</strong> and its entrance is well
                    marked. Provider office / clinic / hospital shall be
                    available to clients of the Company at all times during
                    working hours.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Provider shall have less than three days{" "}
                    <strong>accessibility available</strong> to all clients and
                    the
                    <strong>
                      waiting time generally not to exceed 15 minutes
                    </strong>
                    . Emergency patients shall be seen by the provider or
                    associate on the same day.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    In case the Plan requires providers to have a timely recall
                    system for any client. The said client should be{" "}
                    <strong>recalled within the specified time.</strong>
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Documents related to a particular Plan shall be furnished to
                    the provider from time to time either in hard copy or
                    through electronic communications such as mails.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    The Company can <strong>Audit physically</strong>, Provider
                    Healthcare premises/ office/ clinic/ hospital record/patient
                    records etc. at any time during the term of this agreement.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Provider shall obtain adequate{" "}
                    <strong>liability insurance</strong> and shall maintain the
                    same during the entire tenure.
                  </p>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <p>
                    Provider shall always remain{" "}
                    <strong>
                      responsible for all the acts and liabilities
                    </strong>{" "}
                    whatsoever for the services they have provided.
                  </p>
                </li>
              </ol>
            </React.Fragment>
          </div>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall remain responsible for possessing all the{" "}
              <strong>Healthcare / Medical as well as other equipments</strong>{" "}
              and the same shall always be kept in good and well-maintained
              conditions.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall always use adequate and{" "}
              <strong>prescribed methods of sterilisation</strong> as per the
              current prevailing norms and recent scientific recommendations.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall always keep and maintain the clinic / hospital
              well equipped and follow disinfection process from time to time.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the provider shall <strong>maintain adequate</strong> staf,
              laboratory facilities and equipment, and to properly perform
              services required hereunder. The provider shall always maintain
              its clinic / hospital in consonance with the applicable local
              municipal law with respect to building, construction, use of the
              premises, fire fighting and other requirements / guidelines /
              norms. Any kind of medical emergency shall also be meted out by
              the provider solely
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider hereby assures the Company that{" "}
              <strong>Services</strong> shall be <strong>provided</strong> to
              patients / members in the{" "}
              <strong>same manner and same quality care</strong>, as such
              Services are provided to any other patients in their clinic,
              except as required pursuant to this Agreement.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That provider shall{" "}
              <strong>
                maintain proper medical records and history of patients
              </strong>{" "}
              / clients as may be sent by the Company including the date of
              examinations / treatments / sittings etc., name of physician,
              phone number, date of last physical examination, and whether
              patient is under physician care.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the provider shall maintain healthcare examination record of
              patient / client providing his/her clinical observations including
              the information related to teeth, any observations around general
              health, evaluation of all soft tissues of the head, neck, palate,
              tongue, and lips.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              The <strong>patient</strong> should be made{" "}
              <strong>aware of all costs</strong> and all future visits in
              choosing one type of treatment over another. Consent and signature
              of patient’s choice of treatment should be documented in his/her
              file.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider, at its sole cost and expense, shall maintain
              throughout the term of this Agreement and for a period of four
              years following termination of this Agreement, a
              <strong>professional liability insurance</strong> i.e., medical
              mal-practice insurance covering adequate amount with respect to
              any claim or claims that may arise out of, or as a result of any
              alleged malpractice, negligence, acts or omissions caused or
              alleged to have been caused by Provider, its staff, agents or
              employees in the performance of, or omission of, any duty assumed
              by Provider, its staff, agents or employees and Associates.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider shall provide access at reasonable times upon
              demand by The Company or its authorized representatives / officers
              for inspection or to periodically audit or inspect the facilities,
              offices, equipment, books, documents and records of Provider
              relating to the performance of this Agreement and the Services
              provided to Members.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That Provider shall defend, <strong>indemnify</strong> and hold
              harmless, and shall cause indemnify and hold harmless the Company
              and its directors, officers, employees, affiliates and agents
              against any claim, loss, damage, cost, expense or liability
              arising out of or related to the performance or nonperformance or
              negligence or any deficiency in service committed by Provider or
              Associates or their respective employees or agents of any Services
              to be performed or arranged by Provider under this Agreement. All
              the disputes or controversies or claims between Provider and a
              patient arising out of any of the acts of the Provider or
              Associates, other than claims for benefits shall be sole
              responsibility of the provider only. The Company shall defend,{" "}
              <strong>indemnify</strong> and hold harmless Provider and its
              directors, officers, employees, affiliates and agents against any
              claim, loss, damage, cost, expense or liability arising out of or
              related to the performance or nonperformance by the Company, its
              employees or agents of any services to be performed by the Company
              under this Agreement.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That all the <strong>disputes</strong>, controversies, or claims
              arising out of the interpretation, performance or breach of this
              Agreement or any other differences arising out of this agreement
              or any part thereof or in relation to any transaction arising out
              of this agreement shall be referred to any person / sole
              arbitrator appointed by the Company whose decision shall remain
              final and binding on both the parties. The arbitration proceedings
              will be governed by the provisions of the Arbitration and
              Conciliation Act, 1996 with all the modifications for the time
              being in force. The arbitration proceedings will be conducted in
              English language at Jaipur city only. The courts of Jaipur city
              shall only have jurisdiction in case of disputes of any kind
              whatsoever
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Provider shall maintain <strong>confidential</strong> all
              information provided to it by the Company while performing under
              this agreement.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That any <strong>waiver</strong> by either party to this Agreement
              of a breach or violation of any provision of this Agreement shall
              not operate as or be construed to be a waiver of any subsequent
              breach or violation thereof.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That this Agreement, including all exhibits, attachments and
              amendments hereto, contains all the terms and conditions agreed
              upon by the parties regarding the subject matter of this
              Agreement.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That in case of any complaint by the subscriber regarding
              treatment, provision for second opinion, audits etc. the same
              shall be referred to the <strong>peer review committee</strong>{" "}
              established for this purpose and Provider shall abide by all the
              decisions taken by any of them.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the provider shall regularly, i.e. on completion of end of
              each day, upload all the details including the treatments rendered
              to the clients / individual / corporate houses / members /
              Enrollees / subscribers etc., along with all the radiographs as
              well as all the details of charged money/ payments/ fees etc. to
              the software as provided by the Company
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the software will be provided to Provider at no initial cost,
              but its regular maintenance cost as may be specified from time to
              time shall have to be paid by Provider on a monthly or yearly
              basis, as may be decided by the company.
            </p>
          </li>
          <li style={{ marginTop: "10px" }}>
            <p>
              That the Company and Provider both shall bear equally the expenses
              of registration of these presents
            </p>
          </li>
          <li style={{ marginTop: "10px",textAlign:"center" }}>
            <h4>
              HENCE both the parties hereto now hereby set and subscribe their
              respective hands and seals on this SERVICE PROVIDER AGREEMENT on
              this day, month and the year as mentioned.
            </h4>{" "}
          </li>
        </ol>
        <br />
        <br />
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#c3c3c3",
            padding: "10px",
          }}
        >
          <input
            type="checkbox"
            style={{ margin: "5px 15px", width: "18px", height: "18px" }}
            checked={accepted}
            onChange={(e) => {
              setAccepted(e.target.checked);
            }}
          />{" "}
          <span
            style={{ fontWeight: "bold", color: "#2D256C", fontSize: "20px" }}
          >
            I agree to the terms and conditions
          </span>
        </div>
        <br />
        <br />
        <Terms_Conditions />
        {accepted && (
          <div style={{ textAlign: "center", padding: 10 }}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default TermsAndConditions;
