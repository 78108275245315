import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  addCoupon,
  getClinicProcedure,
  getClinicSpecialities,
} from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";
import { Troubleshoot } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";


// const phoneRegExp = /^[6-9]\d{9}$/;
// speciality: yup.array().required("You must select an option!"),


const AddCoupon = () => {
  const [startDate, setStartDate] = useState(null);
  const [entireStartDate,setEntireStartDate]=useState("")
  const [endDate, setEndDate] = useState(null);
  const [entireEndDate,setEntireEndDate]=useState("")
  const [startTime, setStartTime] = useState(null);
  const [extractStartTime,setExtractStartTime]=useState("")
  const [endTime, setEndTime] = useState(null);
  const [extractEndTime,setExtractEndTime]=useState("")
  const [consultaion, setConsultaion] = useState(false);
  const [restofBill, setRestofBill] = useState(false);
  const [totalBill, setTotalBill] = useState(false);
  const [freeConsultation, setFreeConsultation] = useState(false);
  const [alignment, setAlignment] = useState("inpercentage");
  const [restofBillToggle, setRestofBillToggle] = useState("inpercentage");
  const [totalbillToggle, setTotalBillToggle] = useState("inpercentage");
  const [specialities, setSpecialities] = useState([]);
  const [allSpecialities,setAllSpecialities]=useState([])
  const [specialityOptions, setSpecialityOptions] = useState("");
  const [procedures,setProcedures]=useState([])
  const [allProcedures, setAllProcedures] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate=useNavigate("")
  let token=JSON.parse(localStorage.getItem("token"));
  let uniqueId=token?.uniqueId
  let username=token?.username

  const handleCheckboxChange = (checkboxValue) => {
    console.log("checkboxvalue",checkboxValue)
      if (checkboxValue === "checkbox1" || checkboxValue === "checkbox2") {
        setSelectedCheckbox((prevSelectedCheckboxes) => {
          if (prevSelectedCheckboxes.includes("checkbox3")) {
            return [checkboxValue];
          } else {
            return prevSelectedCheckboxes.includes(checkboxValue)
              ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
              : [...prevSelectedCheckboxes, checkboxValue];
          }
        });
      } else if (checkboxValue === "checkbox3") {
        setSelectedCheckbox((prevSelectedCheckboxes) =>
          prevSelectedCheckboxes.includes(checkboxValue)
            ? prevSelectedCheckboxes.filter((value) => value !== checkboxValue)
            : [checkboxValue]
        );
      }
  };
console.log(selectedCheckbox);

  const schema = yup
  .object({
    couponcode: yup.string().required("This Field is required"),
    description: yup.string().required("This Field is required"),
    moq: yup.string().required("This Field is required"),
    noofusage: yup.string().required("This Field is required"),
    startdate:yup.string().required("This Field is required"),
    enddate:yup.string().required("This Field is required"),
    starttime:yup.string().required("This Field is required"),
    endtime:yup.string().required("This Field is required"),
    procedures: yup.array().of(yup.string().required('select onnnnn')).min(1).required("You must select an option!"),
    totalnoofusage: yup.string().required("This Field is required"),
    ...(selectedCheckbox.includes("checkbox1") ? (alignment==="inpercentage"? {consultaionChargePlanAmountPercentage:yup.number().typeError('Please enter a valid number')
    .max(100, 'Number must be less than or equal to 100')
    .required('Number is required'),
    consultationChargeMaxDiscount:yup.string().required("This Field is required")}:{consultationChargePlanAmountValue:yup.string().required("This Field is required")}):{}),
    ...(selectedCheckbox.includes("checkbox2") ? (restofBillToggle==="inpercentage"? {restOfBillCouponAmountPercentage:yup.number().typeError('Please enter a valid number')
     .max(100, 'Number must be less than or equal to 100')
     .required('Number is required'),
     restOfBillMaxDiscount:yup.string().required("This Field is required")}:{ restOfBillCouponAmountValue:yup.string().required("This Field is required")}) : {}),
     ...(selectedCheckbox.includes("checkbox3")? (totalbillToggle==="inpercentage"? {totalBillPlanAmountPercentage:yup.number().typeError('Please enter a valid number')
     .max(100, 'Number must be less than or equal to 100')
     .required('Number is required'),
     totalBillMaxDiscount:yup.string().required("This Field is required")}:{totalBillPlanAmountValue:yup.string().required("This Field is required")}) : {})
    // noOfFreeConsulation:yup.string().required("This Field is required"),
  })
  .required();


  // ---------------------Time extraction--------------------
  function startTimeExtracting(time){
    const hours=time?.$H;
    const min=time?.$m;
    let  totaltime=`${hours>9?hours:"0"+hours}:${min>9?min:"0"+min}`;
    // console.log("starttime",totaltime)
    setExtractStartTime(totaltime)
  }
  

  function endTimeExtracting(time){
    const hours=time?.$H;
    const min=time?.$m;
    let  totaltime=`${hours>9?hours:"0"+hours}:${min>9?min:"0"+min}`;
    // console.log("endtime",totaltime);
    setExtractEndTime(totaltime)
  }


  // --------------Date Extracting start --------------------;
  function StartDateExtracting(startDate) {
    let currentdate = startDate?.$D;
    let month = startDate?.$M + 1;
    let year = startDate?.$y;
    const entireStartDate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setStartDate(entireStartDate)
    return entireStartDate
  }
  

  function EndDateExtracting(endDate) {
    let currentdate = endDate?.$D;
    let month = endDate?.$M + 1;
    let year = endDate?.$y;
    const entireEndDate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setEndDate(entireEndDate)
    // setEntireEndDate(entireEndDate)
    return entireEndDate
  }

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue
  } = useForm({
    mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });
  console.log('startDate',startDate);

  const onTagsChange = (event, values) => {
    console.log(values.length);
    if (values.length === 1) {
      setSpecialityOptions(values[0]);
    } else if (values.length > 1) {
      setSpecialityOptions(values[values.length - 1]);
    } else {
      setSpecialityOptions("");
    }
    setAllSpecialities(values)
    // console.log("values", values);
  };

  const onProceduresChange = (event, values) => {
    setProcedures(values)
  };

  // console.log(specialityOptions);
  const getSpecialities = async () => {
    try {
      let response = await getClinicSpecialities();
      let data = response.data?.map((el) => el.speciality);
      console.log(data);
      setSpecialities(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getProcedures = async () => {
    try {
      let response = await getClinicProcedure(specialityOptions);
      console.log('ggg',response.data);
      setAllProcedures([...allProcedures,...response.data?.map((el) => el.procedureName)]);
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log("allprocedures",allProcedures);

  const onSubmit = async (data) => {
    console.log("data",data);
    if(!(selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3"))){
      toast.info("Please Select the Any One Of Coupon Payment Details", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    console.log(selectedCheckbox.includes("checkbox1"),selectedCheckbox.includes("checkbox2"),selectedCheckbox.includes("checkbox3") )
    console.log("extracttime",extractStartTime)
    console.log("extractendtime",extractEndTime)
    console.log("procedures",data.procedures)
    console.log("specialities",allSpecialities)
    if(data && 
      (selectedCheckbox.includes("checkbox1") || selectedCheckbox.includes("checkbox2") ||selectedCheckbox.includes("checkbox3"))
       && extractStartTime && 
       extractEndTime && 
       !isEndTimeBeforeStartTime && 
       allSpecialities.length>=1){
      const formdata={
        couponCode:data.couponcode,
        speciality:allSpecialities,
        description:data.description,
        procedures:data.procedures,
        moq:data.moq,
        startDate:data.startdate,
        startTime:extractStartTime,
        endDate:data.enddate,
        endTime:extractEndTime,
        noOfUsagePerUser:data.noofusage,
        totalNoOfUsage:data.totalnoofusage,
        consultationCharge:selectedCheckbox.includes("checkbox1"),
        consultationChargePlanAmtPer:data.consultaionChargePlanAmountPercentage?.toString(),
        consultationChargePlanAmounttVal:data.consultationChargePlanAmountValue,
        consultationChargeMaxDiscount:data.consultationChargeMaxDiscount,
        restOfTheBill:selectedCheckbox.includes("checkbox2"),
        restOfTheBillPlanAmtPer:data.restOfBillCouponAmountPercentage?.toString(),
        restOfTheBillPlanAmounttVal:data.restOfBillCouponAmountValue,
        restOfTheBillMaxDiscount:data.restOfBillMaxDiscount,
        totalBill:selectedCheckbox.includes("checkbox3"),
        totalBillPlanAmtPer:data.totalBillPlanAmountPercentage?.toString(),
        totalBillPlanAmounttVal:data.totalBillPlanAmountValue,
        totalBillMaxDiscount:data.totalBillMaxDiscount,
        freeConsultation:false,
        noOfFreeConsultation:"",
        clinicUniqueId:uniqueId,
        createdBy:username
      }
      console.log("formdata",formdata)
      try {
        setIsFormSubmitted(true)
        let res=await addCoupon(formdata)
        toast.success("Coupon Added Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if(res){
          navigate("/user/coupons")
        }else{
          setIsFormSubmitted(false)
        }
      } catch (error) {
        toast.error("Please Check your server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsFormSubmitted(false)
      }
    }
  };

  const isSameDate = startDate && endDate && dayjs(startDate).isSame(endDate, 'day');
  const isEndTimeBeforeStartTime = isSameDate && endTime && startTime && endTime.isBefore(startTime, 'minute');
  console.log(isEndTimeBeforeStartTime)
  console.log(watch("procedures"),"procedures") 
  console.log()
  useEffect(() => {
    if(specialityOptions){
      getProcedures();
    }
    if(specialityOptions===""){
      setAllProcedures([])
    }
    
  }, [specialityOptions]);

  return (
    <div>
      <Box sx={{ paddingLeft: "10px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5" fontSize="17px" mb="10px">Basic Coupon Details</Typography>
          <Card>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Coupon Code
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="Coupon Name"
                    {...register("couponcode")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.couponcode?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Speciality
                  </FormLabel>
                
                  <Autocomplete
                    multiple
                    freeSolo
                    options={["All",...specialities]}
                    onChange={onTagsChange}
                    onFocus={getSpecialities}
                    
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  {allSpecialities.length===0 && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%", mt: "10px" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Description
                </FormLabel>
                <TextField
                  className="inputbox"
                  size="small"
                  placeholder="Description"
                  {...register("description")}
                />
                <span style={{ color: "red",fontSize:"10px" }} className="error">
                  {errors.description?.message}
                </span>
              </FormControl>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              sx={{ mt: "10px" }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={8}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Procedures
                  </FormLabel>
                  {/* <Select placeholder="Select" {...register("procedure")} size="small" className="inputbox">
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="ophthalmology">Ophthalmology</MenuItem>
                  <MenuItem value="ent">ENT</MenuItem>
                  <MenuItem value="dental">Dental</MenuItem>
                  <MenuItem value="gastroenterology">Gastroenterology</MenuItem>
                  <MenuItem value="gynecology">Gynecology</MenuItem>
                </Select> */}
                <Controller name='procedures'  control={control}  render={({ field ,fieldState:{error}}) =>{
                  const {onChange,value,ref}=field;
                  return <> <Autocomplete
                  multiple
                  freeSolo
                  options={["All",...allProcedures]}
                  onChange={(e,newValue)=>{
                    console.log('newValues',newValue);
                    onChange(newValue) 
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
                </>
                }}/>
                <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.procedures?.message}
                  </span>
                 
                  {/* {procedures.length === 0 && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    MOQ
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="MOQ"
                    {...register("moq")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.moq?.message}
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              sx={{ mt: "10px" }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Start Date
                  </FormLabel>
                  <Controller name='startdate'  control={control} render={({ field ,fieldState:{error}}) =>{
                    const {onChange,value,ref}=field;
                    return  <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={new Date()}
                      value={startDate === undefined ? "" : startDate}
                      onChange={(date) => {
                        // setStartDate(date);
                        onChange(date) 
                        setValue("startdate",StartDateExtracting(date),{ shouldValidate: true });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ backgroundColor: "#FFFFFF" }}
                        />
                      )}
                      onYearChange={onYearChangeHandler}
                      orientation={"portrait"}
                    />
                  </LocalizationProvider>
                    </>
                  }} />
                  {/* {startDate === null && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )} */}
                   <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.startdate?.message}
                  </span>
                   
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Start Time
                  </FormLabel>
                  <Controller name='starttime'  control={control} render={({ field ,fieldState:{error}})=>{
                     const {onChange,value,ref}=field;
                    return <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={startTime}
                      onChange={(time) => {
                        onChange(time)
                        setStartTime(time)
                        startTimeExtracting(time)
                      }}
                      
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ backgroundColor: "#FFFFFF" }}
                          placeholder="Select Time"
                        />
                      )}
                    />
                  </LocalizationProvider>
                    </>
                  }} />
                  {/* {startTime === null && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )} */}
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.starttime?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    End Date
                  </FormLabel>
                  <Controller name='enddate'  control={control} render={({ field ,fieldState:{error}})=>{
                    const {onChange,value,ref}=field;
                    return  <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    inputFormat="DD/MM/YYYY"
                     minDate={new Date()}
                      value={endDate}
                      onChange={(date) => {
                        // setEndDate(date);
                        onChange(date)
                        setValue("enddate",EndDateExtracting(date),{ shouldValidate: true });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ backgroundColor: "#FFFFFF" }}
                        />
                      )}
                      onYearChange={onYearChangeHandler}
                      orientation={"portrait"}
                    />
                  </LocalizationProvider>
                    </>
                  }} />
                  {/* {endDate === null && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )} */}
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.enddate?.message}
                  </span>
                  
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              sx={{ mt: "10px" }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    End Time
                  </FormLabel>
                  {/* {console.log("startDate",startDate,"endDate",endDate,"startTime",startTime)} */}
                  <Controller name='endtime'  control={control} render={({ field ,fieldState:{error}})=>{
                    const {onChange,value,ref}=field;
                    return <>     
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={endTime}
                      onChange={(time) => {
                        onChange(time)
                        setEndTime(time)
                        endTimeExtracting(time)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ backgroundColor: "#FFFFFF" }}
                          placeholder="Select Time"
                        />
                      )}
                      minTime={isEndTimeBeforeStartTime ? startTime : null}
                    />
                  </LocalizationProvider>
                    </>
                  }}/>
                  {/* {endTime === null && (
                    <span style={{ color: "red",fontSize:"10px" }} className="error">
                      This Field Required
                    </span>
                  )} */}
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.endtime?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    No. Of Usage Per User
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="No. Of Usage Per User"
                    {...register("noofusage")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.noofusage?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2D256C",
                    }}
                    className="formlabel"
                  >
                    Total No Of Usage
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    size="small"
                    placeholder="Total No Of Usage"
                    {...register("totalnoofusage")}
                  />
                  <span style={{ color: "red",fontSize:"10px" }} className="error">
                    {errors.totalnoofusage?.message}
                  </span>
                </FormControl>
              </Grid>
            </Grid>
          </Card>

          {/* ----------------------------------Coupon Payment Details----------------------- */}
          <Typography sx={{ mt: "10px",fontSize:"17px",mb:"10px" }} variant="h5">
            Coupon Payment Details
          </Typography>
          <Card>
            <Box sx={{ mt: "10px" }}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#D83F87",
                    fontWeight: "bold",
                  },
                }}
                required
                control={
                  <Checkbox
                    size="small"
                    // onClick={(e) => {
                    //   if(e.target.checked){
                    //     setConsultaion(true)
                    //     setRestofBill(true)
                    //     setTotalBill(false)
                    //   }else{
                    //     setConsultaion(false)
                    //     setRestofBill(false)
                    //     setTotalBill(false)
                    //   }
                    // }}
                    checked={selectedCheckbox.includes('checkbox1')}
                    onChange={() => {
                      handleCheckboxChange('checkbox1')
                      // reset({totalBillPlanAmountPercentage:"",
                      // totalBillPlanAmountValue:"",
                      // totalBillMaxDiscount:""})
                      setValue("totalBillPlanAmountPercentage","",{ shouldValidate: true })
                      setValue("totalBillPlanAmountValue","",{ shouldValidate: true })
                      setValue("totalBillMaxDiscount","",{ shouldValidate: true })
                    }}
                  />
                }
                label="For Consultation Charges"
              />
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "5px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      // disabled={!consultaion}
                      disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                      className="formlabel"
                    >
                      Coupon Discount
                    </FormLabel>
                    <Box sx={{ display: "flex" }}>
                      <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={(e) => {
                          setAlignment(e.target.value);
                          if(e.target.value==="inpercentage"){
                            // reset({consultationChargePlanAmountValue:"",})
                            setValue("consultationChargePlanAmountValue","",{ shouldValidate: true })
                          }
                          if(e.target.value==="fixedamount"){
                            // reset({consultaionChargePlanAmountPercentage:"",consultationChargeMaxDiscount:""})
                            setValue("consultaionChargePlanAmountPercentage","",{ shouldValidate: true })
                            setValue("consultationChargeMaxDiscount","",{ shouldValidate: true })
                          }
                        }}
                        aria-label="Platform"
                        // disabled={!consultaion}
                        disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                      >
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="inpercentage"
                          size="small"
                          // disabled={!consultaion}
                          disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          In Percentage
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="fixedamount"
                          size="small"
                          // disabled={!consultaion}
                          disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          Fixed Amount
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormControl sx={{ width: "65%" }}>
                        {alignment === "inpercentage"  &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            name="percentage"
                            placeholder="%"
                            {...register("consultaionChargePlanAmountPercentage")}
                            type="text"
                          />
                          {!totalBill && <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.consultaionChargePlanAmountPercentage?.message}
                          </span>}
                          </>
                          }
                        {alignment === "fixedamount"  &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!consultaion}
                            disabled={selectedCheckbox.includes("checkbox1")?selectedCheckbox.includes("checkbox3"):[]}
                            placeholder="Rs."
                            name="amount"
                            {...register("consultationChargePlanAmountValue")}
                          />
                          {!totalBill && <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.consultationChargePlanAmountValue?.message}
                          </span>}
                          </>
                          }
                        
                      </FormControl>
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                      // disabled={!consultaion}
                      disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                    >
                      Maximum Discount Value
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Maximum Discount Value"
                      // disabled={!consultaion}
                      disabled={selectedCheckbox.includes("checkbox1") && alignment==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                      {...register("consultationChargeMaxDiscount")}
                    />
                    {<span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.consultationChargeMaxDiscount?.message}
                    </span>}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* ----------------------------For Rest of Bill------------------- */}

            <Box sx={{ mt: "10px" }}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#D83F87",
                    fontWeight: "bold",
                  },
                }}
                required
                control={
                  <Checkbox
                    size="small"
                    // onClick={(e) => {
                    //   if(e.target.checked){
                    //     setRestofBill(true)
                    //     setConsultaion(true)
                    //     setTotalBill(false)
                    //   }else{
                    //     setRestofBill(false)
                    //     setConsultaion(false)
                    //     setTotalBill(false)
                    //   }
                    // }}
                    checked={selectedCheckbox.includes('checkbox2')}
                    onChange={() => {
                      handleCheckboxChange('checkbox2')
                      // reset({totalBillPlanAmountPercentage:"",
                      // totalBillPlanAmountValue:"",
                      // totalBillMaxDiscount:""})
                      setValue("totalBillPlanAmountPercentage","",{ shouldValidate: true })
                      setValue("totalBillPlanAmountValue","",{ shouldValidate: true })
                      setValue("totalBillMaxDiscount","",{ shouldValidate: true })
                    }}
                  />
                }
                label="For Rest Of The Bill"
              />
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "5px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      // disabled={!restofBill}
                      disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                      className="formlabel"
                    >
                      Coupon Discount
                    </FormLabel>
                    <Box sx={{ display: "flex" }}>
                      <ToggleButtonGroup
                        color="primary"
                        value={restofBillToggle}
                        exclusive
                        onChange={(e) => {
                          setRestofBillToggle(e.target.value);
                          if(e.target.value==="inpercentage"){
                            // reset({restOfBillCouponAmountValue:"",})
                            setValue("restOfBillCouponAmountValue","",{ shouldValidate: true })
                          }
                          if(e.target.value==="fixedamount"){
                            // reset({restOfBillCouponAmountPercentage:"",restOfBillMaxDiscount:""})
                            setValue("restOfBillCouponAmountPercentage","",{ shouldValidate: true })
                            setValue("restOfBillMaxDiscount","",{ shouldValidate: true })
                          }
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="inpercentage"
                          size="small"
                          // disabled={!restofBill}
                          disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          In Percentage
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="fixedamount"
                          size="small"
                          // disabled={!restofBill}
                          disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                        >
                          Fixed Amount
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormControl sx={{ width: "65%" }}>
                        {restofBillToggle === "inpercentage" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            type="number"
                            placeholder="%"
                            {...register("restOfBillCouponAmountPercentage")}
                          />
                          { <span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillCouponAmountPercentage?.message}
                          </span>}
                          </>
                            }
                          {restofBillToggle === "fixedamount" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!restofBill}
                            disabled={selectedCheckbox.includes("checkbox2")?selectedCheckbox.includes("checkbox3"):[]}
                            placeholder="Rs."
                            {...register("restOfBillCouponAmountValue")}
                            type="number"
                          />
                          {<span style={{ color: "red",fontSize:"10px" }} className="error">
                            {errors.restOfBillCouponAmountValue?.message}
                          </span>}
                          </>
                        }
                      </FormControl>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                      // disabled={!restofBill}
                      disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle==="inpercentage" ?selectedCheckbox.includes("checkbox3"):[]}
                    >
                      Maximum Discount Value
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Maximum Discount Value"
                      // disabled={!restofBill}
                      disabled={selectedCheckbox.includes("checkbox2") && restofBillToggle==="inpercentage"?selectedCheckbox.includes("checkbox3"):[]}
                      type="number"
                      {...register("restOfBillMaxDiscount")}
                    />
                    {<span style={{ color: "red",fontSize:"10px" }} className="error">
                          {errors.restOfBillMaxDiscount?.message}
                    </span>}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* ----------------Total Bill--------------------- */}
            <Box sx={{ mt: "10px" }}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#D83F87",
                    fontWeight: "bold",
                  },
                }}
                required
                control={
                  <Checkbox
                    size="small"
                    // onClick={(e) => {
                    //   if(e.target.checked){
                    //     setConsultaion(false);
                    //     setRestofBill(false);
                    //     setTotalBill(true)
                    //   }else{
                    //     setConsultaion(false);
                    //     setRestofBill(false);
                    //     setTotalBill(false)
                    //   }
                    // }}
                    checked={selectedCheckbox.includes("checkbox3")}
                    onChange={() => {
                      handleCheckboxChange('checkbox3')
                      // reset({consultationChargePlanAmountValue:"",
                      // consultaionChargePlanAmountPercentage:"",
                      // consultationChargeMaxDiscount:"",
                      // restOfBillCouponAmountPercentage:"",
                      // restOfBillCouponAmountValue:"",
                      // restOfBillMaxDiscount:""})
                      setValue("consultationChargePlanAmountValue","",{ shouldValidate: true })
                      setValue("consultaionChargePlanAmountPercentage","",{ shouldValidate: true })
                      setValue("consultationChargeMaxDiscount","",{ shouldValidate: true })
                      setValue("restOfBillCouponAmountPercentage","",{ shouldValidate: true })
                      setValue("restOfBillCouponAmountValue","",{ shouldValidate: true })
                      setValue("restOfBillMaxDiscount","",{ shouldValidate: true })
                    }}
                  />
                }
                label="For Total Bill"
              />
              <Grid
                container
                rowSpacing={1}
                sx={{ mt: "5px" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={8}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      // disabled={!totalBill}
                      disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                      className="formlabel"
                    >
                      Coupon Discount
                    </FormLabel>
                    <Box sx={{ display: "flex" }}>
                      <ToggleButtonGroup
                        color="primary"
                        value={totalbillToggle}
                        exclusive
                        aria-label="Platform"
                        onChange={(e) => {
                          setTotalBillToggle(e.target.value);
                          if(e.target.value==="inpercentage"){
                            // reset({totalBillPlanAmountValue:"",})
                            setValue("totalBillPlanAmountValue","",{ shouldValidate: true })
                          }
                          if(e.target.value==="fixedamount"){
                            // reset({totalBillPlanAmountPercentage:"",totalBillMaxDiscount:""})
                            setValue("totalBillPlanAmountPercentage","",{ shouldValidate: true })
                            setValue("totalBillMaxDiscount","",{ shouldValidate: true })
                          }
                        }}
                      >
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="inpercentage"
                          size="small"
                          // disabled={!totalBill}
                          disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                        >
                          In Percentage
                        </ToggleButton>
                        <ToggleButton
                          sx={{
                            "&.Mui-selected, &.Mui-selected:hover": {
                              color: "#FFFFFF",
                              backgroundColor: "#2D256C",
                            },
                          }}
                          value="fixedamount"
                          size="small"
                          // disabled={!totalBill}
                          disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                        >
                          Fixed Amount
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormControl sx={{ width: "65%" }}>
                        {totalbillToggle === "inpercentage" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                            sx={{
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                            }}
                            type="text"
                            InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                            placeholder="%"
                            {...register("totalBillPlanAmountPercentage")}
                          />
                          {(!consultaion || !restofBill)&&<span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.totalBillPlanAmountPercentage?.message}
                          </span>}
                          </>
                          }
                        {totalbillToggle === "fixedamount" &&
                          <>
                          <TextField
                            size="small"
                            // disabled={!totalBill}
                            disabled={selectedCheckbox.includes('checkbox3') ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                            placeholder="Rs."
                            type="number"
                            {...register("totalBillPlanAmountValue")}
                          />
                            {(!consultaion || !restofBill)&&<span style={{ color: "red",fontSize:"10px" }} className="error">
                              {errors.totalBillPlanAmountValue?.message}
                            </span>}
                          </>
                        }
                      </FormControl>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#2D256C",
                      }}
                      className="formlabel"
                      // disabled={!totalBill}
                      disabled={(selectedCheckbox.includes('checkbox3') && totalbillToggle==="inpercentage") ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                    >
                      Maximum Discount Value
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Maximum Discount Value"
                      // disabled={!totalBill}
                      disabled={(selectedCheckbox.includes('checkbox3') && totalbillToggle==="inpercentage") ? selectedCheckbox.includes('checkbox1') ||selectedCheckbox.includes('checkbox2'):[]}
                      type="number"
                      {...register("totalBillMaxDiscount")}
                    />
                    {(!consultaion || !restofBill)&&<span style={{ color: "red",fontSize:"10px" }} className="error">
                          {errors.totalBillMaxDiscount?.message}
                    </span>}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {/* ----------------------------Free Consultation--------------------------- */}
            {/* <Box sx={{ mt: "10px" }}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#D83F87",
                    fontWeight: "bold",
                  },
                }}
                required
                control={
                  <Checkbox
                    size="small"
                    onClick={(e) => setFreeConsultation(e.target.checked)}
                  />
                }
                label="Free Consultation"
              />
              <br />

              <FormControl sx={{ width: "35%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                  disabled={!freeConsultation}
                >
                  No. Of Free Consultation
                </FormLabel>
                <TextField
                  className="inputbox"
                  size="small"
                  placeholder="No. Of Free Consultation"
                  type="number"
                  disabled={!freeConsultation}
                  {...register("noOfFreeConsulation")}
                />
                <span style={{ color: "red",fontSize:"10px" }} className="error">
                          {errors.noOfFreeConsulation?.message}
                </span>
              </FormControl>
            </Box> */}
          </Card>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
            <Button
              disabled={isFormSubmitted}
              sx={{
                backgroundColor: isFormSubmitted?"#898989":"#2d256c",
                textAlign: "center",
                color: "#FFFFFF",
                textTransform: "capitalize",
                fontSize: "12px",
                padding: "8px 15px",
                "&:hover": { backgroundColor: "#2d256c!important" },
              }}
              size="small"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default AddCoupon;
