import React, { useEffect, useState } from "react";
import "../../styles/AppointmentInfo.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Card, Modal, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCustomerAppointmentDetailsAndHistory,
  getPreviousAppointments,
} from "../../services/clinicOnboarding";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import moment from "moment";
import {
  checkPaymentStatusForAppointmentId,
  processAppointmentPayment,
} from "../../services/PlansAndProcedure";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const AppointmentInfo = () => {
  const [appointmentStatus, setAppointmentStatus] = useState(false);
  const [previousAppointments, setPreviousAppointments] = useState({});
  const [appointmentInfo, setAppointmentInfo] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let appointmentId = appointmentInfo[0]?.appointmentId;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  // console.log("iiiiiiiiiiiiiiiiiiiiiiii",userId)
  const token = JSON.parse(localStorage.getItem("token"));
  const clinicUniqueId = token?.uniqueId;
  // console.log("clinicunique", clinicUniqueId, appointmentId);
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions = clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let StartAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Start Appointment"
  )?.permission;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function checkStatus() {
    const { data } = await checkPaymentStatusForAppointmentId(appointmentId);
    // console.log("data: ", data);
    if (data.success === true) {
      // window.open(data?.data?.instrumentResponse?.redirectInfo?.url,"_self")
      toast.success("Status Completed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate(`/user/paymentStatus/${data?.data?.merchantTransactionId}`);
    } else {
      toast.warning("Status Pending", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  async function payViaClinic() {
    const { data } = await processAppointmentPayment(appointmentId);
    // console.log("data: ", data);
    if (data.success === true) {
      setIsSubmitting(true);
      window.open(data?.data?.instrumentResponse?.redirectInfo?.url, "_self");
    } else {
      toast.error("Cannot be processed at the moment", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSubmitting(false);
    }
  }
  const AppointmentInfo = async () => {
    try {
      const res = await getCustomerAppointmentDetailsAndHistory(userId);
      let data = res.data;
      // console.log("appointment Info", data);
      let newdata = data.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      // console.log("today clinic new data", newdata);
      setAppointmentInfo(newdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getPreviousAppointmentsData = async () => {
    try {
      const res = await getPreviousAppointments(clinicUniqueId, appointmentId);
      if (res && res.data) {
        setPreviousAppointments(res.data);
        setAppointmentStatus(true);
      } else {
        setAppointmentStatus(false);
      }
      // console.log("prev appointments", res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      await AppointmentInfo();
      if (appointmentId) {
        await getPreviousAppointmentsData();
      }
    };

    fetchData();
  }, [appointmentId]);
  return (
    <div>
      <Box>
        <Typography
          className="apoointinfo"
          sx={{ fontSize: "17px" }}
          variant="h5"
        >
          Appointment Information
        </Typography>
        <Card className="appointmentmaincard">
          {appointmentInfo.map((el, id) => {
            return (
              <Card className="leftcard">
                <Box className="headbox">
                  <Box className="subbox1">
                    <Typography variant="h5">Appointment ID</Typography>
                    <Typography variant="h4">{el.appointmentId}</Typography>
                  </Box>
                  <Box className="subbox2">
                    {StartAppointmentPermission &&
                      el.appointmentStatus === "Accepted" && (
                        <Button
                          sx={{
                            backgroundColor: "#00984F",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            borderRadius: "3px",
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "3px",
                            "&:hover": { backgroundColor: "#00984F!important" },
                          }}
                          onClick={() =>
                            navigate(
                              `/user/startappointment/${el.specialty}/${el.appointmentId}/${el.customerUniqueId}/${el.patientNumber}/${el.planName}/${el?.member}`
                            )
                          }
                        >
                          <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                          Start Appointment
                        </Button>
                      )}

                    {StartAppointmentPermission &&
                      el.appointmentStatus === "Billing Completed" && (
                        <Button
                          sx={{
                            backgroundColor: "#00984F",
                            textAlign: "left",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            borderRadius: "3px",
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "3px",
                            "&:hover": { backgroundColor: "#00984F!important" },
                          }}
                          onClick={() => {
                            handleOpen();
                          }}
                        >
                          <CalendarMonthIcon sx={{ fontSize: "0.9rem" }} />{" "}
                          Proceed to Payment
                        </Button>
                      )}
                  </Box>
                </Box>
                <Box className="bodybox">
                  <Box className="datetimebox">
                    <Box className="datebox">
                      <Typography variant="h5">Appointment Date</Typography>
                      <Typography variant="h4">
                        {formattedDate(el.date)}
                      </Typography>
                    </Box>
                    <Box className="timebox">
                      <Typography variant="h5">Appointment Time</Typography>
                      <Typography variant="h4">{el.time}</Typography>
                    </Box>
                  </Box>
                  <Box className="specialitybox">
                    <Typography
                      sx={{
                        fontSize: "10px",
                        backgroundColor: "#FFEFF9",
                        padding: "5px 10px",
                        color: "gray",
                        textAlign: "center",
                      }}
                    >
                      {el.specialty}
                    </Typography>
                  </Box>
                  <Box className="remarksbox">
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{el.remark}</Typography>
                  </Box>
                </Box>
              </Card>
            );
          })}
          <Card className="rightcard">
            <Box className="rightheadbox">
              <Box className="subbox1">
                <Typography variant="h5">
                  {previousAppointments?.customerName}
                </Typography>
                <Typography variant="h4" sx={{ display: "flex", gap: "5px" }}>
                  <PhoneIcon style={{ fontSize: "12px" }} />{" "}
                  {previousAppointments?.customerNo}
                </Typography>
              </Box>
            </Box>
            <Box className="rightbodybox">
              <Typography variant="h4">Previous Appointments</Typography>
              <Box className="previousAppointmentmainBox">
                {appointmentStatus ? (
                  <>
                    {previousAppointments?.perviousAppointmentResponse
                      ?.filter(
                        (appointment) =>
                          appointment.appointmentStatus == "Completed"
                      )
                      .map((el, id) => {
                        return (
                          <Card
                            className="appointmentscard"
                            onClick={() =>
                              navigate(
                                `/user/pastappointmentinformation/${el.appointmentId}`
                              )
                            }
                          >
                            <Box className="cardhead">
                              <Typography variant="h5">
                                {el.appointmentId}
                              </Typography>
                              <VisibilityIcon
                                sx={{ cursor: "pointer" }}
                                // onClick={handleOpen}
                              />
                            </Box>
                            <Box className="cardbodybox">
                              <Box className="datebox">
                                <CalendarMonthIcon />
                                <Typography variant="h4">
                                  {formattedDate(el.createdAt)}
                                </Typography>
                              </Box>
                              {/* <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              mt: "10px",
                              color: "#2D256C",
                            }}
                            variant="h4"
                          >
                            {el.clinicname}
                          </Typography> */}
                              <Box className="specialitybox">
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    backgroundColor: "#FFEFF9",
                                    padding: "5px",
                                    color: "gray",
                                    textAlign: "center",
                                  }}
                                >
                                  {el.speciality}
                                </Typography>
                              </Box>
                              {/* <Box className="analysisbox">
                            <Typography>
                              <b>Doctor’s Analysis </b>
                              {el.doctoranalysis}
                            </Typography>
                          </Box> */}
                            </Box>
                          </Card>
                        );
                      })}
                  </>
                ) : (
                  <Box>
                    <Typography sx={{ fontSize: "12px", mt: "10px" }}>
                      No history found
                    </Typography>{" "}
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
        </Card>

        {/* ----------------------------Modal----------------------------- */}

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Modal> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBottom: "10px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#2D256C",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
                    checkStatus();
                  }}
                >
                  Check Status
                </Button>
                <Button
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: isSubmitting ? "#898989" : "#D83F87",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#2D256C!important" },
                  }}
                  size="small"
                  onClick={() => {
                    payViaClinic();
                  }}
                >
                  Pay Here
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#2D256C",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    padding: "10px",

                    "&:hover": { backgroundColor: "#D83F87!important" },
                  }}
                  size="small"
                  onClick={() => {
                    navigate(`/user/clinicappointments/today`);
                  }}
                >
                  Back to Appointments
                </Button>
              </div>
            </>
            {/* )} */}
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default AppointmentInfo;
