import React from 'react'
import AllClinicListCard from './AllClinicListCard'

const AllClinics = () => {
  return (
    <div>
        <AllClinicListCard/>
    </div>
  )
}

export default AllClinics