import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AppointmentSearchForCSE,
  getClinicListNames,
} from "../../services/customerOnboarding";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import CseTableCard from "./CseTableCard";

const Schema = yup.object().shape({
  appointmentId: yup.string(),
  clientNumber: yup.number(),
  clientName: yup.string(),
  clinicName: yup.string(),
  tronId: yup.string(),
  fromDate: yup.string(),
  toDate: yup.string(),
  status: yup.string(),
});
const Appointments = () => {
  const [clinicNames, setClinicNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [acceptReject,setAcceptReject] = useState(false);
  const [searchClicked,setSearchClicked] = useState(false);
  const [cseSearchData,setCseSearchData]=useState([]);

  const {
    values,
    // touched,
    // errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      appointmentId: "",
      clientNumber: "",
      clientName: "",
      clinicName: "",
      tronId: "",
      fromDate: "",
      toDate: "",
      status: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const handleFromDate = (date) => {
    let currentdate = date?.$D;
    let month = date?.$M + 1;
    let year = date?.$y;
    const entireDate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setFromDate(entireDate);
    setFieldValue("fromDate", entireDate);
  };
  // console.log("fromdate",fromDate);

  const handleToDate = (date) => {
    let currentdate = date?.$D;
    let month = date?.$M + 1;
    let year = date?.$y;
    const entireDate = `${year}-${month > 9 ? month : "0" + month}-${
      currentdate > 9 ? currentdate : "0" + currentdate
    }`;
    setToDate(entireDate);
    setFieldValue("toDate", entireDate);
  };

  async function onSubmit(values, actions) {
    console.log("values", values);

    const payload={
      "appointmentId": values.appointmentId,
      "clientNumber": values.clientNumber,
      "clientName": values.clientName,
      "clinicName": values.clinicName,
      "tronId": values.tronId,
      "fromDate":values.fromDate,
      "toDate":values.toDate,
      "status": values.status
    }

    try {
      const res=await AppointmentSearchForCSE(payload);
      const data=res.data;
      let sortedData=data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
      
        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`);
      });
      let newdata=sortedData.map((el)=>{
        el.time=modifiedTime(el.time);
        function modifiedTime(timing){
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];
      
          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;
      
          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;
      
          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";
      
          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
      return el
      })
      setCseSearchData(newdata)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    const fetchClinicNames = async () => {
      setLoading(true);
      try {
        const response = await getClinicListNames();
        const data = response.data;
        setClinicNames(data); 
      } catch (error) {
        console.error("Error fetching clinic names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClinicNames();
  }, []);
  return (
    <div>
      <Box>
        <Typography variant="h5" fontSize="17px" fontWeight="600">
          Appointments
        </Typography>
        <Card sx={{mt:"10px"}}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={3} sm={3} xs={6}>
                <TextField
                  size="small"
                  placeholder="Appointment Id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="appointmentId"
                  id="appointmentId"
                  sx={{ backgroundColor: "#ffffff" }}
                  value={values.appointmentId}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} sm={3} xs={6}>
                <TextField
                  size="small"
                  placeholder="Client Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="clientNumber"
                  id="clientNumber"
                  sx={{ backgroundColor: "#ffffff" }}
                  value={values.clientNumber}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} sm={3} xs={6}>
                <TextField
                  size="small"
                  placeholder="Client Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="clientName"
                  id="clientName"
                  sx={{ backgroundColor: "#ffffff" }}
                  value={values.clientName}
                  fullWidth
                />
              </Grid>

              <Grid item md={3} sm={3} xs={6}>
                <Autocomplete
                  size="small"
                  options={clinicNames}
                  loading={loading}
                  getOptionLabel={(option) => option.clinicName} // Specify the property to display
                  onChange={(e, newValue) =>
                    setFieldValue(
                      "clinicName",
                      newValue ? newValue.clinicUniqueId : ""
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Clinic"
                      name="clinicName"
                      id="clinicName"
                      sx={{ backgroundColor: "#ffffff" }}
                      value={values.clinicName}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={3} sm={3} xs={6}>
                <TextField
                  size="small"
                  placeholder="Tron Id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="tronId"
                  id="tronId"
                  sx={{ backgroundColor: "#ffffff" }}
                  value={values.tronId}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} sm={3} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    maxDate={toDate}
                    onChange={(date) => {
                      handleFromDate(date);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="From Date"
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} sm={3} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  inputFormat="DD/MM/YYYY"
                    value={toDate}
                    minDate={fromDate}
                    onChange={(date) => {
                      handleToDate(date);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="To Date"
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={3} sm={6}>
                <Select
                  // label="status"
                  placeholder="Select"
                  size="small"
                  onChange={(selectedOption) => {
                    handleChange(selectedOption);
                  }}
                  // className="inputbox"
                  // MenuProps={{ style: { maxHeight: 250, maxWidth: 250 } }}
                  // sx={{ minWidth: 240 }}
                  name="status"
                  id="status"
                  value={values.status}
                  fullWidth
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="upcoming">Upcoming</MenuItem>
                  <MenuItem value="past">Past</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                  <MenuItem value="appointmentRequests">
                    Appointment Request
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                mt: "10px",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#2d256c",
                  textAlign: "center",
                  color: "#FFFFFF!important",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  cursor:!values.appointmentId &&
                  !values.clientNumber &&
                  !values.clientName &&
                  !values.clinicName &&
                  !values.tronId &&
                  !values.fromDate &&
                  !values.toDate &&
                  !values.status
                    ? "default" // Text color for disabled state
                    : "pointer",
                  "&:hover": { backgroundColor: "#2d256c!important" },
                }}
                type="submit"
                onClick={()=>setSearchClicked(true)}
                disabled={
                  !values.appointmentId &&
                  !values.clientNumber &&
                  !values.clientName &&
                  !values.clinicName &&
                  !values.tronId &&
                  !values.fromDate &&
                  !values.toDate &&
                  !values.status
                }
              >
                Search
              </Button>

              <Button
                sx={{
                  backgroundColor: "#444444",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  padding: "8px 15px",
                  borderRadius: "3px",
                  "&:hover": { backgroundColor: "#444444!important" },
                }}
                onClick={ () => {
                  resetForm()
                  setCseSearchData([])
                  setFromDate(null)
                  setToDate(null)
                  setSearchClicked(false)
                }}
                type="reset"
              >
                Reset
              </Button>
            </Box>
          </form>
        </Card>

        <CseTableCard searchClicked={searchClicked} handleSubmit={handleSubmit} acceptReject={acceptReject} setAcceptReject={setAcceptReject} cseSearchData={cseSearchData} setCseSearchData={setCseSearchData} />
      </Box>
    </div>
  );
};

export default Appointments;
