import http from "./httpServices";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token = JSON.parse(tokens)?.token;
const id = JSON.parse(tokens)?.uniqueId;
const username = JSON.parse(tokens)?.username;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function createOrEditRole(data){
    return http.post(config.apiUrl + "createOrEditRole", data, head);
}
export function checkRoleUniqueness(data){
  return http.get(config.apiUrl + "checkRoleUniqueness/"+data, head);
}
export function roles(){
  return http.get(config.apiUrl + "roles", head);
}
export function getRoleById(id){
  return http.get(config.apiUrl + "getRoleById/"+id, head);
}
export function getRolePermissions(id){
  return http.get(config.apiUrl + "getRolePermissions/"+id, head);
}
export function checkRoleUniquenessOnEdit(id,name){
  return http.get(config.apiUrl + "checkRoleUniquenessOnEdit/"+id+"/"+name, head);
}
