import { Autocomplete, Button, Card, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { CssTextField } from '../../styles/textFields';


const Payment = ({plan, duration,setDuration,familyInfo,submitToProceed,setSelected,isSubmitting}) => {

  let Rupees = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
});
  console.log('familyInfo: ', familyInfo);
    return ( <>
    <Card>

        <div style={{margin:"20px", padding:"30px", border:"2px solid #D3D3D3" ,borderRadius:"12px"}}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
            <Typography variant="h5"sx={{color:"#D83F87",fontWeight:"800",marginTop:"24px"}}>{plan?.planName} </Typography>
           
            </Grid>
            <Grid item xs={6}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
        Duration Of Plan (in Years)
      </Typography>
            <Autocomplete
            disableClearable
            options={[1,2,3,4,5]}
            onChange={(event, value) => {
                        setDuration(value)
            }}
            value={duration}
            fullWidth
            size="small"
            renderInput={(params) => (
              <CssTextField
                // disabled={id && !edit ? true : false}
                {...params}
                
                placeholder="Duration(in Years)"
                variant="outlined"
                type="text"
              />
            )}
          />
            </Grid>
          </Grid>
            

        </div>
        <div style={{borderRadius:"12px", border:"2px solid #D3D3D3",margin:"20px"}}>
          <div style={{backgroundColor:"#2F0770", color:"#fff", padding:"5px", borderRadius:"12px 12px 0px 0px"}}>
            <Typography variant="h6">
              Payment Details
            </Typography>
          </div>
          <Grid container spacing={2} sx={{padding:"30px"}}>
            {[
              {title:"Price Per Person", value: Rupees.format(parseInt(plan?.planDiscountPrice)*12)},
              {title:"Number Of Members", value:parseInt(familyInfo?.length)},
              {title:"Total Price", value:Rupees.format(parseInt(familyInfo?.length)*parseInt(plan?.planDiscountPrice)*12*parseInt(duration))},
              ].map((item,index)=><React.Fragment key={index}>
                <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="400" fontSize="14px">{item.title}</Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="600" fontSize="14px">{item.value}</Typography>
                </Grid>
                
              </React.Fragment>)}
          </Grid>
              <Divider/>
              <Grid container spacing={2}  sx={{padding:"20px"}}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="400" fontSize="14px">Discount</Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="600" fontSize="14px">₹ 0.00</Typography>
                </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="400" fontSize="14px">Taxes</Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography variant="subtitle2" fontWeight="600" fontSize="14px">{Rupees.format((parseInt(familyInfo?.length)*parseInt(plan?.planDiscountPrice)*12*parseInt(duration)*18)/100)} </Typography>
                </Grid>
              </Grid>
              <Divider/>
              <Grid container spacing={2}  sx={{padding:"20px"}}>
                <Grid item xs={6}><Typography variant='h6'fontWeight="bold">Total</Typography></Grid>
                <Grid item xs={6}><Typography variant='h6'fontWeight="bold"> {Rupees.format(parseInt(familyInfo?.length)*parseInt(plan?.planDiscountPrice)*12*parseInt(duration)+(parseInt(familyInfo?.length)*parseInt(plan?.planDiscountPrice)*12*parseInt(duration)*18)/100)}</Typography></Grid>
              </Grid>
        </div>

        <br/>
      <br/>

      <div  style={{display:"flex", alignContent:"space-between"}}>

            <Button
          variant="contained"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",
          }}
          onClick={()=>setSelected("Health Info")}
    
        >
    Back </Button>
            <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          sx={{
            width: "15em",
            backgroundColor: isSubmitting?"#898989":"#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",
          }}
          onClick={()=>submitToProceed()}
    
        >
     Proceed To Payment   </Button>
      </div>
     
    </Card>
    </> );
}
 
export default Payment;