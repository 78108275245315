import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Verification from "./Verification";
import BasicInfo from "./BasicInfo";
import Plans from "./Plans";
import FamilyInfo from "./FamilyInfo";
import HealthInfo from "./HealthInfo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Payment from "./Payment";
import BasicInfopink from "../../images/BasicInfopink.png";
import BasicInfoN from "../../images/BasicInfo.png";
import Family from "../../images/Familuy.png";
import FamilyPink from "../../images/Family-Pink.png";
import Health from "../../images/health.png";
import HealthPink from "../../images/health-Pink.png";
import VerificationN from "../../images/Verification.png";
import VerificationPink from "../../images/Verification-pink.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlansN from "../../images/Plans.png";
import PlansPink from "../../images/Plans-Pink.png";
import PaymentN from "../../images/Payment.png";
import PaymentPink from "../../images/wallet-Pink.png";
import walletblue from "../../images/walletblue.svg";
import Familyblue from "../../images/Family blue.svg";
import healthblue from "../../images/healthblue.svg";
import Plansblue from "../../images/Plansblue.svg";
import BasicInfoblue from "../../images/BasicInfoblue.svg";
import Verificationblue from "../../images/Verificationblue.svg";
import {
  addB2CCustomerDetails,
  getActiveRetailPlanAndPricingList,
} from "../../services/login";
import { WindowSharp } from "@mui/icons-material";
import Footer from "../Footer";

const Landing = () => {
  const steppers = [
    "Basic Info",
    "Verification",
    "Plans",
    "Family Info",
    "Health Info",
    "Payment",
  ];

  const stepsDesc = [
    {
      step: "Eligibility Check",
      desc: "Verify service availability in your area using a pin code to ensure you can access our insurance services.",
    },

    {
      step: "Mobile Verification",
      desc: "Secure your account by confirming your mobile number with a OTP and providing your name.",
    },

    {
      step: "Plan Selection",
      desc: "Choose the insurance plan that best fits your needs from a selection of five different options.",
    },

    {
      step: "Member Details",
      desc: "Provide the names, dates of birth, and relationships of all individuals for whom the insurance is being purchased.",
    },

    {
      step: "Health Assessment",
      desc: "Fill out essential health information for each covered member to customize the policy.",
    },

    {
      step: "Payment",
      desc: "Complete your insurance purchase securely and conveniently by making the required payment.",
    },
  ];

  const stepperImage = [
    { name: "Basic Info", image: BasicInfoN, imageSelected: BasicInfoblue },
    {
      name: "Verification",
      image: VerificationN,
      imageSelected: Verificationblue,
    },
    { name: "Plans", image: PlansN, imageSelected: Plansblue },
    { name: "Family Info", image: Family, imageSelected: Familyblue },
    { name: "Health Info", image: Health, imageSelected: healthblue },
    { name: "Payment", image: PaymentN, imageSelected: walletblue },
  ];

  const [selected, setSelected] = React.useState("Basic Info");
  const [plan, setPlan] = React.useState();
  const [pincode, setPincode] = React.useState();
  const [number, setNumber] = React.useState();
  const [oTP, setOTP] = React.useState();
  const [payment, setPayment] = React.useState();
  const [plantype, setPlanType] = React.useState();
  const [familyPlans, setFamilyPlans] = React.useState();
  const [indivisualPlans, setIndivisualPlansPlans] = React.useState();
  const [isSubmitting,setIsSubmitting]=useState(false)
  console.log("indivisualPlans: ", indivisualPlans);

  const [duration, setDuration] = React.useState(1);
  console.log("plan: ", plan);
  const [familyInfo, setFamilyInfo] = React.useState([
    { relation: "Self", name: "", dob: "" },
    { relation: "", name: "", dob: "" },
    { relation: "", name: "", dob: "" },
    { relation: "", name: "", dob: "" },
  ]);

  useEffect(() => {
    plantype === "Indivisual"
      ? setFamilyInfo([{ relation: "Self", name: "", dob: "" }])
      : setFamilyInfo([
          { relation: "Self", name: "", dob: "" },
          { relation: "", name: "", dob: "" },
          { relation: "", name: "", dob: "" },
        ]);
  }, [plantype]);

  console.log("familyInfo: ", familyInfo);
  const [health, setHealth] = React.useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  async function getPlanDetails() {
    const { data } = await getActiveRetailPlanAndPricingList();
    console.log("data: ", data);
    if (data) {
      const indivisual = data.filter((item) => item.planType === "Individual");
      console.log("indivisual: ", indivisual);
      const family = data.filter((item) => item.planType === "Family");
      console.log("family: ", family);
      setIndivisualPlansPlans(indivisual);
      setFamilyPlans(family);
    }
  }

  React.useEffect(() => {
    getPlanDetails();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const selectedIndex = steppers.indexOf(selected);
  console.log("selectedIndex: ", selectedIndex);

  const submitToProceed = async () => {
 var windowReference= window.open();
   
    console.log("Health", health);
    const val = {
      name: name,
      pincode: pincode,
      number: number,
      email: email,
      planUniqueId: plan?.uniqueId,
      duration: duration,
      pricePerPerson: parseInt(plan?.planDiscountPrice) * 12,
      noOfMembers: parseInt(familyInfo?.length),
      totalPrice:
        parseInt(familyInfo?.length) *
        parseInt(plan?.planDiscountPrice) *
        12 *
        parseInt(duration),
      discount: 0,
      taxes:
        (parseInt(familyInfo?.length) *
          parseInt(plan?.planDiscountPrice) *
          12 *
          parseInt(duration) *
          18) /
        100,
      total:
        parseInt(familyInfo?.length) *
          parseInt(plan?.planDiscountPrice) *
          12 *
          parseInt(duration) +
        (parseInt(familyInfo?.length) *
          parseInt(plan?.planDiscountPrice) *
          12 *
          parseInt(duration) *
          18) /
          100,
      b2cCustomerMemberDetailsRequest: health,
    };
    try {
      const { data } = await addB2CCustomerDetails(val);
      console.log("data: ", data);
      if(data){
        setIsSubmitting(true)
   windowReference.location=data?.data?.instrumentResponse?.redirectInfo?.url;
      
   }
    } catch (e) {
      console.log(e);
      setIsSubmitting(false)
    }
  };

  return (
    <div className="b2ccontent">
      <Navbar />
      <div
        style={{
          // backgroundColor: "#1A0655",
          minHeight: "100vh",
          padding: width > 786 ? "200px 200px 0px 200px" : "80px 10px 0px 10px",
        }}
        className="myFont"
      >
        <Grid container spacing={2}>
          (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {stepperImage.map((item, index) => (
                <Grid key={index} item xs={2}>
                  <div
                    style={{
                      backgroundColor:
                        selectedIndex >= index ? "#D83F87" : "#fff",
                      textAlign: "center",
                      padding: selectedIndex === index ? "20px" : "15px",
                      borderRadius: "8px",
                      maxWidth: "80px",
                    }}
                  >
                    <img
                      style={{
                        height: width > 786 ? "80%" : "100%",
                        width: width > 786 ? "80%" : "100%",
                      }}
                      src={
                        selected === item.name
                          ? item?.image
                          : item?.imageSelected
                      }
                      alt="Header Images"
                    ></img>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          )
          <Grid item xs={12}>
            <div style={{ display: "flex", borderRadius: "12px" }}>
              <div
                style={{
                  backgroundColor: "#D83F87",
                  padding: "15px",
                  textAlign: "center",
                  borderRadius: "12px 0px 0px 12px",
                }}
              >
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  {selectedIndex + 1}
                </Typography>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "10px 10px 5px 15px",
                  borderRadius: "0px 12px 12px 0px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#D83F87", fontWeight: "bold" }}
                >
                  {stepsDesc[selectedIndex].step}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "#434343" }}>
                  <i>{stepsDesc[selectedIndex].desc}</i>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={11} p={width > 786 ? "5" : "1"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {selected === "Basic Info" && (
                <BasicInfo
                  selected={selected}
                  setSelected={setSelected}
                  pincode={pincode}
                  setPincode={setPincode}
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                />
              )}
              {selected === "Verification" && (
                <Verification
                  selected={selected}
                  setSelected={setSelected}
                  number={number}
                  setNumber={setNumber}
                  oTP={oTP}
                  setOTP={setOTP}
                  setFamilyInfo={setFamilyInfo}
                  setHealth={setHealth}
                />
              )}
              {selected === "Plans" && (
                <Plans
                  selected={selected}
                  setSelected={setSelected}
                  plan={plan}
                  setPlan={setPlan}
                  setPlanType={setPlanType}
                  indivisualPlans={indivisualPlans}
                  familyPlans={familyPlans}
                  number={number}
                />
              )}
              {selected === "Family Info" && (
                <FamilyInfo
                  selected={selected}
                  setSelected={setSelected}
                  familyInfo={familyInfo}
                  width={width}
                  setFamilyInfo={setFamilyInfo}
                  planType={plantype}
                />
              )}
              {selected === "Health Info" && (
                <HealthInfo
                  selected={selected}
                  setSelected={setSelected}
                  health={health}
                  setHealth={setHealth}
                  familyInfo={familyInfo}
                />
              )}
              {selected === "Payment" && (
                <Payment
                  selected={selected}
                  setSelected={setSelected}
                  plan={plan}
                  duration={duration}
                  setDuration={setDuration}
                  familyInfo={familyInfo}
                  submitToProceed={submitToProceed}
                  setPayment={setPayment}
                  isSubmitting={isSubmitting}
                />
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
      <Footer/>
    </div>
  );
};

export default Landing;
