import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="privacypolicycontent">
      <Navbar />
      <div className="privacypolicy">
        <h1>Privacy Policy</h1>
        <br />

        <p>
          This privacy statement applies to www.Tron.health owned and operated
          by Tron Healthcare Pvt Ltd.. This sets out how Tron uses and protects
          any information that you provide to “Tron Healthcare Pvt Ltd.” using
          our website www.tron.health.
        </p>
        <br />
        <p>
          Tron Healthcare Pvt Ltd. is committed to ensuring that your privacy is
          protected. Should we ask you to provide certain information by which
          you can be identified when using www.tron.health, please be rest
          assured that the information will only be used in accordance with this
          privacy policy
        </p>
        <br />
        <br />
        <h2>What We Do With The Information We Gather</h2>
        <br />
        <h3>We require this information for the following reasons :</h3>
        <br />
        <p>
          To understand your needs and serve you better. We may use the
          information to improve our services. We may periodically send
          promotional emails about new products, special offers or other
          information that may interest you. From time to time, we may use your
          information to contact you for market research purposes. We will do
          this by using your contact details including email, phone, fax or
          postal address. We may use the information to customise the website
          according to your interests
        </p>

        <br />

        <h3>Security</h3>
        <br />
        <p>
          Tron endeavours to take reasonable and appropriate measures to protect
          your personal information from unauthorised access or disclosure.{" "}
        </p>
        <br />

        <h3>Facebook & Twitter </h3>
        <br />
        <ol>
          <li>
            The contents on the Facebook/Twitter page/account or any other
            Social Media page belonging to Tron are for general reference
            purposes and do not constitute any binding commitment or the
            specifically endorsed views of Tron Healthcare Pvt Ltd. (Company).
            The views expressed by other persons, followers and 3rd parties on
            this page are individual opinions and the Company does not take
            responsibility for the same.
          </li>
          <b />
          <li>
            The Company reserves the right to use information received from any
            user or 3rd party on the page for its own purposes including but not
            limited to brand communication & product development.
          </li>
        </ol>
        <br />
        <h3>Changes To Policy</h3>
        <br />
        <p>
          Tron, anytime can change, modify or remove portions of this policy at
          its sole discretion without any prior written notice to you. It will
          be your responsibility to review the policy for updates/changes. Your
          continued use of the Services will mean your acceptance and agreement
          to the revisions. By visiting and using this site you confirm that you
          are aware of the privacy policy.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
