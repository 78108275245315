import { Card, Grid, Switch, Typography } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { CssTextField } from "../../styles/textFields";
import { useParams } from "react-router-dom";

const ClinicWorkingHours = ({ fields, setFields, edit }) => {
  const { id } = useParams();

  const changeTimings = (e, i, name) => {
    const values = [...fields];
    console.log("values: ", values);
    values[i][name] = e;
    setFields(values);
  };
  return (
    <>
      <Card>
        <div className="workingHours">
          <Grid container spacing={2} className="grids">
            <Grid item xs={3}></Grid>
            <Grid item xs={5}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Morning Timings
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                Evening Timings
              </Typography>
            </Grid>
            {fields.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={1}>
                  <Switch
                    checked={item.status}
                    disabled={id && !edit ? true : false}
                    onChange={() => {
                      const values = [...fields];
                      values[index].status = !values[index].status;
                      setFields(values);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    mt={1}
                    mb={1}
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {item?.week}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <MobileTimePicker
                    //    defaultValue={}
                    disabled={id && !edit ? true : false}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Select Time"
                        size="small"
                        name={item.morningStartTime}
                        fullWidth
                        variant="outlined"
                        focused
                      />
                    )}
                    name={item.morningStartTime}
                    onChange={(e) => {
                      changeTimings(e, index, "morningStartTime");
                    }}
                    value={item.morningStartTime}
                    label="Select Time"
                  />
                </Grid>
                <Grid item xs={2}>
                  <MobileTimePicker
                    //    defaultValue={}

                    disabled={id && !edit ? true : false}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Select Time"
                        size="small"
                        name={item.morningEndTime}
                        fullWidth
                        variant="outlined"
                        focused
                      />
                    )}
                    onChange={(e) => {
                      changeTimings(e, index, "morningEndTime");
                    }}
                    value={item.morningEndTime}
                    label="Select Time"
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {" "}
                  <MobileTimePicker
                    //    defaultValue={}

                    disabled={id && !edit ? true : false}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Select Time"
                        size="small"
                        name={item.eveningStartTime}
                        fullWidth
                        variant="outlined"
                        focused
                      />
                    )}
                    onChange={(e) => {
                      changeTimings(e, index, "eveningStartTime");
                    }}
                    value={item.eveningStartTime}
                    label="Select Time"
                  />
                </Grid>
                <Grid item xs={2}>
                  <MobileTimePicker
                    //    defaultValue={}

                    disabled={id && !edit ? true : false}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Select Time"
                        size="small"
                        name={item.eveningEndTime}
                        fullWidth
                        variant="outlined"
                        focused
                      />
                    )}
                    name={item.eveningEndTime}
                    onChange={(e) => changeTimings(e, index, "eveningEndTime")}
                    value={item.eveningEndTime}
                    label="Select Time"
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default ClinicWorkingHours;
