import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../styles/OurOPDnetwork.scss";
import {
  Autocomplete,
  Box,
  Button,
  FormLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import networkmap from "../assets/Mappp1.png";
import { useFormik } from "formik";
import * as yup from "yup";
import clinicdemo from "../assets/clinicdemopic.svg";
import kmimage from "../assets/km'slogo.svg";
import config from "../config.json";
import Loader from "./Loader";
import Footer from "./Footer";
import { searchClinicWithPincodeAndCity } from "../services/customerOnboarding";
import { getCitiesListForDropdown } from "../services/clinicOnboarding";
import { CssTextField } from "../styles/textFields";
import { toast } from "react-toastify";
import { addPotentialCustomer, getListOfClinicCities } from "../services/login";

const Schema = yup.object().shape({
  pincode: yup
    .number()
    .positive()
    .integer()
    .min(100000, "Please Enter Correct Pincode")
    .max(999999, "Pincode is Too Long"),
  city: yup.string(),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const OurOPDnetwork = () => {
  const [loading, setLoading] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [checkClicked, setCheckClicked] = useState(false);
  const [cityNames, setCityNames] = useState([]);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () =>{ 
    console.log("dhopen tjhe modal");
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    setName("")
    setNumber("")
    setEmail("")
  };
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      pincode: "",
      city: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    console.log("values", values);
    if (!values.pincode && values.city) {
      console.log("onlycity");
      setLoading(true);
      try {
        setCheckClicked(true)
        const res = await searchClinicWithPincodeAndCity(values);
        const data = res.data;
        if (data && data.length>0) {
          setClinicData(data);
          setLoading(false);
          setCheckClicked(false)
        }else{
          setClinicData([]);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setClinicData([]);
    }
    if(values.pincode && !values.city){
      console.log("onlypincode");
      setLoading(true);
      try {
        setCheckClicked(true)
        const res = await searchClinicWithPincodeAndCity(values);
        const data = res.data;
        if (data && data.length>0) {
          setClinicData(data);
          setLoading(false);
          setCheckClicked(false)
        }else{
          handleOpen();
          setClinicData([]);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    if (values.pincode && values.city) {
      console.log("both");
      setLoading(true);
      try {
        setCheckClicked(true)
        const res = await searchClinicWithPincodeAndCity(values);
        const data = res.data;
        if (data && data.length>0) {
          setClinicData(data);
          setLoading(false);
          setCheckClicked(false)
        }else{
          setClinicData([]);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  async function potentialClientsInfo() {
    const { data } = await addPotentialCustomer({
      name: name,
      mobileNumber: number,
      emailId:email,
      pincode: pincode,
    });
    console.log("data: ", data);
    if(data==="Sucessfully Added."){
      handleClose()
      toast.success("Sucessfully Submitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFieldValue("pincode","",true)
    }else{
      toast.error("Some Error Occoured, please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    const fetchCityNames = async () => {
      setLoading(true);
      try {
        const response = await getListOfClinicCities();
        const data = response.data;
        setCityNames(data);
      } catch (error) {
        console.error("Error fetching clinic names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCityNames();
  }, []);
  return (
    <div className="opdnetworkcontent">
      <Navbar />
      <Box className="ouropdnetworkmainbody">
        <Box className="headingbox">
          <h2>Tron OPD Network</h2>
          <p>
          Our OPD offerings are designed to provide you with the best of healthcare in your local area. 
          Enter your city and pincode to see your nearest clinic.
          </p>
        </Box>
        <Grid className="contentbody" container spacing={3}>
          <Grid item sm={6} xs={12} md={6} lg={6}>
            <Box className="leftbox">
              <img src={networkmap} alt="OPD-Service" />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} md={6} lg={6}>
            <Box className="rightbox">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormLabel className="formlabel">Pincode</FormLabel>
                    <TextField
                      size="small"
                      placeholder="Pincode"
                      onChange={(e)=>{
                        handleChange(e)
                        setPincode(e.target.value)
                      }}
                      onBlur={handleBlur}
                      name="pincode"
                      fullWidth
                      id="pincode"
                      sx={{ backgroundColor: "#ffffff" }}
                      value={values.pincode}
                    />
                    {errors.pincode && touched.pincode && (
                      <span style={{ color: "red", fontSize: "10px" }}>
                        {errors.pincode}
                      </span>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormLabel className="formlabel">City Name</FormLabel>
                    <Autocomplete
                      size="small"
                      options={cityNames}
                      loading={loading}
                      onChange={(e, newValue) =>
                        setFieldValue("city", newValue ? newValue : "")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select City"
                          name="city"
                          id="city"
                          sx={{ backgroundColor: "#ffffff" }}
                          value={values.city}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  sx={{
                    backgroundColor: "#2d256c",
                    textAlign: "center",
                    color: "#FFFFFF",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    padding: "8px 15px",
                    marginTop: "10px",
                    height: "40px",
                    borderRadius: "3px",
                    "&:hover": { backgroundColor: "#2d256c!important" },
                  }}
                  type="submit"
                >
                  Search
                </Button>
              </form>
              {clinicData?.length > 0 && (
                <p style={{ color: "#d83f87", fontSize: "14px", mt: "10px" }}>
                  {clinicData?.length} Clinics found in your area.
                </p>
              )}

              <Box className="clinicsContainer">
                {checkClicked && clinicData.length === 0 ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <p>No Results Found</p>
                  </Box>
                ) : (
                  <Box className="clinicsmainbox">
                    {clinicData?.map((el, id) => {
                      return (
                        <Box className="carddata">
                          <Box className="leftside">
                            {el.imageUrl === null ? (
                              <img src={clinicdemo} alt="clinicpic" />
                            ) : (
                              <img
                                src={config.imageUrl + el.imageUrl}
                                alt="clinicpic"
                                className="clinicmainpic"
                              />
                            )}
                          </Box>
                          <Box className="rightside">
                            <h5>
                              {el.clinicName}
                            </h5>
                            <h6>{el.category}</h6>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Box className="kmsbox">
                                {el.distance !== 0 ? (
                                  <>
                                    <img src={kmimage} alt="kmsimage" />
                                    <br />
                                    <p>
                                      {Math.round(parseInt(el.distance))} Kms
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                              <h6 className="address">
                                {el.address}
                              </h6>
                            </Box>
                            <Box className="specialitybox">
                              {el?.clinicType?.split(",").map((item, index) => (
                                <p key={index}>{item}</p>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                    {loading ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />

      {/* -----------------------------Modal----------------------------- */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            We're not available in this area yet, but we'll let you know once
            this pin code joins the Tron network.
          </Typography>
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Name
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Name"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Email
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Email"
            variant="outlined"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
            Mobile Number
          </Typography>
          <CssTextField
            fullWidth
            size="small"
            placeholder="Enter Number"
            variant="outlined"
            type="number"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <br />
          <br />
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "15em",
              backgroundColor: "#2d256c!important",
              fontSize: "12px",

              padding: "8px 15px",
              // marginLeft: "10px",
            }}
            onClick={() => name && email && number && potentialClientsInfo()}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default OurOPDnetwork;
