import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { Card } from '@mui/material';
import Appbar from './Appbar';
import Navbar from './Navbar';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { addCustomerFeedback, checkIfFeedbackSubmitted } from '../services/login';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Feedback = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const {id}= useParams()
    const navigate= useNavigate()

    const [userFriendliness,setUserFriendliness]=React.useState()
    const [experience,setExperience]=React.useState()
    const [starRating, setStarRating]=React.useState()
    const [check, setCheck]=React.useState(false)
    
  const [width, setWidth] = React.useState(window.innerWidth);

//   const handleWindowSizeChange = () => { setWidth(window.innerWidth) };

    console.log('starRating: ', starRating);

    const success = () => {
        toast.success("Succesfully Sent The Feedback", {
          position: toast.POSITION.TOP_RIGHT,
        });
      };

      const error = () => {
        toast.error("Some Error Occoured", {
          position: toast.POSITION.TOP_RIGHT,
        });
      };



    const onSubmit= async()=>{
        const val={
            "id":"",
            "appointmentId":id,
            "starRating":starRating,
            "experience":experience,
            "userFriendliness":userFriendliness
        }
        try{
            const {data}= await addCustomerFeedback(val);
            if(data==="Sucessfully Added Feedback."){
                success()
                navigate("/login")
            }
        }catch(e){
            error()
        }
    }

    React.useEffect(()=>{
        const checkId= async()=>{
            const {data}= await checkIfFeedbackSubmitted(id)
            if(data){
                setCheck(data)
            }
        }
        checkId()
    },[])

    
    return ( <>
    
    <Navbar /> 
    {!check?<div style={{backgroundColor:"#2D256C", height:"100vh", paddingTop:width>786 ? "120px":"25%"}}>

    <Card  sx={{marginLeft:"20px", marginRight:width>786 ? "40%":"20px", padding:"15px"}}>
    <Typography variant="h4" sx={{fontWeight:"bold", fontSize:"20px"}}>
                  Thank you for booking an appointment with tron.health
                </Typography>
    <br/>
    <Typography variant="subtitle2" sx={{fontWeight:"bold", fontSize:"12px"}}>
         Tell us about your experience for booking an appointment   
                </Typography>
                <Stack spacing={1}>
      <Rating name="half-rating"  value={starRating}
       onChange={(e)=>{setStarRating(e.target.value)}} 
       size="large"
       />
       </Stack>
       <br/>
    <Typography variant="subtitle2" sx={{fontWeight:"bold", fontSize:"12px"}}>
         Tell us about your experience for booking an appointment   
                </Typography>
                <TextField
          multiline
          sx={{width:"80%"}}

          value={experience}
          onChange={(e)=>setExperience(e.target.value)}
          rows={4}
        />
        <br/>
        <br/>
    <Typography variant="subtitle2" sx={{fontWeight:"bold", fontSize:"12px"}}>
         Share your feedback about the user friendliness of tron.healthcare interface   
                </Typography>
               
                <TextField
          multiline
          sx={{width:"80%"}}
          value={userFriendliness}
          onChange={(e)=>setUserFriendliness(e.target.value)}
          rows={4}
        />

<br/>
<br/>
<Button
                  variant="contained"
                  onClick={()=>onSubmit()}
                  sx={{ width: "15em", backgroundColor:"#D83F87" ,"&:hover":{backgroundColor:"#2d256c!important"}, fontSize:"12px",padding:"8px 15px" }}
                >
                  Submit
                </Button>
    </Card>
    </div>:<div style={{marginTop:"100px"}}>
        <Typography variant='h4' fontWeight="bold">Already Submitted The Feedback</Typography>
        </div>}
    </> );
}
 
export default Feedback;