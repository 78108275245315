import { Box, Card, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/ViewRetailCustomerdetails.scss";
import { TabContext, TabPanel } from "@material-ui/lab";
import { getB2CcustomerDetailsById } from "../../services/customerOnboarding";
import moment from "moment";

const ViewRetailCustomersDetails = () => {
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [customerProfileData,setCustomerProfileData]=useState({})
  let b2cCustomerMemberdetails=customerProfileData?.b2cCustomerDetails?.b2cCustomerMemberDetailsRequest;
  let retailPlanAndPricing=customerProfileData?.retailPlanAndPricing
  
  // console.log(id);
  // console.log(value);
  const handleChange = (event, newValue) => {
    // console.log(newValue)
    // navigate(`/user/viewRetailCustomerdetails/${newValue}`);
    setValue(newValue);
  };

  const getProfileDetails=async()=>{
    try {
      const res=await getB2CcustomerDetailsById(id);
      // console.log(res.data);
      setCustomerProfileData(res.data)
    } catch (error) {
      console.log(error.message);
    }
  }

  const formattedDate=(date)=>{
    const dob = moment(date)
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob
  }

  useEffect(()=>{
    getProfileDetails()
  },[])

  useEffect(() => {
    if (b2cCustomerMemberdetails?.length > 0 && !value) {
      setValue(b2cCustomerMemberdetails[0]?.name);
    }
  }, [b2cCustomerMemberdetails, value]);


  return (
    <div>
       <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12} sm={12}>
          <Card className="mainpersondetails">
            <Box className="namebox">
              <Typography variant="h5">{customerProfileData?.b2cCustomerDetails?.name}</Typography>
              <Typography>{customerProfileData?.b2cCustomerDetails?.planUniqueId}</Typography>
            </Box>
            <Box className="personaldetails">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={3} md={3}>
                      <Typography className="heading">Plan Type</Typography>
                      <Typography className="content">
                        {customerProfileData?.retailPlanAndPricing?.planType}
                      </Typography>
                    </Grid>
                <Grid item xs={3} md={3}>
                  <Typography className="heading">Pincode</Typography>
                  <Typography className="content">{customerProfileData?.b2cCustomerDetails?.pincode}</Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography className="heading">Plan Duration</Typography>
                  <Typography className="content">{customerProfileData?.b2cCustomerDetails?.duration=="1"?`${customerProfileData?.b2cCustomerDetails?.duration}year`:`${customerProfileData?.b2cCustomerDetails?.duration}years`}</Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography className="heading">Enrolled Date</Typography>
                  <Typography className="content">{formattedDate(customerProfileData?.b2cCustomerDetails?.merchantTransactionDatetime)}</Typography>
                </Grid>
              </Grid>
              <Grid
                mt="5px"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={4} md={3} sm={3}>
                  <Typography className="heading">Phone Number</Typography>
                  <Typography className="content">{customerProfileData?.b2cCustomerDetails?.number}</Typography>
                </Grid>
                <Grid item xs={8} md={3} sm={6}>
                  <Typography className="heading">Mail Id</Typography>
                  <Typography className="content">{customerProfileData?.b2cCustomerDetails?.email}</Typography>
                </Grid>
                <Grid item xs={4} md={3} sm={3}>
                  <Typography className="heading">
                    Discounts On Treatment
                  </Typography>
                  <Typography className="content">{customerProfileData?.retailPlanAndPricing?.discountPercent}%</Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", mt: "20px" }}>
        <TabContext value={value}>
          <Box>
            <Tabs onChange={handleChange} aria-label="lab API tabs example">
            {
              b2cCustomerMemberdetails?.map((el,id)=>{
                return(
                    <Tab
                      sx={
                        value === `${el.name}`
                          ? {
                              backgroundColor: "#2D256C",
                              width: "130px",
                              borderRadius: "0px 0px 0px 0px",
                              color: "white",
                              minHeight: "40px",
                              fontSize: "12px",
                              textTransform:"capitalize",
                              fontWeight: 600,
                            }
                          : {
                              backgroundColor: "white",
                              width: "130px",
                              border: "0.5px solid #D9D9D9",
                              borderRadius: "0px 0px 0px 0px",
                              color: "black",
                              minHeight: "40px",
                              textTransform:"capitalize",
                              fontSize: "12px",
                              fontWeight: 600,
                            }
                      }
                      label={el.name}
                      value={el.name}
                    />
                    )
                  })
                }
                </Tabs>
          </Box>
          {
            b2cCustomerMemberdetails?.map((el,id)=>{
              return(
                <TabPanel value={el.name}>
                  <MemberDetails tabvalue={value} memberData={b2cCustomerMemberdetails} retailPlanAndPricing={retailPlanAndPricing}/>
                </TabPanel>
              )
            })
          }
          {/* <TabPanel value="member1">
            <MemberDetails tabvalue={value}/>
          </TabPanel> */}
          {/* <TabPanel value="member2">
            <MemberDetails tabvalue={value}/>
          </TabPanel>
          <TabPanel value="member3">
            <MemberDetails tabvalue={value}/>
          </TabPanel>
          <TabPanel value="member4">
            <MemberDetails tabvalue={value}/>
          </TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
};

export default ViewRetailCustomersDetails;

const MemberDetails = ({tabvalue,memberData,retailPlanAndPricing}) => {
  // console.log("line230",tabvalue)
  // console.log("memberdata",memberData);
  const selectedMember = memberData.find((member) => member.name === tabvalue);
  console.log("selectedmember",selectedMember);
  const formattedDate=(date)=>{
    const dob = moment(date)
    const formattedDob = dob.format("MMMM Do, YYYY");
    return formattedDob
  }
  const formatRupeesWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      <Card className="membercarddetails">
        <Box className="detailsbox">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} md={6}>
              <Typography className="heading">Date of Birth</Typography>
              <Typography className="content">{formattedDate(selectedMember.dob)}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="heading">Relation</Typography>
              <Typography className="content">{selectedMember.relation}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="detailsbox">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4} md={4}>
              <Typography className="heading">Total Coverage Amount</Typography>
              <Typography className="content">
                Rs. {formatRupeesWithCommas(retailPlanAndPricing?.treatmentCover)}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                className="heading"
                sx={{ color: "#444444!important" }}
              >
                Utilised Coverage
              </Typography>
              <Typography className="content">
                Rs.{" "}
                {formatRupeesWithCommas(Math.floor(
                  retailPlanAndPricing?.treatmentCover -
                    selectedMember?.treatmentCover
                ))}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                className="heading"
                sx={{ color: "#00984F!important" }}
              >
                Remaining Coverage Amount
              </Typography>
              <Typography className="content">
                Rs. {formatRupeesWithCommas(selectedMember?.treatmentCover)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box className="healthinfobox" >
          <Typography variant="h4">Health Info Questions</Typography>
          <Grid container spacing={1} columnSpacing={{xs:5 ,sm:1, md:1}} mt="10px">
            {
              selectedMember?.b2cCustomerQuestionDetails?.map((el,id)=>{
                return(
                  <>
                  <Grid item md={7} xs={7}>{el.question}</Grid>
                  <Grid item md={5} xs={5}>{el.response}</Grid>
                  </>
                )
              })
            }
          </Grid>
        </Box>
      </Card>
    </div>
  );
};
