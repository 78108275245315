import { Box, Card, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../styles/PlansAndCouponsTablePanel.scss";

const RetailPlansTabPanel = () => {
  return <div>RetailPlansTabPanel</div>;
};

export { RetailPlansTabPanel };

const B2BplansTabPanel = () => {
  const [b2bPlansData, setB2bPlansData] = useState([
    {
      planName: "Family Plan",
      validity: "2 Years",
      moq: 8000,
      maximumdiscount: "2,00,000",
      speciality: ["Opthalmology", "Ent", "Gastroentrology", "Gynecology"],
      startTime: "23 June,2023 | 11:15 AM",
      endTime: "25 July,2023 | 12:15 PM",
    },
  ]);
  return (
    <div>
      <Card className="thecardbox">
        {b2bPlansData.map((el, id) => {
          return (
            <Box className="b2bplansmaintablebox">
              <Box className="tablecontentbox1">
                <Typography variant="caption" className="idhead">
                  Plan Name
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#2D2828",
                    margin: "0px",
                    fontSize: "12px",
                  }}
                >
                  {el.planName}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box className="tablecontentbox2">
                <Box className="box1">
                  <Box className="subbox1">
                    <Typography>Validity</Typography>
                    <Typography variant="h6">{el.validity}</Typography>
                  </Box>
                  <Box className="subbox2">
                    <Box className="moqbox">
                      <Typography>MOQ</Typography>
                      <Typography variant="h6">{el.moq}</Typography>
                    </Box>
                  </Box>
                  <Box className="subbox3">
                    <Typography>Maximum Discount</Typography>
                    <Typography variant="h6">{el.maximumdiscount}</Typography>
                  </Box>
                </Box>
                <Box className="box2">
                  <Typography className="heading">
                    Speciality Covered
                  </Typography>

                  <Box className="specialitybox">
                    {el.speciality.map((speciality, id) => {
                      return <Typography>{speciality}</Typography>;
                    })}
                  </Box>
                </Box>
              </Box>
              {/* <Box className="divider"></Box> */}
              <Divider orientation="vertical" flexItem />
              <Box className="tablecontentbox3">
                <Box className="box1">
                  <Typography>Start Time</Typography>
                  <Typography variant="h6">{el.startTime}</Typography>
                </Box>
                <Box className="box2">
                  <Typography>End Time</Typography>
                  <Typography variant="h6">{el.endTime}</Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Card>
    </div>
  );
};

export { B2BplansTabPanel };

const AdminMyCoupons = () => {
  const [couponsData, setCouponsData] = useState([
    {
      couponCode: "HAPPY 25",
      noOfUsage: "2",
      moq: 8000,
      speciality: ["Opthalmology", "Ent", "Gastroentrology", "Gynecology"],
      startTime: "23 June,2023 | 11:15 AM",
      endTime: "25 July,2023 | 12:15 PM",
    },
  ]);
  return (
    <div>
      <Card className="thecardbox">
        {couponsData.map((el, id) => {
          return (
            <Box className="couponsmaintablebox">
              <Box className="tablecontentbox1">
                <Typography variant="caption" className="idhead">
                  Coupon Code
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#2D2828",
                    margin: "0px",
                    fontSize: "12px",
                  }}
                >
                  {el.couponCode}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box className="tablecontentbox2">
                <Box className="box1">
                  <Box className="subbox1">
                    <Typography>No Of Usage</Typography>
                    <Typography variant="h6">{el.noOfUsage}</Typography>
                  </Box>
                  <Box className="subbox2">
                    <Box className="moqbox">
                      <Typography>MOQ</Typography>
                      <Typography variant="h6">{el.moq}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="box2">
                  <Typography className="heading">
                    Speciality Covered
                  </Typography>

                  <Box className="specialitybox">
                    {el.speciality.map((speciality, id) => {
                      return <Typography>{speciality}</Typography>;
                    })}
                  </Box>
                </Box>
              </Box>
              {/* <Box className="divider"></Box> */}
              <Divider orientation="vertical" flexItem />
              <Box className="tablecontentbox3">
                <Box className="box1">
                  <Typography>Start Time</Typography>
                  <Typography variant="h6">{el.startTime}</Typography>
                </Box>
                <Box className="box2">
                  <Typography>End Time</Typography>
                  <Typography variant="h6">{el.endTime}</Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Card>
    </div>
  );
};

export { AdminMyCoupons };
