import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  PostAcceptOrRejectAppointment,
  getCancelledReqWithDate,
  getClinicAppointrequestAccepted,
  getClinicAppointrequestCancelled,
  getClinicPastAppointments,
  getClinicPastRequestWithDate,
  getClinicTodayAppointmentRequests,
  getClinicUpcomingAppointmentRequests,
  getUpcomingReqWithDate,
} from "../../services/clinicOnboarding";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader";
import "../../styles/ClinicTables.scss";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";
import moment from "moment";

export const ClinicTodayTable = () => {
  const [clinicTodayData, setClinicTodayData] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const [acceptReject, setAcceptReject] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  const navigate = useNavigate();
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let TodayPermission = clinicPermissions?.find(
    (item) => item.action === "Today"
  )?.permission;
  let OpenAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Open Appointment"
  )?.permission;
  // let OpenAppointmentPermission=false
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  // let TodayPermission=false
  console.log("todaypermissions", TodayPermission);
  const getClinicTodayData = async () => {
    try {
      const response = await getClinicTodayAppointmentRequests(uniqueId);
      console.log(response.data);
      const data = response.data;
      const sortedData = data.sort((a, b) => {
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("today clinic new data", newdata);
      setClinicTodayData(newdata);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };
  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };
  const error = () => {
    toast.success("Cancelled Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      console.log("Rejected data post success..");
      setAcceptReject(true);
      error();
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    getClinicTodayData();
    console.log("clinictodaydata", clinicTodayData);
  }, [acceptReject]);
  return (
    <Box>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {clinicTodayData.map((el, id) => {
          return (
            <>
              {TodayPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId}
                        </Typography>
                        {/* <Box
                  style={{
                    background: "#00984F",
                    padding: "10px",
                    borderRadius: "6px",
                    lineHeight: "0.5",
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#fff" }}>
                    {el.request}
                  </Typography>
                </Box> */}
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Appointment Status
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {el.appointmentStatus}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7.5}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.patientName}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PhoneIcon style={{ fontSize: "12px" }} />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "12px" }}
                              >
                                {el.patientNumber}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            >
                              {/* <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {el.location}
                      </Typography> */}
                              {/* <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {el.customernumber}
                      </Typography> */}
                            </Box>
                            <Box sx={{ textAlign: "center", width: "70%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                  width: "100%",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "25px",
                        }}
                        container
                      >
                        {OpenAppointmentPermission && (
                          <button
                            style={{
                              background: "#D83F87",
                              textAlign: "left",
                              border: "none",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              marginBottom: "10px",
                              width: "65%",
                              fontSize: "12px",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                            onClick={() => {
                              if (el.appointmentStatus === "Completed") {
                                navigate(
                                  `/user/pastappointmentinformation/${el.appointmentId}`
                                );
                              } else {
                                navigate(
                                  `/user/appointmentinformation/${el.id}`
                                );
                              }
                            }}
                          >
                            <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                            Open
                          </button>
                        )}
                        <br />
                        {CancelAppointmentPermission &&
                          el.appointmentStatus === "Accepted" && (
                            <button
                              style={{
                                background: "#3E2732",
                                border: "none",
                                textAlign: "left",
                                alignItems: "center",
                                borderRadius: "3px",
                                cursor: "pointer",
                                color: "#fff",
                                padding: "8px 15px",
                                fontSize: "12px",
                                width: "65%",
                                display: "flex",
                                gap: "5px",
                              }}
                              onClick={() => {
                                handleRejectdialogOpen(el.id);
                                console.log("reject id........ ", el.id);
                              }}
                            >
                              <CancelPresentationIcon
                                style={{ fontSize: "0.9rem" }}
                              />{" "}
                              Cancel
                            </button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Reject ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              handleReject(itemId);
              handleRejectDialogClose();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="mobilescreencard"
      >
        {clinicTodayData.map((el, id) => {
          return (
            <>
              {TodayPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Status:{" "}
                        <span className="date">{el.appointmentStatus}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      {OpenAppointmentPermission && (
                        <button
                          onClick={() => {
                            if (el.appointmentStatus === "Completed") {
                              navigate(
                                `/user/pastappointmentinformation/${el.appointmentId}`
                              );
                            } else {
                              navigate(`/user/appointmentinformation/${el.id}`);
                            }
                          }}
                          style={{
                            background: "#D83F87",
                            textAlign: "left",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            marginBottom: "10px",
                            width: "90%",
                            fontSize: "10px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                          className="openbtn"
                        >
                          <CalendarMonthIcon style={{ fontSize: "14px" }} />{" "}
                          Open
                        </button>
                      )}
                      {CancelAppointmentPermission &&
                        el.appointmentStatus === "Accepted" && (
                          <button
                            style={{
                              background: "#3E2732",
                              border: "none",
                              textAlign: "left",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "5px 10px",
                              fontSize: "10px",
                              width: "90%",
                              display: "flex",
                              gap: "5px",
                            }}
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            <CancelPresentationIcon
                              style={{ fontSize: "14px" }}
                            />{" "}
                            Cancel
                          </button>
                        )}
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </Box>
  );
};

// ---------------------------upcoming-----------------------

export const ClinicUpcomingTable = ({
  calenderOpen,
  setAcceptedUpcoming,
  acceptedUpcoming,
  newSelectDate,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const [acceptReject, setAcceptReject] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [itemId, setItemid] = useState("");
  // console.log(acceptedUpcoming);
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let UpcomingPermission = clinicPermissions?.find(
    (item) => item.action === "Upcoming"
  )?.permission;
  let CancelAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Cancel Appointment"
  )?.permission;

  // let TodayPermission=false

  async function getAppointmentUpcomingData() {
    try {
      const response = await getClinicUpcomingAppointmentRequests(uniqueId);
      // console.log(response.data)
      const data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("newdata response with time..", newdata);
      if (newdata) {
        setAcceptedUpcoming(newdata);
        setAcceptReject(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleRejectdialogOpen = (id) => {
    console.log("rejectid", id);
    setRejectOpen(true);
    setItemid(id);
  };
  const handleRejectDialogClose = () => {
    setRejectOpen(false);
    setAcceptReject(false);
  };
  const error = () => {
    toast.success("Cancelled Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleReject = async (id) => {
    const Rejectdata = {
      id: id,
      response: "Rejected",
      updatedBy: token.username,
    };
    // console.log("Rejected data.........",Rejectdata)
    try {
      await PostAcceptOrRejectAppointment(Rejectdata);
      console.log("Rejected data post success..");
      setAcceptReject(true);
      error();
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect(() => {
  //   getAppointmentUpcomingData();
  //   if(newSelectDate!==null){
  //     try {
  //       let response=await getUpcomingReqWithDate(newSelectDate,uniqueId)
  //       let data=response.data;
  //       console.log("upcomingreq with data",data)
  //       setAcceptedUpcoming(data)
  //     } catch (error) {
  //       console.log(error.message)
  //     }
  //   }
  // }, [acceptReject]);

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getUpcomingReqWithDate(newSelectDate, uniqueId);
        let data = response.data;
        console.log("upcomingreq with data", data);
        setAcceptedUpcoming(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newSelectDate) {
      fetchData();
    } else {
      getAppointmentUpcomingData();
    }
  }, [acceptReject, newSelectDate]);
  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="thecardbox"
      >
        {acceptedUpcoming.map((el, id) => {
          return (
            <>
              {UpcomingPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId == null ? (
                            <>No Appointment ID</>
                          ) : (
                            el.appointmentId
                          )}
                        </Typography>
                        {/* <Box
                  style={{
                    background: "#00984F",
                    padding: "10px",
                    borderRadius: "6px",
                    lineHeight: "0.5",
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#fff" }}>
                    {el.request}
                  </Typography>
                </Box> */}
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Appointment Status
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {el.appointmentStatus}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.patientName}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PhoneIcon style={{ fontSize: "12px" }} />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "12px" }}
                              >
                                {el.patientNmber === null ? (
                                  <>No number</>
                                ) : (
                                  el.patientNumber
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            ></Box>
                            <Box sx={{ textAlign: "center", width: "70%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                  width: "100%",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark === "" ? <>No Remarks</> : el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "45px",
                        }}
                        container
                      >
                        {CancelAppointmentPermission && (
                          <button
                            style={{
                              background: "#3E2732",
                              border: "none",
                              textAlign: "left",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                              color: "#fff",
                              padding: "8px 15px",
                              fontSize: "12px",
                              width: "65%",
                              display: "flex",
                              gap: "5px",
                            }}
                            onClick={() => {
                              handleRejectdialogOpen(el.id);
                              console.log("reject id........ ", el.id);
                            }}
                          >
                            <CancelPresentationIcon
                              style={{ fontSize: "0.9rem" }}
                            />{" "}
                            Cancel
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
      {/* -------------------------Dialog box for Reject---------------------- */}
      <Dialog
        open={rejectopen}
        onClose={handleRejectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to Reject ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#D83F87",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#D83F87!important" },
            }}
            onClick={() => {
              handleReject(itemId);
              handleRejectDialogClose();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={handleRejectDialogClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="mobilescreencard"
      >
        {acceptedUpcoming.map((el, id) => {
          return (
            <>
              {UpcomingPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Status:{" "}
                        <span className="date">{el.appointmentStatus}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      {CancelAppointmentPermission && (
                        <button
                          style={{
                            background: "#3E2732",
                            border: "none",
                            textAlign: "left",
                            alignItems: "center",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "5px 10px",
                            fontSize: "10px",
                            width: "90%",
                            display: "flex",
                            gap: "5px",
                          }}
                          className="cancelbtn"
                          onClick={() => {
                            handleRejectdialogOpen(el.id);
                            console.log("reject id........ ", el.id);
                          }}
                        >
                          <CancelPresentationIcon
                            style={{ fontSize: "14px" }}
                          />{" "}
                          Cancel
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
};

// --------------------------------clinic past-------------------------------------

export const ClinicPastTable = ({
  clinicPastAppointments,
  setClinicPastAppointments,
  newPastSelectedDate,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const [itemId, setItemid] = useState("");
  const navigate = useNavigate();
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let PastPermission = clinicPermissions?.find(
    (item) => item.action === "Past"
  )?.permission;
  let OpenAppointmentPermission = clinicPermissions?.find(
    (item) => item.action === "Open Appointment"
  )?.permission;

  // let TodayPermission=false

  const getClinicPastData = async () => {
    try {
      const response = await getClinicPastAppointments(uniqueId);
      // console.log(response.data)
      const data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeB}`) - new Date(`1970/01/01 ${timeA}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("newdata response with time..", newdata);
      if (newdata) {
        setClinicPastAppointments(newdata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getClinicPastRequestWithDate(
          newPastSelectedDate,
          uniqueId
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setClinicPastAppointments(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newPastSelectedDate) {
      fetchData();
    } else {
      getClinicPastData();
    }
  }, [newPastSelectedDate]);
  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="desktopscreencard"
      >
        {clinicPastAppointments.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={2.5}>
                    <Box
                      sx={{
                        padding: "15px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        Appointment ID
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", fontSize: "12px" }}
                      >
                        {el.appointmentId}
                      </Typography>
                      {/* <Box
                  style={{
                    background: "#00984F",
                    padding: "10px",
                    borderRadius: "6px",
                    lineHeight: "0.5",
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#fff" }}>
                    {el.request}
                  </Typography>
                </Box> */}
                    </Box>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid item xs={7.5}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Box sx={{ padding: "15px" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {el.patientName}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <PhoneIcon style={{ fontSize: "12px" }} />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.patientNumber}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            {/* <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {el.location}
                      </Typography> */}
                            {/* <Typography
                        variant="caption"
                        sx={{
                          color: "#D83F87",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {el.customernumber}
                      </Typography> */}
                          </Box>
                          <Box sx={{ textAlign: "center", width: "70%" }}>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                background: "#FFEFF9",
                                padding: "5px",
                                color: "gray",
                                borderRadius: "6px",
                                width: "100%",
                              }}
                            >
                              {el.specialty}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ padding: "15px" }}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Appointment Date
                          </Typography>
                          <Typography
                            v4ant="subtitle1"
                            sx={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            {formattedDate(el.date)}
                          </Typography>
                          <Box sx={{ marginTop: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Time
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.time}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box
                          sx={{
                            padding: "15px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#D83F87",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            Remarks
                          </Typography>{" "}
                          <br />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "12px" }}
                          >
                            {el.remark}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}

                  <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "45px",
                      }}
                      container
                    >
                      {OpenAppointmentPermission && (
                        <button
                          style={{
                            background: "#D83F87",
                            textAlign: "left",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "8px 15px",
                            marginBottom: "10px",
                            width: "65%",
                            fontSize: "12px",
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                          onClick={() =>
                            navigate(
                              `/user/pastappointmentinformation/${el.appointmentId}`
                            )
                          }
                        >
                          <CalendarMonthIcon style={{ fontSize: "0.9rem" }} />{" "}
                          Open
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>

      {/* ----------------------------------Table for mobile screen--------------------------- */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="mobilescreencard"
      >
        {clinicPastAppointments.map((el, id) => {
          return (
            <>
              <Card
                sx={{
                  padding: "0px 0%!important",
                  marginBottom: "10px",
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px",
                  boxShadow: "none",
                }}
                className="mobilecontentcard"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className="appointmentid" variant="h6">
                      {el.appointmentId}
                    </Typography>
                    <Typography className="dateheading" variant="caption">
                      Date
                    </Typography>
                    <Typography className="date" variant="subtitle1">
                      {formattedDate(el.date)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="speciality">
                      {el.specialty}
                    </Typography>
                    <Typography variant="caption" className="timeheading">
                      Time
                    </Typography>
                    <Typography variant="subtitle1" className="time">
                      {el.time}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{ backgroundColor: "#F5F5F5!important" }}
                    item
                    xs={4}
                  >
                    {OpenAppointmentPermission && (
                      <button
                        onClick={() =>
                          navigate(
                            `/user/pastappointmentinformation/${el.appointmentId}`
                          )
                        }
                        style={{
                          background: "#D83F87",
                          textAlign: "left",
                          border: "none",
                          borderRadius: "3px",
                          cursor: "pointer",
                          color: "#fff",
                          padding: "5px 10px",
                          marginBottom: "10px",
                          marginTop: "15px",
                          width: "90%",
                          fontSize: "10px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                        }}
                        className="openbtn"
                      >
                        <CalendarMonthIcon style={{ fontSize: "14px" }} /> Open
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </>
          );
        })}
      </Card>
    </>
  );
};

// ----------------------------clinic cancelled-----------------------

export const ClinicCancelledTable = ({
  calenderOpen,
  setCancelledAppointments,
  cancelledAppointments,
  newCancelSelectedDate,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const [acceptReject, setAcceptReject] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(cancelledAppointments);
  const { permissionsData } = useContext(Permissions);
  let clinicPermissionsModule = permissionsData?.filter(
    (item) => item.module === "appointment"
  );
  let clinicPermissions =
    clinicPermissionsModule && clinicPermissionsModule[0]?.permissions;
  // console.log("clinicpermissions",clinicPermissions);
  let CancelledPermission = clinicPermissions?.find(
    (item) => item.action === "Cancelled"
  )?.permission;
  // let TodayPermission=false

  async function getAppointmentCancelledData() {
    setLoading(true);
    try {
      const response = await getClinicAppointrequestCancelled(uniqueId);
      // console.log(response.data)
      const data = response.data;
      let sortedData = data.sort((a, b) => {
        // Sort by date (descending)
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        // Sort by time (ascending)
        const timeA = a.time.split(" - ")[0];
        const timeB = b.time.split(" - ")[0];
        return (
          new Date(`1970/01/01 ${timeB}`) - new Date(`1970/01/01 ${timeA}`)
        );
      });
      let newdata = sortedData.map((el) => {
        el.time = modifiedTime(el.time);
        function modifiedTime(timing) {
          const parts = timing.split(" - ");
          const startTime = parts[0];
          const endTime = parts[1];

          // Converting start time to 12-hour format
          const start = new Date(`2000-01-01T${startTime}`);
          const startHours = start.getHours();
          const startMinutes = start.getMinutes();
          const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
            .toString()
            .padStart(2, "0")}`;

          // Converting end time to 12-hour format
          const end = new Date(`2000-01-01T${endTime}`);
          const endHours = end.getHours();
          const endMinutes = end.getMinutes();
          const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
            .toString()
            .padStart(2, "0")}`;

          // Determine AM/PM
          const amPm = startHours < 12 ? "AM" : "PM";

          return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
        }
        return el;
      });
      console.log("newdata response with time..", newdata);
      if (newdata) {
        setCancelledAppointments(newdata);
        setAcceptReject(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  // useEffect(() => {
  //   getAppointmentCancelledData();

  // }, [acceptReject]);

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCancelledReqWithDate(
          newCancelSelectedDate,
          uniqueId
        );
        let data = response.data;
        console.log("upcomingreq with data", data);
        setCancelledAppointments(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (newCancelSelectedDate) {
      fetchData();
    } else {
      getAppointmentCancelledData();
    }
  }, [acceptReject, newCancelSelectedDate]);

  if (loading)
    return (
      <h1>
        <Loader />
      </h1>
    );
  return (
    <>
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="thecardbox"
      >
        {cancelledAppointments.map((el, id) => {
          return (
            <>
              {CancelledPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          padding: "15px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#D83F87",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                        >
                          Appointment ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {el.appointmentId == null ? (
                            <>No Appointment ID</>
                          ) : (
                            el.appointmentId
                          )}
                        </Typography>
                        {/* <Box
                  style={{
                    background: "#00984F",
                    padding: "10px",
                    borderRadius: "6px",
                    lineHeight: "0.5",
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#fff" }}>
                    {el.request}
                  </Typography>
                </Box> */}
                      </Box>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={7}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {el.patientName}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PhoneIcon style={{ fontSize: "12px" }} />
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "12px" }}
                              >
                                {el.patientNmber === null ? (
                                  <>No number</>
                                ) : (
                                  el.patientNumber
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                paddingLeft: "20px",
                              }}
                            ></Box>
                            <Box sx={{ textAlign: "center", width: "70%" }}>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  background: "#FFEFF9",
                                  padding: "5px",
                                  color: "gray",
                                  borderRadius: "6px",
                                  width: "100%",
                                }}
                              >
                                {el.specialty}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Box sx={{ padding: "15px" }}>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Appointment Date
                            </Typography>
                            <Typography
                              v4ant="subtitle1"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {formattedDate(el.date)}
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#D83F87",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                Appointment Time
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {el.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3}>
                          <Box
                            sx={{
                              padding: "15px",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#D83F87",
                                fontWeight: "bold",
                                fontSize: "10px",
                              }}
                            >
                              Remarks
                            </Typography>{" "}
                            <br />
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "12px" }}
                            >
                              {el.remark === "" ? <>No Remarks</> : el.remark}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}

                    <Grid item xs={2} sx={{ backgroundColor: "#F5F5F5" }}>
                      <Box sx={{ pt: "40px" }}>
                        <Typography>This appointment was cancelled.</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>

      {/* -----------------------------------------Table for mobile screen------------------------------ */}
      <Card
        sx={{
          padding: "1% 2% !important",
          marginBottom: "10px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
          overflowY: "scroll",
          height: "65vh",
          boxShadow: "none",
        }}
        className="mobilescreencard"
      >
        {cancelledAppointments.map((el, id) => {
          return (
            <>
              {CancelledPermission && (
                <Card
                  sx={{
                    padding: "0px 0%!important",
                    marginBottom: "10px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                  className="mobilecontentcard"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography className="appointmentid" variant="h6">
                        {el.appointmentId
                          ? el.appointmentId
                          : "No AppointmentID"}
                      </Typography>
                      <Typography className="dateheading" variant="caption">
                        Date
                      </Typography>
                      <Typography className="date" variant="subtitle1">
                        {formattedDate(el.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="speciality">
                        {el.specialty}
                      </Typography>
                      <Typography variant="caption" className="timeheading">
                        Time
                      </Typography>
                      <Typography variant="subtitle1" className="time">
                        {el.time}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ backgroundColor: "#F5F5F5!important" }}
                      item
                      xs={4}
                    >
                      <Typography sx={{ fontSize: "12px", mt: "10px" }}>
                        This appointment was canceled.
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
};
