import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import "../../styles/AllCllincListCard.scss";
import { data } from "../../dummydata";
import AppointmentsSearchbar from "./AppointmentsSearchbar";
import {useNavigate} from "react-router-dom"
import Loader from "../Loader";

const PageNumber=1;
const AllClinicListCard = () => {
  const [dataSource,setDataSource]=useState(data);
  const [page,setPage]=useState(PageNumber);
  const [scrollPosition, setScrollPosition] = useState(0)
  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()
  
  const scrollToEnd=(e)=>{
const {scrollTop,scrollHeight,clientHeight} = e.target;
console.log(scrollTop,scrollHeight,clientHeight);
if(scrollTop+clientHeight>=scrollHeight){
  
  setScrollPosition(scrollTop)
}
    // setPage(page+1)
    console.log("scrolling")
  }
  useEffect(()=>{
    // To make api call we can use axios here like fetch("api") or axios.get("")
    // example fetch(`https://dummydata.js/apppointments?page=${page}&size=5`)
    // this below is to fetch dummy data

    const fetchData=()=>{
      setLoading(true)
      setTimeout(()=>{
        setDataSource([...dataSource,...data])
        setLoading(false)
      },1500)
    }

    if(scrollPosition>0){
      fetchData()
    }
    
  },[scrollPosition])
  console.log(dataSource.length)
  return (
    <div className="cliniclistbox">
      <AppointmentsSearchbar/>
      <Box className="cardbox" >
        <Box className="card" onScroll={scrollToEnd}>
          { dataSource.map((el, id) => {
            return (
              <Box className="carddata">
                <Box>
                  <img src={el.demopic} alt="demopic" />
                </Box>
                <Box>
                  <Typography variant="h5">{el.heading}</Typography>
                  <Typography variant="h6">{el.subhead}</Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                  >
                    <Box className="kmsbox">
                      <img src={el.kmsimg} alt="kmsimage" />
                      <br />
                      <Typography>{el.kms}</Typography>
                    </Box>
                    <Typography className="address" variant="h6">
                      {el.address}
                    </Typography>
                  </Box>
                  <Box className="specialitybox">
                    <Typography>{el.speciality}</Typography>
                    <Typography>{el.speciality}</Typography>
                    <Typography>{el.speciality}</Typography>
                  </Box>
                  <button onClick={()=>{navigate("/user/customerclinicView")}} >{el.buttontext}</button>
                </Box>
              </Box>
            );
          })}
        </Box>
        {loading && <div style={{textAlign:"center"}} ><Loader/></div>}
      </Box>
    </div>
  );
};

export default AllClinicListCard;
