import React from "react";
import { Card, Grid, Typography } from '@mui/material';
import { CssTextField } from "../../styles/textFields";
import { useParams } from 'react-router-dom';

const ClinicFinance = ({  fields, formik, edit }) => {
  const {id}=useParams()
  return <>
  <Card>
  
    <Grid container spacing={2}>
        {fields.map((item,index)=>
         <React.Fragment key={index}>
           <Grid item md={6} xs={12}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  {item.lable}
                </Typography>
                <CssTextField
                
                disabled={(id)&&(!edit)?true:false}
                  size="small"
                  fullWidth
                  placeholder={item.placeholder}
                  variant="outlined"
                  type="text"
                  name={item.value}
                  onChange={formik.handleChange}
                  value={formik.values[item.value]}
                  error={
                    formik.touched[item.value] &&
                    Boolean(formik.errors[item.value])
                  }
                  helperText={
                    formik.touched[item.value] && formik.errors[item.value]
                  }
                />
                </Grid> 
         </React.Fragment>)}
    </Grid>
    </Card>
  </>;
};

export default ClinicFinance;
