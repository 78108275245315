import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CssTextField } from "../../styles/textFields";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  CurrencyRupee,
  Dashboard,
  DisplaySettings,
  Dock,
  Leaderboard,
  PersonPin,
} from "@mui/icons-material";
import { IOSSwitch } from "../../styles/iosSwitch";
import {
  checkRoleUniqueness,
  checkRoleUniquenessOnEdit,
  createOrEditRole,
  getRoleById,
} from "../../services/rolesAndPermissions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader";

const AddRole = () => {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [uniqueness, setUniqueness] = useState();
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const [clinic, setClinic] = useState([
    { action: "Add", permission: false },
    { action: "Edit", permission: false },
    { action: "View", permission: false },
    { action: "List", permission: false },
    { action: "Assign To", permission: false },
    { action: "Mail Trigger", permission: false },
    { action: "Verify", permission: false },
    { action: "Clinic Details", permission: false },
  ]);

  const [report, setReport] = useState([
    { action: "Report", permission: false },
  ]);

  const [staff, setStaff] = useState([
    { action: "Add", permission: false },
    { action: "Edit", permission: false },
    { action: "View", permission: false },
  ]);

  const [procedure, setProcedure] = useState([
    { action: "Add Procedure", permission: false },
    { action: "Add Complaint", permission: false },
    { action: "Add Speciality", permission: false },
    { action: "Edit", permission: false },
    { action: "View", permission: false },
  ]);

  const [plans, setPlans] = useState([
    { action: "List", permission: false },
    { action: "Add Plan", permission: false },
    { action: "Edit", permission: false },
    { action: "Plan History", permission: false },
    { action: "Deactivate", permission: false },
  ]);
  const [coupons, setCoupons] = useState([
    { action: "List", permission: false },
    { action: "Add Coupon", permission: false },
    { action: "Edit", permission: false },
    { action: "Coupon History", permission: false },
    { action: "Deactivate", permission: false },
  ]);

  const [customer, setCustomer] = useState([
    { action: "List", permission: false },
    { action: "Add", permission: false },
    { action: "Edit", permission: false },
    { action: "View", permission: false },
  ]);

  const [appointment, setAppointments] = useState([
    { action: "Today", permission: false },
    { action: "Upcoming", permission: false },
    { action: "Cancelled", permission: false },
    { action: "New Appointment", permission: false },
    { action: "Request Appointment", permission: false },
    { action: "Accept Appointment", permission: false },
    { action: "Reschedule Appointment", permission: false },
    { action: "Open Appointment", permission: false },
    { action: "Start Appointment", permission: false },
    { action: "Cancel Appointment", permission: false },
    { action: "Billing", permission: false },
    { action: "History", permission: false },
  ]);

  const [billing, setBilling] = useState([
    { action: "Appointment Details", permission: false },
    { action: "Customer  Details", permission: false },
  ]);

  const [clinicId, setClinicId] = useState("");
  const [reportId, setReportId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [procedureId, setProcedureId] = useState("");
  const [plansId, setPlansId] = useState("");
  const [couponsId, setCouponsId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [billingId, setBillingId] = useState("");

  const checkNameUniqueness = async () => {
    if (!name) return;
    if (id) {
      const { data } = await checkRoleUniquenessOnEdit(id, name);
      if (data) {
        setUniqueness(data);
      }
      return;
    }
    const { data } = await checkRoleUniqueness(name);
    console.log("data: ", data);
    if (data) {
      setUniqueness(data);
    }
  };

  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const submitData = async () => {
    let vals = {};
    if (id) {
      vals = {
        id: id,
        role: name,
        description: description,
        permissions: [
          { module: "clinic", id: clinicId, permissions: clinic },
          { module: "report", id: reportId, permissions: report },
          { module: "staff", id: staffId, permissions: staff },
          { module: "procedure", id: procedureId, permissions: procedure },
          { module: "plans", id: plansId, permissions: plans },
          { module: "coupons", id: couponsId, permissions: coupons },
          { module: "customer", id: customerId, permissions: customer },
          {
            module: "appointment",
            id: appointmentId,
            permissions: appointment,
          },
          { module: "billing", id: billingId, permissions: billing },
        ],
      };
    } else {
      vals = {
        role: name,
        description: description,
        permissions: [
          { module: "clinic", permissions: clinic },
          { module: "report", permissions: report },
          { module: "staff", permissions: staff },
          { module: "procedure", permissions: procedure },
          { module: "plans", permissions: plans },
          { module: "coupons", permissions: coupons },
          { module: "customer", permissions: customer },
          { module: "appointment", permissions: appointment },
          { module: "billing", permissions: billing },
        ],
      };
    }
    try {
      setSubmitting(true);
      const { data } = await createOrEditRole(vals);
      console.log("data: ", data);
      if (data === "Sucess") {
        navigate("/user/roles");
        success();
        setSubmitting(false);
      }
    } catch (er) {
      setSubmitting(false);
      error();
    }
  };

  function findModuleSetPermission(data, module, setter, setId) {
    const vals = data.filter((item) => item.module === module);
    console.log("vals: ", vals);
    console.log("vals.permissions: ", vals.permissions);
    setter(vals[0].permissions);
    setId(vals.id);
  }

  useEffect(() => {
    if (!id) return;

    const getData = async () => {
      const { data } = await getRoleById(id);
      console.log("data: ", data);
      if (data) {
        setName(data.role);
        setDescription(data.description);
        findModuleSetPermission(
          data.permissions,
          "clinic",
          setClinic,
          setClinicId
        );
        findModuleSetPermission(
          data.permissions,
          "report",
          setReport,
          setReportId
        );
        findModuleSetPermission(
          data.permissions,
          "staff",
          setStaff,
          setStaffId
        );
        findModuleSetPermission(
          data.permissions,
          "procedure",
          setProcedure,
          setProcedureId
        );
        findModuleSetPermission(
          data.permissions,
          "plans",
          setPlans,
          setPlansId
        );
        findModuleSetPermission(
          data.permissions,
          "coupons",
          setCoupons,
          setCouponsId
        );
        findModuleSetPermission(
          data.permissions,
          "customer",
          setCustomer,
          setCustomerId
        );
        findModuleSetPermission(
          data.permissions,
          "appointment",
          setAppointments,
          setAppointmentId
        );
        findModuleSetPermission(
          data.permissions,
          "billing",
          setBilling,
          setBillingId
        );
      }
    };

    getData();
  }, []);

  if (submitting) {
    <Loader />;
  }

  return (
    <>
      <Typography variant="h5" mb="10px" fontSize="17px">
        Add Roles
      </Typography>
      <Card
        sx={{
          // margin: "8px 15px 15px 15px",
          py: 3,
          px: 3,
          borderRadius: "8px",
          minheight: "90vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Name{" "}
            </Typography>
            <CssTextField
              size="small"
              fullWidth
              // disabled={id && !edit ? true : false}
              placeholder="Name"
              variant="outlined"
              type="text"
              name="Name"
              onChange={(e) => setName(e.target.value)}
              onBlur={() => {
                checkNameUniqueness();
              }}
              value={name}
              error={uniqueness === false}
              helperText={uniqueness === "false" && "This Role Already Exist"}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
              Description{" "}
            </Typography>
            <CssTextField
              size="small"
              fullWidth
              // disabled={id && !edit ? true : false}
              placeholder="Description"
              variant="outlined"
              type="text"
              name="Name"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </Grid>
        </Grid>
        <br />
        <br />

        <TabContext value={value}>
          <TabList onChange={handleChange}>
            <Tab icon={<DisplaySettings />} label="CLINIC" />
            <Tab icon={<CurrencyRupee />} label="REPORT" />
            <Tab icon={<Dashboard />} label="STAFF" />
            <Tab icon={<Dock />} label="PROCEDURES & SPECIALITIES" />
            <Tab icon={<Dock />} label="PLANS & PRICING" />
            <Tab icon={<Leaderboard />} label="COUPONS" />
            <Tab icon={<PersonPin />} label="CUSTOMER" />
            <Tab icon={<PersonPin />} label="APPOINTMENT" />
            <Tab icon={<PersonPin />} label="BILLING & SETTLEMENT" />
          </TabList>

          <TabPanel value={0}>
            <Grid container spacing={2}>
              {clinic?.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...clinic];

                            ops[i].permission = !ops[i].permission;
                            setClinic(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={1}>
            <Grid container spacing={2}>
              {report.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...report];

                            ops[i].permission = !ops[i].permission;
                            setReport(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={2}>
            <Grid container spacing={2}>
              {staff.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...staff];

                            ops[i].permission = !ops[i].permission;
                            setStaff(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={3}>
            <Grid container spacing={2}>
              {procedure.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...procedure];

                            ops[i].permission = !ops[i].permission;
                            setProcedure(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={4}>
            <Grid container spacing={2}>
              {plans.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...plans];

                            ops[i].permission = !ops[i].permission;
                            setPlans(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={5}>
            <Grid container spacing={2}>
              {coupons.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...coupons];

                            ops[i].permission = !ops[i].permission;
                            setCoupons(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={6}>
            <Grid container spacing={2}>
              {customer.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...customer];

                            ops[i].permission = !ops[i].permission;
                            setCustomer(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={7}>
            <Grid container spacing={2}>
              {appointment.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...appointment];

                            ops[i].permission = !ops[i].permission;
                            setAppointments(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={8}>
            <Grid container spacing={2}>
              {billing.map((item, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{item.action}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      key={i}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={item.permission}
                          name={item.action}
                          label={null}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          onChange={() => {
                            const ops = [...billing];

                            ops[i].permission = !ops[i].permission;
                            setBilling(ops);
                          }}
                        />
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </TabPanel>
        </TabContext>

        {value === 8 && (
          <div style={{ textAlign: "right" }}>
            <Button
              className="addnewbtn"
              variant="contained"
              onClick={() => {
                submitData();
              }}
              sx={{ marginTop: 0 }}
            >
              Submit
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export default AddRole;
