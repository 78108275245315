import React, { useEffect, useRef, useState } from "react";
import "../../styles/CustomerClinicView.scss";
// import democlinicpic from "../../assets/democlinicpic.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import img1 from "../../assets/carouselimg1.svg";
// import img2 from "../../assets/carouselimg2.svg";
// import img3 from "../../assets/carouselimg3.svg";
// import doctordemopic from "../../assets/doctordemopic.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import {
  Box,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckingExistingCustomer,
  bookAppointment,
  bookAppointmentOtp,
  clinicDetailsByuniqueId,
  getAvailableTimeSlots,
  getCustomerMembersForPLan,
  getSpecialityOptions,
  plansDropdownForAppointmentBooking,
  verifyBookAppointmentOtp,
} from "../../services/customerOnboarding";
import VerifiedIcon from "@mui/icons-material/Verified";
import config from "../../config.json";
import { toast } from "react-toastify";
import BookAppointmentModal from "./BookAppointmentModal";
import { getPlansAndPricingList } from "../../services/PlansAndProcedure";
import moment from "moment";

const phoneRegExp = /^[6-9]\d{9}$/;
const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    // phonenumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    speciality: yup.string().notOneOf([""], "You must select an option!"),
    slot: yup.string().required("You must select an option!"),
    datevalid: yup.string().required("This Field is required"),
    time: yup.string().required("This Field Is Required"),
    plan: yup.string().required("This Field Is Required"),
  })
  .required();

// ----------------------------------carousel---------------------------------

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
const CustomerClinicView = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token.uniqueId;
  const designation = token.designation;
  const username = token.username;

  const [formvalues, setFormvalues] = useState(false);
  const [date, setDate] = useState(new Date());
  const [entireClinicDetails, setEntireClinicDetails] = useState([]);
  const [isOptionsFetched, setIsOptionsFetched] = useState(false);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [dailyBasis, setDailybasis] = useState("");
  const [availableTime, setAvailableTime] = useState([]);
  const [otp, setOtp] = useState();
  const [allPlans, setAllPlans] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  // const dayschedule=useRef("");
  // console.log("dailybasis", dailyBasis);
  const [formData, setFormData] = useState([]);
  const [verifyNumber, setVerifyNumber] = useState(
    designation === "Customer" ? true : false
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verifySubmit, setVerifySubmit] = useState(false);
  const [bookAppointmentModalopen, setBookAppointmentModalOpen] =
    useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [existingCustomerId, setExistingCustomerId] = useState("");

  // const [Data, setEntireData] = useState([]);
  console.log("date selection", date);

  // --------------Date Extracting start --------------------;

  // console.log("before conversion", date);
  let currentdate = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let entiredate = `${year}-${month > 9 ? month : "0" + month}-${
    currentdate > 9 ? currentdate : "0" + currentdate
  }`;
  console.log(entiredate);
  // --------------------------Date Extracting end------------

  const { id } = useParams();
  // console.log(id);
  const clinicimage = entireClinicDetails.clinicImageRequest?.filter(
    (x) => x.image
  );

  const initialvalue_clinicdetails =
    entireClinicDetails.initialValue_ClinicDetails;

  const onYearChangeHandler = () => {
    alert("Year changed!");
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    // mode: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all",
    resolver: yupResolver(schema),
  });

  const success = () => {
    toast.success("Booking Appointment Sucessfull", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const successOTP = () => {
    toast.success("OTP sent,please check your registered mobile", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Please Check your server", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const errorOTP = () => {
    toast.error("Some Error Occoured, Please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const onSubmit = async (data) => {
    if (data) {
      // console.log(data);

      function convertTimeFormat(time) {
        // Split the time range by "-"
        const [startTime, endTime] = time.split(" - ");

        // Convert start time
        const convertedStartTime = convertTo24HourFormat(startTime);

        // Convert end time
        const convertedEndTime = convertTo24HourFormat(endTime);

        // Return the converted time range
        return `${convertedStartTime} - ${convertedEndTime}`;
      }

      function convertTo24HourFormat(time) {
        const [hour, minute, period] = time.split(/:| /);

        // Convert hour to 24-hour format
        let convertedHour = parseInt(hour, 10);
        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }

        // Return the formatted time
        return `${padZero(convertedHour)}:${padZero(minute)}`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      const formdata = {
        member: data.name,
        // patientName: data.name.memberName,
        patientNumber: designation === "Customer" ? username : data.phonenumber,
        clinicUniqueId: id,
        specialty: data.speciality,
        slot: data.slot,
        customerUniqueId:
          designation === "Customer" ? uniqueId : existingCustomerId,
        time: convertTimeFormat(data.time),
        date: entiredate,
        remark: data.remarks,
        planName: data.plan,
        createdBy: token.username,
      };
      console.log("formdata: ", formdata);
      verifyAndBook(formdata);
      // console.log(formdata);
      // setIsSubmitting(true)
      // const { data: response } = await bookAppointmentOtp(data.phonenumber);
      // console.log("response: ", response);
      // if (response.type === "success") {
      //   setFormvalues(true);
      //   setFormData(formdata);
      // }else{
      //   setIsSubmitting(false)
      // }
    }
  };

  const getEntireclinicdetailsbyId = async () => {
    try {
      const { data } = await clinicDetailsByuniqueId(id);
      // console.log("data", data);
      // console.log("clinicimagereq", data.clinicImageRequest);
      setEntireClinicDetails(data);
      // setEntireData([...Data, data]);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchOptions = async () => {
    try {
      const response = await getSpecialityOptions(id);
      // console.log("response", response);
      let data = response.data;
      setSpecialityOptions(data);
      console.log("Speciality Options", data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getTimeslots = async () => {
    try {
      // console.log("dailyBasis in getTimeSlot", dailyBasis);
      const response = await getAvailableTimeSlots(
        id,
        watch("speciality"),
        entiredate,
        watch("slot")
      );
      // console.log("response......", response.data);
      // console.log(data);
      let data = response.data;
      if (data.length === 0) {
        toast.info(
          "Timings not available Please Choose another Date or Check by changing slot",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      const modifiedTimings = data.map((timing) => {
        const parts = timing.split(" - ");
        const startTime = parts[0];
        const endTime = parts[1];

        // Converting start time to 12-hour format
        const start = new Date(`2000-01-01T${startTime}`);
        const startHours = start.getHours();
        const startMinutes = start.getMinutes();
        const formattedStartTime = `${startHours % 12 || 12}:${startMinutes
          .toString()
          .padStart(2, "0")}`; //removed this after tostring .padStart(2, "0")

        // Converting end time to 12-hour format
        const end = new Date(`2000-01-01T${endTime}`);
        const endHours = end.getHours();
        const endMinutes = end.getMinutes();
        const formattedEndTime = `${endHours % 12 || 12}:${endMinutes
          .toString()
          .padStart(2, "0")}`; //removed this after tostring .padStart(2, "0")

        // Determine AM/PM
        const amPm =
          startHours < 12 && watch("slot") === "morning" ? "AM" : "PM";

        return `${formattedStartTime} ${amPm} - ${formattedEndTime} ${amPm}`;
      });
      // console.log("modifiedtimings", modifiedTimings);
      if (data) {
        await setAvailableTime(modifiedTimings);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log('example');

  const formattedDate = (practicedate) => {
    const dob = moment(practicedate);
    const formattedDob = dob.format("YYYY");
    return formattedDob;
  };

  const handleMobileNumberChange = (e) => {
    console.log("327 line ", e.target.value);
    if (e.target.value) {
      setVerifyNumber(false);
      setMobileNumber(e.target.value);
    }
    // console.log("watch--2", watch("phonenumber"));
  };
  // console.log("watch--1", watch("speciality"));

  const handleExistingCustomer = async () => {
    console.log("xxxshshduhu");
    try {
      const res = await CheckingExistingCustomer(mobileNumber);
      const data = res.data;
      console.log(res);
      if (data?.uniqueId) {
        console.log("uniqueid", data.uniqueId);
        setVerifyNumber(true);
        setExistingCustomerId(data.uniqueId);
      } else {
        toast.info("Please Enter Your Registered Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Please enter number and verify or Something Error in Server",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  useEffect(() => {
    getEntireclinicdetailsbyId();
  }, []);

  // const { update } = useFieldArray({ name: 'plan' });
  useEffect(() => {
    // console.log("speciality", watch("speciality"));
    // console.log("speciality", token.uniqueId);
    const getPlans = async () => {
      if (watch("speciality")) {
        const { data } = await plansDropdownForAppointmentBooking(
          watch("speciality"),
          designation === "Customer" ? uniqueId : existingCustomerId
        );
        if (data) {
          console.log("Plans ", data);
          setAllPlans(data);
          setValue("plan", data[0]?.planUniqueId);
        }
      }
    };
    getPlans();
  }, [watch("speciality")]);
  console.log("watchplans", watch("plan"));

  useEffect(() => {
    // console.log("speciality", watch("plan"));
    // console.log("speciality", token.uniqueId);
    const getMembers = async () => {
      const { data } = await getCustomerMembersForPLan(
        watch("plan"),

        designation === "Customer" ? uniqueId : existingCustomerId
      );
      if (data) {
        setAllMembers(data);
        setValue("name", data[0]?.memberId);
      }
    };
    if (watch("plan")) {
      getMembers();
    }
  }, [watch("plan")]);

  async function verifyAndBook(val) {
    setVerifySubmit(true);
    // try {
    //   const { data } = await verifyBookAppointmentOtp(
    //     otp,
    //     formData.patientNumber
    //   );
    //   // console.log('data: ', );

    // if (data.type === "success") {
    console.log("formData: ", val);
    try {
      await bookAppointment(val);
      success();
      if (token.role === "Customer") {
        navigate("/user/customerappointmentsRequest");
      }
      if (token.designation === "Customer Support Executive") {
        navigate("/user/cseAppointments");
      }
    } catch (err) {
      console.log(err.message);
      error();
    }
    //   } else {
    //     errorOTP();
    //     setVerifySubmit(false)
    //   }
    // } catch (er) {
    //   console.log("er: ", er);
    //   errorOTP();
    // }
  }

  useEffect(() => {
    if (watch("slot") && date) {
      getTimeslots(watch("slot"));
    }
  }, [watch("slot"), date]);
  if (entireClinicDetails.length === 0) return <h1>Loading</h1>;
  return (
    <Box>
      <Card className="customerclinicviewmainbox">
        <Box className="clinicdetailsandformbox">
          <Box className="detailsbox">
            <Box className="clinicdetailsbox">
              {clinicimage && (
                <img
                  src={config.imageUrl + clinicimage[0]?.image}
                  alt="clinicpic"
                />
              )}
              <Box className="locationdetails">
                {initialvalue_clinicdetails && (
                  <Typography variant="h5">
                    {initialvalue_clinicdetails.clinicName}
                  </Typography>
                )}
                <Box>
                  <LocationOnIcon />
                  {initialvalue_clinicdetails && (
                    <Typography>
                      {initialvalue_clinicdetails.address}
                    </Typography>
                  )}
                </Box>
                <Typography variant="h6">View Location</Typography>
              </Box>
            </Box>
            <Box className="specialitiesdescbox">
              <Box className="specialitydesc">
                <Typography variant="h6">Our Speciality</Typography>
              </Box>
              <Box className="specilalitiesbtnbox">
                {initialvalue_clinicdetails &&
                  initialvalue_clinicdetails.clinicType.map(
                    (speciality, id) => {
                      return <button>{speciality}</button>;
                    }
                  )}
              </Box>
            </Box>

            {/*--------------------------------slider---------------------------- */}
            <Carousel
              className="carouselcontainer"
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
            >
              {clinicimage &&
                clinicimage.map((request, index) => {
                  return (
                    <Box className="imagebox" key={request.id}>
                      <img
                        src={config.imageUrl + request.image}
                        alt={`clinicImage${index}`}
                      />
                    </Box>
                  );
                })}
            </Carousel>
          </Box>

          <Box className="clinicformbox">
            {/* {formvalues ? (
              <Box>
                <div className="forms">
                  <Typography variant="h5">Appointment Verification</Typography>
                  <FormControl sx={{ width: "95%" }}>
                    <FormLabel className="formlabel">
                      Please fill the OTP received on your number.
                    </FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      sx={{ width: "95%" }}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <button
                     disabled={verifySubmit}
                      style={{ width: "95%",backgroundColor:verifySubmit?"#898989":"" }}
                      onClick={() => {
                        verifyAndBook();
                      }}
                    >
                      Verify
                    </button>
                  </FormControl>
                </div>
              </Box>
            ) :  */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h5">Book Your Appointment</Typography>
              <Box sx={{ width: "90%", display: "flex", gap: "10px" }}>
                {/* <FormControl sx={{ width: "90%" }}>
                    <FormLabel className="formlabel">Patient Name</FormLabel>
                    <TextField
                      className="inputbox"
                      size="small"
                      placeholder="Name"
                      {...register("name")}
                    />
                    <span className="error">{errors.name?.message}</span>
                  </FormControl> */}
                {designation !== "Customer" && (
                  <FormControl sx={{ width: "80%" }}>
                    <FormLabel className="formlabel">Mobile Number</FormLabel>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <OutlinedInput
                        className="inputbox"
                        size="small"
                        {...register("phonenumber")}
                        sx={{ paddingRight: "0px" }}
                        placeholder="Mobile Number"
                        onChange={(e) => handleMobileNumberChange(e)}
                        endAdornment={
                          !verifyNumber && (
                            <InputAdornment
                              position="end"
                              onClick={handleExistingCustomer}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  color: "#2d256c",
                                  backgroundColor: "#f5f3f7",
                                  padding: "5px",
                                }}
                              >
                                Verify
                              </span>
                            </InputAdornment>
                          )
                        }
                      />
                      {/* <TextField
                      className="inputbox"
                      size="small"
                      {...register("phonenumber")}
                      placeholder="Mobile Number"
                      onChange={handleMobileNumberChange}
                    />
                <span onClick={handleExistingCustomer} style={{fontSize:"12px",cursor:"pointer",color:"#2d256c",backgroundColor:"#f5f3f7",padding:"5px"}}>Verify</span> */}
                      {verifyNumber && <VerifiedIcon sx={{ color: "green" }} />}
                    </Box>
                    <span className="error">{errors.phonenumber?.message}</span>
                    {/* {!verifyNumber && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleExistingCustomer}
                      >
                        Verify
                      </span>
                    )} */}
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <FormLabel className="formlabel">Specialty</FormLabel>
                  <Select
                    // value={age}
                    {...register("speciality")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                    onFocus={() => {
                      if (!isOptionsFetched) {
                        fetchOptions();
                      }
                    }}
                  >
                    {specialityOptions.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.speciality?.message}</span>
                </FormControl>
              </Box>

              <FormControl sx={{ width: "90%" }}>
                <FormLabel className="formlabel">Plan</FormLabel>

                <Select
                  // value={age}
                  value={watch("plan") || ""}
                  {...register("plan")}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                >
                  {allPlans?.map((el, id) => {
                    return (
                      <MenuItem key={id} value={el.planUniqueId}>
                        {el?.plan}
                      </MenuItem>
                    );
                  })}
                </Select>
                <span className="error">{errors.plan?.message}</span>
              </FormControl>
              <FormControl sx={{ width: "90%" }}>
                <FormLabel className="formlabel">Member Name</FormLabel>
                <Select
                  value={watch("name") || ""}
                  {...register("name")}
                  placeholder="Select"
                  size="small"
                  className="inputbox"
                >
                  {allMembers?.map((el, id) => {
                    return (
                      <MenuItem key={id} value={el.memberId}>
                        {el?.memberName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <span className="error">{errors.name?.message}</span>
              </FormControl>
              <FormControl sx={{ width: "90%" }}>
                <FormLabel className="formlabel">Select Date</FormLabel>
                {/* <TextField {...register("datevalid",{valueAsDate: true})} className="inputbox" type="date" size="small" /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        {...register("datevalid", { valueAsDate: true })}
                        sx={{ backgroundColor: "#FFFFFF" }}
                      />
                    )}
                    onYearChange={onYearChangeHandler}
                    orientation={"portrait"}
                  />
                </LocalizationProvider>
                <span className="error">{errors.datevalid?.message}</span>
              </FormControl>

              <Box sx={{ width: "90%", display: "flex", gap: "10px" }}>
                <FormControl fullWidth>
                  <FormLabel className="formlabel">Morning/Evening</FormLabel>
                  {/* {console.log(watch("slot"))} */}
                  <Select
                    placeholder="Select"
                    {...register("slot")}
                    // onChange={(e) => {
                    //   // e.target.blur()
                    //   // getTimeslots(e.target.value);
                    //   setDailybasis(e.target.value);
                    //   if (e.target.value) {
                    //     errors.slot.message = null;
                    //   }
                    // }}
                    value={watch("slot") || ""}
                    size="small"
                    className="inputbox"
                    // defaultValue="morning"
                  >
                    <MenuItem value="morning">Morning</MenuItem>
                    <MenuItem value="evening">Evening</MenuItem>
                  </Select>

                  <span className="error">{errors.slot?.message}</span>

                  {/* {dailyBasis && availableTime.length===0 ? <span style={{color:"red",fontSize:"12px"}}>Timings not available Please Choose another Date or Check by changing slot</span>:""} */}
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel className="formlabel">Select Time</FormLabel>
                  <Select
                    {...register("time")}
                    placeholder="Select"
                    size="small"
                    className="inputbox"
                  >
                    <MenuItem value=""></MenuItem>
                    {availableTime.map((el, id) => {
                      return (
                        <MenuItem key={id} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <span className="error">{errors.time?.message}</span>
                </FormControl>
              </Box>
              <FormControl sx={{ width: "90%", mt: "10px" }}>
                <FormLabel className="formlabel">Remarks</FormLabel>
                <TextField
                  className="inputbox"
                  {...register("remarks")}
                  size="small"
                  placeholder="Please write the remarks here"
                />
              </FormControl>
              <button
                disabled={!verifyNumber || isSubmitting}
                style={{
                  cursor: isSubmitting
                    ? "not-allowed"
                    : verifyNumber
                    ? "pointer"
                    : "default",
                  backgroundColor: verifyNumber ? "" : "#989898",
                }}
                type="submit"
              >
                Send Appointment Request
              </button>
            </form>

            {/* } */}
          </Box>
        </Box>

        <Box className="doctorscarouselbox">
          <Typography variant="h5">Our Experts</Typography>

          {/*--------------------------------Doctors Carousel---------------------------- */}

          <Box>
            <Carousel
              className="carouselcontainer"
              swipeable={true}
              draggable={true}
              showDots={true}
              gap="10px"
              // autoPlay="false"
              autoPlaySpeed={1000}
              keyBoardControl={true}
              containerClass="react-multi-carousel-list"
              responsive={responsive}
              itemClass="item"
            >
              {entireClinicDetails &&
                entireClinicDetails.initialValues_DoctorDetails.map(
                  (el, id) => {
                    return (
                      <Card
                        sx={{
                          backgroundColor: "#F5F3F7",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        variant="outlined"
                      >
                        <img
                          style={{ height: "120px" }}
                          src={config.imageUrl + el.image}
                          alt="doctorimage"
                        />
                        <Box>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 600, color: "#2D256C" }}
                            >
                              Dr. {el.name}
                            </Typography>
                            <Typography
                              sx={{ color: "#000000", fontSize: "12px" }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.degree}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#959595" }}>
                              {formattedDate(el.practiceSince)}{" "}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ color: "#D83F87", fontSize: "12px" }}
                            >
                              Specialization
                            </Typography>
                            <Typography
                              sx={{ color: "#444444", fontWeight: 600 }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.specialisation}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    );
                  }
                )}
            </Carousel>
          </Box>
        </Box>

        {/* ----------------------------Clinicdetails Box Mobile View------------------------- */}

        <Box className="mobileviewdetailsbox">
          <Box className="clinicimagesbox">
            <Box className="clinicmainimage">
              {clinicimage && (
                <img
                  src={config.imageUrl + clinicimage[0]?.image}
                  alt="clinicpic"
                />
              )}
            </Box>

            <Box className="subimages">
              {clinicimage &&
                clinicimage.slice(0, 3).map((request, index) => {
                  return (
                    <Box className="imagebox" key={request.id}>
                      <img
                        src={config.imageUrl + request.image}
                        alt={`clinicImage${index}`}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
          <Box className="clinicdetails">
            <Box className="clinicnameAndAddress">
              <Box className="details">
                <Box>
                  {initialvalue_clinicdetails && (
                    <Typography variant="h5">
                      {initialvalue_clinicdetails.clinicName}
                    </Typography>
                  )}
                  <Box className="address">
                    <LocationOnIcon />
                    {initialvalue_clinicdetails && (
                      <Typography>
                        {initialvalue_clinicdetails.address}
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="h6">View Location</Typography>
                </Box>
                <Box className="bookanappointmentbtn">
                  {/* <button onClick={handleOpen}>Book Appointment</button> */}
                  <BookAppointmentModal />
                </Box>
              </Box>
              <Box className="specilalitiesbtnbox">
                {initialvalue_clinicdetails &&
                  initialvalue_clinicdetails.clinicType.map(
                    (speciality, id) => {
                      return <button>{speciality}</button>;
                    }
                  )}
              </Box>
            </Box>
          </Box>
          <Box className="doctordetailsbox">
            <Typography variant="h5">Our Experts</Typography>
            <Box className="doctordetailscard">
              {entireClinicDetails &&
                entireClinicDetails.initialValues_DoctorDetails.map(
                  (el, id) => {
                    return (
                      <Card
                        sx={{
                          backgroundColor: "#FFF6FF",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        variant="outlined"
                      >
                        <img
                          style={{ height: "100px" }}
                          src={config.imageUrl + el.image}
                          alt="doctorimage"
                        />
                        <Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color: "#2D256C",
                                fontSize: "12px",
                              }}
                            >
                              Dr. {el.name}
                            </Typography>
                            <Typography
                              sx={{ color: "#000000", fontSize: "12px" }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.degree}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ color: "#959595", fontSize: "10px" }}
                            >
                              {formattedDate(el.practiceSince)}{" "}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ color: "#D83F87", fontSize: "12px" }}
                            >
                              Specialization
                            </Typography>
                            <Typography
                              sx={{
                                color: "#444444",
                                fontWeight: 600,
                                fontSize: "12px",
                              }}
                            >
                              {el.specialisationAndDegree.map(
                                (specialization, id) => {
                                  return <>{specialization.specialisation}</>;
                                }
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    );
                  }
                )}
            </Box>
          </Box>
        </Box>
      </Card>
      <Box className="customerfooter" sx={{ textAlign: "right" }}>
        <Typography>Finding it hard to book an appointment,</Typography>
        <Box>
          <Typography>
            connect with us at
            <a href={`tel:8040091997`}> +91 8040091997</a> |
            <a href={`tel:7300002911`}> +91 7300002911</a> | or mail us at
            <a href={`mailto:support@tron.health`}> support@tron.health.</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerClinicView;
