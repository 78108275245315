import http from "./httpServices";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token = JSON.parse(tokens)?.token;
const id = JSON.parse(tokens)?.uniqueId;
const username = JSON.parse(tokens)?.username;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};



export function AddClinicSpeciality(data){
    return http.post(config.apiUrl + "addClinicSpecialities", data, head);
}
export function initiatePayment(data){
    return http.post(config.apiUrl + "initiatePayment", data, head);
}
export function getClinicSpecialities(){
  return http.get(config.apiUrl + "getClinicSpeciality", head);
}
export function AddClinicComplaints(data){
  return http.post(config.apiUrl + "addClinicComplaint", data, head);
}
export function EditClinicComplaint(data){
  return http.post(config.apiUrl + "editClinicComplaint", data, head);
}
export function AddClinicProcedure(data){
  return http.post(config.apiUrl + "addClinicProcedure", data, head);
}
export function EditClinicProcedure(data){
  return http.post(config.apiUrl + "editClinicProcedure", data, head);
}
export function getClinicComplaints(speciality){
  return http.get(config.apiUrl + "getClinicComplaints/"+speciality,head);
}
export function getClinicProcedure(speciality){
  return http.get(config.apiUrl + "getClinicProcedures/"+speciality,head);
}
export function addCoupon(data){
  return http.post(config.apiUrl + "addCoupon", data, head);
}
export function getCoupons(uniqueId){
  return http.get(config.apiUrl + "getCouponList/"+uniqueId,head);
}
export function getIndividualCouponDetails(id){
  return http.get(config.apiUrl +"getCouponDetails/"+id,head);
}
export function activateOrDeactivateCoupons(data){
  return http.post(config.apiUrl + "activateOrDeactivateCoupon", data, head);
}
export function addPlanAndPricing(data){
  return http.post(config.apiUrl + "addPlanAndPricing", data, head);
}
export function getPlansAndPricingList(uniqueId){
  return http.get(config.apiUrl + "getPlanAndPricingList/"+uniqueId,head);
}
export function getIndividualPlanDetailsById(id){
  return http.get(config.apiUrl + "getPlanAndPricing/"+id,head);
}

export function activateOrDeactivatePlan(data){
  return http.post(config.apiUrl + "activateOrDeactivatePlan", data, head); 
}
export function getCouponListForCustomer(){
  return http.get(config.apiUrl + "myCoupons/"+id, head);
}
export function getCouponDetails(id){
  return http.get(config.apiUrl + "getCouponDetails/"+id, head);
}
export function getCouponHistoryDetails(couponcode){
  return http.get(config.apiUrl + "getCouponHistoryFromCouponPerspective/"+couponcode, head);
}

export function getCouponDetailsForCustomers(couponcode){
  return http.get(config.apiUrl + "getCouponHistoryFromCustomerPerspective/"+couponcode+"/"+id, head);
}

export function getPlanHistoryDetails(planname){
  return http.get(config.apiUrl + "getPlanHistoryFromCouponPerspective/"+planname, head);
}
export function addAppointmentCharges(data){
  return http.post(config.apiUrl + "addAppointmentCharges",data, head);
}

export function getPlanDetailsForCustomer(planname){
  return http.get(config.apiUrl + "getPlanHistoryFromCustomerPerspective/"+planname+"/"+id, head);
}

export function getPlansListForCustomer(){
  return http.get(config.apiUrl + "myActivePlansForCustomers/"+id, head);
}
export function billingAndSettlementListForClinic(){
  return http.get(config.apiUrl + "billingAndSettlementListForClinic/"+id, head);
}
export function billingAndSettlementListForAdmin(){
  return http.get(config.apiUrl + "billingAndSettlementListForAdmin", head);
}
export function billingAndSettlementStatusForClinic(val){
  return http.get(config.apiUrl + "billingAndSettlementStatusForClinic/"+val, head);
}

export function addRetailPlanAndPricing(data){
  return http.post(config.apiUrl + "addRetailPlanAndPricing",data, head);
}

export function getRetailPlansAndPricingList(){
  return http.get(config.apiUrl + "getRetailPlanAndPricingList",head);
}

export function getRetailPlanAndPricing(id){
  return http.get(config.apiUrl + "getRetailPlanAndPricing/"+id, head);
}

export function activateOrDeactivateRetailPlan(id){
  return http.get(config.apiUrl + "activateOrDEactivateRetailPlan/"+id, head); 
}

export function getRetailPlanAndPricingCustomerList(){
  return http.get(config.apiUrl + "getRetailPlanAndPricingCustomerList", head); 
}
export function processAppointmentPayment(id){
  return http.get(config.apiUrl + "processAppointmentPayment/"+id, head); 
}
export function checkPaymentStatusForAppointmentId(id){
  return http.get(config.apiUrl + "checkPaymentStatusForAppointmentId/"+id, head); 
}
export function retryAppointmentChargesPayment(id){
  return http.get(config.apiUrl + "retryAppointmentChargesPayment/"+id, head); 
}
