import { Autocomplete, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { CssTextField } from '../../styles/textFields';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { toast } from 'react-toastify';


const FamilyInfo = ({familyInfo, setFamilyInfo, setSelected,width, planType}) => {

  
  const error = () => {
    toast.error("Please Fill All The Details", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const val= familyInfo.filter(item=>!item.relation||!item.name||!item.dob)
  console.log('val: ', val);


    return (  <Card sx={{overflow:"scroll"}}>
        <Grid container spacing={2}>

            {familyInfo.map((item, index)=><React.Fragment key={index}>
  <Grid item xs={1} lg={0.5}> 
  <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold" fontSize="16px">
  {index+1 }.    </Typography>
  
  </Grid>
        <Grid item  xs={10} lg={3.5}>
        <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold" fontSize="16px">
        Relation
      </Typography>
      <Autocomplete
            disableClearable
            disabled={item.relation==="Self" ? true : false}
            options={["Father" ,"Mother", "Sister", "Brother", "Son", "Daughter","Wife"]}
            onChange={(event, value) => {
                const data=[...familyInfo];
                        data[index].relation=value;
                        setFamilyInfo(data)
                        
            }}
            value={item.relation}
            fullWidth
            size="small"
            renderInput={(params) => (
              <CssTextField
                // disabled={id && !edit ? true : false}
                {...params}
                fullWidth
                placeholder="Relation"
                variant="outlined"
                type="text"
              />
            )}
          />
        </Grid>
        {width<786 &&<Grid item xs={1}></Grid>}
        <Grid item xs={6} lg={3}> <Typography mt={1} mb={1} variant="subtitle2"
        fontSize="16px" fontWeight="bold">
        Name
      </Typography>
      <CssTextField
      fullWidth
          size="small"
          placeholder="Name"
          variant="outlined"
          type="text"
          onChange={(e)=>{const data=[...familyInfo];
            data[index].name=e.target.value;
            setFamilyInfo(data)
            }}
            value={item?.name}
        />
    
      </Grid>
        <Grid item xs={5} lg={3}>
        <Typography
                        mt={1}
                        mb={1}
                        variant="subtitle2"
                        fontSize="16px"
                        fontWeight="bold"
                      >
                        Date Of Birth
                      </Typography>
                      <MobileDatePicker
                        onChange={(newValue) => {
                        const data=[...familyInfo];

                        data[index].dob=newValue;
                        setFamilyInfo(data)
                        }}
                        value={item?.dob}
                        // maxDate={new Date()}
                        maxDate={ new Date().setFullYear(new Date().getFullYear() - 18)}
                        renderInput={(params) => (
                          <CssTextField
                            focused
                            {...params}
                            placeholder="DateOf Birth"
                            size="small"
                            // name={item.value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
        </Grid>
        {index+1>3?<Grid item xs={1} lg={0.5}>
        <DeleteSweepIcon
                sx={{
                  height: 30,
                  width: 30,
                  cursor: "pointer",
                  marginTop:"40px" 
                }}
                onClick={() => {
                  const data = [...familyInfo];
                  
                  data.splice(index, 1);
                  setFamilyInfo(data);
                }}
              />
            
            </Grid>:<Grid item xs={1} lg={0.5}></Grid>}
       {planType==="Family" && index+1===familyInfo.length?<Grid item xs={12} lg={1.5}><Button
          variant="contained"
          type="submit"
          
          sx={{
            backgroundColor: "#2d256c!important",
            fontSize: "12px",

            padding: "8px 15px",
            marginLeft: "10px",
            marginTop:"40px"  
          }}
          onClick={()=>{
            const val=[...familyInfo]
            val.push( {relation:"", name:"", dob:""})
            setFamilyInfo(val)
        }}
        >
          Add New
        </Button></Grid>:<Grid item xs={12} lg={1.5}></Grid>}
        
            </React.Fragment>)}

        </Grid>
<br/>
<br/>
<div style={{display:"flex", alignContent:"space-between"}}>

        <Button
          variant="contained"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",

          }}
          onClick={
            ()=>
            { setSelected("Plans")
        }
        }
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "15em",
            backgroundColor: "#2d256c!important",
            fontSize: "12px",
            padding: "8px 15px",
            marginLeft: "10px",

          }}
          onClick={
            ()=>
            {val.length===0 && setSelected("Health Info")
            val.length>0&&error();
        }
        }
        >
          Next
        </Button>
</div>
 
    </Card>);
}
 
export default FamilyInfo;