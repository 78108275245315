import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { getCustomerList } from "../../services/customerOnboarding";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const columns = [
  { id: "s.no", label: "S.No", minWidth: 50 },
  { id: "firstname", label: "FirstName", minWidth: 80 },
  {
    id: "lastname",
    label: "Last Name",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "uniqueId",
    label: "UniqueId",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "mobilenumber",
    label: "Mobile Number",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "dob",
    label: "D.O.B",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
const CustomerListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customerData, setCustomerData] = useState([]);
  const [customerViewDetails, setCustomerViewDetails] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {permissionsData}=useContext(Permissions);
  let customerPermissionsModule=permissionsData?.filter(item=>item.module==="customer");
  let customerPermissions=customerPermissionsModule[0]?.permissions;
  let AddPermission = customerPermissions?.find((item) => item.action === "Add")?.permission;
  let ViewPermission=customerPermissions?.find((item) => item.action === "View")?.permission;
  let EditPermission = customerPermissions?.find((item) => item.action === "Edit")?.permission;
  let ListPermission = customerPermissions?.find((item) => item.action === "List")?.permission;
  // let ListPermission=false;
  console.log(customerPermissions);

  const handleOpen = (item) => {
    console.log(item);
    setCustomerViewDetails(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const getTheCustomerList = async () => {
  //   try {
  //     let response = await getCustomerList();
  //     const data = response.data;
  //     data.sort((a, b) => a.firstName.localeCompare(b.firstName));
  //     data.forEach((el)=>{
  //       if(el.dob){
  //         el.dob=convertDateofBirth(el.dob)
  //       }
  //     })
  //     function convertDateofBirth(dateofbirth){
  //       const [year, month, day] = dateofbirth.split("-");
  //       return `${day}-${month}-${year}`;
  //     }
  //     console.log(data);
  //     setCustomerData(data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  const getTheCustomerList = async () => {
    try {
      let response = await getCustomerList();
      const data = response.data;
  
      // Sort by createdAt in descending order, and then by firstName in alphabetical order
      data.sort((a, b) => {
        const createdAtComparison = new Date(b.createdAt) - new Date(a.createdAt);
        if (createdAtComparison === 0) {
          return a.firstName.localeCompare(b.firstName);
        }
        return createdAtComparison;
      });
  
      // Convert date of birth format
      data.forEach((el) => {
        if (el.dob) {
          el.dob = convertDateofBirth(el.dob);
        }
      });
  
      console.log(data);
      setCustomerData(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  function convertDateofBirth(dateofbirth) {
    const [year, month, day] = dateofbirth.split("-");
    return `${day}-${month}-${year}`;
  }
  
  useEffect(() => {
    getTheCustomerList();
  }, []);

  useEffect(() => {
    console.log("changes", searchValue);
    if (searchValue.trim() === "") {
      // Clear search term and fetch the entire data again
      console.log("searchvalue is empty");
      getTheCustomerList();
    } else {
      const filteredData = customerData.filter(
        (customer) =>
          customer.firstName.toLowerCase().includes(searchValue) ||
          customer.lastName.toLowerCase().includes(searchValue) ||
          customer.mobileNumber.toLowerCase().includes(searchValue) ||
          customer.email.toLowerCase().includes(searchValue)
      );
      console.log("filterdata", filteredData);
      setCustomerData(filteredData);
    }
  }, [searchValue]);
  return (
    <div>
      <Box>
        <Grid container spacing={1}>
          <Grid item md={8} sm={7} xs={12}>
            <Typography variant="h5" fontSize="17px">Customer Listing</Typography>
          </Grid>
          <Grid item md={4} sm={7} xs={12}>
        <Stack direction="row" spacing={1} >
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 250,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search google maps" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "5px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          {AddPermission && <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "12px",
              padding: "8px 15px",
              borderRadius:"3px",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            onClick={() => navigate("/user/addcustomer")}
          >
            Add Customer
          </Button>}
        </Stack>
        </Grid>
        </Grid>
      </Box>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          mt: "10px",
          backgroundColor: "#f7f7fc",
        }}
        className="scrollbar"
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#2D256C",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {ListPermission && <TableBody>
              {customerData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, id) => {
                  return (
                    <TableRow
                      sx={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                      key={item.id}
                      tabIndex={-1}
                    >
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {id + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.firstName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.lastName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.uniqueId}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.mobileNumber}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#444444", fontSize: "14px" }}
                      >
                        {item.dob}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#D83F87" }}>
                        {ViewPermission && <VisibilityIcon sx={{color:"#2d256c"}} onClick={() => handleOpen(item)} />}{" "}
                        {EditPermission && <EditIcon sx={{color:"#2d256c"}}
                          onClick={() =>
                            navigate(`/user/editcustomer/${item.id}`)
                          }
                        />}{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={customerData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: "#FFFFFF" }}
        />
      </Paper>

      {/* ---------------------------Modal for customer view------------------------ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center", fontSize: "14px" }}
            variant="h5"
          >
            Customer Details
          </Typography>
          <Divider />
          <Box>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>First Name:</Typography>
              <Typography>{customerViewDetails.firstName}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>Last Name:</Typography>
              <Typography>{customerViewDetails.lastName}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>Email:</Typography>
              <Typography>{customerViewDetails.email}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>UniqueId:</Typography>
              <Typography>{customerViewDetails.uniqueId}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                Mobile Number:
              </Typography>
              <Typography>{customerViewDetails.mobileNumber}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                Date Of Birth:
              </Typography>
              <Typography>{customerViewDetails.dob}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>Created At:</Typography>
              <Typography>{customerViewDetails.createdAt}</Typography>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomerListing;
