import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import * as yup from "yup";
import { getClinicByPincode, getClinicDetailsByPincodeAndClinicNameInTextfield, getPossibleClinicNames } from "../../services/customerOnboarding";
import clinicdemo from "../../assets/clinicdemopic.svg";
import kmimage from "../../assets/km'slogo.svg";
import Loader from "../Loader";
import config from "../../config.json";
import { useNavigate } from "react-router-dom";

const Schema = yup.object().shape({
  pincode: yup
    .number()
    .positive()
    .integer()
    .min(100000, "Please Enter Correct Pincode")
    .max(999999, "Pincode is Too Long"),
  clinicName: yup.string()
});


const CseBookingAppointment = () => {
  const [clinicData, setClinicData] = useState([]);
  const [clinicNameSuggestions,setClinicNameSuggestions]=useState([])
  const [loading,setLoading]=useState(false);
  const navigate=useNavigate("")

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      pincode: "",
      clinicName: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    // console.log("values",values)
    if(values.pincode || values.clinicName){
      setLoading(true)
      try {
        const res = await getClinicDetailsByPincodeAndClinicNameInTextfield(values);
        const data=res.data;
        if (data) {
          // console.log("data: ", data);
          setClinicData(data)
          setLoading(false)
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    
    if(values.pincode && values.clinicName){
      console.log("values",values)
      setLoading(true)
      try {
        const res = await getClinicDetailsByPincodeAndClinicNameInTextfield(values);
        const data=res.data;
        if (data) {
          console.log("clinicnamesdata: ", data);
          setClinicData(data)
          setLoading(false)
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  async function fetchClinicNameSuggestions(value) {
    // Make an API call to get clinic name suggestions based on the value
    console.log("target",value)
    const payload={
      "clinicName":value
    }
    try {
      // Replace this with your actual API endpoint
      const res = await getPossibleClinicNames(payload);
      const data=res.data;
      setClinicNameSuggestions(data)
      
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setFieldValue("clinicName", suggestion);
    setClinicNameSuggestions([]); // Clear suggestions
  };
  return (
    <div>
      <Typography sx={{fontSize:"17px", fontWeight:"600",mb:"10px",color:"#d83f87"}} >New Appointment</Typography>
      <form onSubmit={handleSubmit}>
        {/* <Box display="flex" gap="10px"> */}
        <Grid container spacing={1}>
          {/* <Box> */}
          <Grid item md={3} xs={4} sm={3}>
            <TextField
              size="small"
              placeholder="Pincode"
              onChange={handleChange}
              onBlur={handleBlur}
              name="pincode"
              id="pincode"
              sx={{backgroundColor:"#ffffff"}}
              value={values.pincode}
            />
            <Box>
            {errors.pincode && touched.pincode && (
              <span style={{ color: "red",fontSize:"10px" }}>{errors.pincode}</span>
              )}
            </Box>
            </Grid>
          {/* </Box> */}
          {/* <Box> */}
          <Grid item md={3} xs={6} sm={4}>
       
            <Paper
            className="Clinicnametextinputbox"
              
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(e)=>{
                  handleChange(e)
                  fetchClinicNameSuggestions(e.target.value);
                }}
                onBlur={handleBlur}
                name="clinicName"
                value={values.clinicName}
                id="clinicName"
              />
              <IconButton type="button" sx={{ p: "5px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            {errors.clinicName && touched.clinicName && (
              <span style={{ color: "red",fontSize:"10px" }}>{errors.clinicName}</span>
            )}
             <Box
             className="clinicsuggestionsbox"
              sx={{
                display: clinicNameSuggestions.length>0 ? "block" : "none",
              }}
            >
              {clinicNameSuggestions.map((suggestion) => (
                <div
                  key={suggestion}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ padding: "8px", cursor: "pointer" }}
                >
                  {suggestion}
                </div>
              ))}
            </Box>
            </Grid>
          {/* </Box> */}
          <Grid item sm={12} xs={12} md={3}>

          
          <Button
            sx={{
              backgroundColor: "#2d256c",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "capitalize",
              fontSize: "12px",
              padding: "8px 15px",
              height:"40px",
              borderRadius: "3px",
              "&:hover": { backgroundColor: "#2d256c!important" }, 
            }}
            type="submit"
          >
            Submit
          </Button>
          </Grid>
          </Grid>
        {/* </Box> */}

      </form>

      <Box className="cliniclistmainbox">
          <Box className="cseclinicsbox">
            {clinicData?.map((el, id) => {
              return (
                <Box className="carddata">
                  <Box>
                    {el.imageUrl === null ? (
                      <img src={clinicdemo} alt="clinicpic" />
                    ) : (
                      <img
                        style={{ width: "350px", height: "200px" }}
                        src={config.imageUrl + el.imageUrl}
                        alt="clinicpic"
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h5">{el.clinicName}</Typography>
                    <Typography variant="h6">{el.clinicType}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Box className="kmsbox">
                        {el.distance !== 0 ? (
                          <>
                            <img src={kmimage} alt="kmsimage" />
                            <br />
                            <Typography>
                              {Math.round(parseInt(el.distance))} Kms
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Typography className="address" variant="h6">
                        {el.address}
                      </Typography>
                    </Box>
                    <Box className="specialitybox">
                      {el?.clinicType?.split(",").map((item, index) => (
                        <Typography key={index}>{item}</Typography>
                      ))}
                    </Box>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/user/customerclinicView/${el.uniqueId}`);
                      }}
                    >
                      Book An Appointment
                    </button>
                  </Box>
                </Box>
              );
            })}
            {loading ? <><Loader/></>:""}
          </Box>
        </Box>
    </div>
  );
};

export default CseBookingAppointment;
