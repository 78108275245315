import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import {
  addRetailPlanAndPricing,
  getClinicProcedure,
  getClinicSpecialities,
  getRetailPlanAndPricing,
} from "../../services/PlansAndProcedure";

const AddandEditRetailplan = () => {
  const [specialities, setSpecialities] = useState([]);
  const [prevSpecialities, setPrevSpecialities] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [fetching, setFetching] = useState(Boolean(id));
  const token = JSON.parse(localStorage.getItem("token"));
  const uniqueId = token?.uniqueId;
  const navigate = useNavigate();
  //   console.log("specialityoptions", specialityOptions);

  const schema = yup
    .object({
      planname: yup.string().required("This Field is required"),
      specialities: yup
        .array()
        .min(1, "Select at least one option")
        .of(
          yup
            .string()
            .required("Option is required")
            .oneOf(specialities, "Invalid option")
        ),
      description: yup.string().required("This Field is required"),
      procedures: yup
        .array()
        .min(1, "Select at least one option")
        .of(
          yup
            .string()
            .required("Option is required")
            .oneOf(procedures, "Invalid option")
        ),
      plantenure: yup.number().required("You must select an option!"),
      treatmentcover: yup
        .number()
        .positive("Must be a Positive number")
        .integer("Must be an integer")
        .required("This Field is required"),
      discountpercent: yup
        .number()
        .min(0, "Percentage cannot be negative")
        .max(100, "Number must be less than or equal to 100")
        .required("Number is required"),
      planType: yup.string().oneOf(["Individual", "Family"]).required(),
      recommended: yup.boolean().required("This Field is required"),
      noOfMembers: yup
        .number()
        .positive("Must be a positive number")
        .integer("Must be an integer")
        .required("Number is required"),
      plandiscountprice: yup
        .number()
        .positive("Must be a Positive number")
        .integer("Must be an integer")
        .required("This Field is required"),
      // plandisplayprice: yup
      //   .number()
      //   .positive("Must be a Positive number")
      //   .integer("Must be an integer")
      //   .required("This Field is required"),
      // plandisplaydiscountpercent: yup
      //   .number()
      //   .min(0, "Percentage cannot be negative")
      //   .max(100, "Number must be less than or equal to 100")
      //   .required("Number is required"),
    })
    .required();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      planname: "",
      specialities: [],
      description: "",
      procedures: [],
      treatmentcover: "",
      discountpercent: "",
      planType: "",
      noOfMembers: "",
      plandiscountprice: "",
      plandisplayprice: "",
      plandisplaydiscountpercent: "",
      plantenure: "",
      recommended: false,
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    if (id) {
      const formdata = {
        id: id,
        planName: values.planname,
        specialities: values.specialities,
        description: values.description,
        procedures: values.procedures,
        treatmentCover: values.treatmentcover,
        discountPercent: values.discountpercent,
        planType: values.planType,
        minimumSize: values.noOfMembers,
        planDiscountPrice: values.plandiscountprice,
        plantenure: values.plantenure,
        recommended: values.recommended,
        // planDisplayPrice: values.plandisplayprice,
        // planDisplayDiscountPercent: values.plandisplaydiscountpercent,
      };
      try {
        setIsSubmitting(true);
        //api calling here
        const res = await addRetailPlanAndPricing(formdata);

        if (res) {
          toast.success("Plan Updated Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          actions.resetForm();
          navigate("/user/retailPlansAndPricing");
        } else {
          setIsSubmitting(false);
        }
      } catch (error) {
        toast.error("Please Check your server", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmitting(false);
      }
    } else {
      if (values) {
        console.log("values", values);
        const formdata = {
          planName: values.planname,
          specialities: values.specialities,
          description: values.description,
          procedures: values.procedures,
          treatmentCover: values.treatmentcover,
          discountPercent: values.discountpercent,
          planType: values.planType,
          minimumSize: values.noOfMembers,
          planDiscountPrice: values.plandiscountprice,
          plantenure: values.plantenure,
          recommended: values.recommended,
          // planDisplayPrice: values.plandisplayprice,
          // planDisplayDiscountPercent: values.plandisplaydiscountpercent,
        };
        console.log("formdata", formdata);
        try {
          setIsSubmitting(true)
          //api calling here
          const res = await addRetailPlanAndPricing(formdata);

          if (res) {
            toast.success("Plan Added Sucessfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            actions.resetForm();
            navigate("/user/retailPlansAndPricing");
          }else{
            setIsSubmitting(false)
          }
        } catch (error) {
          toast.error("Please Check your server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      }
    }
  }

  const handleSetInitialProcedures = async (specialities) => {
    // console.log("line180",specialities)
    let tempProcedures = ["All"]; //A small change here on date 24-11-2023
    for (let i = 0; i < specialities.length; i++) {
      const res = await getClinicProcedure(specialities[i]);
      const resultProcedures = res.data.map((el) => el.procedureName);
      tempProcedures = [...tempProcedures, ...resultProcedures];
    }
    setProcedures(tempProcedures);
  };

  useEffect(() => {
    const getUniqueCustomerDetails = async () => {
      try {
        setFetching(true);
        let res = await getRetailPlanAndPricing(id);
        let data = res.data;
        if (data) {
          setFetching(false);
          // getProcedures({specialities:data.specialities})
          setFieldValue("planname", data.planName, true);
          setFieldValue("specialities", data.specialities, true);
          setPrevSpecialities(data.specialities);
          setFieldValue("procedures", data.procedures, true);
          // setProcedures(data.procedures)
          handleSetInitialProcedures(data.specialities);
          setFieldValue("description", data.description, true);
          setFieldValue("treatmentcover", data.treatmentCover, true);
          setFieldValue("discountpercent", data.discountPercent, true);
          setFieldValue("plandiscountprice", data.planDiscountPrice, true);
          // setFieldValue("plandisplayprice", data.planDisplayPrice, true);
          setFieldValue("planType", data.planType, true);
          setFieldValue("noOfMembers", data.minimumSize, true);
          setFieldValue("plantenure", data.plantenure, true);
          setFieldValue("recommended", data.recommended, true);
          // setFieldValue(
          //   "plandisplaydiscountpercent",
          //   data.planDisplayDiscountPercent,
          //   true
          // );
        }
        console.log("customer Data by id", data);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (id) {
      getUniqueCustomerDetails();
    }

    const getSpecialities = async () => {
      try {
        let response = await getClinicSpecialities();
        let data = response.data?.map((el) => el.speciality);
        // console.log(data);
        setSpecialities(["All", ...data]);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSpecialities();
  }, [id]);

  // remove procedures
  const RemoveProcedures = async (prevSpecialities, currentSpecialities) => {
    if (currentSpecialities.length === 0) {
      setPrevSpecialities([]);
      setProcedures([]);
      return;
    }
    let removeSpeciality;
    for (let i = 0; i < prevSpecialities.length; i++) {
      if (!currentSpecialities.includes(prevSpecialities[i])) {
        removeSpeciality = prevSpecialities[i];
      }
    }
    const res = await getClinicProcedure(removeSpeciality);
    const proceduresToRemove = res.data.map((el, id) => el.procedureName);
    let tempProcedures = procedures;
    tempProcedures = tempProcedures.filter((item) => {
      if (!proceduresToRemove.includes(item)) {
        return item;
      }
    });
    console.log("line263", tempProcedures);
    setProcedures(tempProcedures);
    setPrevSpecialities([...currentSpecialities]);
    return;
  };

  useEffect(() => {
    const getProcedures = async () => {
      try {
        let response = await getClinicProcedure(specialityOptions);
        //   console.log('ggg',response.data);
        let tempProcedures = [...procedures];
        if (tempProcedures.length === 0) {
          tempProcedures.push("All");
        }
        tempProcedures = [
          ...tempProcedures,
          ...response.data?.map((el) => el.procedureName),
        ];
        setProcedures(tempProcedures);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (specialityOptions) {
      getProcedures();
    }
    if (specialityOptions === "") {
      setProcedures([]);
    }
  }, [specialityOptions]);
  // console.log(fetching);

  if (fetching)
    <>
      <Loader />
    </>;
  // console.log(customerDataById);
  return (
    <div>
      <Typography variant="h5" fontSize="17px">
        {id ? "Edit Retail Plan" : "Add Retail Plan"}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Card>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Name
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.planname}
                  placeholder="Plan Name"
                  name="planname"
                  id="planname"
                  error={touched.planname && Boolean(errors.planname)}
                  helperText={touched.planname && errors.planname}
                />
              </FormControl>
              {/* {errors.planname && touched.planname && (
                <span style={{ color: "red" }}>{errors.planname}</span>
              )} */}
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Type
                </FormLabel>
                <Select
                  value={values.planType}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "Individual") {
                      setFieldValue("noOfMembers", 1);
                    }
                    if (e.target.value === "Family") {
                      setFieldValue("noOfMembers", 3);
                    }
                  }}
                  onBlur={handleBlur}
                  name="planType"
                  id="planType"
                  error={touched.planType && Boolean(errors.planType)}
                  // helperText={touched.planType && errors.planType}
                  size="small"
                >
                  <MenuItem value="Individual">Individual</MenuItem>
                  <MenuItem value="Family">Family</MenuItem>
                </Select>
              </FormControl>
              {errors.planType && touched.planType && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  {errors.planType}
                </span>
              )}
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  No. Of Members
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={(event) => {
                    handleChange(event);
                    if (values.planType === "Individual") {
                      setFieldValue("noOfMembers", 1);
                    }
                  }}
                  onBlur={handleBlur}
                  size="small"
                  value={values.noOfMembers}
                  placeholder="No. Of Members"
                  name="noOfMembers"
                  id="noOfMembers"
                  type="number"
                  inputProps={{
                    min: values.planType === "Family" ? 3 : 1,
                  }}
                  error={touched.noOfMembers && Boolean(errors.noOfMembers)}
                  helperText={touched.noOfMembers && errors.noOfMembers}
                  readOnly={values.planType === "Individual"}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt="5px"
          >
            <Grid item xs={8} md={8}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Description
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.description}
                  placeholder="Description"
                  name="description"
                  id="description"
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Specialities
                </FormLabel>
                <Autocomplete
                  multiple
                  id="specialities"
                  name="specialities"
                  options={specialities}
                  getOptionLabel={(option) => option}
                  value={values.specialities}
                  onChange={async (event, value) => {
                    setFieldValue("specialities", value);
                    // console.log("line316",value);
                    console.log(
                      "line362",
                      value,
                      "prevspeciality",
                      prevSpecialities
                    );

                    // remove functionality
                    if (prevSpecialities.length > value.length) {
                      RemoveProcedures(prevSpecialities, value);
                      return;
                    }
                    setPrevSpecialities([...value]);

                    // add procedures functionality

                    if (value.length >= 1) {
                      setSpecialityOptions(value[value.length - 1]);
                    } else {
                      setSpecialityOptions("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={
                        touched.specialities && Boolean(errors.specialities)
                      }
                      helperText={touched.specialities && errors.specialities}
                    />
                  )}
                />
              </FormControl>
              {/* {errors.specialities && touched.specialities && (
                <span style={{ color: "red" }}>{errors.specialities}</span>
              )} */}
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt="5px"
          >
            <Grid item xs={12} md={12}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Procedures
                </FormLabel>
                <Autocomplete
                  multiple
                  id="procedures"
                  name="procedures"
                  options={procedures}
                  getOptionLabel={(option) => option}
                  value={values.procedures}
                  onChange={(event, value) => {
                    setFieldValue("procedures", value);
                    // console.log(value.length);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={touched.procedures && Boolean(errors.procedures)}
                      helperText={touched.procedures && errors.procedures}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt="5px"
          >
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Tenure
                </FormLabel>
                <Select
                  value={values.plantenure}
                  onChange={(e) => {
                    console.log("handleChange triggered", e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  name="plantenure"
                  id="plantenure"
                  // error={touched.plantenure && Boolean(errors.plantenure)}
                  // helperText={touched.plantenure && errors.plantenure}
                  size="small"
                >
                  {[1, 2, 3, 4].map((el) => {
                    return <MenuItem value={el}>{el}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              {errors.plantenure && touched.plantenure && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  {errors.plantenure}
                </span>
              )}
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Treatment Cover
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.treatmentcover}
                  placeholder="Plan Price"
                  name="treatmentcover"
                  id="treatmentcover"
                  type="number"
                  error={
                    touched.treatmentcover && Boolean(errors.treatmentcover)
                  }
                  helperText={touched.treatmentcover && errors.treatmentcover}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Discount Percentage
                </FormLabel>
                <OutlinedInput
                  id="discountpercent"
                  name="discountpercent"
                  className="inputbox"
                  placeholder="Discount Percentage"
                  onChange={handleChange}
                  value={values.discountpercent}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  type="number"
                  size="small"
                  error={
                    touched.discountpercent && Boolean(errors.discountpercent)
                  }
                />
              </FormControl>
              {errors.discountpercent && touched.discountpercent && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  {errors.discountpercent}
                </span>
              )}
            </Grid>
          </Grid>
          <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.recommended}
                  checked={values.recommended}
                  name="recommended"
                  id="recommended"
                />
              }
              label="Recommended"
            />
          </FormGroup>
        </Card>
        <Typography variant="h5" fontSize="17px">
          Plan Basic Details
        </Typography>
        <Card>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt="5px"
          >
            <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Monthly Price
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.plandiscountprice}
                  placeholder="Discounted Price(Actual Price)"
                  name="plandiscountprice"
                  id="plandiscountprice"
                  type="number"
                  error={
                    touched.plandiscountprice &&
                    Boolean(errors.plandiscountprice)
                  }
                  helperText={
                    touched.plandiscountprice && errors.plandiscountprice
                  }
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={4} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Display Price
                </FormLabel>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.plandisplayprice}
                  placeholder="Plan Display Price"
                  name="plandisplayprice"
                  id="plandisplayprice"
                  type="number"
                  error={
                    touched.plandisplayprice && Boolean(errors.plandisplayprice)
                  }
                  helperText={
                    touched.plandisplayprice && errors.plandisplayprice
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#2D256C",
                  }}
                  className="formlabel"
                >
                  Plan Display Discount Percent
                </FormLabel>
                <OutlinedInput
                  id="plandisplaydiscountpercent"
                  name="plandisplaydiscountpercent"
                  className="inputbox"
                  placeholder="Plan Discount Percent"
                  onChange={handleChange}
                  value={values.plandisplaydiscountpercent}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  type="number"
                  size="small"
                  error={
                    touched.plandisplaydiscountpercent &&
                    Boolean(errors.plandisplaydiscountpercent)
                  }
                />
              </FormControl>
              {errors.plandisplaydiscountpercent &&
                touched.plandisplaydiscountpercent && (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    {errors.plandisplaydiscountpercent}
                  </span>
                )}
            </Grid> */}
          </Grid>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={isSubmitting}
            sx={{
              backgroundColor: isSubmitting ? "#898989" : "#2d256c!important",
              textAlign: "center",
              color: "#FFFFFF",
              mt: "10px",
              textTransform: "capitalize",
              fontSize: "12px",
              padding: "8px 15px",
              borderRadius: "3px",
              "&:hover": { backgroundColor: "#2d256c!important" },
            }}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default AddandEditRetailplan;
