import http from "./httpServices";
import config from "../config.json";

const tokens = localStorage.getItem("token");
const token = JSON.parse(tokens)?.token;
const id = JSON.parse(tokens)?.uniqueId;
const username = JSON.parse(tokens)?.username;

const head = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

const head_formData = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "access-control-allow-origin": "*",
    Authorization: `Bearer ${token}`,
  },
};

export function createClinicDetails(data) {
  return http.post(config.apiUrl + "clinicdetails", data, head);
}
export function activateOrDeactivate(data) {
  return http.post(config.apiUrl + "deactivateorreacivate", data, head);
}

export function uploadClinicDocuments(data) {
  return http.post(config.apiUrl + "clinicImage", data, head_formData);
}

export function getClinicList() {
  return http.get(config.apiUrl + "clinicdetails", head);
}
export function getParticularClinicData(val) {
  return http.get(config.apiUrl + "clinicdetailsuniqueid/" + val, head);
}
export function getClinicDataDateRange(from, to) {
  return http.get(
    config.apiUrl + "clinicdetailswithindaterange/" + from + "/" + to,
    head
  );
}
export function retriggerMail(id) {
  return http.get(config.apiUrl + "clinicagreementmailtrigger/" + id, head);
}
export function getClinicDataDateRangeParticular(from, to) {
  return http.get(
    config.apiUrl +
      "clinicdetailsofuserwithindaterange/" +
      username +
      "/" +
      from +
      "/" +
      to,
    head
  );
}
export function getClinicStaff(staff) {
  return http.get(config.apiUrl + "clinicdetailsofuser/" + staff, head);
}
export function getCitiesList(staff) {
  return http.get(config.apiUrl + "getListOfClinicCities", head);
}
export function getIpAddress() {
  return http.get(config.apiUrl + "getIpAddress", head);
}

export function postTermsAgree(data) {
  return http.post(config.apiUrl + "clinicAgreement", data);
}

export function changeAssignedPerson(data) {
  return http.post(config.apiUrl + "clinicAssign", data, head);
}

export function getAppointmentRequestData(uniqueId) {
  return http.get(
    config.apiUrl + "getClinicAppointmentRequests/" + uniqueId + "/Pending",
    head
  );
}

export function PostAcceptOrRejectAppointment(data) {
  return http.post(config.apiUrl + "acceptOrRejectAppointment", data, head);
}

export function getClinicUpcomingAppointmentRequests(uniqueId) {
  return http.get(
    config.apiUrl + "getClinicUpcommingAppointmentRequests/" + uniqueId,
    head
  );
}

export function getClinicAppointrequestAccepted(uniqueId) {
  return http.get(
    config.apiUrl + "getClinicAppointmentRequests/" + uniqueId + "/Accepted",
    head
  );
}

export function getClinicAppointrequestCancelled(uniqueId) {
  return http.get(
    config.apiUrl + "getClinicAppointmentRequests/" + uniqueId + "/Rejected",
    head
  );
}

export function getClinicTodayAppointmentRequests(uniqueId) {
  return http.get(
    config.apiUrl + "getTodayClinicAppointmentRequests/" + uniqueId,
    head
  );
}

export function getUpcomingReqWithDate(entiredate, uniqueId) {
  return http.get(
    config.apiUrl +
      "getClinicUpcommingAppointmentRequestsOfDate/" +
      entiredate +
      "/" +
      uniqueId,
    head
  );
}

export function getCancelledReqWithDate(entiredate, uniqueId) {
  return http.get(
    config.apiUrl +
      "getClinicAppointmentRequestsOfDate/Rejected/" +
      entiredate +
      "/" +
      uniqueId,
    head
  );
}

export function getCustomerAppointmentDetailsAndHistory(userId) {
  return http.get(
    config.apiUrl + "getCustomerAppointmentDetailsAndHistory/" + userId,
    head
  );
}
export function getCouponDetails(data) {
  return http.get(config.apiUrl + "getCouponDetailsByCouponCode/" + data, head);
}
export function verificationReports(data) {
  return http.post(config.apiUrl + "tronVerificationReports", data, head);
}

export function getPlanDetails(uniqueId, speciality, member) {
  return http.get(
    config.apiUrl +
      "myPlansForSpeciality/" +
      uniqueId +
      "/" +
      speciality +
      "/" +
      member,
    head
  );
}

export function ChangingPassword(data) {
  return http.post(config.apiUrl + "passwordReset", data, head);
}

export function getClinicPastAppointments(uniqueId) {
  return http.get(
    config.apiUrl + "getClinicAppointmentRequests/" + uniqueId + "/Completed",
    head
  );
}

export function getClinicPastRequestWithDate(entiredate, uniqueId) {
  return http.get(
    config.apiUrl +
      "getClinicAppointmentRequestsOfDate/Completed/" +
      entiredate +
      "/" +
      uniqueId,
    head
  );
}

export function getPastAppointmentDetails(appointmentId) {
  return http.get(
    config.apiUrl + "getPastAppointmentDetails/" + appointmentId,
    head
  );
}
export function billingAndSettlementListForAdminSearch(data) {
  return http.post(
    config.apiUrl + "billingAndSettlementListForAdminSearch",
    data,
    head
  );
}

export function getCitiesListForDropdown() {
  return http.get(config.apiUrl + "getCitiesList"); // earlier api here getCitiesListForDropdown
}

export function getAppointmentPaymentStatus(data) {
  return http.get(config.apiUrl + "getAppointmentPaymentStatus/" + data, head);
}
export function sendClinicLoginCredsMail(data) {
  return http.get(config.apiUrl + "sendClinicLoginCredsMail/" + data, head);
}

export function GetAppointmentCharges(id) {
  return http.get(config.apiUrl + "getAppointmentCharges/" + id, head);
}

export function getPreviousAppointments(clinicUniqueId, appointmentId) {
  return http.get(
    config.apiUrl +
      "getClinicCustomerPerviousAppointment/" +
      clinicUniqueId +
      "/" +
      appointmentId,
    head
  );
}
