import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Navbar from "./Navbar";
import "../styles/contact.scss";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import contactImage from "../assets/Contact-2.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { contact } from "../services/Contact";
import { toast } from "react-toastify";
import Footer from "./Footer";

const Contact = () => {
  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([5-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  const schema = yup
    .object({
      fullname: yup.string().min(3, "This Field is Required").required(),
      clinicname: yup.string().required(),
      enquiry: yup.string().required(),
      email: yup.string().email().trim().min(3, "Enter Email").required(),
      phone: yup
        .string()
        .matches(phoneRegex, "Invalid Phone Number")
        .required("Phone Number is required"),
    })
    .required();

  const generateCaptcha = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomString = "";
    for (let i = 0; i < 6; i++) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    // setCaptchaText(randomString);
    return randomString;
  };
  const [captchaText, setCaptchaText] = useState(generateCaptcha());
  const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit,setIsSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, event) => {
    setLoading(true);
    const userInput = event.target.captcha.value;
    if (userInput === captchaText) {
      console.log("CAPTCHA passed!");
      try {
        setIsSubmit(true)
        console.log(data);
        let res=await contact(data);
        setCaptchaError(false);
        if(res){
          reset();
          toast.success("Form Submitted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmit(false)
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong", error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false)
      }

      setTimeout(() => {
        setCaptchaText(generateCaptcha());
        setLoading(false);
      }, 2000);
    } else {
      console.log("CAPTCHA failed!");
      setLoading(false);
      setCaptchaText(generateCaptcha()); // Regenerate a new CAPTCHA
      setCaptchaError(true);
      setIsSubmit(false)
      toast.error("Invalid Captcha", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="contactcontent">
      <Navbar />
      <Box className="contactmainbody">
        <Box className="headingbox">
          <h2>Reach Out to Us</h2>
          <p>
            Whether you have questions about our services, want to explore
            partnership opportunities, or need assistance, we're here to help.
          </p>
        </Box>
        <Grid className="contentbody1" container spacing={3}>
          <Grid item sm={12} xs={12} md={6} lg={6}>
            <Box className="leftbox">
              <img src={contactImage} alt="health-care-services" />
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} md={6} lg={6}>
            <Box className="rightbox">
              <h3 style={{ color: "black" }}>Let's get you started</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="formlabel">Pincode</FormLabel>
                      <TextField
                        fullwidth
                        placeholder="Full Name"
                        size="small"
                        {...register("fullname")}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.fullname?.message}
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormControl fullwidth sx={{ width: "100%" }}>
                      <FormLabel className="formlabel">Clinic Name</FormLabel>
                      <TextField
                        fullWidth
                        placeholder="Clinic Name"
                        size="small"
                        {...register("clinicname")}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.clinicname?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt="5px">
                  <Grid item sm={6} xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="formlabel">Email Address</FormLabel>
                      <TextField
                        placeholder="Email Address"
                        size="small"
                        {...register("email")}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.email?.message}
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="formlabel">Phone Number</FormLabel>
                      <TextField
                        type="number"
                        placeholder="Phone Number"
                        size="small"
                        {...register("phone")}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.phone?.message}
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="formlabel">Enquiry Message</FormLabel>
                      <TextField
                        type="text"
                        placeholder="Enquiry Message"
                        size="small"
                        multiline
                        maxRows={4}              
                        {...register("enquiry")}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.enquiry?.message}
                      </span>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl className="captchamainbox">
                  <FormLabel className="formlabel">
                    Enter the text you see in the image:
                  </FormLabel>
                  <Box className="captchabox">
                    <Typography>{captchaText}</Typography>
                  </Box>
                  <TextField
                    type="text"
                    id="captcha"
                    name="captcha"
                    size="small"
                  />
                  {captchaError ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Invalid Captcha
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
                <Box>
                  <button disabled={isSubmit} style={{backgroundColor:isSubmit?"#898989":"#1a0655",cursor:isSubmit?"default":"pointer"}} type="submit">
                    {loading ? <CircularProgress size={20} /> : "Submit"}
                  </button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
        <Grid className="contentbody2" container spacing={3}>
          <Grid item sm={6} xs={12} md={6} lg={6}>
            <Box className="leftside">
              <h2>Contact Us</h2>
              <p>
                Join forces with us to redefine OPD healthcare and create a
                paradigm shift.
              </p>

              <Box className="contactdetails">
                <PinDropIcon sx={{ color: "#D83F87" }} />
                <p>G1-275, EPIP Sitapura, Jaipur - 30202</p>
              </Box>
              <Box className="contactdetails">
                <LocalPhoneIcon sx={{ color: "#D83F87" }} />
                <p>+91-7300002911</p>
              </Box>
              <Box className="contactdetails">
                <LocalPhoneIcon sx={{ color: "#D83F87" }} />
                <p>+91-8040091997</p>
              </Box>
              <Box className="contactdetails">
                <EmailIcon sx={{ color: "#D83F87" }} />
                <p>support@tron.health</p>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} md={6} lg={6}>
            <Box className="rightbox">
              <img src={contactImage} alt="health-care-services" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default Contact;
