import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { CssTextField } from "../../styles/textFields";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  createStaffDetails,
  getParticularStaff,
  getTeamLeadList,
} from "./../../services/staffOnboarding";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { useContext } from "react";
import { Permissions } from "../Context/PermissionsContext";

const StaffOnboardingForm = () => {
  const [isSubmitting,setIsSubmitting]=useState(false);
  const success = () => {
    toast.success("Data Submitted Sucessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const error = () => {
    toast.error("Some Error Occoured, please try again later", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const navigate = useNavigate();

  const tokens = localStorage.getItem("token");
  const uniqueId = JSON.parse(tokens)?.uniqueId;
  const role = JSON.parse(tokens)?.role;
  const createdBy = JSON.parse(tokens)?.username;

  const { id } = useParams();

  const initialValues_staffRegistration = {
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    altEmail: "",
    mobile: "",
    altMobile: "",
    designation: "",
    teamLeadAssigned: "",
    baseCity: "",
    areaOfOperation: [],
    dateob: "",
    dateoj: "",
    password: "",
    uniqueId: "",
  };

  const [newValues_staffRegistration, setNewValues_staffRegistration] =
    useState(initialValues_staffRegistration);

  const form_staffRegistration = [
    { lable: "First Name", type: "text", placeholder: "", value: "firstName" },
    { lable: "Last Name", type: "text", placeholder: "", value: "lastName" },
    {
      lable: "Display Name",
      type: "text",
      placeholder: "",
      value: "displayName",
    },
    { lable: "Email", type: "text", placeholder: "", value: "email" },
    { lable: "Alt Email", type: "text", placeholder: "", value: "altEmail" },
    { lable: "Mobile", type: "text", placeholder: "", value: "mobile" },
    { lable: "Alt Mobile", type: "text", placeholder: "", value: "altMobile" },
    { lable: "Base City", type: "text", placeholder: "", value: "baseCity" },
    {
      lable: "Area Of Operations (Please Press Enter After Every Entry)",
      type: "dropdown",
      placeholder: "",
      value: "areaOfOperation",
      options: [],
    },
    { lable: "Date Of Birth", type: "date", placeholder: "", value: "dateob" },
    {
      lable: "Date Of Joining",
      type: "date",
      placeholder: "",
      value: "dateoj",
    },
    { lable: "Password", type: "text", placeholder: "", value: "password" },
    {
      lable: "Designation",
      type: "dropdown",
      placeholder: "",
      value: "designation",
      options: [
        "Team Lead",
        "Onboarding Executive",
        "Customer Support Executive",
        "Admin",
      ],
    },
  ];
  //! {lable: "", type: "", placeholder: "",value: "" },
  const validationSchema_staffRegistration = yup.object({
    firstName: yup.string().required("This Field Is Required"),
    lastName: yup.string().required("This Field Is Required"),
    displayName: yup.string().required("This Field Is Required"),
    password: yup.string().required("This Field Is Required"),
    baseCity: yup.string().required("This Field Is Required"),
    dateoj: yup.string().required("This Field Is Required"),
    dateob: yup.string().required("This Field Is Required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("This Field Is Required"),
    mobile: yup
      .string()
      .matches(/[6-9]{1}[0-9 ]{3}[0-9 ]{3}[0-9]{3}/, {
        message: "Invalid Mobile number",
        excludeEmptyString: false,
      })
      .required("This Field Is Required"),
    designation: yup.string().required("This Field Is Required"),
    areaOfOperation: yup
      .array()
      .min(1, "Should Have Atleast One Entry")
      .required("This Field Is Required"),
  });

  const submitValues = async (values) => {
    setSubmitting(true);
    const val = values;
    val.createdBy = createdBy;
    val.teamLeadAssigned = val?.teamLeadAssigned?.uniqueId;
    // val.role=role;
    val.uniqueId = uniqueId;
    try {
      setIsSubmitting(true)
      const { data } = await createStaffDetails(val);
      if (data === "success") {
        setEdit(false);
        success();
        setSubmitting(false);
        navigate("/user/staffList");

        formik_staffRegistration.resetForm();
      }
    } catch (err) {
      setSubmitting(false);
      setIsSubmitting(false); //isSubmitting is for button disable
      error();
    }
  };

  const formik_staffRegistration = useFormik({
    enableReinitialize: true,
    initialValues: id
      ? newValues_staffRegistration
      : initialValues_staffRegistration,
    validationSchema: validationSchema_staffRegistration,
    onSubmit: (values) => {
      submitValues(values);
    },
  });

  const [edit, setEdit] = useState(false);
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { permissionsData } = useContext(Permissions);
  let staffPermissionsModule = permissionsData?.filter(
    (item) => item.module === "staff"
  );
  let staffPermissions = staffPermissionsModule[0]?.permissions;
  let EditPermission = staffPermissions?.find(
    (item) => item.action === "Edit"
  )?.permission;
  // let EditPermission=false
  // console.log(EditPermission);

  useEffect(() => {
    const getTeamList = async () => {
      const { data } = await getTeamLeadList();
      console.log("data: ", data);
      if (data) {
        setTeamLeadList(data);
      }
    };

    getTeamList();
  }, []);

  useEffect(() => {
    let list;
    const getTeamList = async () => {
      const { data } = await getTeamLeadList();
      console.log("data: ", data);
      if (data) {
        list = data;
        getValues();
      }
    };

    async function getValues() {
      if (!id) return;
      const { data } = await getParticularStaff(id);
      console.log("data: ", data);
      if (data) {
        console.log("data: ", data.teamLeadAssigned);
        const vals = list.filter(
          (item) => item.uniqueId === data.teamLeadAssigned
        );
        console.log("vals: ", vals);
        const values = data;
        values.teamLeadAssigned = vals[0];
        console.log("values: ", values);

        setNewValues_staffRegistration(values);
      }
    }
    getTeamList();
  }, [id]);

  console.log(formik_staffRegistration.values, newValues_staffRegistration);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        open={submitting}
        onClose={() => {
          // setSubmitting(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Loader />
        </Box>
      </Modal>

      <div className="flexAlagAlag">
        <Typography variant="h5" fontSize="17px">
          Staff Registration
        </Typography>
        {id && EditPermission && (
          <Edit
            onClick={() => {
              setEdit(true);
            }}
          ></Edit>
        )}
      </div>

      <form
        onSubmit={formik_staffRegistration.handleSubmit}
        id="staffRegistration"
      >
        <Card>
          <Grid container spacing={2}>
            {form_staffRegistration.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  {item.lable}
                </Typography>
                {item.type === "text" ? (
                  <CssTextField
                    size="small"
                    fullWidth
                    disabled={id && !edit ? true : false}
                    placeholder={item.lable}
                    variant="outlined"
                    type="text"
                    name={item.value}
                    onChange={formik_staffRegistration.handleChange}
                    value={formik_staffRegistration?.values[item.value]}
                    error={
                      formik_staffRegistration.touched[item.value] &&
                      Boolean(formik_staffRegistration.errors[item.value])
                    }
                    helperText={
                      formik_staffRegistration.touched[item.value] &&
                      formik_staffRegistration.errors[item.value]
                    }
                  />
                ) : item.type === "dropdown" ? (
                  <Autocomplete
                    multiple={item.value === "areaOfOperation" ? true : false}
                    freeSolo={item.value === "areaOfOperation" ? true : false}
                    disableClearable
                    disabled={id && !edit ? true : false}
                    options={item?.options}
                    name={item?.value}
                    onChange={(event, value) => {
                      formik_staffRegistration.setFieldValue(item.value, value);
                    }}
                    value={
                      formik_staffRegistration?.values[item.value]
                        ? formik_staffRegistration?.values[item.value]
                        : null
                    }
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <CssTextField
                        disabled={id && !edit ? true : false}
                        {...params}
                        fullWidth
                        placeholder={item.lable}
                        variant="outlined"
                        type="text"
                        error={
                          formik_staffRegistration.touched[item.value] &&
                          Boolean(formik_staffRegistration.errors[item.value])
                        }
                        helperText={
                          formik_staffRegistration.touched[item.value] &&
                          formik_staffRegistration.errors[item.value]
                        }
                      />
                    )}
                  />
                ) : item.type === "date" ? (
                  <MobileDatePicker
                    focused
                    inputFormat="dd/MM/yyyy"
                    name={item.value}
                    disabled={id && !edit ? true : false}
                    onChange={(value) =>
                      formik_staffRegistration.setFieldValue(
                        item.value,
                        value,
                        true
                      )
                    }
                    value={formik_staffRegistration.values[item.value]}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <CssTextField
                        disabled={id && !edit ? true : false}
                        {...params}
                        size="small"
                        name={item.value}
                        fullWidth
                        variant="outlined"
                        placeholder={item.lable}
                        error={
                          formik_staffRegistration.touched[item.value] &&
                          Boolean(formik_staffRegistration.errors[item.value])
                        }
                        helperText={
                          formik_staffRegistration.touched[item.value] &&
                          formik_staffRegistration.errors[item.value]
                        }
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            ))}
            {formik_staffRegistration.values.designation ===
              "Onboarding Executive" && (
              <Grid item xs={4}>
                <Typography mt={1} mb={1} variant="subtitle2" fontWeight="bold">
                  Team Lead Assigned
                </Typography>
                <Autocomplete
                  disableClearable
                  disabled={id && !edit ? true : false}
                  options={teamLeadList}
                  getOptionLabel={(option) => option?.displayName}
                  name="teamLeadAssigned"
                  onChange={(event, value) => {
                    formik_staffRegistration.setFieldValue(
                      "teamLeadAssigned",
                      value
                    );
                  }}
                  value={
                    formik_staffRegistration?.values.teamLeadAssigned
                      ? formik_staffRegistration?.values.teamLeadAssigned
                      : null
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <CssTextField
                      disabled={id && !edit ? true : false}
                      {...params}
                      fullWidth
                      placeholder="Enter the Team Lead Name"
                      variant="outlined"
                      type="text"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Card>
        <div className=" buttonNext">
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            sx={{
              "&:hover": { backgroundColor: isSubmitting?"#898989":"#2d256c!important" },
              padding: "8px 15px",
              fontSize: "12px",
            }}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default StaffOnboardingForm;
