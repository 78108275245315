import axios from 'axios';

// axios.defaults.headers.common['x-auth-token']=localStorage.getItem('token');

function backToLogin() {
    window.localStorage.removeItem("token");
      window.location.href = "/login";
  }

axios.interceptors.response.use(null,error=>{
    const expectedError=error.response && error.response.status>=400 && error.response.status<=500;
    
    console.log('error.response: ', error.response);
    if (error.response && error.response.status === 401) {

    if (window.confirm("Token Expired, Please Login Again!") === true) {
        
        console.log('"You pressed OK!": ', "You pressed OK!");
        backToLogin()
    } else {
        console.log('"You canceled!": ', "You canceled!");
        backToLogin()
      } 
}
    if(expectedError){
        console.log("Error",error);
        return Promise.reject(error);
    }
  
   
    }
);

export default{
    get:axios.get,
    post:axios.post,
    put:axios.put,
    delete:axios.delete
};